.profile-review-div {
  color: white;
  padding: vw(94px) vw(10px) vw(31px) vw(10px);
  text-align: center;
  @media only screen and (max-width: 900px) {
    padding: 0px;
    .profile-review-img {
      width: 134px;
    }
    h2 {
      font-size: 20px;
      line-height: 1.3;
      margin-bottom: 20px;
      margin-top: 15px;
      padding: 0px;
    }
    h5 {
      font-size: 13px;
      line-height: 1.3;
    }
    span {
      font-size: 13px;
      line-height: 1.3;
    }
  }
  @media only screen and (max-width: 575px) {
    padding: 0px;
    .profile-review-img {
      width: 100px;
    }
    h2 {
      font-size: 15px;
    }
    h5 {
      font-size: 11px;
    }
    span {
      font-size: 13px;
    }
  }
}
.profile-review-img {
  width: vw(134px);
  height: auto;
}
.pr-roboto-font-40-medium {
  font-size: vw(40px);
  line-height: vw(48.76px);
  @include fontRobotoMedium;
  padding-top: vw(34px);
  margin-bottom: vw(58px);
}

.pr-roboto-font-40-medium--invest {
  margin-bottom: vw(37px);
}

.pr-roboto-font-18-regular {
  font-size: vw(18px);
  line-height: vw(21.94px);
  @include fontRobotoRegular;
  margin-bottom: vw(2px);
}

.pr-roboto-font-18-regular--invest {
  margin-bottom: vw(21px);
}

.profile-review-btn {
  // width: vw(247px);
  // height: vw(60px);
  padding: vw(13px) vw(25px);

  background: $vl-web-app-pages-text-color;
  border: 1px solid #feffff;
  box-sizing: border-box;
  border-radius: vw(59px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: vw(31px);
  margin-bottom: vw(34px);

  font-size: vw(28px);
  line-height: vw(33px);
  @include fontRobotoRegular;
}
.pr-do-it-text {
  font-size: vw(18px);
  line-height: vw(27px);
  color: rgba(254, 255, 255, 0.48);
  @include fontRobotoBold;
  text-decoration: underline;
  text-decoration-style: dashed;
}
