.google-signup-div {
  width: 605px;
  min-height: 525px;
  background: rgba(62, 58, 101, 0.71);
  border-radius: 17px;
  transform: rotate(-0.11deg);
  padding: 42px 50px 46px 64px;
  margin-top: 119px;
  position: relative;

  &::after {
    position: absolute;
    content: "";
    width: 83%;
    height: 1px;
    background: rgba(255, 255, 255, 0.21);
    top: 140px;
    left: 47px;
    @media only screen and (max-width: 767px) {
      content: "";
      left: 30px;
    }
    @media only screen and (max-width: 435px) {
      content: "";
      left: 15px;
    }
    @media only screen and (max-width: 525px) {
      content: "";
      top: 100px;
    }
    @media only screen and (max-width: 353px) {
      content: "";
      top: 90px;
    }
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    max-width: 90%;
    min-height: auto;
    margin-top: 50px;
    padding: 30px;
  }
  @media only screen and (max-width: 435px) {
    padding: 30px 15px;
  }
}

.google-signup-div-alredy-signup {
  width: 605px;
  // min-height: vw(525.79px);
  background: rgba(62, 58, 101, 0.71);
  border-radius: 17px;
  transform: rotate(-0.11deg);
  padding: 42px 50px 46px 64px;
  margin-top: 119px;
  position: relative;

  &::after {
    position: absolute;
    content: "";
    width: 83%;
    height: 1px;
    background: rgba(255, 255, 255, 0.21);
    top: 140px;
    left: 47px;
    @media only screen and (max-width: 767px) {
      content: "";
      left: 30px;
    }
    @media only screen and (max-width: 435px) {
      content: "";
      left: 15px;
    }
    @media only screen and (max-width: 525px) {
      content: "";
      top: 100px;
    }
    @media only screen and (max-width: 353px) {
      content: "";
      top: 90px;
    }
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    max-width: 90%;
    min-height: auto;
    margin-top: 50px;
    padding: 30px;
  }
  @media only screen and (max-width: 435px) {
    padding: 30px 15px;
  }
}

.google-signup-div {
  .profile-img {
    border-radius: 50%;
    width: 70px;
    height: auto;
    margin-right: 47px;
    @media only screen and (max-width: 525px) {
      width: 50px;
      margin-right: 20px;
    }
    @media only screen and (max-width: 353px) {
      margin-right: 10px;
      width: 40px;
    }
  }
}

.email-text {
  font-size: 18px;
  line-height: 1.3;
  margin-bottom: 0px;
  @media only screen and (max-width: 435px) {
    font-size: 15px;
  }
  @media only screen and (max-width: 353px) {
    font-size: 14px;
  }
}
.gmail-signup-btn-div {
  padding: 76px 0px 20px 0;
  justify-content: space-between;
  @media only screen and (max-width: 767px) {
    padding-top: 50px;
  }
  @media only screen and (max-width: 353px) {
    padding-top: 30px;
    padding-bottom: 0px;
  }
}

.calender-icon {
  position: absolute;
  //left: vw(800px);
  //top: vw(485px);
  top: 20px;
  right: 22px;
  font-size: 20px;
}
.checkbox-form {
  margin-top: 90px;
  .vl-checkbox {
    margin-bottom: 10px;
    .checkbox-textlist {
      font-size: 15px;
      line-height: 18px;
      padding-left: 17px;
      color: #ffff;
      @media only screen and (max-width: 435px) {
        font-size: 13px;
      }
    }
  }
  @media only screen and (max-width: 525px) {
    margin-top: 65px;
  }
  @media only screen and (max-width: 353px) {
    margin-top: 45px;
  }
}
