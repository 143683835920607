.general-profile-main-div {
  padding: vw(88px) vw(135px) vw(89px) vw(83px);
  .general-profile-title {
    font-size: 30px;
    font-weight: 800;
    line-height: 1.3;
    color: rgba(0, 0, 0, 0.78);
    margin: 0;
    text-transform: capitalize;
    @media only screen and (max-width: 575px) {
      font-size: 20px;
    }
  }
  .profilelist-top {
    .vl-profile-page-image-upload-div {
      .upload-img__mainBlock {
        @media only screen and (max-width: 900px) {
          width: 80%;
          height: auto;
          margin-bottom: 0px;
          .upload-img__img-block {
            margin: 0px;
          }
        }
        @media only screen and (max-width: 767px) {
          width: 20%;
        }
        @media only screen and (max-width: 575px) {
          width: 40%;
          margin-top: 20px;
          margin: auto;
        }
      }
    }
    .general-profile-text-colm-block {
      .vl-profile-page-phone-number-block {
        line-height: 1.5;
        .vl-form-input {
          .react-phone-input-block-row {
            .react-phone-input-block {
              .react-tel-input {
                .form-control {
                  height: auto !important;
                  background-color: transparent;
                }
              }
            }
          }
        }
        .react-phone-input-block {
          height: auto;
          input {
            font-size: 15px;
            line-height: 1.5;
          }
        }
      }
      .vl-interested-in-block {
        position: relative;
        .save-btn-wrap {
          position: absolute;
          top: -3px;
          right: 0;
          background-color: #292950;
          color: #fff;
          padding: 3px 10px;
          border-radius: 4px;
          @media screen and (max-width: 900px) {
            top: 3px;
            right: 17px;
          }
          @media screen and (max-width: 768px) {
            top: -3px;
            right: 14px;
          }
          @media screen and (max-width: 767px) {
            top: -3px;
            right: 0;
          }
          .save-btn-text {
            margin-left: 7px;
            @media only screen and (max-width: 768px) {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 767px) {
    padding: 15px;
  }
}
.general-profile-inner {
  @media only screen and (max-width: 767px) {
    margin-top: 10px;
  }
}
.general-profile-text-colm-block-text1 {
  font-size: vw(14px);
  line-height: vw(17px);
  @include fontMontserratMedium;
  margin-bottom: vw(11px);
  color: rgba(39, 45, 46, 0.38);
  @media only screen and (max-width: 900px) {
    font-size: 15px;
    line-height: 1.5;
  }
  @media only screen and (max-width: 900px) {
    font-size: 12px;
  }
}
.general-profile-text-colm-block-text2 {
  font-size: vw(15px);
  line-height: vw(22px);
  @include fontMontserratBold;
  margin-bottom: vw(24px);
  color: rgba(50, 50, 119, 0.74);
  @media only screen and (max-width: 900px) {
    font-size: 16px;
    line-height: 1.5;
  }
  @media only screen and (max-width: 900px) {
    font-size: 13px;
  }
}
.revenue_title {
  margin-bottom: 0.5729166667vw !important;
}
.general-profile-img-block {
  width: vw(263px);
  height: vw(261px);
  border-radius: vw(8px);
  background: #ffffff;
  img {
    width: 100%;
    height: 100%;
    border-radius: inherit;
  }
}
.general-profile-img-block-text {
  font-size: vw(14px);
  line-height: vw(17px);
  @include fontMontserratMedium;
  margin-bottom: 0;
  padding-top: vw(12px);
  padding-left: vw(50px);
}
.gp-bio-div {
  padding-top: vw(67px);
  padding-right: vw(50px);

  button {
    font-size: vw(24px);
    color: rgba(39, 45, 46, 0.38);
    @media only screen and (max-width: 900px) {
      font-size: 15px;
      line-height: 1.5;
    }
  }
}
.general-profile-bio-main{
  margin-bottom: vw(11px);
  .vl-profile-page-button{
    .vl-display-loader-div{
      svg{
        height: 15px;
        width: 15px;

      }
    }
  }
}
.general-profile-bio-text {
  font-size: vw(24px);
  line-height: 0;
  @include fontMontserratMedium;
  color: rgba(39, 45, 46, 0.38);
  // margin-bottom: vw(11px);
  @media only screen and (max-width: 900px) {
    font-size: 15px;
    line-height: 0;
  }
  button {
    padding: 0;
    i {
      font-size: 12px;
      padding-left: 10px;
    }
  }
}
.general-profile-bio-desc {
  font-size: vw(18px);
  line-height: vw(21px);
  @include fontMontserratRegular;
  color: rgba(41, 41, 80, 1);
  margin-bottom: vw(31px);
  padding-top: vw(38px);
  @media only screen and (max-width: 900px) {
    font-size: 15px;
    line-height: 1.5;
    margin-bottom: 20px;
  }
}
.preferences-area {
  .preferences-block-div {
    min-height: vw(451px);
    background: rgba(50, 50, 119, 0.02);
    border-radius: vw(7px);
    width: vw(812px);
    padding: 40px;
    margin-right: vw(8px);
    @media only screen and (max-width: 900px) {
      width: 100%;
      padding: 40px;
      margin-right: 0px;
    }
    @media only screen and (max-width: 575px) {
      padding: 20px 15px;
    }
    .preferences-block-title {
      font-size: vw(25px);
      line-height: vw(30px);
      @include fontMontserratBold;
      color: #292950;
      margin-bottom: vw(59px);
      @media only screen and (max-width: 900px) {
        font-size: 15px;
        line-height: 1.5;
      }
    }
    .preferences-block-btn-outer-div {
      padding-bottom: vw(22px);
      .preferences-block-text-1 {
        font-size: vw(18px);
        line-height: vw(21px);
        @include fontMontserratBold;
        color: rgba(0, 0, 0, 0.46);
        margin-bottom: vw(22px);
        @media only screen and (max-width: 900px) {
          font-size: 13px;
          line-height: 1.3;
          margin-bottom: 20px;
        }
        @media only screen and (max-width: 757px) {
          margin-bottom: 10px;
        }
      }
      &:last-child {
        padding-bottom: 0;
      }
      .preferences-block-btn-div {
        // width: vw(290px);
        min-height: vw(65px);
        background: #292950;
        border-radius: vw(8px);
        // margin-right: vw(22px);
        padding: 0px 15px;
        padding: vw(22px);
        margin-bottom: vw(22px);

        span {
          display: block;
          @media only screen and (max-width: 900px) {
            font-size: 13px;
            line-height: 1.3;
          }
        }
        @media only screen and (max-width: 767px) {
          padding: 10px;
        }
      }
    }
  }
}
.preferences-block-font-18-bold {
  font-size: vw(18px);
  line-height: vw(21px);
  @include fontMontserratBold;
  color: #eee6e6;
  margin-bottom: 0;
}
.preferences-block-div--expertise {
  margin-right: 0;
  width: vw(593px);
}
.expertise-card-outer-div {
  width: vw(220px);
  background: #292950;
  border-radius: vw(18px);
  padding-bottom: vw(15px);
  margin-right: vw(22px);
}
.expertise-card-img-div {
  width: 100%;
  height: vw(195px);
  text-align: center;
  background: #ffffff;
  border-radius: inherit;
  padding-top: vw(34px);
  margin-bottom: vw(15px);

  h5 {
    font-size: vw(18px);
    line-height: vw(21px);
    @include fontMontserratBold;
    margin-bottom: 0;
    color: #292950;
    padding-top: vw(10px);
  }
}
.gp-expertise-img1 {
  width: vw(93px);
  height: auto;
}
.gp-expertise-img2 {
  width: vw(62px);
  margin-top: vw(20px);
  margin-bottom: vw(22px);
}
/*===============================
     
     investment-portfolio

================================*/
.additional-details {
  padding-top: 50px;
  @media only screen and (max-width: 767px) {
    padding-top: 30px;
  }
}
.gp-investment-portfolio-div {
  padding-top: vw(47px);
  @media only screen and (max-width: 900px) {
    padding-top: 50px;
  }
  @media only screen and (max-width: 767px) {
    padding-top: 30px;
  }
}
.preferences-block-title--invest {
  position: relative;
  @media only screen and (max-width: 900px) {
    font-size: 25px;
    line-height: 1.3;
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 757px) {
    font-size: 20px;
  }
  &::after {
    position: absolute;
    width: vw(1022px);
    height: 1px;
    content: "";
    // background: rgba(0, 0, 0, 0.09);
    top: vw(15px);
    right: 50px;
    @media only screen and (max-width: 900px) {
      content: "";
      width: 66%;
      top: 13px;
    }
    @media only screen and (max-width: 757px) {
      content: "";
      width: 100%;
      top: 30px;
    }
  }
}
.average-amount {
  padding-top: 50px;
  @media only screen and (max-width: 900px) {
    padding-top: 30px;
  }
}
.gp-investment-portfolio-text-1 {
  font-size: vw(18px);
  line-height: vw(21px);
  @include fontMontserratBold;
  color: #323277;
  margin-bottom: vw(23px);
  @media only screen and (max-width: 900px) {
    font-size: 13px;
    line-height: 1.3;
    margin-bottom: 15px;
  }
  @media only screen and (max-width: 757px) {
    margin-bottom: 5px;
  }
}
.gp-investment-portfolio-text-2 {
  font-size: vw(36px);
  line-height: vw(44px);
  @include fontMontserratMedium;
  color: rgba(116, 116, 196, 0.87);
  margin-bottom: vw(23px);
  @media only screen and (max-width: 900px) {
    font-size: 25px;
    line-height: 1.3;
    margin-bottom: 0px;
  }
  @media only screen and (max-width: 757px) {
    margin-bottom: 20px;
    font-size: 20px;
  }
}
.investment-group {
  padding-top: 30px;
}
.vl-interested-in-block {
  display: flex;
  align-items: center;

  button {
    width: vw(20px);
    height: vw(20px);
    margin-left: vw(25px);
    margin-bottom: vw(10px);
    color: rgba(39, 45, 46, 0.38);
    font-size: vw(14px);
    display: block;
  }

  .vl-display-loader-div div svg {
    width: vw(15px);
    padding-top: vw(2px);
  }
}

// mentor-profile
.vl-main-page-div {
  .vl-main-page-div__content {
    .general-profile-main-div {
      .mentor-profile-inputs {
        padding: 4.5833333333vw 0 0 0;
      }
    }
  }
  .not-authorize-text-main {
    height: 70vh;
  }
}
.mentor_personal_profile_wrap {
  background: #fff;
  margin-left: 280px;
  @media screen and (max-width: 1200px) {
    margin-left: 250px;
  }
  @media screen and (max-width: 900px) {
    margin-left: 0;
  }
  .vl-main-page-div__content {
    .vl-profile-page-image-upload-div {
      .upload-img__mainBlock {
        @media screen and (max-width: 992px) {
          height: 200px !important;
          width: 200px !important;
        }
        @media screen and (max-width: 576px) {
          height: 180px !important;
          width: 100% !important;
        }
      }
    }
    .general_profile_name {
      margin: 0 30px 0 60px;
      padding: 50px 0;
      // font-family: Inter !important;

      @media screen and (max-width: 900px) {
        margin: 0 15px;
      }
      .upload-img__btn-input-block {
        .upload-img__btn-input-block-text {
          @media screen and (min-width: 2000px) {
            font-size: 20px !important;
          }
        }
      }
      .inactive-profile {
        padding: 4px 10px;
        font-size: 16px;
        background-color: red;
        border-radius: 4px;
        color: #ffffff;
        margin-left: 10px;
      }
      .active-profile {
        border-radius: 4px;
        padding: 4px 10px;
        font-size: 16px;
        background-color: green;
        color: #fff;
        margin-left: 10px;
        @media only screen and (max-width: 575px) {
          font-size: 12px;
          font-weight: 600;
          padding: 4px 7px;
        }
      }
      .general-profile-title {
        color: #000;
        font-size: 24px;
        font-weight: 700;
        text-transform: capitalize;
      }
      .profilelist-top {
        display: flex;
        align-items: center;
        margin-bottom: 50px;

        @media screen and (max-width: 992px) {
          flex-wrap: wrap;
          justify-content: center;
          margin-top: 50px;
          margin-bottom: 30px;
        }
        .vl-profile-page-image-upload-div {
          @media screen and (max-width: 768px) {
            display: flex;
            justify-content: center;
          }
        }
        .genral_wrap {
          @media screen and (max-width: 992px) {
            margin-top: 50px;
          }
          .general-profile-bio-text {
            text-align: end;
            margin-right: 180px;
            @media screen and (max-width: 992px) {
              margin-right: 40px;
            }
            .fa-save {
              font-size: 18px;
              @media screen and (min-width: 2000px) {
                font-size: 24px;
              }
            }
            .fa-pencil {
              font-size: 16px;
              @media screen and (min-width: 2000px) {
                font-size: 24px;
              }
            }
          }
        }
        .vl-profile-page-image-upload-div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .upload-img__mainBlock {
            .upload-img__img-block {
              background: #d9d9d9;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              @media screen and (max-width: 992px) {
                width: 148px;
                height: 144px;
              }
            }
            .upload-profile-picture {
              width: 93px;
              color: #a0a0a0;
              text-align: center;
              font-family: Inter;
              font-size: 16px;
              font-weight: 400;
            }
          }
        }
        .image-save-cancel-btn {
          .vl-common-save-btn {
            border: 1px solid #4e4e51;
          }
        }
        .phone-edit-input {
          margin-bottom: 20px;
          @media screen and (min-width: 2132px) {
            margin-bottom: 40px;
          }
          @media screen and (min-width: 2400px) {
            margin-bottom: 0;
          }
          @media screen and (min-width: 3840px) {
            margin-bottom: 45px;
          }
          @media screen and (max-width: 1440px) {
            margin-bottom: 0 !important;
          }
          .edit-input {
            height: 66px;
          }
          .vl-profile-page-phone-number-block {
            @media screen and (max-width: 900px) {
              width: 95%;
              max-width: 95%;
            }
            @media screen and (max-width: 768px) {
              width: 100%;
              max-width: 100%;
            }
          }
        }
        .general-profile-text-colm-block {
          .edit_general_profile {
            // @media screen and (min-width: 2200px) {
            //   margin-bottom: 40px !important;
            // }
            @media screen and (min-width: 3840px) {
              margin-bottom: 45px !important;
            }
          }
          .general-profile-text-colm-block-text1 {
            color: #000;
            font-size: 16px;
            font-weight: 700;
            line-height: normal;
            margin: 0;
            @media screen and (max-width: 992px) {
              font-size: 14px;
            }
            @media screen and (min-width: 2000px) {
              font-size: 20px;
            }
          }
          .vl-form-input {
            width: 100% !important;
            max-width: 100%;
            @media screen and (min-width: 2200px) {
              height: 136px !important;
            }
            @media screen and (min-width: 5760px) {
              height: 235px !important;
            }
          }

          .edit-option {
            margin-bottom: 10px !important;
            @media screen and (max-width: 1440px) {
              margin-bottom: 0 !important;
            }
            @media screen and (min-width: 2132px) {
              margin-bottom: 20px !important;
            }
            @media screen and (min-width: 2400px) {
              margin-bottom: 0 !important;
            }
            @media screen and (min-width: 3840px) {
              margin-bottom: 45px !important;
            }
            .sign_up_date_main {
              @media screen and (max-width: 768px) {
                margin-bottom: 0 !important;
              }
            }
          }
          .general-profile-text-colm-block-text2 {
            color: #000;
            font-size: 14px;
            font-family: "Roboto-Regular" !important;
            font-weight: 500;
            line-height: normal;
            margin: 0;
            @media screen and (max-width: 992px) {
              font-size: 12px;
            }
            @media screen and (min-width: 2000px) {
              font-size: 18px;
            }
            .funding-inputs {
              margin-right: 0 !important;
              .react-datepicker-wrapper {
                @media screen and (min-width: 2200px) {
                  height: 86px !important;
                }
                @media screen and (min-width: 3840px) {
                  height: 116px !important;
                }
                @media screen and (min-width: 5760px) {
                  height: 166px !important;
                }
              }
            }
          }
          .sign_up_date_main {
            width: 100% !important;
          }
        }
        .email_edit_text {
          .error-message {
            margin-bottom: 10px !important;
          }
        }
      }
      .get-started-phone-input-div {
        height: 66px;
        @media screen and (max-width: 1200px) {
          height: 60px;
        }
        @media screen and (min-width: 2200px) {
          height: 136px;
        }
        @media screen and (min-width: 5760px) {
          height: 235px;
        }
        .react-tel-input {
          @media screen and (max-width: 1200px) {
            height: 40px !important;
          }
        }
      }
      .vl-form-input {
        .vl-form-input--getStarted-location {
          .input-border-div {
            width: 400px;
            input {
              width: 266px !important;
            }
          }
        }
      }
      .vl-profile-page-phone-number-block {
        .get-started-phone-input-div {
          position: relative;
          .react-phone-input-block {
            .react-tel-input {
              width: inherit;
              height: 3.0208333333vw;
              border: 0.1041666667vw solid #292950;
              border-radius: 0.3645833333vw;
              position: relative;
              background: #f0f0f3;
              padding: 10px 30px;
              @media screen and (max-width: 992px) {
                padding: 10px;
              }
              @media screen and (min-width: 3840px) {
                padding: 10px 60px;
              }
              .form-control {
                height: 100% !important;
                color: #000;
                font-size: 14px !important;
                font-family: "Roboto-Regular" !important;
                font-weight: 800;
                line-height: normal;
                background: transparent;

                @media screen and (max-width: 1200px) {
                  font-size: 12px !important;
                }
                @media screen and (min-width: 2200px) {
                  font-size: 22px !important;
                }
                @media screen and (min-width: 5760px) {
                  font-size: 55px !important;
                }
              }
            }
          }
          .phone_number_label {
            color: #000000;
            background-color: #f0f0f3;
            font-size: 0.7291666667vw;
            line-height: 0.8854166667vw;
            font-weight: 400;
            font-family: "Roboto-Regular", sans-serif, "Helvetica";
            margin-bottom: 0;
            position: absolute;
            top: -0.4166666667vw;
            left: 1.7708333333vw;
            padding-right: 0.4166666667vw;
            padding-bottom: 0;
            padding-left: 0.4166666667vw;
            z-index: 1;
            @media screen and (max-width: 900px) {
              font-size: 13px;
            }
          }
        }
        .react-phone-input-block {
          .react-tel-input {
            .form-control {
              color: #000;
              font-size: 14px;
              font-family: "Roboto-Regular" !important;
              font-weight: 500;
              line-height: normal;
              background: transparent;
              height: auto !important;
              @media screen and (max-width: 992px) {
                font-size: 12px !important;
              }
              @media screen and (min-width: 2000px) {
                font-size: 18px;
              }
            }
          }
        }
      }
      .textarea_wrap {
        margin-bottom: 40px;
        .raise_capital_profile_textarea {
          max-width: 971px;
          width: 100%;
          .input-border-div {
            textarea {
              padding: 20px;
            }
          }
        }
      }
      .gp-bio-div {
        padding: 0;
        .general-profile-bio-text {
          color: #000;
          font-size: 24px;
          font-weight: 700;
          line-height: normal;
          // margin-bottom: 20px;
          font-style: italic;
        }
      }
      .preferences-area {
        .preferences-block-title {
          color: #000;
          font-size: 24px;
          font-weight: 700;
          line-height: normal;
        }

        .preferences-block-div {
          width: 100%;
          min-height: 100%;
          padding: 0;
          .preferences-block-btn-outer-div {
            width: 100%;
            .preferences-block-text-1 {
              color: #a0a0a0;
              font-size: 20px;
              font-weight: 700;
              line-height: normal;
            }
            .preferences-block-btn-div {
              border-radius: 15px;
              border: 1px solid #000;
              background: #fff;
              .preferences-block-font-18-bold {
                color: #000;
              }
            }
          }
          .preferences-block-btn-outer-div {
            padding-bottom: 0;
          }
          .general-profile-text-colm-block-text1 {
            color: #676767;
            font-size: 16px;
            font-weight: 700;
            line-height: normal;
            @media screen and (max-width: 992px) {
              font-size: 14px;
            }
          }
          .revenue_title {
            color: #000;
            font-family: "Roboto-Regular";
            font-size: 15px;
            font-weight: 500;
            line-height: normal;
          }
          .preferences_wrap {
            @media screen and (max-width: 992px) {
              flex-wrap: wrap;
            }
            @media screen and (max-width: 768px) {
              margin-bottom: 30px !important;
            }
            .investor_area_input_wrap {
              width: 100%;
              @media screen and (max-width: 992px) {
                display: flex;
                gap: 10px;
                justify-content: space-between;
              }
              @media screen and (max-width: 576px) {
                flex-wrap: wrap;
              }
              .area_input_main {
                width: 100%;
                @media screen and (max-width: 992px) {
                  width: 48%;
                }
                @media screen and (max-width: 576px) {
                  width: 100%;
                }
              }
            }
          }
        }
      }
      .vl-form-input--textarea {
        .input-border-div {
          height: 148px;
          border-radius: 25px;
          border: 1px solid #000;
          background: #fff;
        }
      }
      .preferences-block-title {
        color: #000;
        font-size: 24px;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 0;
      }
      .investor_wrap {
        @media screen and (max-width: 768px) {
          flex-wrap: wrap;
        }
      }
      .gp-investment-portfolio-div {
        padding-top: 0;
        .investment-group {
          padding: 0;
        }
        .general-profile-text-colm-block-text1 {
          color: #676767;
          font-size: 16px;
          font-weight: 700;
          line-height: normal;
          margin: 0;
          @media screen and (max-width: 992px) {
            font-size: 14px;
          }
        }
        .general-profile-text-colm-block-text2 {
          color: #000;
          font-size: 15px;
          font-family: "Roboto-Regular";
          font-weight: 500;
          line-height: normal;
          margin: 0;
          @media screen and (max-width: 992px) {
            font-size: 12px;
          }
        }
      }
      .average-amount {
        padding-top: 0;
      }
      .mentor_profile_personal_details {
        .mentor_details_title {
          color: #000;
          font-size: 24px;
          font-weight: 700;
          line-height: normal;
        }
      }
      .Interested-profile-details-main {
        border-top: none;
        margin: 0;
        .expertise-areas-main {
          .expertise-areas-sub-title-main {
            @media screen and (max-width: 768px) {
              flex-wrap: wrap;
              gap: 20px;
            }
            .expertise-areas-sub-title-wrap {
              width: auto;
              display: flex;
              justify-content: center;
            }
          }
        }
        .Interested-profession-details {
          .profession-sub-title-main {
            display: block;
            .profession-sub-title {
              color: #676767;
              font-size: 16px;
              font-weight: 700;
              line-height: normal;
              margin: 0;
            }
            .profession-sub-title-wrap {
              width: 50%;
              @media screen and (max-width: 768px) {
                width: 100%;
              }
            }
            .profession-content {
              color: #000;
              font-size: 14px;
              font-weight: 500;
              line-height: normal;
            }
          }
        }
        .expertise-areas-title {
          color: #a0a0a0;
          font-size: 20px;
          font-weight: 700;
          line-height: normal;
          margin-bottom: 30px;
        }
        .expertise_image_wrap {
          width: 190px;
          height: 240px;
          border-radius: 18px;
          background: #292950;
          .upload_image_wrap {
            width: 190px;
            height: 190px;
            border-radius: 18px;
            border: 1px solid #000;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .upload_expertise_img {
              width: 93px;
              height: 73px;
              margin-bottom: 20px;
              border-radius: 10px;
            }
            .expertise-areas-sub-title {
              color: #292950;
              font-size: 18px;
              font-weight: 700;
              margin: 0;
              line-height: normal;
            }
          }

          .expertise-areas-content {
            color: #eee6e6 !important;
            text-align: center;
            font-size: 18px;
            font-weight: 700;
            line-height: normal;
            margin: 13px 0 !important;
            @media screen and (max-width: 768px) {
              font-size: 14px !important;
            }
          }
        }
      }
    }
    .mentor_profile_image {
      border-radius: 50% !important;
      object-fit: cover;
    }
    .vl-profile-page-image-upload-div {
      .upload-img__btn-input-block {
        @media screen and (max-width: 768px) {
          margin: 0;
        }
        .upload-img__btn-input-block-text {
          color: #000;
          font-size: 13px;
          font-weight: 400;
          @media screen and (max-width: 992px) {
            font-size: 10px;
          }
        }
      }
    }
    .vl-form-input {
      .vl-form-input--getStarted-location {
        .input-border-div {
          width: 200px !important;
          @media screen and (max-width: 1024px) {
            width: 200px !important;
          }
        }
      }
    }
  }
}
