.vl-checkbox {
  width: 100%;
  // height: vw(35px);
  height: auto;
  display: flex;
  align-items: center;
  //label {
  //  text-decoration: underline;
  //}
  .rc-checkbox {
    .rc-checkbox-inner {
      width: 18px;
      height: 18px;
      input {
        font-size: 18px;
      }
    }
  }

  label {
    cursor: pointer;
  }

  .rc-checkbox {
    cursor: default;
    vertical-align: text-bottom;
  }

  .rc-checkbox-inner {
    background-color: $vl-web-app-pages-text-color;
    border: vw(1px) solid #ffffff !important;
  }

  .rc-checkbox-inner:after {
    border: vw(1px) solid $vl-web-app-pages-text-color !important;
    border-top: 0 !important;
    border-left: 0 !important;
    left: 5px !important;
    top: 0 !important;
    width: 7px !important;
    height: 14px !important;
  }

  .rc-checkbox-inner {
    width: vw(31px);
    height: vw(31px);
    border-color: #ffffff;
    border-radius: vw(5px);
    border-width: vw(1px);
  }

  .rc-checkbox-checked .rc-checkbox-inner:after,
  .rc-checkbox-inner:after {
    left: vw(8px);
    top: vw(1px);
    width: vw(10px);
    height: vw(21px);
    @media only screen and (max-width: 900px) {
      top: 1px !important;
      width: 6px !important;
      height: 11px !important;
      left: 5px !important;
    }
  }

  .rc-checkbox-input {
    .rc-checkbox-inner {
      box-sizing: content-box;
    }
  }

  .rc-checkbox:hover .rc-checkbox-inner,
  .rc-checkbox-input:focus + .rc-checkbox-inner {
    border-color: #ffffff;
  }

  .rc-checkbox-checked .rc-checkbox-inner {
    border-color: #ffffff;
    background-color: #ffffff;
  }

  .rc-checkbox {
    input {
      width: 100%;
      height: 100%;
    }
  }

  // .rc-checkbox-inner {
  //   background-color: #42474c !important;
  // }
}
.custom-checkbox{
  align-items: flex-start;
  .rc-checkbox{
    margin-top: 5px;
  }
  .error-message{
    font-size: 19px;
    @media only screen and (max-width: 900px){
      font-size: 15px;
    }
    @media only screen and (max-width: 767px){
      font-size: 12px;
    }
  }
}
.vl-checkbox .rc-checkbox-disabled {
  .rc-checkbox-input {
    user-select: none !important;
    cursor: default !important;
  }

  // .rc-checkbox {
  //   .rc-checkbox-inner {
  //     border-color: rgba(0, 0, 0, 0.3) !important;
  //   }
  // }

  // .rc-checkbox.rc-checkbox-checked {
  //   .rc-checkbox-inner {
  //     border-color: #70f84e !important;
  //   }
  // }
}

.vl-checkbox--invest-calculator {
  .rc-checkbox-disabled .rc-checkbox-inner,
  .rc-checkbox-inner,
  .rc-checkbox-checked .rc-checkbox-inner {
    background: #f0f0f3;
    border: 1px solid #292950 !important;
   
  }

  .rc-checkbox-inner {
    border-radius: 3px;
    width: vw(26px);
    height: vw(26px);
  }

  .rc-checkbox-checked .rc-checkbox-inner:after,
  .rc-checkbox-inner:after {
    top: vw(6px);
    width: vw(5px);
    height: vw(11px);
  }

  .rc-checkbox-inner:after {
    border: 1px solid #f0f0f3 !important;
    border-top: 0 !important;
    border-left: 0 !important;
  }

  .rc-checkbox-checked .rc-checkbox-inner:after {
    border-color: #292950 !important;
  }
  label {
    font-size: vw(23px);
    line-height: vw(26.95px);
    @include fontRobotoRegular;
    padding-left: vw(33px);
    margin-bottom: vw(3px);
    color: black;
    @media only screen and (max-width: 900px){
      font-size: 15px;
      line-height: 1;
    }
    @media only screen and (max-width: 767px){
      line-height: 1.5;
      font-size: 12px;
    }
  }
}
