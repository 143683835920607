.explore-title {
  text-align: center;
  padding-top: vw(200px);
}
.explore-div {
  padding: vw(70px) vw(56px) vw(30px) vw(56px);
}
.active-list-not-found {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  p {
    margin: 0;
    color: #292950;
    opacity: 1;
    @media screen and (max-width: 768px) {
      font-size: 25px;
    }
  }
}
.explore-font-24-roboto-bold {
  font-size: vw(24px);
  line-height: vw(28.13px);
  @include fontRobotoBold;
  color: rgba(0, 0, 0, 0.46);
  margin-bottom: vw(39px);
}
.explore-card-btn-div {
  // padding-left: vw(33px);
  padding-bottom: vw(10px);

  .tag-btn:nth-child(2n + 1) {
    background: #6969b7;
  }

  .tag-btn:nth-child(2n + 0) {
    background: #6dbbc7;
  }
}

.explore-card-btn-div--main-card {
  // width: vw(495px);
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  flex-wrap: nowrap !important;
  height: 36px;
  @media only screen and (max-width: 900px) {
    width: 50%;
  }
  @media only screen and (max-width: 575px) {
    width: 70%;
    margin-bottom: 10px;
  }
}

.explore-card {
  // width: vw(496px);
  // min-height: vw(427px);
  border-radius: 20px;
  padding-top: vw(191px);
  // margin-right: vw(29px);
  margin-bottom: 29px;
  word-wrap: break-word;
  position: relative;
  background-image: url("../../../imgs/desktop/backgrounds/explore-card-bg.png");
  background-position: top;
  background-size: cover;
  .explore-card-text-div {
    height: 450px;
    overflow: hidden;
    @media screen and (min-width: 1517px) {
      height: 560px;
    }
    @media screen and (min-width: 2049px) {
      height: 1320px;
    }
  }
  .explorecard-location {
    min-height: 50px;
    .explorecard-inner {
      display: flex;
      align-items: center;
    }
    @media only screen and (max-width: 1024px) {
      min-height: 30px;
    }
    @media only screen and (max-width: 900px) {
      min-height: 40px;
    }
  }
  .btn-explore {
    padding-top: 35px;
  }
  @media only screen and (max-width: 575px) {
    // padding-top: 100px;
    padding-top: 160px;
  }
  @media only screen and (max-width: 991px) {
    width: 100%;
    padding-top: 140px;
    .tag-btn {
      font-size: 14px;
      width: auto;
      padding: 6px 10px;
    }
    .explorecard-location {
      margin-top: 10px;
      img {
        width: 14px;
        height: 15px;
      }
      h5 {
        font-size: 12px;
        line-height: 1.5;
        margin-left: 5px;
      }
    }
    .explore-card-follow-btn {
      width: auto;
      padding: 5px 10px;
      height: auto;
      i {
        font-size: 14px;
      }
      span {
        font-size: 14px;
      }
    }
    .explore-desc {
      font-size: 13px;
      line-height: 15px;
      padding: 0px;
      min-height: 135px;
    }
    .button-last {
      align-items: center;
      justify-content: center;
      .explore-card-view-details-div {
        span {
          font-size: 14px;
        }
      }
    }
    .percentage-inner {
      // margin-bottom: 10px;
      .percentage-text-div {
        width: 40%;
        h4 {
          font-size: 12px;
          line-height: 1.3;
        }
      }
      .funds-amount-div {
        width: 60%;
        tr {
          td {
            span {
              font-size: 12px;
              line-height: 1.5;
            }
          }
        }
      }
    }
    .last-textlist {
      tr {
        td {
          span {
            font-size: 11px;
            line-height: 1.5;
          }
        }
      }
    }
    .explore-card-view-details-div {
      margin-top: 10px;
      align-items: center;
      justify-content: center;
      span {
        font-size: 12px;
      }
    }
  }

  @media only screen and (max-width: 900px) {
    .explore-desc {
      // min-height: 65px;
      min-height: 95px;
    }
    .percentage-inner {
      // margin-bottom: 10px;
      .percentage-text-div {
        width: 25%;
      }
      .funds-amount-div {
        width: 70%;
        tr {
          td {
            span {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .tag-btn {
      font-size: 12px;
      width: 49%;
      line-height: 1;
      padding: 6px 10px;
      margin: 5px;
    }
    .explorecard-location {
      margin-top: 10px;
      min-height: 30px;
      img {
        width: 12px;
        height: 15px;
      }
      h5 {
        font-size: 10px;
      }
    }
    .explore-card-follow-btn {
      width: auto;
      padding: 5px 10px;
      height: auto;
      i {
        font-size: 12px;
      }
      span {
        font-size: 12px;
      }
    }
    .explore-desc {
      font-size: 11px;
      line-height: 15px;
      padding: 0px;
      min-height: 70px;
      // min-height: 127px;
    }
    .button-last {
      align-items: center;
      justify-content: center;
      .explore-card-view-details-div {
        span {
          font-size: 12px;
        }
      }
    }

    .last-textlist {
      tr {
        td {
          span {
            font-size: 11px;
            line-height: 1.5;
          }
        }
      }
    }
    .explore-card-view-details-div {
      margin-top: 10px;
      align-items: center;
      justify-content: center;
      span {
        font-size: 12px;
      }
    }
  }
  @media only screen and (max-width: 575px) {
    .explore-desc {
      // min-height: 110px;
      min-height: 65px;
    }
    .percentage-inner {
      .funds-amount-div {
        tr {
          td {
            span {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}

.explore-card-btn-div,
.explore-card-text-div {
  z-index: 22;
  position: relative;
  overflow: hidden;
}

.explore-card-bg-img {
  width: 100%;
  // height: vw(250px);
  height: 190px;
  display: block;
  position: absolute;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  @media only screen and (max-width: 1500px) {
    height: 150px;
  }
  @media only screen and (max-width: 1024px) {
    height: 100px;
  }
  @media only screen and (max-width: 991px) {
    height: 140px;
  }
  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
  }
}

.tag-btn {
  // width: vw(141px);
  padding: 5px 20px;
  min-height: 1.3;
  background: #6969b7;
  border-radius: vw(39px);
  color: white;
  font-size: vw(14px);
  line-height: vw(17.07px);
  @include fontMontserratSemiBold;
  // padding: vw(9px) vw(10px) vw(8px) vw(10px);
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: vw(10px);
  margin-bottom: vw(10px);
}
.tag-btn--2 {
  background: #6dbbc7;
  width: vw(119px);
}
.explore-card-text-div {
  padding: 29px 22px 24px 22px;
  // min-height: vw(427px);
  height: 450px;
  overflow: hidden;
  background: #292950;
  color: white;
  border-radius: 20px;
  @media only screen and (max-width: 900px) {
    padding: 29px 15px;
  }
  @media only screen and (max-width: 525px) {
    padding: 15px;
  }
  .font-16-open-sans-regular {
    color: #a5adbb;
  }
  .font-22-dm-serif-regular {
    // width: vw(300px);
    @media only screen and (max-width: 900px) {
      font-size: 20px;
      width: 100%;
      line-height: 1;
    }
    @media only screen and (max-width: 767px) {
      font-size: 18px;
    }
  }
}
.explore-card-location-img {
  width: vw(11.3px);
  height: vw(17px);
  margin-right: vw(6px);
}
.explore-desc {
  color: #e0e0e0;
  // text-align: justify;
  font-size: vw(16px);
  line-height: vw(25px);
  color: rgba(224, 224, 224, 1);
  @include fontOpenSansRegular;
  // padding-top: vw(20px);
  padding-right: vw(25px);
  margin-bottom: 0;
  min-height: vw(141px);
  max-height: vw(141px);
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    width: 100%;
    min-height: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-height: inherit;
    overflow-y: hidden;
  }
  img {
    width: 60%;
    height: 60%;
  }
  li {
    &:first-child {
      margin-top: 0px;
    }
    margin-top: 5px;
  }
}
.explore-card--hover {
  // padding-top: vw(95px);
  @media only screen and (max-width: 991px) {
    // padding-top: 60px;
    // padding-top: 100px;
  }
  .explore-card-text-div {
    // min-height: vw(521px);
    height: 450px;
    overflow: hidden;

    @media screen and (min-width: 1517px) {
      height: 560px;
    }
    @media screen and (min-width: 2049px) {
      height: 1320px;
    }
  }

  table td {
    line-height: vw(25px);
  }
}
.percentage-text {
  color: #feffff;
  font-size: vw(20px);
  line-height: vw(29.96px);
  @include fontMontserratRegular;
  margin-bottom: 0;
  // padding-left: vw(28px);
}
.funds-text {
  color: #f0f0f3;
  font-size: vw(14px);
  line-height: vw(17.07px);
  margin-bottom: 0;
  @include fontMontserratSemiBold;
  // padding-top: vw(15px);
}
.percentage-text-div {
  width: 5vw;
  flex-shrink: 0;
  text-align: center;
}
.funds-amount-div {
  width: vw(270px);
}
.funds-amounts-text-1 {
  font-size: vw(12px);
  // line-height: vw(14.63px);
  margin-bottom: 0;
  @include fontMontserratSemiBold;
}
.funds-amounts-no-1 {
  color: #feffff;
  font-size: vw(20px);
  line-height: vw(24.38px);
  margin-bottom: 0;
  margin-right: 2px;
  @include fontMontserratRegular;
  padding-left: vw(36px);
}
.funds-amount-div {
  padding-right: vw(3px);
  position: relative;

  &::after {
    position: absolute;
    content: "";
    background: #feffff;
    width: 1px;
    height: 100%;
    left: -13%;
    top: 0;
    @media only screen and (max-width: 1440px) {
      left: -3%;
    }
    @media only screen and (max-width: 900px) {
      content: "";
      left: -6%;
    }
    @media only screen and (max-width: 575px) {
      content: "";
      left: -8%;
    }
  }
}

.funds-amount-div {
  table {
    width: 100%;

    tr:nth-child(1) {
      td {
        width: 60%;
      }
    }

    tr:nth-child(2) {
      td {
        width: 40%;
      }
    }
  }
}

.funds-amounts-text-2 {
  color: #f0f0f3;
  font-size: vw(14px);
  line-height: vw(17.07px);
  margin-bottom: 0;
  @include fontMontserratSemiBold;
}
.funds-amounts-no-2 {
  color: #feffff;
  font-size: vw(15px);
  line-height: vw(19px);
  margin-bottom: 0;
  @include fontMontserratRegular;
  padding-left: vw(5px);
}
.explore-card-follow-btn {
  flex-shrink: 0;
  width: vw(95px);
  height: vw(40px);
  color: #ffffff;
  background: transparent;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  font-size: vw(12px);
  line-height: vw(15px);
  @include fontRobotoMedium;
  white-space: nowrap;
  i {
    font-size: vw(15px);
    padding-right: vw(9px);
  }
}
.explore-card-follow-btn--following {
  color: #292950;
  background: #ffffff;
  border-color: #ffffff;
}
.explore-card-font-14-semibold {
  font-size: vw(14px);
  line-height: vw(17.07px);
  margin-bottom: vw(9px);
}
.explore-card-font-18-semibold {
  font-size: vw(18px);
  line-height: vw(22px);
  white-space: nowrap;
}
.explore-card-font-18-semibold,
.explore-card-font-14-semibold {
  color: #5a9ba5;
  text-shadow: 0 vw(4px) vw(4px) rgba(0, 0, 0, 0.25);
  // @include fontMontserratSemiBold;
  font-family: "Montserrat-regular";

  width: fit-content;
}
.explore-card-view-details-div {
  width: vw(214px);
  height: vw(59px);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #feffff;
  box-sizing: border-box;
  // margin-left: vw(84px);
  .explore-card-view-details-div__text {
    font-size: vw(18px);
    line-height: vw(21.9px);
    @include fontMontserratBold;
    margin-bottom: 0;
  }
  @media only screen and (max-width: 900px) {
    width: 130px;
    height: 30px;
    span {
      font-size: 13px;
      line-height: 1.3;
    }
  }
}

.explore-card-view-details-div--home {
  border-color: #000000;
}

.explore-card-progress-outer-div {
  //width: vw(416px);
  //height: vw(13px);
  //background: rgba(196, 196, 196, 0.16);
  //border-radius: vw(19px);
  margin-top: vw(17px);
}
.view-btn {
  position: absolute;
  bottom: 28px;
  left: 50%;
  transform: translate(-50%, 0%);
}
.sort-tags-btn {
  width: vw(232px);
  height: vw(45px);
  background: rgba(90, 155, 165, 0.42);
  background: rgba(90, 155, 165, 0.42);
  border-radius: vw(39px);
  padding: vw(12px) vw(29px);
  margin-right: vw(18px);
  color: white;
  h5 {
    font-size: vw(14px);
    line-height: vw(16.41px);
    @include fontMontserratBold;
  }

  &:nth-child(1) {
    background: #5a9ba5;
  }
}
.explore-search-input-div {
  width: vw(601px);
  height: vw(54px);
  position: relative;
  background: rgba(196, 196, 196, 0.17);
  border-radius: vw(13px);
  .explore-search-img-div {
    width: vw(11px);
    //height: 80%;
    margin-left: vw(18px);
    img {
      width: 100%;
      height: auto;
      margin-bottom: vw(8px);
      cursor: pointer;
    }
  }
  input {
    width: 90%;
    height: 100%;
    border: none;
    background: transparent;
    color: rgba(0, 0, 0, 0.46);
    font-size: vw(12px);
    line-height: vw(14px);
    @include fontRobotoBold;
    padding-left: vw(13px);
    padding-right: vw(10px);

    &::placeholder {
      font-size: vw(12px);
      line-height: vw(14px);
      @include fontRobotoMedium;
    }
  }
}
.explore-search-input-div--location {
  width: vw(277px);
  position: relative;
  .explore-search-img-div {
    margin-right: vw(22px);
  }
  input {
    width: 80%;
    padding-left: vw(18px);
  }
  &::before {
    position: absolute;
    content: "";
    width: 1px;
    height: 55%;
    top: vw(13px);
    left: vw(50px);
    background: rgba(0, 0, 0, 0.42);
    z-index: 99;
  }
}
.explore-search-input-div--location-outer-div {
  margin-left: vw(9px);
}
