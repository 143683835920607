.signup-bg {
  min-height: 100vh;
  background-color: #292950;
  background-image: url("../../../imgs/desktop/backgrounds/signup-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: top;
  //padding: vw(131px) 0 0 0;
  //padding-top: vw(80px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.vl-logo-div {
  text-align: center;
  img {
    width: 237px;
    height: 63px;
  }
}
.signup-card-block {
  width: 605px;
  min-height: 699px;
  background-color: rgba(62, 58, 101, 0.71);
  border-radius: 17px;
  transform: rotate(-0.11deg);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 69px 0 36px 0;
  position: relative;
}
.facebook-signup-btn,
.google-signup-btn {
  width: 445px;
  height: 68px;
  border-radius: 5px;
  padding: 0;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.login-google-img {
  width: 35px;
}
.login-facebook-img {
  width: 30px;
}
.google-signup-btn {
  background: #4285f4;
  padding-right: 115px;
  padding-left: 4px;
}
.facebook-signup-btn {
  background: #475993;
  margin-top: 15px;
  padding: 0 101px 0 19px;
  margin-bottom: 18px;
}
.login-google-img-div {
  width: 66px;
  background: white;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}
.signup-manually-text {
  position: relative;
  //margin-top: vw(25px);

  &::after,
  &::before {
    position: absolute;
    content: "";
    width: 200px;
    height: 1px;
    background: rgba(255, 255, 255, 0.21);
    top: 10px;
  }
  &::after {
    left: 115%;
  }
  &::before {
    right: 115%;
  }
}
.signup-input-div {
  width: 476px;
  height: 66px;
  //height: auto;
  position: relative;
  input {
    width: 100%;
    height: 66px;
    //height: 100%;
    background: transparent;
    border: 1px solid #ffffff;
    border-radius: 8px;
    padding: 0 19px;
    //padding-bottom: vw(5px);
    font-size: 16px;
    line-height: 18.75px;
    color: #ffffff;
    @include fontRobotoMedium;

    &::placeholder {
      font-size: 16px;
      line-height: 18px;
      color: #ffffff;
      @include fontRobotoItalic;
    }
  }
}
.getstart-form {
  width: 475px;
  margin-top: 38px;
  .get-started-dropdown-div {
    .vl-form-input {
      label {
        color: #ffffff;
        background-color: #485b77;
      }
    }
  }
  .first-line {
    max-width: 49% !important;
  }
  .vl-form-input {
    width: 100% !important;
    max-width: 100% !important;
    @media only screen and (max-width: 900px) {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
  .vl-form-input.vl-form-input--getStarted {
    .input-border-div {
      width: 100%;
      height: 66px;
      background-color: transparent;
      border: 1px solid #ffffff;
      border-radius: 8px;

      input {
        padding-right: 19px;
        padding-left: 19px;
        font-size: 16px;
        line-height: 18.75px;
        color: #ffffff;
        @include fontRobotoMedium;

        &::placeholder {
          font-size: 16px;
          line-height: 18px;
          color: #ffffff;
          @include fontRobotoItalic;
          @media only screen and (max-width: 900px) {
            font-size: 13px;
          }
        }
      }
      @media only screen and (max-width: 575px) {
        height: 50px;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
}
.vl-form-input.vl-form-input--getStarted-location {
  .input-border-div {
    width: 476px;
  }
}

.error-message.error-message--get-started,
.get-started-phone-input-div .error-message,
.vl-form-input--getStarted .error-message {
  margin-bottom: 10px;
  margin-left: 20px;
}

.get-started-save-button-div {
  padding-top: 13px;
  padding-bottom: 23px;
}
.sign-up-back-btn {
  background: transparent;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    font-size: 17px;
    line-height: 21px;
    @media only screen and (max-width: 435px) {
      font-size: 15px;
    }
  }
}
.sign-up-back-btn--back {
  padding-right: 91px;
}
.back-img {
  width: 31px;
  margin-right: 8px;
  margin-left: 8px;
}
.signup-btn-div {
  //padding-bottom: vw(37px);
  padding-right: 60px;
  padding-left: 49px;
  padding-top: 50px;
  width: 100%;
}
//.signup-btn-div-next {
//  width: 100%;
//}
.vl-checkbox--3 {
  height: 70px !important;
  label {
    width: 500px;
  }
}
.signup-submit-btn {
  width: 277px;
  height: 69px;
  border-radius: 5px;
  background: #292950;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-size: 20px;
  line-height: 23px;
  @include fontRobotoBold;
  @media only screen and (max-width: 767px) {
    width: auto;
    height: auto;
    padding: 10px 30px;
  }
  @media only screen and (max-width: 435px) {
    font-size: 15px;
  }
}
.signup-colum-div {
  padding-left: 50px;
}
