.vl-invest-profile-page-setup-one-img-block {
  width: vw(255px);
  height: vw(255px);
  margin: 0 auto;
  margin-bottom: vw(52px);

  @media only screen and (max-width: 900px) {
    width: 40%;
    height: auto;
  }

  @media only screen and (max-width: 575px) {
    width: 50%;
  }
  img {
    width: inherit;
    height: inherit;
  }
}
.text-lins {
  @media only screen and (max-width: 900px) {
    font-size: 25px;
    line-height: 1.5;
  }
  @media only screen and (max-width: 575px) {
    font-size: 20px;
    line-height: 1.3;
    margin-bottom: 10px;
    margin-top: 20px;
  }
}
.raise-capital-startup-btn {
  @media only screen and (max-width: 900px) {
    padding: 10px 30px;
    span {
      font-size: 15px;
      line-height: 1.5;
    }
  }
  @media only screen and (max-width: 575px) {
    padding: 7px 25px;
    span {
      font-size: 14px;
      line-height: 1.3;
    }
  }
}
.invest-personal-tab-panel {
  padding: vw(70px) vw(100px) vw(100px) vw(75px);
  .vl-form-input {
    label {
      @media only screen and (max-width: 900px) {
        font-size: 13px;
      }
    }
  }
  .vl-select-container {
    margin-bottom: vw(15px);
  }
  h3 {
    @media only screen and (max-width: 900px) {
      font-size: 15px;
      margin-top: 30px;
      margin-bottom: 20px;
      line-height: 1.5;
    }
    @media only screen and (max-width: 900px) {
      span {
        font-size: 10px;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    padding: 0px 15px 20px 15px;
  }
}
.edit_invest_personal_tab_wrap {
  padding: 20px 0;
  @media screen and (max-width: 768pxpx) {
    padding: 10px 0;
  }
  .industry_input_wrap {
    margin-bottom: 0;
  }
  .performance_title {
    @media screen and (max-width: 900px) {
      margin-top: 0;
    }
  }
  .input_industry_main {
    max-width: 100% !important;
  }
  .industry_question_one {
    margin-bottom: 20px;
    @media screen and (max-width: 1024px) {
      margin-top: 0;
    }
  }
}
.invest-personal-portfolio {
  padding-left: 0;
  padding-right: 0;
}
.edit_invest_portfolio_wrap {
  .edit_invest_type {
    margin-top: 40px;
  }
}
.pernal-info-save-btn-div {
  // padding-top: vw(62px);
  // padding-right: vw(130px);
  padding-right: 34px;
}
.invest-preferences-font-24-roboto {
  font-size: vw(24px);
  line-height: vw(28.13px);
  margin-bottom: vw(45px);
  color: $vl-web-app-pages-text-color;
  @include fontRobotoItalic;
}
.preferences-save-btn-div {
  padding: vw(49px) vw(130px) 0 0;
}
/*=========================================
                calculator
==========================================*/

.vl-invest-calculator-form-limit-card-outer-div {
  padding-top: vw(62px);
}

.invest-personal-tab-panel--calculator {
  padding-top: vw(33px);
  padding-bottom: vw(60px);
  @media only screen and (max-width: 900px) {
    .invest-calcultor-font-25-medium {
      font-size: 15px;
      line-height: 1;
    }
    .invest-calcultor-font-20-regular {
      font-size: 12px;
      line-height: 1.3;
    }
    .invest-calcultor-font-16-regular {
      font-size: 12px;
      line-height: 1.3;
      margin-bottom: 15px;
    }
  }
}

.vl-calculator-payment-note {
  font-size: vw(23px);
  line-height: vw(28px);
  @include fontRobotoRegular;
  color: black;
  padding: vw(45px) 0 vw(45px) 0;
  margin-bottom: vw(25px);
  @media only screen and (max-width: 900px) {
    font-size: 13px;
    line-height: 1.3;
    margin-bottom: 20px;
  }
}

.payment-update-btn-div {
  padding-right: vw(100px);
  padding-bottom: vw(50px);
}

.proceed-to-payment-btn-div {
  .proceed-btn {
    width: 100%;
    text-align: end;
    @media only screen and (max-width: 900px) {
      text-align: center;
    }
  }
  button {
    width: vw(320px);
    height: vw(61px);
    margin-bottom: vw(5px);
    margin-left: vw(10px);
    @media only screen and (max-width: 767px) {
      font-size: 15px;
      height: auto !important;
      width: auto !important;
      padding: 10px 30px;
      margin-top: 20px;
      span {
        line-height: 1.3;
      }
    }
  }
}

.vl-payment-font-button-note {
  font-size: vw(18px);
  line-height: vw(21px);
  font-style: italic;
  @include fontRobotoLight;
  margin-bottom: 0;
  @media only screen and (max-width: 900px) {
    font-size: 15px;
    line-height: 1.3;
  }
}

.invest-calcultor-font-25-medium {
  font-size: vw(25px);
  line-height: vw(28.13px);
  @include fontRobotoMedium;
  color: #000000;
  margin-bottom: vw(29px);
}
.invest-calcultor-font-20-regular {
  font-size: vw(20px);
  line-height: vw(23.44px);
  @include fontRobotoRegular;
  margin-bottom: vw(25px);
  color: #000000;
}
.invest-calcultor-font-16-regular {
  font-size: vw(16px);
  line-height: vw(18.75px);
  @include fontRobotoRegular;
  margin-bottom: vw(46px);
  color: #000000;
}
.invest-calculator-font-24-medium {
  font-size: vw(24px);
  line-height: vw(28.13px);
  @include fontRobotoMedium;
  color: rgba(0, 0, 0, 1);
  margin-bottom: vw(51px);
  padding-top: vw(47px);
}
.invest-calculator-invest-block {
  width: vw(1319px);
  border: 1px solid #455051;
  box-sizing: border-box;
  margin-top: vw(67px);
}
.invest-calculator-invest-block-1 {
  text-align: center;
  padding: vw(26px) 0 vw(21px) 0;
  background: #292950;
  color: white;
  h3 {
    font-size: vw(28px);
    line-height: vw(33px);
    margin-bottom: 0;
    @include fontRobotoMedium;
  }
}
.invest-calculator-invest-block-2 {
  padding: vw(50px) 0 vw(24px) vw(45px);
}
.invest-calculator-invest-block-font-24-regular {
  font-size: vw(24px);
  line-height: vw(28.13px);
  margin-bottom: 0;
  @include fontRobotoRegular;
  color: #272d2e;
}
.invest-calculator-invest-block-font-18-regular {
  font-size: vw(18px);
  line-height: vw(21.09px);
  @include fontRobotoRegular;
  color: #272d2e;
  margin-bottom: vw(10px);
}
.invest-calculator-invest-block-font-24-bold {
  font-size: vw(24px);
  line-height: vw(28px);
  color: #292950;
  @include fontRobotoBold;
  margin-bottom: 0;
}
.invest-calculator-invest-block-div {
  padding: vw(5px) vw(54px) vw(5px) vw(30px);
  border-left: vw(8px) solid #292950;
}
.invest-calculator-invest-block-div--2 {
  padding-right: vw(61px);
}
.calculator-save-btn-div {
  padding: vw(10px) vw(260px) vw(100px) 0;
  .vl-common-save-btn {
    @media only screen and (max-width: 767px) {
      font-size: 15px;
      line-height: 1.3;
      height: auto;
      width: auto;
      padding: 10px 30px;
    }
  }
  @media only screen and (max-width: 900px) {
    padding: 3.64583vw 5.20833vw 5.20833vw 3.90625vw !important;
  }
  @media only screen and (max-width: 767px) {
    padding: 0px !important;
  }
}
/*===========================================================
                invest portfolio
==============================================================*/
.invest-personal-tab-panel--portfolio {
  padding-top: vw(51px);
  @media only screen and (max-width: 900px) {
    .invest-types {
      margin-bottom: 20px;
      .invest-preferences-font-24-roboto {
        margin: 0px;
      }
      .vl-form-input--bordered-select-div {
        margin-bottom: 0px !important;
      }
    }
  }
}
.invest-investment-portfolio-font-18 {
  font-size: vw(18px);
}
.invest-investment-font-26-regular {
  font-size: vw(26px);
  line-height: vw(30.47px);
  @include fontRobotoRegular;
  color: #000000;
  @media only screen and (max-width: 900px) {
    font-size: 13px;
    line-height: 1;
  }
}
.invest-portfolio-font-24-italic {
  font-size: vw(24px);
  line-height: vw(28.13px);
  margin-bottom: vw(28px);
  @include fontRobotoItalic;
  color: $vl-web-app-pages-text-color;
}
.font-20-roboto-regular {
  font-size: vw(20px);
  line-height: vw(23.44px);
  @include fontRobotoRegular;
  margin-bottom: 0;
}
.portfolio-btn-div {
  padding: vw(55px) vw(249px) 0 0;
}
.portfolio_button_wrap {
  padding-right: 0;
}
.invest-thumb {
  background: white;
  border-radius: 50%;
  width: vw(35px);
  height: vw(35px);
  cursor: pointer;
  bottom: vw(-20px);
  border: vw(5px) solid $vl-web-app-pages-text-color;
  color: white;
  font-size: 0;

  &:focus {
    outline: none;
  }
  @media only screen and (max-width: 900px) {
    width: 13px;
    height: 13px;
    bottom: -8px;
  }
}

.invest-slider-div {
  width: vw(960px);
  height: vw(11px);
  margin-bottom: vw(25px);
  background: #292950;
  margin-top: vw(25px);

  .horizontal-slider {
    width: vw(965px);
    //height: vw(11px);
    //background: #292950;
  }
  @media only screen and (max-width: 900px) {
    width: 80%;
    height: 2px;
    margin: 25px 0px;
  }
}

.invest-slider-div--disabled {
  // background: rgba(41, 41, 80, 0.32);

  .invest-thumb {
    cursor: default;
    border-color: rgba(41, 41, 80, 0.32);
  }
}

//.invest-slider-div--2 {
//  .invest-thumb {
//    bottom: vw(-16px);
//  }
//}
.explore-card-view-details-div-invest {
  width: vw(190px);
  height: vw(59px);
  background: #ffffff;
  margin-left: vw(18px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.explore-card-view-details-div__text-invest {
  color: #272d2e;
  font-size: vw(18px);
  line-height: vw(21px);
  @include fontRobotoBold;
}

.explore-card-view-details-div-invest--disabled {
  background: rgba(255, 255, 255, 0.8);
  user-select: none;
  cursor: default;
}

.explore-card-invest-top-div {
  width: vw(400px);
  margin: auto;
  background: #5a9ba5;
  border-top-left-radius: vw(19px);
  border-top-right-radius: vw(19px);
  margin-bottom: vw(-10px);
  padding-top: vw(13px);
  padding-bottom: vw(20px);
}

.explore-card-invest-top-div-text-1 {
  font-size: vw(14px);
  line-height: vw(17.07px);
  @include fontRobotoBold;
  color: #f0f0f3;
  text-transform: capitalize;
}
.explore-card-invest-top-div-text-2 {
  font-size: vw(24px);
  line-height: vw(29.26px);
  @include fontRobotoLight;
  padding-left: vw(19px);
  color: #feffff;
}

.vl-invest-after-setup-portfolio-invest-card-row {
  .invest-card {
    width: vw(496px);
    margin-right: vw(28px);
    margin-bottom: vw(28px);
  }

  .explore-card {
    margin-bottom: 0;
  }

  .explore-desc {
    min-height: vw(100px);
    @media only screen and (max-width: 575px) {
      min-height: 80px !important;
      font-size: 13px !important;
    }
  }

  .invest-card:nth-child(1) .explore-card-invest-top-div {
    background: #5a9ba5;
  }

  .invest-card:nth-child(2) .explore-card-invest-top-div {
    background: #3c5c8a;
  }

  .invest-card:nth-child(3) .explore-card-invest-top-div {
    background: rgba(254, 89, 0, 0.85);
  }
}
.dropdown_main_div {
  height: auto;
  min-height: auto;
  margin-bottom: 0;
  .vl-select-container {
    height: auto;
    min-height: auto;
    .vl-select__control {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
}
.invest_types_wrap {
  .investor_type_title {
    margin-bottom: 10px;
    @media screen and (max-width: 900px) {
      margin-bottom: 20px !important;
      font-size: 14px;
    }
  }
  .vl-form-input--bordered-select-div {
    margin-bottom: 10px;
  }
  .vl-dropdown-common {
    max-width: 50% !important;
  }
}
