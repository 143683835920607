.Interested-profile-details-main {
  border-top: 1px solid #00000017;
  margin-top: 20px;
  .about-details-main {
    padding-top: 10px;
    .about-title {
      font-size: 1.375rem;
    //   @media (min-width: 1200px) {
    //     font-size: 2rem;
    //   }
    }
    .about {
      color: #323277;
      font-size: 0.9375vw;
      font-weight: 500;
      padding: 0 10px;
    }
  }
  .Interested-profession-details {
    margin-top: 30px;
    .profession-title {
      font-size: 1.375rem;
    //   @media (min-width: 1200px) {
    //     font-size: 2rem;
    //   }
    }
    .profession-sub-title-main {
      width: 100%;
      display: flex;
      .profession-sub-title-wrap {
        width: 30%;
        padding: 0 10px;
        @media screen and (max-width: 575px) {
          width: 100%;
        }
        .profession-sub-title {
          color: #272d2e61;
          font-size: 0.9375vw;
          font-weight: 500;
          line-height: 0.8854166667vw;
          margin-bottom: 0.5729166667vw;
          display: flex;
          align-items: center;
          .link-action{
            img{
                width: 25px;
                height: 16px;
                margin-left: 1px;
            }
          }
        }
        .profession-content {
          color: #323277bd;
          font-size: 0.9375vw;
          font-weight: 600;
          line-height: 1.1458333333vw;
          margin: 0;
          @media screen and (max-width: 575px) {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
  .expertise-areas-main {
    margin-top: 30px;
    .expertise-areas-title {
      font-size: 1.375rem;
    //   @media (min-width: 1200px) {
    //     font-size: 2rem;
    //   }
    }
    .expertise-areas-sub-title-main {
      width: 100%;
      display: flex;
      .expertise-areas-sub-title-wrap {
        width: 30%;
        padding: 0 10px;
        @media screen and (max-width: 768px) {
          width: 50%;
        }
        @media screen and (max-width: 575px) {
          width: 100%;
        }
        .expertise-areas-sub-title {
          color: #272d2e61;
          font-size: 0.9375vw;
          font-weight: 500;
          line-height: 0.8854166667vw;
          margin-bottom: 0.5729166667vw;
        }
        .expertise-areas-content{
            color: #323277bd;
            font-size: 0.9375vw;
            font-weight: 700;
            line-height: 1.1458333333vw;
            margin: 0;
            @media screen and (max-width: 575px) {
              margin-bottom: 1rem;
            } 
        }
      }
    }
  }
}
