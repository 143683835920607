.vl-about-us-web{

    
    .our-team-left-div,
    .our-team-right-div,
    .our-advisor-right-div,
    .our-advisor-left-div{
        color: #ffffff;
    }
    
    .our-team-right-div{        
        padding-right: vw(60px);
    }

    .our-advisor-right-div{
        padding-right: vw(5px);
    }

    .our-advisor-left-div{
        padding-left: vw(60px);
    }
}