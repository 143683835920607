.contact-us-bg {
  background-image: url("../../../imgs/desktop/contact-us/contact-us-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: top;
  .contact-us-outer-div {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding-top: vw(101px);
    padding-bottom: vw(77px);
    .contact-us-div {
      width: vw(1254px);
      background: #29294f;
      border-radius: vw(49px) 0 0 vw(49px);
      padding-top: vw(72px);
      padding-left: vw(143px);
      padding-bottom: vw(161px);
      .contact-us-title {
        font-size: vw(50px);
        line-height: vw(61px);
        margin-bottom: vw(19px);
        //@include fontMontserratBold;
        @include fontRobotoBold;
        @media only screen and (max-width: 900px) {
          font-size: 25px;
          line-height: 1.3;
        }
      }
      h5 {
        @media only screen and (max-width: 900px) {
          font-size: 13px;
          line-height: 1.3;
        }
      }
      .contact-us-form-outer-div {
        padding-top: vw(47px);
        .contactus-list {
          padding-top: 48px;
          @media only screen and (max-width: 900px) {
            padding-top: 30px;
          }
        }
        .contact-us-input-div {
          //width: vw(718px);
          width: vw(876px);
          height: auto;

          input {
            width: 100%;
            height: 100%;
            background: transparent;
            border: none;
            border-bottom: 1px solid #ffffff;
            padding-bottom: vw(17px);
            padding-top: vw(5px);
            font-size: vw(20px);
            line-height: vw(23px);
            color: white;
            //@include fontMontserratMedium;
            @include fontRobotoMedium;
            @media only screen and (max-width: 900px) {
              font-size: 13px;
              line-height: 1.3;
              padding: 5px 0px 10px 5px;
            }

            &::placeholder {
              font-size: vw(20px);
              line-height: vw(23px);
              color: white;
              @include fontRobotoItalic;
              //@include fontMontserratItalic;
              @media only screen and (max-width: 900px) {
                font-size: 13px;
                line-height: 1.3;
              }
            }
          }
          @media only screen and (max-width: 900px) {
            width: 100%;
            height: auto;
          }
        }
        .contact-us-select-div {
          width: vw(876px);
          height: vw(58px);
          // margin-bottom: vw(39px);
          // margin-top: vw(30px);

          .contact-us-select-container {
            width: auto;
            height: auto;
            background-color: transparent;
            border: none;
            border-bottom: vw(2px) solid white;
          }
          .contact-us-select {
            &__control {
              font-size: vw(20px) !important;
              line-height: vw(23px);
              @include fontRobotoItalic;
              border: none !important;
              box-shadow: none;
              border-radius: none;
              background: transparent !important;
              padding: 0;
              // min-height: -webkit-fill-available !important;
              border: none;
              border-bottom: 1px solid white;
              outline: none !important;
              color: white;
              &:focus {
                border: none !important;
                outline: none !important;
              }
              @media only screen and (max-width: 900px) {
                font-size: 13px !important;
                line-height: 1.3;
              }
            }

            &__value-container {
              padding: 0 !important;
            }

            &__placeholder {
              color: white !important;
              @media only screen and (max-width: 900px) {
                font-size: 13px;
                line-height: 1.3;
              }
            }
            &__single-value {
              color: white !important;
            }
            &__indicator {
              color: white !important;
              padding: 0 !important;
              svg {
                width: vw(40px) !important;
                height: vw(40px) !important;
                @media only screen and (max-width: 900px) {
                  width: 15px !important;
                  height: 15px !important;
                }
              }
            }

            &__indicator-separator {
              display: none !important;
            }
            // &__option--is-selected {
            //   background-color: lightblue !important;
            // }

            &__menu {
              margin-top: vw(5px);
              z-index: 2;
            }

            &__menu,
            &__option,
            &__option--is-focused,
            &__option--is-selected {
              //min-width: min-content;
              min-width: max-content;
            }
            &__option--is-focused,
            &__option--is-selected {
              background-color: #323277 !important;
            }

            &__option--is-focused {
              background-color: #323277;
            }

            &__menu-list {
              height: fit-content;
              padding: 0 !important;
              color: white !important;
              font-size: vw(20px) !important;
              line-height: vw(23px);
              background-color: #36365a;
              @include fontRobotoItalic;
              @media only screen and (max-width: 900px) {
                font-size: 13px !important;
                line-height: 1.3 !important;
              }
            }
          }
          @media only screen and (max-width: 900px) {
            width: 100%;
            height: auto;
          }
        }
        .contact-us-textarea-outer-div {
          width: vw(880px);
          height: vw(372px);
          margin-bottom: vw(59px);

          label {
            font-size: vw(20px);
            line-height: vw(23px);
            @include fontRobotoItalic;
            margin-bottom: vw(17px);
            @media only screen and (max-width: 900px) {
              font-size: 13px;
              line-height: 1.3;
            }
          }
          textarea {
            width: 100%;
            height: 100%;
            background: transparent;
            border: 1px solid #ffffff;
            padding-bottom: vw(17px);
            padding: vw(15px);
            font-size: vw(20px);
            line-height: vw(23px);
            color: white;
            //@include fontMontserratMedium;
            @include fontRobotoMedium;
            @media only screen and (max-width: 900px) {
              font-size: 13px;
              line-height: 1.3;
            }
            @media only screen and (max-width: 575px) {
              height: 100px;
              padding: 5px;
            }
            &::placeholder {
              font-size: vw(20px);
              line-height: vw(23px);
              color: white;
              @include fontRobotoItalic;
              //@include fontMontserratItalic;
              @media only screen and (max-width: 900px) {
                font-size: 13px;
                line-height: 1.3;
              }
            }
          }
          @media only screen and (max-width: 900px) {
            width: 100%;
            height: auto;
          }
        }
        .contact-btns {
          .contact-us-btn {
            width: vw(226px);
            height: vw(54px);
            background: white;
            margin-top: vw(59px);
            font-size: vw(20px);
            line-height: vw(24px);
            color: #272d2e;
            @include fontRobotoBold;
            //@include fontMontserratBold;
            margin-left: vw(660px);
            border-radius: vw(10px);
            @media only screen and (max-width: 900px) {
              font-size: 15px;
              width: auto;
              height: auto;
              line-height: 1.4;
              padding: 10px 30px;
              margin: auto;
            }
            @media only screen and (max-width: 575px) {
              font-size: 13px;
              padding: 7px 25px;
            }
          }
          @media only screen and (max-width: 900px) {
            text-align: center;
          }
        }
        @media only screen and (max-width: 900px) {
          padding-top: 30px;
        }
      }
      @media only screen and (max-width: 900px) {
        width: 90%;
        padding: 40px;
      }
      @media only screen and (max-width: 575px) {
        width: 95%;
        padding: 15px;
      }
    }
    @media only screen and (max-width: 767px) {
      padding: 50px 0px;
    }
  }
}

.bg-color--contact {
  //background: white;
  .footer-row-div {
    display: none;
  }
}
