// color
$background-color: #f3f6fb;

$form-field-border-radius: vw(7px);
$form-field-background: #f0f0f3;
$form-field-border-color: #292950;
$form-field-font-color: #000000;
$form-field-label-font-size: vw(14px);
$form-field-label-line-height: vw(17px);
$form-field-font-size: vw(18px);
$form-field-line-height: vw(21px);
$form-field-placeholder-color: rgba(0, 0, 0, 0.25);
$form-field-disabled-border-color: rgba(41, 41, 80, 0.32);

// main page
$vl-main-page-div-border-radius: vw(50px);
$vl-web-app-pages-text-color: #4e4e51;
