.application-submitted-wrap {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
  @media screen and (max-width: 900px) {
    height: 100%;
    padding-top: 40px;
  }
  .reviewed-image {
    width: 160px;
    height: 140px;
    margin-bottom: 30px;
  }
  .application-submitted-title {
    color: rgba(0, 0, 0, 0.69);
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
  .notified-text {
    color: rgba(0, 0, 0, 0.69);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 30px;
  }
  .explore-button {
    width: 200px;
    height: 50px;
    align-items: center;
    background: #4e4e51;
    border: none;
    border-radius: 59px;
    color: #fff;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    &:hover {
      background: #4e4e51;
      border: none;
    }
    &:focus {
      background: #4e4e51;
      box-shadow: none;
    }
  }
}
