.reviewed {
  background-color: #fff;
  height: 100vh;
  //   width: calc(100wh - 300px);
  color: black;
  font-family: "Roboto-regular";
  margin-left: 300px;
  @media screen and (max-width: 1150px) {
    margin-left: 250px;
  }
  @media screen and (max-width: 900px) {
    margin-left: 0;
  }
  .reviewed-main {
    position: relative;
    height: 100%;
    .reviewed-wrap {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @media screen and (max-width: 768px) {
        width: 90%;
      }
      .reviewed-image-wrap {
        text-align: center;
        margin-bottom: 27px;
        .reviewed-image {
          width: 175px;
          height: 144px;
          margin: 0 auto;
          @media screen and (max-width: 768px) {
            width: 100px;
            height: 80px;
          }
        }
      }
      .reviewed-image-title {
        font-size: 32px;
        line-height: 39.01px;
        font-weight: 800;
        text-align: center;
        @media screen and (max-width: 768px) {
          font-size: 20px;
          font-size: 20px;
          line-height: 21px;
        }
      }
      .reviewed-content {
        width: 442px;
        font-size: 20px;
        font-weight: 500;
        line-height: 24.38px;
        text-align: center;
        color: #000000b0;
        font-family: "Roboto-regular";
        margin: 0 auto 26px;
        @media screen and (max-width: 768px) {
          width: auto;
          font-size: 12px;
          line-height: 15px;
          margin: 0 auto 16px;
        }
      }
      .reviewed-link-wrap {
        width: auto;
        color: #000;
        border-radius: 59px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        text-decoration: underline;
        .reviewed-link {
          font-size: 18px;
          color: #000;
          font-weight: 600;
          @media screen and (max-width: 768px) {
            font-size: 12px;
            text-align: center;
          }
        }
        @media screen and (max-width: 768px) {
          width: auto;
          padding: 10px;
          height: auto;
        }
      }
    }
  }
  .footer-main {
    margin-top: 0;
  }
}
