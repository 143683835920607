.home-content-div {
  padding: vw(60px) vw(65px) vw(50px) vw(52px);

  .font-18-roboto-bold {
    color: #323277;
  }
  @media only screen and (max-width: 900px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.home-blog-card-main-div {
  // min-height: vw(830px);
  max-height: vw(1360px);
  overflow: auto;
}

.new-home-card-block {
  // min-height: vw(845px);
  max-height: vw(1375px);
  overflow: auto;
  background: #ffffff;
}

.home-blog-card-main-div {
  margin-top: vw(52px);
  padding-right: vw(7px);
}
.home-blog-card-div {
  width: vw(426px);
  margin-right: vw(32px);
  padding-top: vw(10px);

  .font-24-roboto-bold {
    padding-top: vw(4px);
    color: #323b3c;
  }
  .font-16-roboto-regular {
    padding-top: vw(10px);
    color: #516163;
  }
}
.read-more-text {
  font-size: vw(14px);
  line-height: vw(19.2px);
  @include fontRobotoRegular;
  color: #fe5900;
  padding-bottom: 1px;
  border-bottom: 1px solid #fe5900;
}
.home-blog-img-div {
  width: vw(619px);
  height: vw(256px);
  border-radius: vw(7px);
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .font-18-roboto-bold {
    color: #ceced6;
  }
  img {
    width: 100%;
    height: auto;
  }
}
.home-blog-card-desc-div {
  margin-top: vw(10px);
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  margin-bottom: 10px;
  -webkit-box-orient: vertical;

  p {
    margin-bottom: vw(10px);
  }
}
.home-blog-card-main-div-border {
  margin-top: vw(28px);
  border-right: 1px solid rgba(0, 0, 0, 0.17);
  padding-right: vw(20px);
}

//new card
.new-home-card-block {
  margin-top: vw(35px);
  width: vw(412px);
  min-height: vw(200px);
  border: vw(18px) solid #292950;
  border-radius: vw(6px);
}
.new-home-inner-card-block {
  border-radius: vw(6px);
  width: 100%;
  height: 100%;
  padding: vw(21px) vw(12px) 0 vw(8px);
  @media only screen and (max-width: 767px) {
    padding: 15px;
  }
}
.new-home-card-content-block {
  padding-bottom: vw(27px);
  .font-18-roboto-bold {
    color: #5a9ba5;
    line-height: vw(38px);
  }
  @media only screen and (max-width: 900px) {
    margin-bottom: 10px;
  }
}
.new-home-card-content-text-italic {
  font-size: vw(14px);
  line-height: vw(19px);
  color: #000000;
  @include fontRobotoItalic;
  padding-bottom: vw(3px);
  display: inherit;
  text-decoration: underline;
  // border-bottom: 1px solid #000000;
}
.new-home-card-content-para-text {
  font-size: vw(14px);
  line-height: vw(25.6px);
  @include fontRobotoRegular;
  color: #516163;
  padding-top: vw(7px);
  margin-bottom: 0;
}
.new-home-card-content-read-more-text {
  font-size: vw(14px);
  line-height: vw(19px);
  @include fontRobotoRegular;
  color: #c0c2d1;
  padding-bottom: vw(3px);
  display: block;
  text-decoration: underline;
  // border-bottom: 1px solid #c0c2d1;
}

.home-content-div__colm1 {
  width: 70%;
  @media only screen and (max-width: 900px) {
    .font-18-roboto-bold {
      font-size: 15px;
      line-height: 1.5;
    }
    .home-blog-card-main-div {
      .home-blog-card-div {
        width: 45%;
        .font-24-roboto-bold {
          font-size: 13px;
          line-height: 1.3;
        }
        .home-blog-card-desc-div {
          font-size: 12px;
          height: 49px;
          line-height: 1.3;
        }
        .read-more-text {
          font-size: 12px;
          line-height: 1.3;
        }
        @media only screen and (max-width: 767px) {
          width: auto;
        }
      }
    }
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    margin-bottom: 30px;
  }
}
.home-card-main-div {
  padding-left: vw(23px);
  width: 28%;
  @media only screen and (max-width: 900px) {
    .font-18-roboto-bold {
      font-size: 15px;
      line-height: 1.5;
    }
    .new-home-card-block {
      width: 100%;
      .new-home-inner-card-block {
        .font-18-roboto-bold {
          font-size: 13px;
          line-height: 1.3;
        }
        .new-home-card-content-text-italic {
          font-size: 10px;
          line-height: 1;
        }
        .new-home-card-content-para-text {
          font-size: 12px;
          line-height: 1.3;
        }
        a {
          span {
            font-size: 10px;
            line-height: 1;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
}
