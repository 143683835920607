.newsletter-btn {
  background: transparent;
  color: white;
}
.customModal-website--newsletter {
  // width: vw(1033px);
  width: 60% !important;
  max-width: 60% !important;
  background-color: #29294f !important;
  border-radius: vw(19px);
  color: white !important;
  padding: 0 !important;
}

.newsletter-div {
  // padding-top: vw(75px);
  // padding-bottom: vw(60px);
  text-align: center;
  .newsletter-title {
    font-size: vw(50px);
    line-height: vw(60.95px);
    //@include fontMontserratBold;
    @include fontRobotoBold;
    margin-bottom: vw(19px);
    @media only screen and (max-width: 900px) {
      font-size: 25px;
      line-height: 1.3;
      margin: 0px;
    }
  }
  h4 {
    @media only screen and (max-width: 900px) {
      font-size: 15px;
      line-height: 1.3;
    }
  }
}

.newsletter-form-div {
  width: 80%;
  padding: vw(80px) 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
  .subscription-main{
    // .contact-us-input-div{
    //   margin-bottom: 10px;
    // }
    .validation-errors-forget-password{
      font-size: 1rem;
      width: 100%;
    }
  }
  form {
    width: 100%;
    .contact-us-input-div {
      width: 100%;
      margin: auto;
      @media only screen and (max-width: 767px) {
        // width: 100%;
        margin-bottom: 20px;
        padding: 0px;
      }
      input {
        width: 100%;
        font-size: 15px;
        padding: 5px 20px;
        line-height: 1.5;
        @media only screen and (max-width: 767px) {
          // width: 100%;
          font-size: 13px;
          padding: 3px 10px;
        }
      }
    }
  }
  @media only screen and (max-width: 900px) {
    padding: 30px 0px;
  }
}
.newsletter-submit-btn {
  width: vw(226px); 
  height: vw(54px);
  background: #29294f;
  color: white;
  font-size: vw(20px);
  line-height: vw(24px);
  //@include fontMontserratBold;
  @include fontRobotoBold;
  margin-top: vw(84px);
  @media only screen and (max-width: 900px) {
    font-size: 15px;
    line-height: 1.3;
    padding: 7px 25px;
    height: auto;
    width: auto;
  }
}
/*==============================================
            newsletter-thank-you
================================================*/
.customModal-website--newsletter-thank-you {
  width: vw(614px);
  background-color: #29294f !important;
  border-radius: vw(19px);
  color: white !important;
  padding: 0 !important;
}
.newsletter-thnak-you-div {
  text-align: center;
  padding-top: vw(86px);
  padding-bottom: vw(102px);
}
.newsletter-vl-logo {
  width: vw(159px);
  height: auto;
  margin-bottom: vw(40px);
}
.newsletter-thank-you-text {
  font-size: vw(32px);
  line-height: vw(39.01px);
  //@include fontMontserratBold;
  @include fontRobotoBold;
  margin-bottom: vw(18px);
}
.newsletter-submit-btn--thank-you {
  margin-top: vw(40px);
}
