// .vl-topnavbar-page-title,
// .vl-topnavbar {
//   padding: vw(84.51px) vw(50px) 0 vw(52px);
// }
.vl-topnavbar-page-title {
  background-color: #fff;
  .vl-topnavbar-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .mobile-menubar{
      margin-top: 6px;
    }
  }
}
.vl-topnavbar__noImageTitle {
  padding-left: vw(40px);
}

.vl-topnavbar {
  position: absolute;
  right: vw(50px);
}

.vl-topnavbar__activeMenu {
  background-color: #ffffff;
}

.vl-topnavbar__title {
  // max-width: 50%;
  word-break: break-word;
  margin-top: 6px;
  color: #000;
  @media only screen and (max-width: 900px) {
    font-size: 25px;
  }
  @media only screen and (max-width: 575px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 480px) {
    // line-height: 7.1875vw;
    max-width: 100%;
    font-size: 18px;
  }
}

.vl-topnavbar__title-text {
  display: flex;
  align-items: center;
  .vl-topnavbar__title-icon-block {
    width: vw(32px);
    margin-right: vw(26px);
    @media only screen and (max-width: 900px) {
      width: 20px;
    }

    img {
      width: inherit;
      vertical-align: middle;
    }
  }
}

.vl-topnavbar__search-block {
  margin-right: vw(24px);
  width: vw(385px);
  height: vw(54px);
  background-color: rgba(196, 196, 196, 0.17);
  border-radius: vw(13px);
  position: relative;

  form {
    width: inherit;
    height: inherit;
    border-radius: inherit;
  }

  button {
    width: vw(12px);
    height: inherit;
    padding: 0;
    margin-right: vw(10px);
    margin-left: vw(18px);
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    background-color: transparent;

    img {
      width: inherit;
      height: auto;
    }
  }

  input {
    position: absolute;
    left: vw(40px);
    font-size: vw(12px);
    line-height: vw(15px);
    @include fontRobotoMedium;
    color: rgba(0, 0, 0, 1);
    background-color: transparent;
    border: none;
    width: 86%;
    height: inherit;
    border-radius: inherit;

    &::placeholder {
      color: rgba(0, 0, 0, 0.46);
    }
  }
}

.vl-topnavbar__discuss-icon-block {
  width: vw(55px);
  height: vw(55px);
  margin-right: vw(5px);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .vl-topnavbar__discuss-icon {
    width: vw(32px);
  }
}

.vl-topnavbar__circle-icon-yellow {
  color: #ffd500;
}

.vl-topnavbar__notification-circle-icon-block {
  width: vw(55px);
  height: vw(55px);
  margin-right: vw(25px);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .vl-topnavbar__notification-circle-icon-block__inner {
    position: relative;
    width: vw(26px);
    height: vw(32px);

    .fa-circle {
      // width: vw(22px);
      font-size: vw(22px);
      position: absolute;
      top: vw(-6px);
      right: vw(-6px);
      z-index: 2;
    }

    .vl-topnavbar__bell-icon {
      width: vw(26px);
      position: absolute;
      top: vw(2px);
      left: 0;
      z-index: 1;
    }
  }
}

.vl-topnavbar__profile-img-block {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  cursor: default;
  span {
    font-size: 32px;
    line-height: 35px;
    @media only screen and (max-width: 1140px) {
      font-size: 24px;
    }
    @media only screen and (max-width: 525px) {
      margin-bottom: 0px;
      font-size: 20px;
    }
  }
  img {
    width: inherit;
    height: inherit;
    border-radius: inherit;
  }
  @media only screen and (max-width: 900px) {
    width: 35px;
    height: 35px;
  }
  @media only screen and (max-width: 425px) {
    width: 30px;
    height: 30px;
  }
}

.vl-topnavbar__profile-img-block--text {
  background-color: #ffffff;
}

// start up details title
.vl-topnavbar-page-title__title-div {
  padding-right: vw(34px);
  max-width: 50%;

  .vl-topnavbar__title {
    max-width: 100%;
  }

  .vl-topnavbar__title-back-arrow {
    color: #323277;
    font-size: vw(21px);
    margin-right: vw(26px);
  }
}

.vl-topnavbar__follow-btn {
  width: 135px;
  height: 44px;
  text-align: left;
  border: 1px solid #645f5f;
  padding: 9px;
  background-color: transparent;
  margin-right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    padding-left: 9px;
    font-size: 18px;
    @media only screen and (max-width: 768px) {
      font-size: 10px;
      line-height: 0px;
    }
    // @media only screen and (max-width: 425px) {
    //   font-size: 10px;
    // }
  }
  .fa {
    font-size: 25px;
    font-weight: 400 !important;
    margin-right: 5px;
    vertical-align: middle;
    @media only screen and (max-width: 768px) {
      font-size: 10px;
    }
    // @media only screen and (max-width: 425px) {
    //   font-size: 10px;
    //   margin-right: 3px;
    // }
  }
  .vl-topnavbar__title-icon-block {
    width: vw(28px);
    margin-right: vw(10px);
  }
  @media only screen and (max-width: 768px) {
    width: auto;
    height: auto;
    padding: 5px 20px;
  }
  @media only screen and (max-width: 425px) {
    padding: 5px 10px;
  }
}

.vl-topnavbar__follow-btn--following {
  border-color: #292950;
  background-color: #292950;
  color: #feffff;

  span {
    padding-left: vw(5px);
  }
}

// notification block
.vl-topnavbar-notification-block {
  width: vw(296px);
  height: auto;
  color: rgba(0, 0, 0, 0.78);
  background: #ffffff;
  box-shadow: 0 vw(4px) vw(9px) rgba(0, 0, 0, 0.25);
  border-radius: vw(7px);

  position: absolute;
  top: vw(158px);
  right: vw(29px);
  z-index: 1;
  display: none;
}

.vl-topnavbar-notification-block__overflow {
  word-break: break-all;
  height: vw(228px);
  overflow-y: auto;
  margin: vw(2px) vw(7px) vw(1px) vw(19px);
  padding-right: vw(7px);
}

.vl-topnavbar-notification-block__text1 {
  font-size: vw(16px);
  line-height: vw(20px);
  @include fontRobotoMedium;
  margin-bottom: vw(10px);
  color: rgba(0, 0, 0, 0.46);
  padding-top: vw(26px);
  padding-left: vw(19px);
}

.vl-topnavbar-notification-block__text2 {
  font-size: vw(11px);
  line-height: vw(13px);
  margin-bottom: vw(7px);
  @include fontRobotoRegular;
}

.vl-topnavbar-notification-block__text-2-sub-text1 {
  @include fontRobotoBold;
}

.vl-topnavbar-notification-block__text3 {
  font-size: vw(11px);
  line-height: vw(15px);
  @include fontRobotoRegular;
  margin-bottom: vw(7px);
}

.vl-topnavbar-notification-block__text4 {
  color: #4285f4;
  margin-bottom: vw(10px);
  font-size: vw(11px);
  line-height: vw(15px);
  @include fontRobotoBold;
}

.vl-topnavbar-notification-block__text-div-border-bottom {
  border-bottom: vw(2px) solid rgba(0, 0, 0, 0.12);
  margin-bottom: vw(5px);
}

.vl-topnavbar-notification-block__overflow {
  .vl-topnavbar-notification-block__text-div-border-bottom:last-child {
    border-bottom: 0;
    margin-bottom: 0;
  }
}

.vl-topnavbar-notification-block__text5 {
  font-size: vw(11px);
  line-height: vw(13px);
  margin-bottom: vw(7px);
  @include fontRobotoBold;
}

.vl-topnavbar-notification-block__bottom-link-outer-block {
  border-top: vw(2px) solid rgba(0, 0, 0, 0.12);
  text-align: center;
  padding-bottom: vw(10px);
}
