.vl-form-input--bordered-country-flag {
  background-color: $form-field-background;
  border: vw(2px) solid $form-field-border-color;
  border-radius: $form-field-border-radius;
  margin-bottom: vw(55px) !important;
  @media only screen and (max-width: 900px) {
    height: 40px;
  }

  label + div {
    padding-bottom: 0;
  }
  .ReactFlagsSelect-module_selectValue__152eS {
    @media only screen and (max-width: 900px) {
      span {
        font-size: 13px !important;
      }
    }
  }
  ul {
    border: vw(2px) solid rgba(0, 0, 0, 0.32);
    border-radius: vw(8px);
    background: #ffffff;
    margin-top: vw(4px);
    padding: 0;
    max-height: vw(300px);

    div + li {
      border-bottom: vw(2px) solid rgba(0, 0, 0, 0.1);
    }

    div:first-child {
      padding-top: vw(10px);
      width: 100%;
      height: vw(50px);
    }

    input {
      position: absolute;
      top: 0;
      color: $form-field-font-color;
      max-width: 100%;
      width: 100%;
      height: 100%;
      border: none;
      border-bottom: vw(2px) solid rgba(0, 0, 0, 0.1);
      background-color: inherit;
      border-radius: inherit;
      padding: 0;
      padding-left: vw(34px);
      padding-right: vw(34px);
      font-size: $form-field-font-size;
      line-height: $form-field-line-height;
      margin: 0;
      @include fontRobotoBold;

      &::placeholder {
        color: $form-field-placeholder-color;
        @include fontRobotoItalic;
      }
    }

    input:focus {
      outline: none;
    }
  }

  li {
    padding: vw(11px) vw(10px) !important;
    margin: 0;
    // height: vw(30px);

    &::marker {
      font-size: $form-field-font-size;
      line-height: $form-field-line-height;
      @include fontRobotoBold;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }

    span {
      padding-left: vw(10px);
      color: #000000 !important;
      font-size: vw(16px) !important;
      line-height: vw(19.5px);
      @include fontRobotoBold;
    }
  }
  @media only screen and (max-width: 900px) {
    margin-bottom: 20px !important;
    li {
      span {
        span {
          font-size: 13px !important;
          line-height: 1.5;
        }
      }
    }
  }
}

#rfs-btn {
  color: $form-field-font-color;
  position: absolute;
  top: 0;
  left: 0;
  max-width: inherit;
  width: 100%;
  height: vw(58px);
  border: vw(2px) solid $form-field-border-color;
  border-radius: $form-field-border-radius;
  position: relative;
  border: none;
  background-color: inherit;
  border-radius: inherit;
  padding: 0;
  padding-left: vw(34px);
  padding-right: vw(34px);
  font-size: $form-field-font-size !important;
  line-height: $form-field-line-height;
  @include fontRobotoBold;
  @media only screen and (max-width: 900px){
    height: 40px;
  }

  span:first-child {
    padding: 0 !important;
  }

  span {
    font-size: $form-field-font-size;
    line-height: $form-field-line-height;
    @include fontRobotoBold;
    margin: 0;
  }

  &::after {
    border-top: vw(6px) solid #000000;
    border-left: vw(6px) solid transparent;
    border-right: vw(6px) solid transparent;
    border-bottom: 0;
  }

  &[aria-expanded="true"]:after {
    border-top: 0;
    border-left: vw(6px) solid transparent;
    border-right: vw(6px) solid transparent;
    border-bottom: vw(6px) solid #000000;
  }

  &::placeholder {
    color: $form-field-placeholder-color;
    @include fontRobotoItalic;
  }
  @media only screen and (max-width: 768px) {
    height: 40px;
    span {
      font-size: 13px;
      line-height: 2;
    }
  }
}

// disabled
.vl-form-input--bordered-country-flag div:first-child {
  padding-bottom: 0;
}

.vl-form-input--bordered-country-flag--disabled {
  border-color: $form-field-disabled-border-color;

  button {
    &::after {
      display: none;
    }
  }
}
