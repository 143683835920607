.vl-select-container-explore {
  width: vw(277px);
  height: vw(54px);
  margin-left: vw(13px);

  .vl-select-explore {
    &__control {
      font-size: vw(14px) !important;
      line-height: vw(16.5px);
      @include fontMontserratBlack;
      border: none !important;
      box-shadow: none;
      border-radius: vw(13px);
      background: rgba(196, 196, 196, 0.17) !important;
      padding: 0 vw(18px) 0 vw(25px) !important;
      min-height: -webkit-fill-available !important;
      outline: none !important;
      color: rgba(0, 0, 0, 1);
      &:focus {
        border: none !important;
        outline: none !important;
      }
    }

    &__value-container {
      padding: 0 !important;
    }

    &__placeholder {
      color: rgba(0, 0, 0, 1) !important;
      @include fontRobotoMedium;
    }
    &__single-value {
      color: rgba(0, 0, 0, 1) !important;
      @include fontRobotoMedium;
    }
    &__indicator {
      color: #000000 !important;
      padding: 0 !important;

      svg {
        width: vw(14px) !important;
        height: vw(14px) !important;
      }
    }

    &__indicator-separator {
      display: none !important;
    }
    // &__option--is-selected {
    //   background-color: lightblue !important;
    // }

    &__menu {
      margin-top: vw(5px);
      z-index: 2;
    }

    &__menu,
    &__option,
    &__option--is-focused,
    &__option--is-selected {
      //min-width: min-content;
      min-width: max-content;
    }

    &__menu-list {
      padding: 0 !important;
      color: #000000 !important;
      font-size: vw(14px) !important;
      line-height: vw(16.5px);
      @include fontRobotoMedium;
    }
  }
}
