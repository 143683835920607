.vl-img-crop-modal-content-div {
  padding-left: vw(30px);
  @media only screen and (max-width: 900px) {
    padding-left: 0px;
  }
}
.crop_modal_main {
  .crop_image_modal_text {
    padding-top: 0 !important;
  }
}
.vl-img-crop-modal-title {
  color: #ffffff;
  font-size: vw(36px);
  line-height: vw(42.19px);
  margin-bottom: vw(49px);
  @include fontRobotoMedium;
  text-align: center;
  @media only screen and (max-width: 900px) {
    font-size: 15px;
    line-height: 1.5;
  }
}

.vl-img-crop-modal-text1 {
  color: #ffffff;
  font-size: vw(20px);
  line-height: vw(23px);
  margin-bottom: 0;
  @include fontRobotoRegular;
  @media only screen and (max-width: 900px) {
    font-size: 15px;
    line-height: 1.3;
  }
}

.vl-img-crop-modal-text-button-row {
  // margin-bottom: vw(47px);
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .upload-image-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
    }
    .uploaded-image {
      @media only screen and (max-width: 900px) {
        margin-bottom: 10px;
      }
    }
  }
  .upload-img__btn-input-block--imgCropModal {
    @media only screen and (max-width: 768px) {
      margin: 10px 0 !important;
    }
  }
  @media only screen and (max-width: 575px) {
    justify-content: center;
    flex-direction: column;
  }
}

.vl-ReactCrop-block,
.ReactCrop,
.ReactCrop-display-cropped-img {
  width: vw(400px);
  height: vw(400px);
  margin-bottom: vw(30px);
  background: rgba(78, 78, 81, 0.3);
  border-radius: vw(3px);
  margin-bottom: vw(10px);
  overflow: hidden;
  @media only screen and (max-width: 900px) {
    width: 95%;
  }
  @media only screen and (max-width: 575px) {
    width: 100%;
    height: auto;
    min-height: 150px;
    margin: 0;
  }
}

.vl-ReactCrop-block,
.ReactCrop {
  overflow: hidden;
  margin: 0;
}

.vl-ReactCrop-block {
  border: 1px dashed rgba(254, 255, 255, 0.3);
  // border: 1px solid transparent !important;
}

.ReactCrop {
  div {
    width: auto;
    height: auto;
  }

  img {
    width: inherit;
    height: inherit;
  }
}

.ReactCrop-display-cropped-img {
  // margin-left: vw(30px);
  border: 1px solid rgba(254, 255, 255, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReactCrop-modal-upload-img-button-block {
  margin: vw(15px) vw(20px) vw(30px) 0;
  width: auto;
  @media only screen and (max-width: 900px) {
    margin: auto;
  }
}

.ReactCrop__rule-of-thirds-vt::before,
.ReactCrop__rule-of-thirds-vt::after,
.ReactCrop__rule-of-thirds-hz::before,
.ReactCrop__rule-of-thirds-hz::after {
  background-color: rgba(0, 0, 0, 0.3) !important;
}
.uplodeimage-two {
  @media only screen and (max-width: 900px) {
    margin-top: 20px;
  }
}
.vl-image-crop-modal-note-1,
.vl-image-crop-modal-note-2 {
  margin-bottom: vw(20px);
  color: #ffffff;
  @media only screen and (max-width: 900px) {
    font-size: 13px;
    margin-top: 0px;
    line-height: 1.3;
  }
}

.vl-image-crop-modal-note-1 {
  padding-top: vw(10px);
  font-style: italic;
  color: rgba(255, 255, 255, 0.42);
  @media only screen and (max-width: 900px) {
    font-size: 12px;
    margin-top: 0px;
    line-height: 1.3;
    margin: 0px;
  }
}

.vl-image-crop-modal-note-3 {
  color: rgba(255, 255, 255, 0.42);
  font-size: vw(14px);
  line-height: vw(16.41px);
  margin-bottom: 0;
  @include fontRobotoRegular;
  font-style: italic;
  @media only screen and (max-width: 900px) {
    font-size: 13px;
    line-height: 1.3;
  }
  @media only screen and (max-width: 575px) {
    margin-bottom: 20px;
    margin-top: 5px;
  }
}

// select image button
.upload-img__btn-input-block.upload-img__btn-input-block--imgCropModal {
  background: $vl-web-app-pages-text-color;
  border: 1px solid rgba(254, 255, 255, 0.8);
  width: vw(146px);
  height: vw(46px);
  border-radius: vw(59px);
  margin: 0;
  margin-bottom: vw(20px);
  @media only screen and (max-width: 900px) {
    width: auto;
    height: auto;
    padding: 5px 20px;
  }

  .upload-img__btn-input-block-text {
    color: #ffffff !important;
    font-size: vw(14px);
    line-height: vw(16.41px);
    @include fontRobotoMedium;
    @media only screen and (max-width: 900px) {
      font-size: 14px;
      line-height: 1.3;
    }
  }
}
.logo-dimension-text {
  color: #000;
  text-align: center;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}

// finish button
.vl-img-crop-modal-finish-btn-block {
  margin-top: vw(30px);
  padding-bottom: vw(10px);
  button {
    width: vw(231px);
    height: vw(58px);
    border: none;
    color: #292950;
    background: #ffffff;
    border-radius: vw(5px);
    font-size: vw(20px);
    line-height: vw(23.44px);
    @media only screen and (max-width: 900px) {
      width: auto;
      height: auto;
      font-size: 13px;
      line-height: 1.3;
      padding: 7px 30px;
    }
    @media only screen and (max-width: 575px) {
      margin-top: 20px;
    }
  }
}
