.font-16-montserrat-regular {
  margin-bottom: 0;
  font-size: vw(16px);
  line-height: vw(19.82px);
  @include fontRobotoRegular;
}
.font-22-montserrat-regular {
  margin-bottom: 0;
  font-size: vw(22px);
  line-height: vw(26.82px);
  //@include fontMontserratRegular;
  @include fontRobotoRegular;
}
.font-25-montserrat-regular {
  margin-bottom: 0;
  font-size: vw(25px);
  line-height: vw(30.48px);
  //@include fontMontserratRegular;
  @include fontRobotoRegular;
}
.font-30-montserrat-regular {
  margin-bottom: 0;
  font-size: vw(30px);
  line-height: vw(36.57px);
  //@include fontMontserratRegular;
  @include fontRobotoRegular;
}
.font-35-montserrat-regular {
  margin-bottom: 0;
  font-size: vw(35px);
  line-height: vw(42.67px);
  //@include fontMontserratRegular;
  @include fontRobotoRegular;
}

.font-16-montserrat-medium {
  margin-bottom: 0;
  font-size: vw(16px);
  line-height: vw(19.5px);
  //@include fontMontserratMedium;
  @include fontRobotoMedium;
}
.font-22-montserrat-medium {
  margin-bottom: 0;
  font-size: vw(22px);
  // line-height: vw(26.82px);
  //@include fontMontserratMedium;
  @include fontRobotoMedium;
}
.font-45-montserrat-medium {
  margin-bottom: 0;
  font-size: vw(45px);
  line-height: vw(54.86px);
  //@include fontMontserratMedium;
  @include fontRobotoMedium;
}

.font-40-montserrat-semiBold {
  margin-bottom: 0;
  font-size: vw(40px);
  line-height: vw(48.76px);
  //@include fontMontserratSemiBold;
  @include fontRobotoBold;
}
.font-20-montserrat-bold {
  margin-bottom: 0;
  font-size: vw(20px);
  line-height: vw(24.34px);
  //@include fontMontserratBold;
  @include fontRobotoBold;
}
.font-22-montserrat-bold {
  margin-bottom: 0;
  font-size: vw(22px);
  line-height: vw(26.82px);
  //@include fontMontserratBold;
  @include fontRobotoBold;
}
.explore-heading-font {
  font-size: vw(22px);
  // font-weight: 700;
  font-weight: 500;
  margin: 0;
  // line-height: vw(30.57px);
  // @include fontRobotoBold;
  @media only screen and (min-width: 300px) and (max-width: 900px) {
    font-size: 15px;
  }
}
.explore-subheading-font {
  margin-bottom: 0;
  font-size: vw(22px);
  font-weight: 500;
  // line-height: vw(30.57px);
  // @include fontRobotoMedium;
  @media only screen and (min-width: 300px) and (max-width: 900px) {
    font-size: 13px;
  }
}
.font-25-montserrat-bold {
  margin-bottom: 0;
  font-size: vw(25px);
  line-height: vw(30.57px);
  @include fontRobotoBold;
  //@include fontMontserratBold;
}
.font-30-montserrat-bold {
  margin-bottom: 0;
  font-size: vw(30px);
  line-height: vw(36.57px);
  // @include fontMontserratBold;
  @include fontRobotoBold;
}
.font-35-montserrat-bold {
  margin-bottom: 0;
  font-size: vw(35px);
  line-height: vw(42.67px);
  //@include fontMontserratBold;
  @include fontRobotoBold;
}
.font-40-montserrat-bold {
  margin-bottom: 0;
  font-size: vw(40px);
  line-height: vw(48.76px);
  // @include fontMontserratBold;
  @include fontRobotoBold;
}
.font-45-montserrat-bold {
  margin-bottom: 0;
  font-size: vw(45px);
  line-height: vw(54.85px);
  //@include fontMontserratBold;
  @include fontRobotoBold;
}
.font-50-montserrat-bold {
  margin-bottom: 0;
  font-size: vw(50px);
  line-height: vw(60.95px);
  // @include fontMontserratBold;
  @include fontRobotoBold;
}
.font-55-montserrat-bold {
  margin-bottom: 0;
  font-size: vw(55px);
  line-height: vw(67.05px);
  //  @include fontMontserratBold;
  @include fontRobotoBold;
}
