.vl-form-input {
  max-width: vw(598px);
  position: relative;
  @media only screen and (max-width: 900px) {
    max-width: 95% !important;
  }
  @media only screen and (max-width: 767px) {
    max-width: 100% !important;
  }
  label,
  .input-border-div {
    background-color: $form-field-background;
    @media only screen and (max-width: 900px) {
      font-size: 13px;
    }
  }

  label {
    color: #000000;
    font-size: $form-field-label-font-size;
    line-height: $form-field-label-line-height;
    @include fontRobotoRegular;
    margin-bottom: 0;
    position: absolute;
    top: vw(-8px);
    left: vw(34px);
    padding-right: vw(8px);
    padding-bottom: 0;
    padding-left: vw(8px);
    z-index: 1;
    @media only screen and (max-width: 900px) {
      font-size: 13px;
    }
  }

  .input-border-div {
    // max-width: inherit;
    width: inherit;
    height: vw(58px);
    border: vw(2px) solid $form-field-border-color;
    border-radius: $form-field-border-radius;
    position: relative;

    input,
    textarea {
      color: $form-field-font-color !important;
      position: absolute;
      top: 0;
      left: 0;
      max-width: inherit;
      width: 100%;
      height: 100%;
      border: none;
      background-color: inherit;
      border-radius: inherit;
      padding: 0;
      padding-left: vw(34px);
      padding-right: vw(34px);
      font-size: $form-field-font-size;
      line-height: $form-field-line-height;
      opacity: 1;
      @include fontRobotoBold;

      &::placeholder {
        color: $form-field-placeholder-color;
        @include fontRobotoItalic;
      }
      @media only screen and (max-width: 900px) {
        font-size: 13px;
        line-height: 1;
      }
    }
    @media only screen and (max-width: 900px) {
      height: 40px;
      max-width: 100%;
      width: 100%;
    }
  }

  input:disabled {
    color: $form-field-font-color;
  }

  .input-border-div--disabled {
    border-color: $form-field-disabled-border-color;
  }
  @media only screen and (max-width: 900px) {
    width: 100% !important;
    max-width: 100%;
  }
}

.vl-form-input.vl-form-input--errorColor input,
.vl-text-color-red {
  color: red;
}

.error-message {
  color: red;
  font-size: vw(14px);
  line-height: vw(16px);
  @include fontRobotoRegular;
  margin-top: vw(7px);
  margin-bottom: vw(27px);
  margin-left: vw(34px);
  @media only screen and (max-width: 900px) {
    font-size: 10px;
    margin-bottom: 15px;
    margin-top: 3px;
    line-height: 1;
  }
}

.vl-form-input--textarea {
  .input-border-div {
    height: vw(334px);

    textarea {
      padding: vw(20px) vw(34px);
    }
    @media only screen and (max-width: 425px) {
      height: 210px;
    }
  }
}

.vl-form-input--textarea--mentor {
  max-width: vw(1318px);

  textarea {
    padding: vw(11px) vw(9px);

    &::placeholder {
      font-size: vw(24px);
      line-height: vw(28px);
      @include fontRobotoLight;
      font-style: italic;
    }
  }
}

// upload image

.upload-img__mainBlock {
  margin-bottom: vw(68px);
  width: vw(594px);
  .error-message {
    // margin-left: vw(205px);
    text-align: center;
    margin-left: -0.229167vw;
  }
  .upload-img__img-block {
    width: vw(157px);
    height: vw(157px);
    background: rgba(90, 155, 165, 0.0142);
    border-radius: vw(20px);
    margin: 0 auto;
    position: relative;
    margin-bottom: vw(20px);
    .close-profile {
      position: absolute;
      z-index: 2;
      right: -15px;
      top: -8px;
      @media only screen and (max-width: 480px) {
        right: 40px;
      }
      @media only screen and (max-width: 384px) {
        right: 15px;
      }
      @media only screen and (min-width: 2560px) {
        right: -55px;
      }
      .investor-close {
        @media only screen and (max-width: 900px) {
          font-size: 15px;
        }
        @media only screen and (min-width: 2560px) {
          font-size: 45px;
        }
      }
    }
    .user-profile-picture {
      justify-content: center;
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: inherit;
      @media only screen and (max-width: 900px) {
        height: 110px;
        width: 110px;
        // margin-bottom: 30px;
      }
    }
    @media only screen and (max-width: 900px) {
      height: 110px;
      width: 110px;
      // border: 1px solid;
      margin-bottom: 30px;
    }
    @media only screen and (max-width: 575px) {
      display: flex;
      justify-content: center;
      align-items: center;
      height: auto;
      width: 50%;
      margin-bottom: 0;
    }
  }

  .upload-img__btn-input-block {
    position: relative;
    width: vw(267px);
    height: vw(54px);
    border: vw(3px) solid #000000;
    border-radius: $form-field-border-radius;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    input {
      position: absolute;
      left: 0;
      top: 0;
      border-radius: inherit;
    }

    input {
      // cursor: pointer;
      width: 100%;
      height: auto;
      z-index: 1;
      opacity: 0;
    }

    .upload-img__btn-input-block-text {
      font-size: vw(18px);
      line-height: vw(21px);
      @include fontRobotoBold;
      margin-bottom: 0;
      color: #000000;
    }
    @media only screen and (max-width: 900px) {
      height: 30px;
      width: auto;
      margin: auto;
      margin-top: 20px;
      .upload-img__btn-input-block-text {
        font-size: 10px !important;
        padding: 5px !important;
        line-height: 1 !important;
      }
    }
    @media only screen and (max-width: 768px) {
      margin-top: 20px;
    }
    @media only screen and (max-width: 525px) {
      margin-top: 0;
    }
  }
  @media only screen and (max-width: 900px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
  }
}

.upload-img__mainBlock--investor {
  margin-bottom: vw(53px);
}

.upload-img__mainBlock--investor-disabled {
  margin-bottom: vw(75px);
  margin-top: 10px;
}
.input-padding {
  @media only screen and (max-width: 1024px) {
    margin: 0 15px;
    width: 100%;
  }
  @media only screen and (max-width: 768px) {
    margin: 0;
  }
}
