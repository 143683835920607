.react-pdf__Document,
.react-pdf__Page,
.react-pdf__Page__canvas {
  width: inherit !important;
  // height: inherit !important;
  height: auto !important;
  text-align: center;
}

.react-pdf__message,
.react-pdf__message--error {
  padding-top: vw(195px);
  padding-bottom: vw(195px);
}

.vl-pitch-deck-pdf-thumbnail-block {
  .react-pdf__Page {
    cursor: pointer;
  }

  .react-pdf__Page:hover {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      display: block;
      width: inherit;
      height: inherit;
      background-color: rgba(41, 41, 80, 0.11);
    }
  }
}
