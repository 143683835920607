.mentor-young-startups {
  background-color: #fff;
  height: 100vh;
  //   width: calc(100wh - 300px);
  color: black;
  font-family: "Roboto-regular";
  margin-left: 300px;
  @media screen and (max-width: 1150px) {
    margin-left: 250px;
  }
  @media screen and (max-width: 900px) {
    margin-left: 0;
  }
  .mentor-young-startups-main {
    position: relative;
    height: 100%;
    .mentor-young-startups-wrap {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .mentor-young-startups-image-wrap {
        height: auto;
        width: 380px;
        margin: 0 auto;
        .mentor-young-startups-image {
          height: auto;
          width: 100%;
        }
        @media screen and (max-width: 768px) {
          height: auto;
          width: 270px;
        }
      }
      .mentor-young-startups-title {
        font-size: 42px;
        font-weight: 700;
        margin-bottom: 10px;
        font-family: "Roboto-regular";
        text-align: center;
        @media screen and (max-width: 768px) {
          font-size: 20px;
        }
      }
      .mentor-young-startups-content {
        width: 460px;
        font-size: 20px;
        font-weight: 500;
        line-height: 24.38px;
        text-align: center;
        color: #000000b0;
        font-family: "Roboto-regular";
        margin: 0 auto 26px;
        @media screen and (max-width: 768px) {
          width: auto;
          font-size: 12px;
          line-height: 15px;
          margin: 0 auto 16px;
        }
      }
      .mentor-young-startups-link-wrap {
        width: 500px;
        height: 50px;
        background: #4e4e51;
        color: #ffffff;
        border-radius: 59px;
        display: flex;
        justify-content: center;
        align-items: center;
        .mentor-young-startups-link {
          font-size: 24px;
          color: #ffffff;
          font-weight: 500;
          cursor: pointer;
          @media screen and (max-width: 768px) {
            font-size: 13px;
            text-align: center;
          }
        }
        @media screen and (max-width: 768px) {
          width: auto;
          padding: 10px;
          height: auto;
        }
      }
    }
  }
  .footer-main {
    margin-top: 0;
  }
}
