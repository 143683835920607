.vl-notification-main-page {
  padding: vw(108px) vw(29px) vw(71px) vw(71px);
}

.vl-notification-card {
  border-bottom: vw(2px) solid rgba(0, 0, 0, 0.19);
  padding-bottom: vw(20px);
  margin-bottom: vw(25px);
}

.vl-notification-card__img-block {
  width: vw(54px);
  height: vw(55px);
  border-radius: vw(70px);
  background: #dadada;
  margin-right: vw(27px);

  img {
    width: 100%;
    height: 100%;
    border-radius: inherit;
  }
}

.vl-notification-card__text1 {
  line-height: vw(31px);
  padding-right: vw(133px);
}

.vl-notification-card__span-text1 {
  color: #6969b7;
  @include fontRobotoBold;
}

.vl-notification-card__span-text2 {
  color: rgba(0, 0, 0, 0.78);
  @include fontRobotoBold;
}

.vl-notification-card__text2 {
  color: #4285f4;
  padding-top: vw(12px);
}

.vl-notification-card-overflow {
  padding-right: vw(172px);
  height: vw(518px);
  overflow: auto;
}
