// custom-radio-btn-block
.custom-radio-btn-block {
  position: relative;
  margin-right: vw(103px);
  margin-bottom: vw(5px);
  display: flex;
  align-items: center;

  label {
    color: #000000;
    padding-left: vw(45px);
    cursor: pointer;
    font-size: vw(24px);
    line-height: vw(29px);
    @include fontRobotoBold;
    margin-bottom: 0;
  }

  input,
  &__circle-custom {
    height: vw(27px);
    width: vw(27px);
    position: absolute;
    top: 0;
    left: 0;
  }

  input {
    z-index: 2;
    opacity: 0;
    cursor: pointer;
  }

  &__circle-custom {
    border: vw(4px) solid rgba(0, 0, 0, 0.72);
    background-color: transparent;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input:checked ~ &__circle-custom {
    border: vw(4px) solid rgba(0, 0, 0, 0.72);
    background-color: transparent;
  }

  &__circle-custom:after {
    content: "";
    position: absolute;
    opacity: 0;
    user-select: none;

    width: vw(15px);
    height: vw(15px);
    border-radius: 100%;
    background: rgba(0, 0, 0, 0.72);
  }

  input:checked ~ &__circle-custom:after {
    opacity: 1;
    transition: all 0.1s linear;
  }
}

@media only screen and (max-width: 1024px) {
  .custom-radio-btn-block {
    input {
      height: 15px;
      width: 15px;
    }
    span {
      height: 13px;
      width: 13px;
      &::after {
        content: "";
        height: 7px;
        width: 7px;
        // top: 2px;
        // right: 2px;
      }
    }
    label {
      line-height: 1;
      font-size: 13px;
      margin-left: 10px;
    }
  }
}
.custom-radio-btn-block--investor {
  margin-right: vw(50px);
}

.custom-radio-btn-block--disabled {
  label,
  input {
    cursor: default;
  }

  .custom-radio-btn-block__circle-custom {
    border-color: $form-field-disabled-border-color;
  }

  input:checked ~ .custom-radio-btn-block__circle-custom {
    border-color: $form-field-disabled-border-color;
  }

  .custom-radio-btn-block__circle-custom:after {
    background: $form-field-disabled-border-color;
  }
}
