// @import url("../../../imgs/desktop/icon/menu.png");
.start-up-details-page {
  .vl-navbar {
    margin: 0;
    // padding: vw(56px) vw(48px) 0 vw(74px);
    padding: 12px 25px;
    box-shadow: 0px 5px 4px #00000040;
    width: 100%;
    position: fixed;
    top: 0;
    background: #292950;
    z-index: 500;
    @media only screen and (min-width: 300px) and (max-width: 425px) {
      padding: 0;
    }
    @media only screen and (min-width: 424px) and (max-width: 767px) {
      padding: 8px;
    }
  }
  .navbar-logo {
    // width: vw(256px);
    width: 14.33333vw;
    height: auto;
    @media only screen and (min-width: 300px) and (max-width: 900px) {
      width: 150px;
    }
  }
  // burger menu
  .bm-burger-button {
    //margin-right: vw(16px) !important;
    span {
      display: none;
    }
    img,
    button {
      display: flex;
      align-items: center;
      width: vw(73px) !important;
      height: vw(40px) !important;
      @media only screen and (min-width: 300px) and (max-width: 900px) {
        width: 29px !important;
        height: 20px !important;
      }
    }

    img {
      float: right;
    }
    // button {
    //   // left: unset !important;
    //   // right: vw(48px) !important;
    //   //top: vw(60px) !important;
    //   position: unset !important;
    //   height: 50px !important;
    //   width: 50px !important;
    //   // top: vw(142px) !important;
    //   // background: url("../../../imgs/desktop/icon/icons8-menu-50.png") !important;
    //   &::before {
    //     content: "\f0c9";
    //     font-family: FontAwesome;
    //     color: #ffffff;
    //     font-size: 50px;
    //     font-weight: normal;
    //     @media only screen and (min-width: 300px) and (max-width: 429px) {
    //       font-size: 30px;
    //     }
    //   }
    // }
  }
  .bm-menu {
    width: 100%;
    background: #242427 !important;
    border-radius: vw(73px) 0 0 vw(73px);
    height: 100vh !important;
    //transform: matrix(-1, 0, 0, -1, 0, 0);
    //height: vw(1070px) !important ;
    @media only screen and (min-width: 300px) and (max-width: 429px) {
      width: 100%;
      height: auto !important;
      // border-radius: 30px 0 30px 30px;
      box-shadow: #000000d9 -4px 14px 22px;
    }
    @media only screen and (min-width: 300px) and (max-width: 430px) {
      width: 100%;
      height: 100vh !important;
      // border-radius: 30px 0 30px 30px;
      box-shadow: #000000d9 -4px 14px 22px;
    }
  }
  .bm-menu-wrap {
    //width: vw(658px) !important;
    width: vw(441px) !important;
    background-size: cover !important;
    background-position: bottom;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    top: 0;
    @media only screen and (min-width: 300px) and (max-width: 900px) {
      width: 100vw !important;
      align-items: end;
    }
  }

  .myOverlay {
    //background: #000000 !important;
    background-size: cover !important;
    background-position: bottom;
    // width: 100% !important;
    height: 100% !important;
    right: 0;
    top: vw(20px);
    background: inherit !important;
  }

  .menu-item {
    //width: max-content;
    margin: 0;
    //margin-bottom: vw(40px);
    font-size: vw(25px);
    line-height: vw(30.48px);
    //@include fontMontserratSemiBold;
    white-space: nowrap;
    @include fontRobotoBold;
    padding-bottom: vw(15px);
    position: relative;
    &:focus {
      outline: 0;
    }

    @media only screen and (min-width: 300px) and (max-width: 900px) {
      line-height: inherit;
      font-size: 17px;
      line-height: 2;
    }
  }

  .menu-item:hover {
    cursor: pointer;
  }

  .bm-item-list {
    margin-top: vw(220px);
    //padding-left: vw(114.52px);
    padding-left: vw(54.52px);
    //text-align: center;
    overflow-y: hidden;
    height: auto !important;
    @media only screen and (min-width: 300px) and (max-width: 530px) {
      margin: 0;
      padding: 40px;
      height: auto !important;
    }
    @media only screen and (min-width: 530px) and (max-width: 768px) {
      height: 100vh !important;
    }
    @media only screen and (min-width: 424px) and (max-width: 768px) {
      padding: 0;
      margin: 40px;
    }
  }

  .bm-cross-button {
    margin-top: vw(50px) !important;
    margin-right: vw(50px) !important;
    right: 0 !important;
    top: 0 !important;
    width: fit-content !important;
    height: auto !important;
    .close-icon {
      font-size: vw(25px) !important;
      padding-left: vw(10px);
      font-weight: 100;
      @media only screen and (min-width: 300px) and (max-width: 900px) {
        font-size: 25px !important;
      }
    }
  }
}
