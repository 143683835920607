.mentor-details-main {
  background-color: #fff;
  height: 100vh;
  overflow-y: scroll;
  color: black;
  font-family: "Roboto-regular";

  .mentor-details-wrap {
    padding: 40px;
    height: auto;
    @media screen and (max-width: 992px) {
      padding: 30px 57px;
    }
    @media screen and (max-width: 781px) {
      padding: 20px 15px;
    }
    .details-title {
      color: #000;
      font-size: 20px;
      font-style: italic;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 20px;
      @media screen and (min-width: 2000px) {
        margin-bottom: 30px;
      }
    }
    .about-text-area {
      border-radius: 5px;
      // border: 1px solid #000;
      width: 100%;
      background: #fff;
      max-width: 971px;
      height: 161px;
      margin-bottom: 40px;
      .input-border-div {
        height: 100%;
      }
    }
    .profession-input-main {
      border-radius: 5px;
      border: 1px solid #272d2e;
      background: #fff;
      max-width: 447px;
      height: 31.681px;
      margin-bottom: 27px;
      .profession-input {
        max-width: 100%;
        height: 100%;
        label {
          background-color: #fff;
        }
        .input-border-div {
          height: 100%;
          border-color: transparent;
          background: #fff;
        }
      }
    }
    .mentor-profile-inputs {
      .vl-form-input--bordered-select-div--multiSelect {
        height: auto;
      }
      .vl-form-input {
        max-width: 100%;
        margin-bottom: 10px;
      }
      .mentor-profile-inputs-row {
        // margin-bottom: 20px !important;
        .mentor-profile-inputs-col {
          .vl-form-input {
            margin-bottom: 0;
            label {
              background-color: #fff;
            }
            .vl-select-container {
              border-color: #292950;
              background-color: #fff;
              height: 44px !important;
              @media screen and (min-width: 2000px) {
                height: 70px !important;
              }
            }
            .error-message {
              margin-bottom: 0;
              margin-left: 0;
            }
            .input-border-div {
              height: 39px;
              border-color: #292950;
              background-color: #fff;
            }
          }
          .minus-main {
            background-color: #292950;
            border-color: #292950;
            border-radius: 50%;
            @media screen and (max-width: 575px) {
              margin-left: 10px;
            }
            &:focus {
              box-shadow: none;
            }
          }
        }
      }
      .add-more-button {
        width: 100%;
        background-color: transparent;
        color: rgba(0, 0, 0, 0.46);
        border-color: transparent;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        &:focus {
          box-shadow: none;
        }
      }
      .save-btn-main {
        width: 100%;
        text-align: end;
        .save-btn {
          background: #676767;
          width: 176px;
          height: 60px;
          color: #fff;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
    }
    .mentor-profile-inputs-row {
      margin-bottom: 40px !important;
    }
    .mentor_profile_wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      margin-bottom: 50px !important;
      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        gap: 0;
      }
      .mentor_profiles_inputs {
        width: 100%;
        @media screen and (max-width: 992px) {
          width: 48%;
        }
        @media screen and (max-width: 425px) {
          width: 100%;
        }
        .vl-select__single-value {
          line-height: 1.5;
        }
        .vl-form-input {
          @media screen and (max-width: 900px) {
            max-width: 100% !important;
          }
        }
      }
      .mentor_area_main {
        width: 100px;
        height: 100%;
      }
      .mentor_cancel_icon {
        margin-bottom: 0;
        @media screen and (max-width: 768px) {
          width: 100%;
          display: flex;
          justify-content: flex-start;
        }
      }
      .upload_images_cloud_main {
        width: 60px;
        height: 60px;
        margin: 0;
        @media screen and (max-width: 768px) {
          margin-bottom: 20px;
        }
        @media screen and (min-width: 1800px) {
          width: 70px;
          height: 70px;
        }
        .upload-img__img-block {
          height: 100%;
          width: 100%;
          margin: 0;
        }
        .upload-img__img-block {
          width: 100%;
          height: 100%;
          position: relative;
          max-width: 400px;
          margin-bottom: 0 !important;
          cursor: pointer;
          &:hover {
            .overlay {
              opacity: 1;
            }
          }
          .upload-profile-img {
            display: block;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            @media screen and (min-width: 1800px) {
              width: 70px;
              height: 70px;
            }
          }
          .overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
            border-radius: 50%;
            transition: 0.3s ease;
            background: linear-gradient(2deg, #3e3e3e 0%, #00000000 100%);
            .upload_icon {
              width: 35px;
              height: 35px;
              color: white;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              text-align: center;
              .upload_image_icon {
                width: 100%;
                height: 100%;
              }
            }
          }
          .user-profile-picture {
            width: 60px;
            height: 60px;
            @media screen and (min-width: 1800px) {
              width: 70px;
              height: 70px;
            }
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .mentor_profile_input_main {
        width: 100%;
        display: flex;
        gap: 20px;
        justify-content: space-between;
        @media screen and (max-width: 992px) {
          gap: 0;
        }
        @media screen and (max-width: 425px) {
          flex-wrap: wrap;
        }
      }
    }
  }
  .mentor_inputs_wrap {
    .profession_input_main {
      height: 56px !important;
      max-width: 100% !important;
    }
    .mentor-profile-inputs {
      .mentor-profile-inputs-row {
        .mentor-profile-inputs-col {
          .vl-form-input {
            .vl-select-container {
              height: inherit !important;
            }
          }
        }
      }
    }
    .mentor_wrap_profile {
      margin-top: 30px;
    }
  }
}
.mentor-details-margin {
  margin-left: 300px;
  overflow: scroll;
  @media screen and (max-width: 1150px) {
    margin-left: 250px;
  }
  @media screen and (max-width: 900px) {
    margin-left: 0;
  }
}
.mentor-details-none {
  overflow: hidden;
  .mentor-details-wrap {
    padding: 10px 0;
  }
}
