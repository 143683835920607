/* ===============================================================
                    
                        datepicker
                    
================================================================== */

.date-picker-common {
  .disabled-date {
    pointer-events: none;

    &.react-datepicker__day--keyboard-selected {
      background-color: transparent !important;
    }
  }
}

// start datepicker

.date-picker-common {
  width: vw(598px);
  height: vw(58px);
  margin-bottom: vw(50px);
  font-size: $form-field-font-size;

  // date
  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker__input-container input {
    width: 100%;
    height: inherit;
    &::placeholder {
      @media only screen and (max-width: 900px) {
        font-size: 13px !important;
      }
    }
  }

  .react-datepicker__month-container {
    width: max-content;
    // width: 340px !important;
    @media only screen and (max-width: 767px) {
      width: 270px !important;
    }
  }

  // time
  .react-datepicker-time__header {
    color: #ffffff !important;
  }

  // date
  .react-datepicker,
    // time
    .react-datepicker__time-list {
    color: #ffffff !important;
    font-family: "Roboto-Regular", sans-serif;
    border: 0 !important;
    background-color: #4a5055 !important;
    box-shadow: 0 vw(3px) vw(6px) rgba(0, 0, 0, 0.16) !important;
  }

  .react-datepicker__navigation {
    top: vw(18px) !important;
    border: vw(7px) solid transparent !important;
    height: 0 !important;
    width: 0 !important;
    @media only screen and (max-width: 767px) {
      border: 7px solid transparent !important;
      top: 15px;
    }
  }

  .react-datepicker__navigation--next {
    border-left-color: #ffffff !important;
  }

  .react-datepicker__navigation--previous {
    border-right-color: #ffffff !important;
  }

  .react-datepicker__header {
    padding-top: vw(16px) !important;
    background-color: transparent !important;
    border-bottom: 0 !important;
    @media only screen and (max-width: 767px) {
      padding-top: 15px !important;
    }
  }

  .react-datepicker-popper {
    z-index: 9;
  }

  .react-datepicker-popper[data-placement^="bottom"] {
    margin-top: vw(10px);
  }

  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__input-container {
    position: relative;
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    input {
      color: $form-field-font-color;
      font-size: $form-field-font-size;
      line-height: $form-field-line-height;
      @include fontRobotoBold;
      background: $form-field-background !important;
      box-shadow: 0 0 0 !important;
      border: vw(2px) solid $form-field-border-color;
      border-radius: $form-field-border-radius;
      text-align: left;
      padding-left: vw(30px);
      padding-right: vw(30px);
      @media only screen and (max-width: 1024px) {
        font-size: 13px;
        height: 40px !important;
      }
      /*new css
        font-size: vw(12px);
        line-height: vw(14px);
        color: #e2e2e2 !important;
        background: #2d3135 !important;
        box-shadow: vw(-2px) vw(-2px) vw(4px) rgba(74, 78, 83, 0.13),
          vw(2px) vw(2px) vw(6px) rgba(35, 37, 39, 0.8),
          vw(8px) vw(8px) vw(30px) #232527,
          vw(-6px) vw(-6px) vw(20px) rgba(74, 78, 83, 0.37) !important;
        @include fontLato;*/

      &:focus {
        outline: 0;
      }
    }
  }

  // time
  li.react-datepicker__time-list-item:hover,
      // date
    .react-datepicker__day:hover,
    .react-datepicker__month-text:hover,
    .react-datepicker__quarter-text:hover,
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected {
    // background-color: rgba(255, 255, 255, 0.1) !important;
    background-color: rgba(26, 32, 35, 0.2) !important;
    color: #ffffff !important;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range {
    // background-color: rgba(98, 102, 125, 0.8) !important;
    background-color: rgba(255, 255, 255, 0.2) !important;
    border-radius: vw(55px) !important;
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__day--selected {
    border-radius: vw(55px) !important;
  }

  .react-datepicker__current-month {
    font-size: vw(16px) !important;
    color: #ffffff !important;
    font-family: "Roboto-Medium", sans-serif;
    @media only screen and (max-width: 767px) {
      font-size: 13px !important;
      line-height: 1;
      margin-bottom: 10px;
    }
  }

  .react-datepicker__month {
    margin-top: 0;
  }

  .react-datepicker__day-names,
  .react-datepicker__current-month {
    text-transform: uppercase !important;
  }

  .react-datepicker__current-month {
    font-size: vw(16px) !important;
    @media only screen and (max-width: 767px) {
      font-size: 13px !important;
    }
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    font-size: vw(14px) !important;
    color: #ffffff !important;
    font-family: "Roboto-Regular", sans-serif !important;
    width: vw(43px) !important;
    line-height: vw(43px) !important;
    @media only screen and (max-width: 767px) {
      font-size: 12px !important;
      width: 28px !important;
      line-height: 33px !important;
    }
  }

  .react-datepicker__day--selected {
    background-color: #5ccc40 !important;
    color: #ffffff !important;
    font-family: "Roboto-Regular", sans-serif !important;
  }

  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled {
    opacity: 0.5;
    user-select: none;

    &:hover {
      background-color: transparent !important;
    }
  }

  // time
  .react-datepicker-time__header {
    font-size: vw(18px);
    @include fontRobotoBold;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    height: vw(45px);
    padding: vw(8px);
    font-size: vw(18px);
    @include fontRobotoRegular;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected:hover,
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: #5ccc40;
  }

  .react-datepicker__header--time {
    padding: vw(10px);
  }

  .react-datepicker__time-container,
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: vw(160px);
  }

  // .react-datepicker {
  //   left: vw(30px);
  // }
  @media only screen and (max-width: 768px) {
    height: 40px;
    margin-right: 0px;
  }
}

// end datepicker

.date-picker-common--noBorder {
  width: vw(188px);
  margin-right: vw(20px);

  /*new css
    width: vw(128px);
    height: vw(33px);*/
  .react-datepicker__input-container {
    &::after {
      display: none;
    }
  }
}

.date-block-arrow-go {
  cursor: pointer;
  width: vw(58px);
  margin-right: vw(60px);
  margin-bottom: vw(33px);
  //box-shadow: 0 vw(3px) vw(6px) #00000029;
  //new css
  //background: #2d3135;
  //box-shadow: vw(-2px) vw(-2px) vw(4px) rgba(74, 78, 83, 0.13),
  // vw(2px) vw(2px) vw(6px) rgba(35, 37, 39, 0.8),
  //vw(8px) vw(8px) vw(30px) #232527,
  //vw(-6px) vw(-6px) vw(20px) rgba(74, 78, 83, 0.37);
  //border-radius: vw(30px);
}

.datepicker-mr {
  margin-right: vw(148px);
}

.react-datepicker__input-container input:disabled {
  border-color: $form-field-disabled-border-color;
}

.react-datepicker__input-container input::placeholder {
  color: rgba(0, 0, 0, 0.48) !important;
  font-size: $form-field-font-size !important;
  // @include fontRobotoBold;
  @include fontRobotoLight;
  font-style: italic;
}

.signup-bg--more-details {
  .react-datepicker__month-container {
    width: vw(476px);
  }
}
