.Insights-main-wrap {
  .blog-app {
    .vl-website-blogs-details-content {
      color: #000000;
      padding: vw(128px) vw(308px) vw(308px) vw(346px);
      font-size: vw(22px);
      line-height: vw(26.82px);
      @include fontMontserratRegular;
      @media only screen and (max-width: 900px) {
        padding-top: 40px;
        p,
        li {
          font-size: 13px;
          line-height: 1.3;
          word-break: break-word;
        }
      }
      @media only screen and (max-width: 900px) {
        padding-top: 20px;
        padding-left: 0px;
        padding-right: 0px;
      }

      p {
        margin-bottom: vw(20px);
      }

      .media {
        justify-content: center;
      }

      b,
      strong {
        @include fontMontserratBold;
      }

      dl,
      ol,
      ul {
        margin-bottom: vw(20px);
        margin-left: vw(25px);

        li {
          margin-bottom: vw(10px);
        }
      }

      ol {
        li {
          margin-bottom: vw(5px);
          a {
            word-break: break-word;
          }
        }
      }

      .table {
        margin-bottom: vw(20px);
      }

      .table td,
      .table th {
        padding: vw(20px);
      }

      blockquote {
        margin-bottom: vw(20px);
        @include fontMontserratItalic;
        position: relative;
        padding-left: vw(20px);

        &::before {
          content: "";
          width: vw(5px);
          height: 100%;
          background-color: rgba(0, 0, 0, 0.2);
          display: block;
          position: absolute;
          left: 0;
          border-radius: vw(10px);
        }
      }

      .h1,
      .h2,
      .h3,
      .h4,
      .h5,
      .h6,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-bottom: vw(20px);
        @include fontMontserratMedium;
      }

      h1 {
        font-size: vw(54px);
        line-height: vw(57px);
      }
      h2 {
        font-size: vw(40px);
        line-height: vw(43px);
      }
      h3 {
        font-size: vw(31px);
        line-height: vw(34px);
      }
      h4 {
        font-size: vw(27px);
        line-height: vw(30px);
      }
      h5 {
        font-size: vw(22px);
        line-height: vw(25px);
      }
      h6 {
        font-size: vw(20px);
        line-height: vw(23px);
      }

      img {
        width: 100%;
        height: auto;
      }

      a {
        color: #29294f;
        text-decoration: underline !important;
      }
    }
    @media only screen and (max-width: 900px) {
      padding: 40px 30px;
      .vl-blog-img {
        width: 100%;
      }
      h1 {
        font-size: 25px;
        line-height: 1.3;
      }
      h2 {
        font-size: 12px;
        line-height: 1.3;
      }
    }
    @media only screen and (max-width: 767px) {
      padding: 30px 20px 0px 20px;
      h1 {
        font-size: 20px;
      }
      h2 {
        margin-bottom: 15px;
      }
    }
  }
  .vl-blog-details-card-div,
  .kb-card {
    .kb-card-img-block {
      @media only screen and (max-width: 425px) and (min-width: 280px) {
        height: 300px;
      }
    }
    .kb-card-text-div {
      @media only screen and (max-width: 425px) and (min-width: 280px) {
        height: 98px;
      }
    }
  }
}
