/*=================================================================
                         roboto
====================================================================*/
.font-12-roboto-italic {
  font-size: vw(12px);
  line-height: vw(14.06px);
  margin-bottom: 0;
  @include fontRobotoItalic;
}

.font-18-roboto-italic {
  font-size: vw(18px);
  line-height: vw(21.09px);
  margin-bottom: 0;
  @include fontRobotoItalic;
}

.font-24-roboto-italic {
  font-size: vw(24px);
  line-height: vw(28px);
  margin-bottom: 0;
  @include fontRobotoItalic;
}

.font-16-roboto-light {
  font-size: vw(16px);
  line-height: vw(19px);
  margin-bottom: 0;
  @include fontRobotoLight;
}

.font-18-roboto-light {
  font-size: vw(18px);
  line-height: vw(21px);
  margin-bottom: 0;
  @include fontRobotoLight;
}

.font-16-roboto-regular {
  font-size: vw(16px);
  line-height: vw(19px);
  margin-bottom: 0;
  @include fontRobotoRegular;
}

.font-18-roboto-regular {
  font-size: vw(18px);
  line-height: vw(21px);
  margin-bottom: 0;
  @include fontRobotoRegular;
}

.font-14-roboto-medium {
  font-size: vw(14px);
  line-height: vw(16px);
  margin-bottom: 0;
  @include fontRobotoMedium;
}

.font-16-roboto-medium {
  font-size: vw(16px);
  line-height: vw(18.75px);
  margin-bottom: 0;
  @include fontRobotoItalic;
}
.font-roboto-16-roboto-medium {
  font-size: vw(16px);
  line-height: vw(18.75px);
  margin-bottom: 0;
  @include fontRobotoMedium;
}
.font-18-roboto-medium {
  font-size: vw(18px);
  line-height: vw(21.09px);
  margin-bottom: 0;
  @include fontRobotoMedium;
}

.font-20-roboto-medium {
  font-size: vw(20px);
  line-height: vw(23.44px);
  margin-bottom: 0;
  @include fontRobotoMedium;
}
.font-24-roboto-medium {
  font-size: vw(24px);
  line-height: vw(28px);
  margin-bottom: 0;
  @include fontRobotoMedium;
}

.font-12-roboto-bold {
  font-size: vw(12px);
  line-height: vw(14px);
  @include fontRobotoBold;
  margin-bottom: 0;
}

.font-14-roboto-bold {
  font-size: vw(14px);
  line-height: vw(16.41px);
  @include fontRobotoBold;
  margin-bottom: 0;
}

.font-18-roboto-bold {
  font-size: vw(18px);
  line-height: vw(21px);
  @include fontRobotoBold;
  margin-bottom: 0;
}

.font-20-roboto-bold {
  font-size: vw(20px);
  line-height: vw(23px);
  margin-bottom: 0;
  @include fontRobotoBold;
}
.font-24-roboto-bold {
  font-size: vw(24px);
  line-height: vw(28.13px);
  margin-bottom: 0;
  @include fontRobotoBold;
}
.font-32-roboto-bold {
  font-size: vw(32px);
  line-height: vw(37.5px);
  margin-bottom: 0;
  @include fontRobotoBold;
}

.font-36-roboto-bold {
  font-size: vw(36px);
  line-height: vw(42px);
  @include fontRobotoBold;
  margin-bottom: 0;
}

/*=================================================================
                         Montserrat 
====================================================================*/

.font-18-montserrat-semibold {
  font-size: vw(18px);
  line-height: vw(21.94px);
  margin-bottom: 0;
  @include fontMontserratSemiBold;
}
/*=================================================================
                         DM Serif Display
====================================================================*/
.font-22-dm-serif-regular {
  font-size: vw(22px);
  line-height: vw(25px);
  margin-bottom: 0;
  @include fontDMSerifDisplayRegular;
}
/*=================================================================
                         Playfair Display
====================================================================*/
.font-24-playfair-display-bold {
  font-size: vw(24px);
  line-height: vw(38.4px);
  margin-bottom: 0;
  @include fontPlayfairDisplayBold;
}
