.email-verify-alert-bg {
  width: vw(605.31px);
  height: vw(341.96px);
  background: rgba(62, 58, 101, 0.71);
  border-radius: vw(17px);
  transform: rotate(-0.11deg);
  text-align: center;
  padding: vw(55px) 0 vw(50.77px) 0;
  margin-top: vw(30.66px);

  .font-32-roboto-bold {
    color: rgba(255, 255, 255, 0.82);
  }
  .font-18-roboto-medium {
    padding-top: vw(15px);
    color: rgba(255, 255, 255, 0.44);
  }
  .font-18-roboto-italic {
    padding-top: vw(37px);
    color: rgba(255, 255, 255, 0.44);
  }
}

.email-alert-buttons {
  width: 80%;
  display: flex;
  justify-content: space-between;
  .sign-up-back-btn {
    margin-top: vw(30px);
  }
}
