.home-div {
  padding: vw(52px) vw(50px) vw(50px) vw(52px);
}

.color-blue {
  color: #323277;
}
// home card
.home-start-up-card {
  width: vw(412px);
  min-height: vw(108px);
  border-radius: vw(6px);
  display: flex;
  background: #292950;
  margin-bottom: vw(10px);
}
.home-start-up-card-left-side {
  background-color: rgba(116, 116, 196, 0.26);
  height: inherit;
  width: vw(106px);
  display: flex;
  align-items: center;
  justify-content: center;
  //padding: vw(26px) vw(27px) 0 vw(19px);
}
.home-logo {
  width: vw(67px);
}
.home-start-up-card-right-side {
  //color: white;
  padding: vw(25px) vw(10px) vw(26px) vw(25px);
  word-break: break-all;
  width: 70%;
}
.home-location-img {
  width: vw(11.33px);
  height: auto;
  margin-top: vw(4px);
}
.font-16-open-sans-regular {
  font-size: vw(16px);
  line-height: vw(25px);
  color: #a5adbb;
  padding-left: vw(7.5px);
  //padding-top: vw(10px);
  margin-bottom: 0;
}
.home-newly-joined-div {
  padding: vw(32px) 0 vw(25px) 0;
  border-bottom: vw(1px) solid rgba(0, 0, 0, 0.24);
}
.home-right-panel {
  margin-left: vw(23px);
}
//.home-left-panel {
//  width: vw(1155px);
//}
.home-tab-panel-one-left-side {
  width: vw(458px);
  padding-right: vw(32px);
}
.font-16-lora-regular {
  margin-bottom: 0;
  font-size: vw(16px);
  line-height: vw(25.6px);
  @include fontLoraRegular;
  padding-top: vw(8px);
  color: #516163;
}
.home-tab-panel-one-left-side-heading {
  color: #323b3c;
}
.font-14-lora-regular {
  font-size: vw(14px);
  line-height: vw(19px);
  margin-bottom: 0;
  color: #fe5900;
  @include fontLoraRegular;
  padding-top: vw(8px);
}
.home-tab-panel-one-right-side {
  width: vw(619px);
  height: vw(256px);
  background: #ffffff;
  border-radius: vw(7px);
}
.vl-tabs {
  padding-top: vw(32px);
  position: relative;

  &::after {
    position: absolute;
    left: 102%;
    top: vw(45px);
    content: "";
    width: vw(1px);
    height: 85%;
    background: rgba(0, 0, 0, 0.17);
  }
}
.vl-select-container {
  width: vw(120px);
  height: vw(20px);
  margin-bottom: vw(32px);

  .vl-select {
    &__control {
      font-size: vw(16px) !important;
      line-height: vw(19.5px);
      @include fontMontserratBlack;
      border: none !important;
      box-shadow: none;
      border-radius: vw(4px);
      background: transparent !important;
      padding: 0;
      min-height: vw(20px) !important;
      border: 1px solid #00000026;
      outline: none !important;
      color: rgba(0, 0, 0, 0.78);
      &:focus {
        border: none !important;
        outline: none !important;
      }
    }
    &__value-container {
      padding: 0 !important;
      line-height: 1.5;
    }

    &__placeholder {
      font-size: vw(16px) !important;
      line-height: vw(19.5px);
      @include fontMontserratBlack;
      color: rgba(0, 0, 0, 0.78) !important;
    }
    &__single-value {
      color: rgba(0, 0, 0, 0.78) !important;
    }
    &__indicator {
      color: #040404 !important;
      padding: 0 !important;

      svg {
        width: vw(18px);
        height: vw(18px);
      }
    }

    &__indicator-separator {
      display: none !important;
    }
    // &__option--is-selected {
    //   background-color: lightblue !important;
    // }

    &__menu,
    &__option,
    &__option--is-focused,
    &__option--is-selected {
      //min-width: min-content;
      min-width: max-content;
    }

    &__menu-list {
      padding: 0 !important;
      //color: $color-black !important;
      font-size: vw(16px) !important;
      line-height: vw(19.5px);
    }
  }
}
