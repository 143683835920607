.get-started-phone-input-div {
  // width: vw(476px);
  width: 100%;
  height: 100px;
  @media only screen and (max-width: 575px) {
    height: 80px;
  }
  .react-phone-input-block {
    height: 66px;
    @media only screen and (max-width: 575px) {
      height: 50px;
    }
  }

  .input-country-phone-number-container {
    margin-bottom: 0;
    border: 1px solid #ffffff;
  }

  .react-phone-input-block .react-tel-input .form-control {
    font-size: 16px;
    line-height: 18px;
    color: #ffffff;
    border-radius: 8px;
    background-color: transparent;
    @media only screen and (max-width: 900px) {
      font-size: 13px !important;
    }
  }

  .react-phone-input-block .react-tel-input .flag-dropdown,
  .react-phone-input-block .react-tel-input .flag-dropdown:hover,
  .react-phone-input-block .react-tel-input .flag-dropdown:focus,
  .react-phone-input-block .react-tel-input .flag-dropdown:hover .selected-flag,
  .react-phone-input-block
    .react-tel-input
    .flag-dropdown:focus
    .selected-flag {
    font-size: 16px;
    line-height: 18px;
    color: #ffffff;
    background-color: transparent !important;
    border-radius: 0;
  }

  .react-tel-input .flag-dropdown.open .selected-flag,
  .react-tel-input .flag-dropdown.open {
    background: transparent !important;
  }

  .react-phone-input-block .react-tel-input .selected-flag .arrow {
    border-top-color: #ffffff;
  }

  .react-phone-input-block .react-tel-input .selected-flag .arrow.up {
    border-bottom-color: #ffffff;
  }

  .react-tel-input .selected-flag .arrow {
    left: 48px;
  }

  .react-tel-input .selected-flag {
    width: 78px;
    padding: 0 0 0 21px;
    @media only screen and (max-width: 575px) {
      width: 60px;
    }
  }
  .react-tel-input .form-control {
    padding-left: 100px;
  }

  .react-tel-input .selected-flag .arrow {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 6px solid #555;
  }

  .react-tel-input .selected-flag .arrow.up {
    border-bottom: 6px solid #555;
  }

  .react-phone-input-block .react-tel-input .selected-flag:hover,
  .react-phone-input-block .react-tel-input .selected-flag:focus {
    background-color: transparent;
  }

  .react-phone-input-block .react-tel-input .country-list {
    font-size: 16px;
    line-height: 18px;
    color: #000000;
    // @include fontRobotoItalic;
    font-style: italic;
    @include fontRobotoRegular;
    background-color: #ffffff;
    margin-top: 5px;
    margin-bottom: 8px;
  }

  .react-phone-input-block .react-tel-input .country-list .country:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .react-phone-input-block .react-tel-input .country-list .country {
    padding: 10px 25px;

    &:focus {
      outline: none;
    }
  }

  .react-tel-input {
    input {
      font-size: 16px !important;
      line-height: 18px !important;
      color: #ffffff;
      @include fontRobotoMedium;

      &::placeholder {
        color: #ffffff !important;
        @include fontRobotoItalic;
      }
    }
  }
}
