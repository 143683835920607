/* ===============================================================
                    
                    Global reset and css

================================================================== */
#root {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  // color: #ffff;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  word-break: break-word;
}

table {
  td {
    line-height: 0;
  }
}

a,
a:hover {
  text-decoration: inherit !important;
  color: inherit !important;
}
button,
input:focus,
textarea:focus,
a:focus {
  outline: 0 !important;
  border: none;
}

button {
  padding: 0;
  background-color: transparent;
  background: transparent;
}

.outline-0 {
  outline: 0;
}

// button:focus,
.signup-submit-btn,
.vl-discussion-start-btn,
.vl-disc-card-row__imgAndBtnDiv-btn,
.upload-img__btn-input-block,
.raise-capital-tags-arrow-btn,
.vl-common-save-next-btn,
#rfs-btn,
.vl-added-tag-block button,
.vl-mentor-add-area-field-button,
.pitch-and-product-drop-down-main-div .dropzone,
.react-tel-input .selected-flag,
.vl-common-save-btn,
.vl-common-skip-btn,
.vl-mentor-remove-area-field-button,
.drag-and-drop-item-list button,
.vl-profile-page-button {
  &:focus {
    box-shadow: none !important;
    // box-shadow: 0 vw(3px) vw(6px) rgba(0, 0, 0, 0.48) !important;
  }
}

.vl-select-container .vl-select__control--is-focused,
.custom-radio-btn-block input:focus + .custom-radio-btn-block__circle-custom,
// .rc-checkbox:hover .rc-checkbox-inner,
 .rc-checkbox-input:focus + .rc-checkbox-inner,
 .invest-thumb:focus {
  box-shadow: 0 vw(3px) vw(6px) rgba(0, 0, 0, 0.48) !important;
}

.vl-common-save-btn:disabled {
  opacity: 0.7;
}

hr {
  margin-top: vw(16px) !important;
  margin-bottom: vw(16px) !important;
}

dl,
ol,
ul {
  margin-bottom: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// scrollbar chrome
*::-webkit-scrollbar {
  height: vw(15px);
  width: vw(5px);
}

*::-webkit-scrollbar-track,
*::-ms-scrollbar-track-color {
  margin: vw(10px);
  background: #d9d1f9;
  box-shadow: 0 0 vw(6px) rgba(0, 0, 0, 0.16);
  border-radius: vw(35px);
}

*::-webkit-scrollbar-thumb {
  border-radius: vw(32px);
  background: #8ba995;

  &:hover {
    background: #8ba995;
  }
}

html,
body {
  overflow-x: hidden !important;
  //background-color: rgba(243, 246, 251, 1) !important;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
  }
}

html {
  scroll-behavior: smooth;
  width: 100% !important;
  max-width: 100% !important;
}

body {
  color: #ffffff !important;
  // background-color: #ffffff;
  background-color: #292950 !important;
  font-size: vw(16px);
  line-height: vw(18.75px);
  font-family: "Roboto-Medium", sans-serif, "Helvetica";
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.78);
}

img {
  font-size: vw(11px);
}

.resp-sharing-button__link,
.resp-sharing-button__icon {
  display: inline-block;
}

.resp-sharing-button__link {
  text-decoration: none;
  color: #fff;
  margin: 0.5em;
}

.resp-sharing-button {
  border-radius: 5px;
  transition: 25ms ease-out;
  padding: 0.5em 0.75em;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.resp-sharing-button__icon svg {
  width: 1em;
  height: 1em;
  margin-right: 0.4em;
  vertical-align: top;
  font-size: 23px;
}

.resp-sharing-button--small svg {
  margin: 0;
  vertical-align: middle;
}

/* Non solid icons get a stroke */
.resp-sharing-button__icon {
  stroke: #fff;
  fill: none;
}

/* Solid icons get a fill */
.resp-sharing-button__icon--solid,
.resp-sharing-button__icon--solidcircle {
  fill: #fff;
  stroke: none;
}

.resp-sharing-button--twitter {
  background-color: #55acee;
}

.resp-sharing-button--twitter:hover {
  background-color: #2795e9;
}

.resp-sharing-button--pinterest {
  background-color: #bd081c;
}

.resp-sharing-button--pinterest:hover {
  background-color: #8c0615;
}

.resp-sharing-button--facebook {
  background-color: #3b5998;
}

.resp-sharing-button--facebook:hover {
  background-color: #2d4373;
}

.resp-sharing-button--tumblr {
  background-color: #35465c;
}

.resp-sharing-button--tumblr:hover {
  background-color: #222d3c;
}

.resp-sharing-button--reddit {
  background-color: #5f99cf;
}

.resp-sharing-button--reddit:hover {
  background-color: #3a80c1;
}

.resp-sharing-button--google {
  background-color: #dd4b39;
}

.resp-sharing-button--google:hover {
  background-color: #c23321;
}

.resp-sharing-button--linkedin {
  background-color: #0077b5;
}

.resp-sharing-button--linkedin:hover {
  background-color: #046293;
}

.resp-sharing-button--email {
  background-color: #777;
}

.resp-sharing-button--email:hover {
  background-color: #5e5e5e;
}

.resp-sharing-button--xing {
  background-color: #1a7576;
}

.resp-sharing-button--xing:hover {
  background-color: #114c4c;
}

.resp-sharing-button--whatsapp {
  background-color: #25d366;
}

.resp-sharing-button--whatsapp:hover {
  background-color: #1da851;
}

.resp-sharing-button--hackernews {
  background-color: #ff6600;
}
.resp-sharing-button--hackernews:hover,
.resp-sharing-button--hackernews:focus {
  background-color: #fb6200;
}

.resp-sharing-button--vk {
  background-color: #507299;
}

.resp-sharing-button--vk:hover {
  background-color: #43648c;
}

.resp-sharing-button--facebook {
  background-color: #3b5998;
  border-color: #3b5998;
}

.resp-sharing-button--facebook:hover,
.resp-sharing-button--facebook:active {
  background-color: #2d4373;
  border-color: #2d4373;
}

.resp-sharing-button--twitter {
  background-color: #55acee;
  border-color: #55acee;
}

.resp-sharing-button--twitter:hover,
.resp-sharing-button--twitter:active {
  background-color: #2795e9;
  border-color: #2795e9;
}

.resp-sharing-button--email {
  background-color: #777777;
  border-color: #777777;
}

.resp-sharing-button--email:hover,
.resp-sharing-button--email:active {
  background-color: #5e5e5e;
  border-color: #5e5e5e;
}

.resp-sharing-button--linkedin {
  background-color: #0077b5;
  border-color: #0077b5;
}

.resp-sharing-button--linkedin:hover,
.resp-sharing-button--linkedin:active {
  background-color: #046293;
  border-color: #046293;
}

.resp-sharing-button--reddit {
  background-color: #5f99cf;
  border-color: #5f99cf;
}

.resp-sharing-button--reddit:hover,
.resp-sharing-button--reddit:active {
  background-color: #3a80c1;
  border-color: #3a80c1;
}

.resp-sharing-button--whatsapp {
  background-color: #25d366;
  border-color: #25d366;
}

.resp-sharing-button--whatsapp:hover,
.resp-sharing-button--whatsapp:active {
  background-color: #1da851;
  border-color: #1da851;
}
