/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.checkbox-text:hover {
  cursor: pointer;
}

/* blockquote {
  color: red;
} */

/* iframe {
  width: 800px;
  height: 500px;
} */

.cookie-text{
  font-size: 15px;
    line-height: 1.5;
}