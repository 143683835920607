.font-36-montserrat-bold {
  font-size: vw(36px);
  color: #ffffff;
  @include fontMontserratBold;
}
.details-card {
  width: 65%;
  min-height: vw(699px);
  background: rgba(62, 58, 101, 0.71);
  border-radius: vw(17px);
  transform: rotate(-0.11deg);
  padding: vw(49px) 0 vw(17px) 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  h4 {
    padding-top: 50px;
    font-size: 35px;
    line-height: 1.2;
    @media only screen and (max-width: 575px) {
      font-size: 25px;
      padding-top: 25px;
    }
  }
  h5 {
    font-size: 17px;
    line-height: 1.2;
    padding-top: 10px;
    @media only screen and (max-width: 575px) {
      font-size: 15px;
      text-align: center;
    }
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: 80%;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    max-width: 90%;
    min-height: auto;
    margin-top: 0px;
    padding: 30px;
  }
  @media only screen and (max-width: 435px) {
    padding: 30px 15px;
  }
}
.signup-bg--more-details {
  padding-top: vw(10px);
}
.get-started-do-it-later-text {
  font-size: vw(18px);
  line-height: vw(21.09px);
  @include fontRobotoBold;
  margin-bottom: 0;
  color: rgba(254, 255, 255, 0.48);
  padding-bottom: vw(5px);
  width: fit-content;
  border-bottom: 1px dashed rgba(254, 255, 255, 0.48);
}

.get-started-logo-div {
  width: 237px;
  height: auto;
  img {
    width: 100%;
    height: 100%;
  }
}

.react-datepicker__header {
  background: #646488 !important;
  color: white !important;
}
.react-datepicker {
  background: #646488 !important;
  color: white !important;
}
.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: white;
  font-weight: bold;
  font-size: 0.944rem;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: white !important;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #292950 !important;
  color: #fff;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
  color: black !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: white !important;
  font-weight: bold;
  font-size: 0.944rem;
}
