.heading {
    font-weight: bolder;
    margin-block: 10px;
}
p {
    margin-block: 10px;
}
.investment-table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    min-height: 100px;

    th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 18px;
        min-height: 10;
        font-size: 14px;
        @media only screen and (max-width: 575px) {
            padding: 9px;
            font-size: 10px;
            line-height: 1.3;
        }
    }
    td {
        border: 1px solid #dddddd;
        padding: 3px;
        padding-left: 13px;
        p {
            margin-block: 4px;
            @media only screen and (max-width: 575px) {
                font-size: 10px;
                margin: 0px;
                line-height: 1.3;
            }
        }
        @media only screen and (max-width: 575px) {
            padding: 9px;
        }
    }
}

.category-list {
    margin-left: 100px;
    @media only screen and (max-width: 900px) {
        margin-left: 50px;
    }
    @media only screen and (max-width: 900px) {
        margin-left: 25px;
    }
    li {
        line-height: 1.6;
    }
    .sub-category-1 {
        margin-left: 50px;
        @media only screen and (max-width: 900px) {
            margin-left: 25px;
        }
        @media only screen and (max-width: 900px) {
            margin-left: 15px;
        }
        .sub-category-2 {
            margin-left: 50px;
            @media only screen and (max-width: 900px) {
                margin-left: 25px;
            }
            @media only screen and (max-width: 900px) {
                margin-left: 15px;
            }
        }
    }
}
