.email-verified-div {
  background: rgba(62, 58, 101, 0.71);
  border-radius: vw(17px);
  transform: rotate(-0.11deg);
  width: vw(605px);
  height: vw(294.11px);
  text-align: center;
  padding: vw(38.49px) 0 vw(49.78px) 0;
  margin-top: vw(46px);
  .font-18-roboto-medium {
    color: rgba(255, 255, 255, 0.44);
  }
}
