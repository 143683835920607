// react phone input block

.input-country-phone-number-container {
  border: vw(2px) solid $form-field-border-color;
  border-radius: $form-field-border-radius;
  color: $form-field-font-color;
  margin-bottom: vw(65px);
  &.vl-form-input {
    margin-bottom: vw(50px) !important;
  }

  &.input-country-phone-number-container--disabled {
    border-color: $form-field-disabled-border-color;
  }

  .input-border-div,
  input {
    width: vw(302px);
    border-radius: vw(2px) vw(26px) vw(26px) vw(2px);
  }
  .input-border-div::after {
    width: 75%;
    left: 9%;
  }
}

.react-phone-input-block-row {
  width: 100%;
}

.react-phone-input-block {
  width: 100%;
  height: vw(53px);
  //margin-right: vw(5px);
  box-shadow: 0 0 0;
  border-radius: vw(26px) vw(2px) vw(2px) vw(26px);
  @media only screen and (max-width: 900px) {
    height: 40px;
  }

  .react-tel-input {
    width: 100%;
    height: 100%;
  }

  .form-control:focus {
    box-shadow: 0 0 0;
  }

  .react-tel-input .flag-dropdown,
  .react-tel-input .flag-dropdown:hover,
  .react-tel-input .flag-dropdown:hover .selected-flag {
    background-color: $form-field-background !important;
    border: 0;
  }

  .react-tel-input .flag-dropdown:focus,
  .react-tel-input .flag-dropdown:focus .selected-flag {
    background-color: $form-field-background !important;
    border: 0;
    border-radius: 0 !important;
  }

  .react-tel-input .selected-flag .arrow {
    border-top-color: #000000;
  }

  .react-tel-input .selected-flag .arrow.up {
    border-bottom-color: #000000;
  }

  .react-tel-input .form-control {
    width: 100%;
    height: 100%;
    color: inherit;
    font-size: $form-field-font-size;
    line-height: $form-field-line-height;
    @include fontRobotoBold;
    background-color: $form-field-background;
    border: 0;
    border-radius: 26px 2px 2px 26px;
    padding-right: 5px;

    &::placeholder {
      color: $form-field-placeholder-color;
      @include fontRobotoLight;
      font-style: italic;
    }
  }

  .react-tel-input .country-list {
    margin-top: 8px;
    width: 400px;
    color: inherit;
    font-size: 18px;
    @include fontRobotoBold;
    color: #000000;
    background-color: $form-field-background;
  }

  .react-tel-input .country-list .country {
    padding: 10px 13px;
  }

  .react-tel-input .country-list .country .dial-code {
    color: inherit;
  }

  .react-tel-input .country-list .country.highlight {
    color: #ffffff;
    background-color: #292950;
  }

  .react-tel-input .country-list .country:hover {
    color: inherit;
    background-color: rgba(0, 0, 0, 0.16);
  }

  .react-tel-input .selected-flag:hover,
  .react-tel-input .selected-flag:focus {
    background-color: $form-field-background;
  }

  .react-tel-input .selected-flag .arrow {
    left: 48px;
    @media only screen and (max-width: 575px) {
      left: 25px;
    }
  }

  .react-tel-input .selected-flag {
    width: 80px;
    padding: 0 0 0 21px;
    @media only screen and (max-width: 575px) {
      width: 50px;
      padding: 0 0 0 10px;
    }
  }
  .react-tel-input .form-control {
    padding-left: 100px;
    @media only screen and (max-width: 575px) {
      padding-left: 55px;
    }
  }

  .react-tel-input .selected-flag .arrow {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 6px solid #555;
  }

  .react-tel-input .selected-flag .arrow.up {
    border-bottom: 6px solid #555;
  }

  .react-phone-input-block .react-tel-input .selected-flag:hover,
  .react-phone-input-block .react-tel-input .selected-flag:focus {
    background-color: transparent !important;
  }

  .react-tel-input .flag-dropdown.open .selected-flag,
  .react-tel-input .flag-dropdown.open {
    background: transparent !important;
  }
  @media only screen and (max-width: 900px) {
    height: 40px;
    .react-tel-input {
      .form-control {
        font-size: 13px;
      }
    }
  }
}

.react-phone-input-block--disabled {
  .react-tel-input :disabled,
  .selected-flag {
    cursor: default;
  }

  // .react-tel-input .selected-flag .arrow {
  // display: none;
  // }

  .react-tel-input .selected-flag .arrow {
    border-top-color: $form-field-disabled-border-color;
  }

  .react-tel-input .selected-flag .arrow.up {
    border-bottom-color: $form-field-disabled-border-color;
  }

  .react-tel-input .selected-flag:focus {
    box-shadow: none !important;
  }
}

.react-phone-input-block--disabled .react-tel-input :disabled,
.react-phone-input-block--disabled .selected-flag {
  cursor: default;
}
