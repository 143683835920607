.vl-common-save-next-btn {
  width: vw(218px);
  height: vw(68px);
  color: #ffffff;
  background: $vl-web-app-pages-text-color;
  border: vw(3px) solid #ffffff;
  border-radius: vw(5px);

  font-size: vw(24px);
  line-height: vw(28px);
  @include fontRobotoMedium;

  &:disabled {
    opacity: 0.3;
  }

  span {
    margin-right: vw(19px);
  }

  .vl-common-save-next-btn__arrow-img {
    width: vw(35px);
    @media only screen and (max-width: 575px) {
      width: 13px;
    }
  }

  @media only screen and (max-width: 900px) {
    height: 30px !important;
    width: 100px !important;
    font-size: 12px !important;
  }
}

.vl-common-save-btn {
  width: vw(199px);
  height: vw(68px);
  color: #ffffff;
  background: $vl-web-app-pages-text-color;
  border: vw(3px) solid #ffffff;
  border-radius: 5px;
  font-size: vw(30px);
  line-height: vw(35px);
  @include fontRobotoBold;
  @media only screen and (max-width: 900px) {
    height: auto !important;
    width: auto !important;
    font-size: 12px !important;
    line-height: 1.3;
    padding: 5px 20px;
  }
}

.vl-common-skip-btn {
  width: vw(199px);
  height: vw(68px);
  color: #000000;
  background-color: #f0f0f3;
  border: vw(3px) solid #000000;
  border-radius: 5px;
  margin-right: vw(21px);
  font-size: vw(24px);
  line-height: vw(28px);
  @include fontRobotoBold;
  @media only screen and (max-width: 900px) {
    height: auto !important;
    width: auto !important;
    font-size: 12px !important;
    padding: 5px 20px;
  }
}
