.vl-blog-div-web-app {
  padding: vw(158px) vw(220px) vw(100px) vw(161px);
  @media only screen and (max-width: 900px) {
    padding: 75px 30px;
    h2 {
      font-size: 25px;
      line-height: 1.3;
    }
    h3 {
      font-size: 12px;
      line-height: 1.3;
    }
  }
  @media only screen and (max-width: 767px) {
    padding: 70px 20px 30px 20px;
    h2 {
      font-size: 20px;
    }
    h3 {
      margin-bottom: 15px;
    }
  }
  @media only screen and (max-width: 525px) {
    padding-top: 45px;
  }

  .vl-blog-img {
    width: 100%;
  }

  .vl-blog-title {
    padding-top: 0;
  }

  .vl-website-blogs-details-content {
    padding: 0;
    padding-top: vw(82px);
    color: $vl-web-app-pages-text-color;
    p,
      li {
        font-family: "Roboto-Regular",sans-serif,"Helvetica";
        strong{
          font-family: "Roboto-bold",sans-serif,"Helvetica";
        }
      }
    @media only screen and (max-width: 900px) {
      padding-top: 40px;
      p,
      li {
        font-size: 13px;
        line-height: 1.3;
        word-break: break-word;
      }
    }
    @media only screen and (max-width: 900px) {
      padding-top: 20px;
    }
  }
}
.investing-startups-arrow {
  display: none;
  i {
    font-size: 15px;
    line-height: 1.3;
  }
  @media only screen and (max-width: 900px) {
    display: block;
  }
}
