.email-verify-card {
  width: vw(605.31px);
  height: vw(294.11px);
  background: rgba(62, 58, 101, 0.71);
  border-radius: vw(17px);
  transform: rotate(-0.11deg);
  text-align: center;
  ///padding: vw(58.49px) 0 vw(50.78px) 0;
  padding: vw(38.49px) 0 vw(29.78px) 0;
  margin-top: vw(46px);
  .font-18-roboto-medium {
    color: rgba(255, 255, 255, 0.44);
  }
  .email-verify-font-18-roboto-italic {
    color: rgba(255, 255, 255, 0.72);
  }
  h3 {
    margin-top: vw(20px);
  }
}
