.vl-main-page-div {
  // border-radius: $vl-main-page-div-border-radius 0 0
  //   $vl-main-page-div-border-radius;
  // margin-left: 290px;
  margin-left: 300px;
  color: #292950;
  // background-color: #f0f0f3;
  background-color: #fff;
  // height: 100vh;
  overflow-y: scroll;
  @media only screen and (max-width: 1150px) {
    margin-left: 250px;
  }
  @media only screen and (max-width: 900px) {
    width: 100%;
    margin-left: 0px;
    border-radius: 0px;
  }
}

.vl-main-page-div__content {
  overflow-x: hidden;
  min-height: 100vh;
  // padding: 0px 33px;
  @media only screen and (max-width: 1140px) {
    padding: 0px;
  }
}
.vl-side-navbar-main-div {
  background-color: #ffffff;
  width: 300px;
  position: fixed;
  top: 0;
  left: 0;
  @media screen and (max-width: 768px) {
    background-color: transparent;
  }
  .top-vl-side-navbar {
    background-color: #292950;
    border-radius: 0px 30px 30px 0px;
    padding: 32px 20px;
    min-height: 100vh;
    height: 100vh;
    overflow: hidden;
    .mobileclose-btn {
      display: none;
      color: #ffffff;
      margin-left: auto;
      i {
        font-size: 25px;
      }
      @media only screen and (max-width: 900px) {
        display: block;
      }
    }
    .mobilehead-menu {
      margin-bottom: 69px;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .vl-side-navbar-main-div__logo-block {
        width: 180px;
        img {
          width: auto;
          height: inherit;
          @media only screen and (max-width: 900px) {
            width: 100%;
            height: auto;
          }
        }
        @media only screen and (max-width: 1150px) {
          width: 150px;
        }
        @media only screen and (max-width: 900px) {
          width: 220px;
          margin-bottom: 0px;
          height: auto;
        }
        @media only screen and (max-width: 767px) {
          width: 160px;
        }
      }
      @media only screen and (max-width: 900px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;
      }
    }
    .vl-side-navbar-main-div__nav {
      color: #ffffff;
      .border-bottomFor-diff {
        border-bottom: 1px solid #adafaf6e;
        ul {
          margin: 0;
        }
      }
      .select-options {
        font-size: 22px;
        margin-bottom: 18px;
        margin-left: 12px;
      }
      li {
        font-family: "Roboto-regular";
        margin-bottom: 0;
        list-style-type: none;
        margin-bottom: 30px;
        margin-left: 12px;
        white-space: nowrap;
        font-weight: 500;

        a {
          font-size: 15px;
          line-height: 21px;
          img {
            width: 22px;
            margin-right: 27px;
            @media only screen and (max-width: 900px) {
              width: 19px;
              margin-right: 27px;
            }
            @media only screen and (max-width: 525px) {
              width: 17px;
              margin-right: 20px;
            }
          }
        }
        @media only screen and (max-width: 900px) {
          // font-size: 19px;
          margin-bottom: 20px;
          line-height: 2;
          opacity: 1;
        }
      }

      .vl-side-navbar-main-div__nav-active-link {
        opacity: 1;
      }
      .accordion_wrap {
        h2 {
          button {
            position: relative;
            &::after {
              background-image: url("../../../../assets//imgs/desktop/icon/icons8-down-arrow-30.png");
              position: absolute;
              top: 0;
              right: 0;
            }
          }
        }
      }
    }
    @media only screen and (max-width: 900px) {
      border-radius: 0px 25px 25px 0px;
      padding: 30px;
    }
    @media only screen and (max-width: 425px) {
      padding: 20px;
    }
    .accordion-sidebar {
      border: none;
      .accordion-item-wrap {
        background-color: transparent;
        border: none;
        .sidebar-nav-link {
          color: #ffffff !important;
        }
      }
      .accordion-header {
        margin-left: 12px;
      }
      .accordion-button {
        font-size: 15px;
        color: #fff;
        width: 100%;
        background-color: transparent;
        padding: 0;
        &:focus {
          box-shadow: none;
        }
        &:not(.collapsed) {
          box-shadow: none;
        }
        .mentorship-icon {
          margin-right: 27px;
          width: 19px;
          @media screen and (max-width:575px) {
            margin-right: 20px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1150px) {
    width: 250px;
  }
  @media only screen and (max-width: 900px) {
    display: none;
    z-index: 30;
    width: 50%;
    position: fixed;
  }
  @media only screen and (max-width: 600px) {
    width: 85%;
  }
  .sidebar_wrap {
    // @media screen and (max-width: 992px) {
    //   padding-bottom: 0 !important;
    //   padding-top: 15px;
    // }
    .mob_head_main {
      gap: 20px;
      @media screen and (max-width: 992px) {
        margin-bottom: 30px !important;
      }
      .logo_wrap {
        width: auto;
      }
    }
    .sidebar_nav {
      height: 100%;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .vl-topnavbar__profile-img-block {
      cursor: pointer !important;
    }
  }
}
.mentor-page-sidebar {
  position: fixed;
  width: 300px;
  background-color: #fff;

  @media only screen and (max-width: 900px) {
    position: fixed;
    width: 320px;
  }
  @media screen and (max-width: 768px) {
    background-color: transparent;
  }
  @media only screen and (max-width: 575px) {
    width: 250px;
  }
}
