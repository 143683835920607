.vl-tabs {
  .react-tabs {
    width: 100%;
  }
  .react-tabs__tab {
    border: none;
    padding: 0;
    padding-right: vw(20px);
  }

  .react-tabs__tab-list {
    font-size: vw(16px);
    line-height: vw(20px);
    border-radius: none !important;
    border-bottom: none;
    color: rgba(0, 0, 0, 0.78);
    padding: 0;
    padding-bottom: vw(22px);
    background: transparent;
    white-space: nowrap;
    width: vw(1078px);
    @include fontMontserratMedium;
    border-bottom: 1px solid #323277;
    margin-bottom: 0;
  }

  .react-tabs__tab--selected,
  .react-tabs__tab:focus {
    font-size: vw(16px);
    line-height: 20px;
    border: none;
    position: relative;
    box-shadow: none !important;
    padding-bottom: 0;
    background: transparent;
    border-radius: 0 !important;
    color: #000000;
    @include fontMontserratBlack;
  }

  .react-tabs__tab:focus {
    box-shadow: 0 0 0;
    border-color: transparent;
  }

  .react-tabs__tab:focus:after {
    display: none;
  }

  .react-tabs__tab-panel {
    width: 100%;
    padding-top: vw(33px);
    //padding-top: vw(66px);
  }
}
