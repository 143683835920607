.font-12-montserrate-semibold {
  font-size: vw(12px);
  line-height: vw(14.63px);
  margin-bottom: 0;
  color: rgba(255, 255, 255, 0.45);
  @include fontMontserratSemiBold;
  padding-top: vw(12px);
}
.facebook-signup-btn--login {
  margin-bottom: vw(28px);
}

// validation errors

.validation-errors-login {
  margin-top: vw(10px);
  margin-bottom: vw(0px);
  color: red;
  display: inline-block;
  font-size: vw(17px);
  line-height: 15px;
}

.validation-errors-forget-password {
  margin-top: vw(10px);
  margin-bottom: vw(0px);
  color: red;
  font-size: vw(17px);
  line-height: 15px;
  text-align: left;
  width: vw(460px);
}

.validation-errors-more-details {
  margin-top: vw(10px);
  margin-bottom: vw(0px);
  color: red;
  font-size: vw(17px);
  line-height: 15px;
  text-align: left;
}

.validation-errors-set-new-password {
  margin-top: vw(10px);
  margin-bottom: vw(0px);
  color: red;
  font-size: vw(17px);
  line-height: 15px;
  text-align: left;
  width: vw(460px);
}
