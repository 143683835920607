.mentor-profile-flex {
  display: flex;

  .mentor-profile-view-main {
    height: 100vh;
    overflow: auto;
    background-color: #fff;
    .mentor-profile {
      // height: 100vh;
      position: relative;
      @media only screen and (max-width: 992px) {
        height: auto;
      }
      .mentor-view {
        width: 100%;
        position: relative;
        a {
          position: absolute;
          left: 0;
          top: 15px;
          i {
            margin: 0 20px;
            color: #000;
            font-size: 40px;
            @media only screen and (max-width: 575px) {
              margin: 0;
            }
          }
        }
      }
      .mentor-title {
        position: absolute;
        top: 50px;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #000;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 0;
      }

      .mentor-wrap {
        margin-top: 60px;
        margin-bottom: 60px;
        // height: calc(100vh - 77px);
        color: #ffffff;
        @media only screen and (max-width: 992px) {
          flex-direction: column;
          height: 100%;
          margin-top: 110px;
        }
        @media only screen and (max-width: 575px) {
          margin-top: 110px;
        }
        .chat-with-mentor-btn {
          width: 164px;
          height: 35px;
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
          color: #081725 !important;
          background: #fff;
          border: 1px solid #4e4e51;
          letter-spacing: 0.2px;
          border-radius: 5px;
          text-align: center;
          font-size: 15px;
          font-style: normal;
          font-weight: 300;
          padding: 5px 0;
        }
        .content-wrap {
          @media only screen and (max-width: 992px) {
            // padding: 0 130px;
          }
          @media only screen and (max-width: 768px) {
            padding: 0;
          }
          @media only screen and (max-width: 575px) {
            padding: 0 20px;
          }
        }
        .mentor-name {
          color: #000;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 0;
          @media only screen and (max-width: 922px) {
          }
          @media only screen and (max-width: 768px) {
          }
          @media only screen and (max-width: 575px) {
            font-size: 22px;
            font-weight: 500;
            margin: 0;
            // text-align: center;
          }
        }
        .mentor-image {
          width: 292px;
          height: 292px;
          border-radius: 50%;
          border: 2px solid #dee2e6;
          @media screen and (max-width: 1200px) {
            width: 240px;
            height: 240px;
          }
          @media screen and (max-width: 1200px) {
            width: 240px;
            height: 240px;
          }
        }
        .link-icon-main {
          width: 100%;
          display: flex;
          justify-content: end;
          align-items: center;
          gap: 14px;
          margin-top: 16px;
          position: relative;
        }
        .mentor-titles {
          color: #676767;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin-bottom: 7px;
          @media only screen and (max-width: 575px) {
            font-size: 20px;
            font-weight: 500;
            margin: 0;
            // text-align: center;
            width: 100%;
          }
        }
        .areas-of-interest {
          display: flex;
          gap: 10px;
          flex-wrap: wrap;
          margin-top: 5px;
          @media only screen and (max-width: 575px) {
            // justify-content: center;
          }
          .mentor-profile-skill {
            color: #000;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            @media only screen and (min-width: 1440px) {
              padding: 0;
            }
          }
        }

        .setIsChatMenuShow {
          margin-top: 0;
        }
        .mentor-bio {
          color: #ffffff;
          font-size: 20px;
          font-weight: 400;
          line-height: 1.8;
          width: 70%;
          margin: 0 auto;
        }
        .mentor-post {
          color: #000;
          text-transform: capitalize;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin: 0;
          @media only screen and (max-width: 575px) {
            font-size: 16px;
            line-height: 1.5;
            // text-align: center;
          }
        }
        .company-name {
          color: #ffffff;
          text-transform: capitalize;
          font-size: 24px;
          font-weight: 500;
          line-height: 1.8;
          margin: 15px 0 0 0;
          @media only screen and (max-width: 575px) {
            font-size: 18px;
            line-height: 1.5;
          }
        }
        .mentor-info {
          position: relative;
          @media screen and (max-width: 1399px) {
            margin-top: 40px !important;
          }
          .content-wrap {
            .user-linkedin-profile {
              .link-action {
                display: flex;
                align-items: center;
                width: fit-content;
                i {
                  font-size: 32px;
                  color: #fff;
                  margin-right: 15px;
                }
              }
            }
          }
        }
        .chat-bot {
          background-color: #ffffff;
          width: 65px;
          height: 65px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          position: absolute;
          bottom: 30px;
          right: 30px;
          i {
            color: #081725;
            font-size: 28px;
            @media only screen and (max-width: 768px) {
              font-size: 25px;
            }
          }
        }
      }
    }
    .mentor-chat-box {
      width: 425px;
      overflow: visible;
      height: 78vh;
      position: absolute;
      border-radius: 15px;
      right: 80px;
      bottom: 95px;
      @media only screen and (max-width: 992px) {
        right: 80px;
        bottom: 90px;
      }
      @media only screen and (max-width: 767px) {
        width: 50vw;
        height: 76vh;
        right: 90px;
        bottom: 95px;
      }
      @media only screen and (max-width: 575px) {
        width: 100%;
        padding: 0;
        bottom: 0;
        height: 100vh;
        left: 50%;
        transform: translate(-50%, -100px);
      }
      .cs-main-container {
        border-radius: 15px;
        overflow: hidden;
        position: relative;
        border: none;
        max-width: 100% !important;
        padding: 0;
        .cs-chat-container {
          position: relative;
          .scrollbar-container {
            .left-section {
              .cs-message__content-wrapper {
                display: flex;
                justify-content: end;
                align-items: end;
                text-align: justify;
                .cs-message__content {
                  border-radius: 0 0.7rem 0.7rem 0.7rem;
                  background-color: #7a7aff;
                  color: #ffffff;
                }
              }
            }
            .right-section {
              .cs-message__content-wrapper {
                display: flex;
                justify-content: end;
                align-items: end;
                text-align: justify;
                .cs-message__content {
                  border-radius: 0.7rem 0 0.7rem 0.7rem;
                  background: rgb(122, 122, 255);
                  color: #fff;
                }
              }
            }
          }
          .waves-background {
            background-image: url(../../../../assets/imgs/desktop/background/waves.svg);
            background-position: center;
            background-size: 100%;
            background-repeat: no-repeat;
          }
          .cs-message-input {
            gap: 8px;
            align-items: center;
            padding: 12px 10px 12px 0;
            .cs-message-input__content-editor-wrapper {
              max-width: 90%;
            }
            .cs-message-input__tools {
              .cs-button--send {
                width: 50px;
                height: 50px;
                background: linear-gradient(
                  100deg,
                  #323277 -10%,
                  #252558 46%,
                  #121237 94%,
                  #323e45ee 96%
                );
                border-radius: 50%;
                color: #fff;
              }
            }
          }
          .cs-conversation-header {
            background-color: #ffffff;
            flex-direction: column;
            align-items: center;
            padding: 55px 0;
            background: linear-gradient(
              240deg,
              #071623 0%,
              #0d1a2a 46%,
              #323e45 84%,
              rgba(50, 62, 69, 0.9333333333) 96%
            );
            .cs-conversation-header__back {
              position: absolute;
              left: 15px;
              top: 15px;
            }
            .cs-conversation-header__avatar {
              margin: 0 auto;
              width: 100%;
              height: auto;
              .cs-avatar--md {
                width: 100%;
                text-align: center;
                margin-bottom: 4px;
                height: auto;
                min-width: auto;
                min-height: auto;
                img {
                  height: 4rem;
                  width: 4rem;
                  background-color: #efefef;
                  border: 2px solid #fff;
                  border-radius: 10px;
                  overflow: hidden;
                }
              }
            }
            .cs-conversation-header__content {
              margin-top: 10px;
              .cs-conversation-header__user-name {
                text-transform: capitalize;
                font-weight: 600;
                font-size: 18px;
                background: transparent;
                color: #ffffff;
              }
            }
          }
        }
        .cs-message {
          max-width: 100%;
        }
        .cs-message-separator {
          &::before {
            background-color: transparent;
          }
          &::after {
            background-color: transparent;
          }
        }
      }
    }
  }
  .mentor-profile-view-main-logout {
    width: 100%;
  }
}
.mentor-profile-logout{
  margin: 0;
}
.mentor-profile-login{
  margin-left: 300px;
  @media screen and (max-width: 900px) {
    margin: 0;
  }
}