.get-started-dropdown-div {
  .vl-form-input--bordered-select-div {
    height: fit-content;
    @media only screen and (max-width: 575px) {
      height: 50px;
    }
    .other-drop-down {
      display: none;
    }
    .mentor-dropdown {
      display: none;
    }
    .investor-dropdown {
      height: 68px;
    }
  }

  .vl-form-input--bordered-select-div .vl-select-container {
    border: 1px solid #ffffff;
    background-color: transparent;
    height: 63px;
  }

  .vl-form-input--bordered-select-div .select-industry-dropdown {
    height: auto;
    min-height: 3.0208333333vw;
    .vl-select__value-container {
      line-height: 2.5;
    }
  }
  .vl-form-input--bordered-select-div
    .vl-select-container
    .vl-select__single-value {
    font-size: 16px !important;
    line-height: 18px;
    color: #ffffff !important;
    @include fontRobotoMedium;
  }

  .vl-form-input--bordered-select-div .vl-select-container .vl-select__control {
    padding-left: 19px;
  }

  .vl-form-input--bordered-select-div
    .vl-select-container
    .vl-select__placeholder {
    font-size: 16px !important;
    line-height: 18px;
    color: #ffffff !important;
    @include fontRobotoItalic;
    @media only screen and (max-width: 900px) {
      font-size: 13px !important;
    }
  }

  .vl-form-input--bordered-select-div,
  .vl-select-container {
    margin-bottom: 0;
  }

  .vl-select-container .vl-select__indicator {
    color: #ffffff !important;
  }

  .vl-select__menu {
    background-color: #ffffff;
    font-size: 16px;
    line-height: 18.75px;
    @include fontRobotoItalic;
    @media only screen and (max-width: 900px) {
      font-size: 16px;
    }
  }

  .vl-select__option {
    color: #000000;
    padding: 10px 30px 10px 30px;
    min-height: 1.5;
    @include fontRobotoItalic;
  }

  .vl-select__option--is-focused,
  .vl-select__option:hover {
    color: #000000;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .vl-select-container .vl-select__menu-list {
    padding-bottom: 13px !important;
  }

  .vl-select__option--is-selected {
    color: #ffffff;
    background-color: #292950;
  }
}
