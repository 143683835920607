.bg-color--raise-capital {
  .raise-capital-fold-one-bg {
    background-image: url("../../../imgs/desktop/background/raise-bg.png");
    max-height: 100vh;
    .raise-capital-fold-one-text-div {
      height: 88vh;
      display: flex;
      justify-content: center;
      flex-direction: column;
      @media only screen and (max-width: 900px) {
        text-align: center;
        width: 90%;
        margin: auto;
        h2 {
          font-size: 30px;
          line-height: 1.3;
        }
        h5 {
          font-size: 20px;
          line-height: 1.3;
          margin-bottom: 40px;
        }

        .start-btn-raise-fold-one {
          width: 340px;
          height: 52px;
          padding: 0;
          margin: auto;
          span {
            font-size: 20px;
            font-family: "Montserrat-bold";
          }
        }
      }
      @media only screen and (max-width: 575px) {
        height: 65vh;
        h2 {
          font-size: 18px;
          margin-bottom: 10px;
        }
        h5 {
          font-size: 12px;
        }
        .start-btn-raise-fold-one {
          width: 230px;
          span {
            font-size: 12px;
          }
        }
      }
      // padding-top: vw(271.18px);
      // padding-left: vw(227.5px);
      // padding-bottom: vw(394px);
    }
  }
  .raise-capital-fold-two-div {
    padding-top: 125px;
    width: 100%;
    overflow: hidden;
    position: relative;
    .raise-capital-fold-two-inner-div {
      // width: vw(1744px);
      position: relative;
      height: auto;
      padding-top: 100px;
      padding-bottom: 80px;
      &::before {
        position: absolute;
        content: "";
        background: #272d2e;
        width: 89.6875vw;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 50px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        @media only screen and (max-width: 900px) {
          content: "";
          width: 95%;
        }
        @media only screen and (max-width: 549px) {
          content: "";
          border-radius: 0px 50px 50px 0px;
          left: 0;
        }
      }
      .invest-fold2-create-div {
        position: relative;
        width: 88%;
        h4 {
          font-size: 30px;
          font-family: Montserrat-regular;
          margin-bottom: 5px;
          @media only screen and (max-width: 1500px) {
            font-size: 20px;
          }
          @media only screen and (max-width: 768px) {
            font-size: 18px;
          }
        }
        .investpageinner-title {
          font-size: 40px;
          font-family: Montserrat-bold;
          margin-bottom: 60px;
          @media only screen and (max-width: 1500px) {
            font-size: 30px;
          }
          @media only screen and (max-width: 768px) {
            font-size: 20px;
            margin-bottom: 30px;
          }
        }
        .raiseweb-page {
          h4 {
            font-size: 30px;
            font-family: Montserrat-bold;
            margin-bottom: 25px;
            @media only screen and (max-width: 1500px) {
              font-size: 25px;
            }
            @media only screen and (max-width: 768px) {
              font-size: 18px;
              margin-bottom: 7px;
            }
          }
          h5 {
            font-size: 25px;
            margin-bottom: 50px;
            font-family: Montserrat-regular;
            @media only screen and (max-width: 1500px) {
              font-size: 20px;
            }
            @media only screen and (max-width: 768px) {
              font-size: 14px;
              margin-bottom: 30px;
            }
          }
          a {
            .start-btn--invest-join {
              padding: 0;
              width: 414px;
              height: 71px;
              margin-top: 156px;
              @media only screen and (max-width: 1500px) {
                margin-top: 100px;
                width: 350px;
              }
              @media only screen and (max-width: 900px) {
                width: 200px;
                margin-top: 50px;
              }
              @media only screen and (max-width: 575px) {
                height: 50px;
                width: 170px;
              }
              span {
                font-size: 26px;
                font-family: Montserrat-bold;
                @media only screen and (max-width: 1500px) {
                  font-size: 20px;
                }
                @media only screen and (max-width: 900px) {
                  font-size: 15px;
                }
                @media only screen and (max-width: 575px) {
                  font-size: 13px;
                }
              }
            }
          }
        }
        .investpc-mobile {
          display: none;
          @media only screen and (max-width: 991px) {
            display: block;
            margin-bottom: 30px;
          }
        }
        .invest-down-arrow {
          position: absolute;
          left: vw(-90px);
          bottom: 160px;
          @media only screen and (max-width: 900px) {
            bottom: 120px;
          }
        }
        .d-arrow {
          width: 18px;
          height: 85vh;
          @media only screen and (max-width: 900px) {
            height: 70vh;
          }
          @media only screen and (max-width: 575px) {
            display: none;
          }
        }
        @media only screen and (max-width: 900px) {
          width: 80%;
          margin: auto;
        }
      }
      .raise-pc-img {
        position: absolute;
        height: 50%;
        right: 0;
        top: 30%;
        @media only screen and (max-width: 1250px) {
          height: auto;
        }
        @media only screen and (max-width: 991px) {
          display: none;
        }
      }
      @media only screen and (max-width: 900px) {
        padding-top: 70px;
        padding-bottom: 50px;
      }
    }
  }
}

.start-btn-raise-fold-one {
  width: vw(375px);
  height: vw(54px);
  margin-top: vw(60.36px);
}

.d-arrow--raise {
  height: vw(900px);
}
.start-btn--raise-fold2 {
  width: vw(470.12px);
  height: vw(71.28px);
  margin-top: vw(99.24px);
  .font-30-montserrat-bold {
    font-size: vw(26px);
  }
}
.raise-desktop-img-div {
  position: absolute;
  bottom: vw(300px);
  right: 0;
}
.raise-pc-img {
  width: vw(1000px);
  height: auto;
}
.raise-capital-fold-three-div {
  padding-top: 169px;

  .threediv-title {
    font-size: 50px;
    font-family: Montserrat-bold;
    margin-bottom: 30px;
    line-height: 1.3;
    @media only screen and (max-width: 1500px) {
      font-size: 30px;
    }
    @media only screen and (max-width: 767px) {
      font-size: 20px;
    }
  }
  .diligence-process {
    width: 90%;
    h3 {
      font-family: "Montserrat-bold";
      font-size: 30px;
      @media only screen and (max-width: 1500px) {
        font-size: 20px;
      }
      @media only screen and (max-width: 991px) {
        margin: 0px;
      }
      @media only screen and (max-width: 767px) {
        font-size: 15px;
      }
    }
    img {
      margin-top: 10px;
      margin-bottom: 30px;
      width: 100%;
      @media only screen and (max-width: 991px) {
        margin-bottom: 10px;
        margin-top: 0px;
      }
    }
    h5 {
      font-family: "Montserrat-regular";
      font-size: 20px;
      @media only screen and (max-width: 1500px) {
        font-size: 15px;
      }
      @media only screen and (max-width: 767px) {
        font-size: 12px;
      }
    }
    &:nth-child(2) {
      width: vw(341px);
    }
    @media only screen and (max-width: 1440px) {
      width: 100%;
    }
    @media only screen and (max-width: 991px) {
      margin-bottom: 37px;
    }
  }
  @media only screen and (max-width: 900px) {
    padding: 100px 15px 0px 15px;
  }
  @media only screen and (max-width: 767px) {
    padding: 70px 15px 0px 15px;
  }
  // padding-top: vw(133.05px);
  // padding-left: vw(281.71px);
  // .invest-due-process-div {
  //   width: vw(396px);
  // }
  // .invest-due-process-div-2 {
  //   width: vw(470px);
  // }
  // .invest-due-process-div-3 {
  //   width: vw(412px);
  // }
}
.vl-raise-capital-carousel {
  background: white;
  color: black;
  margin-left: vw(36.62px);
  margin-right: vw(36.62px);
  margin-top: vw(216px);
  margin-bottom: vw(195px);
  border-radius: vw(49px);
  position: relative;
  padding-top: vw(64.83px);
}
//.raise-slider-div {
//}
.raise-slider-img-div {
  width: vw(293px);
  height: vw(297px);
  margin-right: vw(36px);

  img {
    width: 100%;
    height: 100%;
  }
}
.raise-slider-para {
  width: vw(417px);
  padding-top: vw(10px);
  padding-left: vw(5px);
}

.next-arrow-div {
  position: absolute;
  top: vw(50px);
  right: vw(118px);
}

.next-arrow {
  background: transparent;
  font-size: vw(50px);
  //@include fontMontserratSemiBold;
  @include fontRobotoBold;
  color: black;
}

.prev-arrow-div {
  position: absolute;
  top: vw(50px);
  left: vw(73px);
}
.bg-color--raise-capital {
  .footer-row-div {
    display: none;
  }
}
.footer-row-div--raise-capital {
  margin-top: vw(100px);
  width: 100%;
  flex-wrap: nowrap;
  display: flex;
  height: 120px;
  @media only screen and (max-width: 900px) {
    height: auto;
    flex-wrap: wrap;
    display: block;
  }
}
.footer-top-right-div--raise-capital {
  // padding-top: 0;
  // padding-left: vw(75.98px);
  display: flex;
  align-items: center;
  // justify-content: center;
  width: 100%;
  padding: 0 0 0 30px;
  .font-25-montserrat-regular {
    @media only screen and (max-width: 767px) {
      font-size: 14px;
      line-height: 1.3;
    }
  }
  @media only screen and (max-width: 900px) {
    width: 100%;
    text-align: center;
    padding: 25px 50px;
  }
}
.footer-knowledge-div--raise-capital {
  // width: vw(599.14px);
  h5 {
    @media only screen and (max-width: 767px) {
      text-align: center;
      font-size: 15px;
    }
  }
}
