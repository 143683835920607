.vl-terms-of-use-web {
  color: $vl-web-app-pages-text-color;

  .privacy-policy-content-block--terms {
    padding: vw(67px) vw(192px) 50px vw(92px);
    @media only screen and (max-width: 900px){
      padding: 40px;
    }
    @media only screen and (max-width: 575px){
      padding: 15px;
    }
  }
}
