.glossary-title-div {
  background: #1e2225;
  border-radius: 0 0 0 vw(117px);

  .glossary-page-title {
    margin-bottom: 0;
    font-size: vv(55px);
    line-height: vw(67px);
    line-height: 1.1;
    //@include fontMontserratBold;
    @include fontRobotoBold;
    padding-left: vw(290px);
    padding-top: vw(96px);
    padding-bottom: vw(46px);
    @media only screen and (max-width: 530px) {
      font-size: 33px;
      text-align: center;
      padding: 30px;
    }
  }
}
.glossary-que-outer-div {
  padding: vw(86px) vw(248px) vw(450px) vw(286px);
  .accordion {
    border: none !important;
    color: white;
    padding: vw(10px) 0 vw(51px) 0;

    &__button {
      background: none;
      padding: vw(10px) 0 0 0;
      color: white;
      width: auto;
      height: auto;
      font-size: vw(30px);
      line-height: vw(36.57px);
      //@include fontMontserratBold;
      @include fontRobotoBold;

      &:hover {
        background: none !important;
        border: none !important;
      }

      &:focus {
        border: none !important   ;
        outline: none !important;
      }

      &::before {
        width: vw(24px);
        height: vw(24px);
        float: right;
        transform: rotate(45deg);
      }
      @media only screen and (max-width: 530px) {
        font-size: 13px;
        line-height: 1.1;
      }
    }

    //.accordion__button[aria-expanded="true"]::before,
    //.accordion__button[aria-selected="true"]::before {
    //}

    &__panel {
      font-size: vw(22px);
      line-height: vw(26.82px);
      // @include fontMontserratRegular;
      @include fontRobotoRegular;

      padding: 0;
      padding-top: vw(50px);
      padding-bottom: vw(10px);

      width: vw(1370px);
      p {
        margin: 0;
        font-size: 18px;
        line-height: 1.2;
        width: 100%;
        @media only screen and (max-width: 1450px) {
          font-size: 15px;
        }
        @media only screen and (max-width: 769px) {
          font-size: 15px;
          width: 100%;
        }
        @media only screen and (max-width: 530px) {
          font-size: 13px;
        }
      }
      span {
        border-bottom: 1px solid white;
      }
      .font-semibold {
        border: none;
        @include fontRobotoBold;
        //@include fontMontserratSemiBold;
      }
    }

    &__item {
      padding-bottom: vw(51.5px);
      padding-top: vw(36px);
    }
    .accordion__item {
      border-bottom: vw(2px) solid white;
      .accordion__heading {
        .accordion__button {
          @media only screen and (max-width: 530px) {
            font-size: 16px;
            line-height: 1.1;
          }
        }
      }
    }
  }
  .accordion__panel {
    width: 100%;
    ul {
      li {
        font-size: 13px;
        line-height: 1.2;
      }
    }
  }
}
.glossary-font-40-bold {
  font-size: vw(40px);
  line-height: vw(48.76px);
  @include fontRobotoBold;
  //@include fontMontserratBold;
  margin-bottom: 0;
  color: #dbfaff;
  @media only screen and (max-width: 530px) {
    font-size: 16px;
    line-height: 1.1;
  }
}
.glossary-font-22-regular {
  font-size: vw(22px);
  line-height: vw(26.82px);
  @include fontRobotoRegular;
  //@include fontMontserratRegular;
  margin-bottom: 0;
  padding-top: vw(43px);
  @media only screen and (max-width: 530px) {
    font-size: 12px;
  }
  @media only screen and (max-width: 769px) {
    font-size: 14px;
    line-height: 1.2;
  }
}
