.style_box__2iia4 {
  box-shadow: vw(0px) vw(3px) vw(6px) rgba(0, 0, 0, 0.16);
  background: #292950 !important;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: vw(10px) vw(18px) vw(13px) vw(18px) !important;
  max-width: 87% !important;
  border-radius: vw(5px) !important;
  @media only screen and (max-width: 900px){
    padding: 10px 20px !important;
  }
  @media only screen and (max-width: 767px){
    padding: 3px 8px !important;
  }
}

.style_message__1DUXc {
  font-size: vw(25px) !important;
  line-height: vw(30px) !important;
  @include fontRobotoBold;
  @media only screen and (max-width: 900px){
    font-size: 15px !important;
    line-height: 1.3 !important;
  }
  @media only screen and (max-width: 767px){
    font-size: 13px !important;
  }
}

.style_mask__9zmSN {
  position: fixed;
  bottom: vw(800px) !important;
  left: vw(1500px) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  z-index: 1999;
}
