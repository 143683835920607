.signup-bg {
  padding: 0;
  min-height: 100vh;
  background-color: #292950;
  background-image: url("../../../imgs/desktop/backgrounds/signup-bg.png");
  background-size: 100% 110%;
  background-repeat: no-repeat;
  background-position: top;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .stay-tuned-div {
    width: vw(962px);
    //width: vw(1250px);
    height: auto;
    text-align: center;
    padding: vw(49px) 0 vw(48px) 0;
    //padding: vw(49px) 0 vw(72.38px) 0;
    background: rgba(62, 58, 101, 0.71);
    border-radius: vw(17px);
    transform: rotate(-0.11deg);
    .button-wrap {
      gap: 20px;
      @media only screen and (max-width: 575px) {
        gap: 10px;
      }
      .signup-submit-btn-stay-white-btn {
        color: black;
        width: 240px;
        height: 60px;
        border-radius: 5px;
        // padding: 0 15px;
        background: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        line-height: 23px;
        font-weight: 500;
        @media only screen and (max-width: 900px) {
          width: 130px;
          padding: 0 8px;
          height: 40px;
          font-size: 14px;
        }
        @media only screen and (max-width: 525px) {
          height: 30px;
          font-size: 11px;
        }
      }
      .signup-submit-btn-st {
        width: 240px;
        height: 60px;
        border-radius: 5px;
        background: #292950;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        line-height: 23px;
        font-weight: 500;
        @media only screen and (max-width: 900px) {
          width: 130px;
          padding: 0 8px;
          height: 40px;
          font-size: 14px;
        }
        @media only screen and (max-width: 525px) {
          height: 30px;
          font-size: 11px;
        }
      }
    }
    .vl-logo-div {
      .stay-tuned-vedaslabs-logo {
        width: 237px;
        height: 63px;
        @media only screen and (max-width: 525px) {
          width: 180px;
          height: auto;
        }
      }
    }
    .st-font-36-bold-roboto {
      font-size: vw(36px);
      line-height: vw(42.19px);
      margin-bottom: vw(37px);
      //margin-bottom: vw(51px);
      @include fontRobotoBold;
      padding-top: vw(51px);
      color: rgba(255, 255, 255, 0.63);
      @media only screen and (max-width: 525px) {
        font-size: 14px;
        line-height: 1.1;
        margin-top: 15px;
        padding: 0;
      }
    }
    .st-font-24-bold-roboto {
      font-size: vw(24px);
      line-height: vw(28.13px);
      @include fontRobotoBold;
      margin-bottom: vw(34px);
      //margin-bottom: vw(74px);
      color: rgba(255, 255, 255, 0.44);
      @media only screen and (max-width: 525px) {
        font-size: 12px;
        margin-bottom: 25px;
      }
    }
    @media only screen and (max-width: 525px) {
      width: 90%;
      height: 220px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  .st-img-div {
    width: 100%;
    height: auto;
    margin-bottom: vw(56px);
    img {
      width: vw(234px);
      height: auto;
    }
  }
}
