.mentor {
  background-color: #fff;
  overflow: hidden;
  position: relative;
  height: 100vh;
  overflow-y: scroll;
  margin-left: 300px;
  @media screen and (max-width: 1150px) {
    margin-left: 250px;
  }
  @media screen and (max-width: 900px) {
    margin-left: 0;
  }
  .card-main-wrap {
    display: flex;
    width: 100%;
    // height: 100vh;
    // position: absolute;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    @media screen and (max-width: 900px) {
      height: calc(100vh - 200px);
    }
    @media only screen and (max-width: 768px) {
      padding: 0;
      flex-direction: column-reverse;
    }
    @media only screen and (max-width: 575px) {
      // display: block;
    }
    .main-chat-box {
      width: 96vw;
      // height: 100vh;
      background-color: #ffffff;
      @media only screen and (max-width: 991px) {
        width: 100%;
      }
      @media only screen and (max-width: 768px) {
        height: calc(100vh - 55px);
      }
      .start-conversation-msg {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 744px;
        .conversation-image {
          svg {
            display: inline-block;
            width: 190px !important;
            height: 190px !important;
          }
        }
        .start-messaging-text {
          color: #262d2e;
          font-size: 20px;
          // background: #303f4282;
          line-height: 1.8;
          font-weight: 500;
          border-radius: 20px;
          margin: 0;
          padding: 0 12px;
          text-align: center;
          @media only screen and (max-width: 1024px) {
            font-size: 16px;
          }
        }
      }
      .main-start-conversation {
        @media only screen and (max-width: 900px) {
          height: 100%;
        }
      }
      .message-list-skeleton {
        .right-message-list {
          text-align: end;
        }
        .message-input-skeleton-wrap {
          .message-input-skeleton {
            top: 390px;
            left: 15px;
            right: 0;
            bottom: 0;
          }
        }
      }
      .responsive_chat_wrap {
        .list-group-flush {
          ::-webkit-scrollbar {
            height: 2px !important;
            width: 0.2604166667vw;
          }
        }
        .list-group {
          overflow: auto;
          &::-webkit-scrollbar {
            height: 2px !important;
            width: 0.2604166667vw;
          }
        }
        .list-group-item {
          padding: 15px 15px 5px !important;
          gap: 3px;
          border: none !important;
          .mentor_images {
            width: 40px;
            height: 40px;
            padding: 0 !important;
            border: 2px solid #fff;
          }
        }
        .mentor_image_text {
          background: #c5c9ff;
          height: 40px;
          width: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 17px;
          font-weight: 700;
          color: #7a7aff;
        }
        .active-chat {
          outline: 3px solid #7a7aff;
          border: 2px solid #fff;
          position: relative;
        }
        .mentor-name {
          font-size: 16px;
          font-weight: 500;
        }
        .tooltip_wrap {
          position: relative;
          display: inline-block;
          opacity: 1;
          font-size: 16px;
          font-weight: 500;
          color: black;
          .tooltip_text {
            visibility: hidden;
            width: 120px;
            background-color: #292950;
            color: #fff;
            text-align: center;
            border-radius: 6px;
            padding: 5px 3px;
            position: absolute;
            z-index: 1;
            bottom: 25px;
            left: 45px;
            margin-left: -60px;
            font-size: 14px;
            font-weight: 500;
            line-height: normal;
            @media screen and (max-width: 375px) {
              left: 5px;
            }
            &::after {
              content: "";
              position: absolute;
              top: 100%;
              left: 30%;
              margin-left: -5px;
              border-width: 5px;
              border-style: solid;
              border-color: #292950 transparent transparent transparent;
              @media screen and (max-width: 375px) {
                left: 68%;
              }
            }
          }
          &:hover {
            .tooltip_text {
              visibility: visible;
            }
          }
        }
      }
    }
    .card {
      // overflow: auto;
      height: 100vh;
      background-color: #f5f7fb;
      // @media only screen and (max-width: 768px) {
      //   height: calc(100vh - 55px);
      // }
      @media only screen and (max-width: 768px) {
        height: auto !important;
      }
      .chat-section {
        text-align: center;
        width: 100%;
        position: relative;
        display: flex;
        padding-left: 10px;
        a {
          position: absolute;
          left: 15px;
          top: 10px;
          .back-button {
            i {
              color: #495057;
              font-size: 35px;
            }
          }
        }
        .chat-title {
          color: #495057;
          font-size: 20px;
          font-weight: 500;
          width: 100%;
          text-align: center;
        }
        .back_arrow_main {
          position: static;
          .back_arrow {
            font-size: 30px;
          }
        }
      }
      .list-group {
        gap: 2px;
        height: calc(100vh - 72px);
        color: #000000;
        border: none;
        border-radius: 4px;
        overflow-y: visible;
        position: sticky;
        flex-direction: column;
        // @media only screen and (max-width: 768px) {
        //   min-height: calc(100vh - 134px);
        // }
        @media only screen and (max-width: 768px) {
          height: calc(100vh - 280px);
          min-height: calc(100vh - 280px);
        }
        .list-group-item {
          position: relative;
          border-radius: 5px;
          display: flex;
          background-color: transparent;
          align-items: center;
          cursor: pointer;
          width: auto;
          border: none;
          margin: 0;
          gap: 15px;
          transition: all ease-out 0.4s;
          &:hover {
            background: #e6ebf5;
            border-radius: 4px;
          }
          .fl-name {
            // border: 2px solid #d6d8dc;
            font-weight: 700;
            color: #7a7aff;
            background-color: #7a7aff63;
            font-size: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
          }
          .mentor-image {
            width: 40px;
            height: 40px;
          }

          .data {
            .mentor-name {
              font-weight: 600;
              color: #495057;
              font-size: 16px;
              line-height: 1;
              margin-bottom: 0;
              margin-top: 0;
              @media only screen and (max-width: 1440px) {
                width: 90px;
              }
              @media only screen and (max-width: 992px) {
                width: 100%;
              }
              @media only screen and (max-width: 575px) {
                font-size: 18px;
              }
            }
            .mentor-msg {
              color: #000;
              font-size: 20px;
              line-height: 1;
              margin: 7px 0;
            }
          }
          .notification {
            position: absolute;
            top: 50%;
            right: 6px;
            background: #7a7aff;
            border-radius: 50%;
            width: 12px;
            height: 12px;
            transform: translate(0, -50%);
          }
        }
        .active-chat {
          background-color: #e6ebf5;
          border-radius: 4px;
        }
      }
    }
    .cs-main-container {
      // width: 100%;
      @media screen and (max-width: 900px) {
        height: calc(100vh - 290px);
      }
      // @media only screen and (max-width: 575px) {
      //   height: 100vh;
      // }
      .cs-chat-container {
        height: 100vh;
        @media screen and (max-width: 900px) {
          height: 100%;
        }
        .cs-message__content_with-d-none {
          display: none !important;
        }
        .cs-message-list {
          height: 100vh;
          overflow: auto;
          &::-webkit-scrollbar {
            height: vw(15px);
            width: vw(5px);
          }

          // *::-webkit-scrollbar-track,
          &::-ms-scrollbar-track-color {
            margin: vw(10px);
            background: #d9d1f9;
            box-shadow: 0 0 vw(6px) rgba(0, 0, 0, 0.16);
            border-radius: vw(35px);
          }

          &::-webkit-scrollbar-thumb {
            border-radius: vw(32px);
            background: #8ba995;

            &:hover {
              background: #8ba995;
            }
          }
          .scrollbar-container {
            padding: 10px;
          }
        }
        .cs-conversation-header {
          .cs-conversation-header__back {
            @media only screen and (max-width: 992px) {
              display: flex;
            }
          }
          .cs-conversation-header__avatar {
            display: flex;
            justify-content: center;
            align-items: center;
            .cs-avatar--md {
              .fl-name {
                width: 40px;
                height: 40px;
                // border: 2px solid #d6d8dc;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 17px;
                font-weight: 800;
                color: #7a7aff;
                background-color: #7a7aff63;
                border-radius: 50%;
              }
            }
          }
        }
        .cs-message-input {
          gap: 8px;
          align-items: center;
          padding: 12px 10px 12px 0;
          justify-content: space-around;
          .cs-message-input__content-editor-wrapper {
            max-width: 93%;
            padding: 15px;
            background-color: #e6ebf5;
            .cs-message-input__content-editor {
              background-color: #e6ebf5;
            }
          }
          .cs-message-input__tools {
            .cs-button--send {
              width: 50px;
              height: 50px;
              background: linear-gradient(
                100deg,
                #323277 -10%,
                #252558 46%,
                #121237 94%,
                #323e45ee 96%
              );
              border-radius: 50%;
              color: #fff;
            }
          }
        }
        .cs-message-list {
          .cs-message-separator {
            &::after {
              display: none;
            }
            &::before {
              display: none;
            }
          }
          .scrollbar-container {
            .left-section {
              .cs-message__content-wrapper {
                display: flex;
                justify-content: end;
                align-items: end;
                text-align: justify;
                max-width: 65%;
                .cs-message__content {
                  border-radius: 0 0.7rem 0.7rem 0.7rem;
                  background: rgb(122, 122, 255);
                  color: #fff;
                }
                @media only screen and (max-width: 575px) {
                  max-width: 85%;
                }
              }
            }
            .right-section {
              .cs-message__content-wrapper {
                display: flex;
                justify-content: end;
                align-items: end;
                text-align: justify;
                max-width: 65%;
                .cs-message__content {
                  border-radius: 0.7rem 0 0.7rem 0.7rem;
                  background: rgb(122, 122, 255);
                  color: #fff;
                }
                @media only screen and (max-width: 575px) {
                  max-width: 85%;
                }
              }
            }
          }
          .scrollbar-container {
            .cs-message {
              max-width: 100%;
              margin-top: 0;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
    .main-chat-box {
      .mentor-profile {
        overflow: auto;
        color: #495057;
        font-size: 1.3125rem;
        font-weight: 600;
        background-color: #f5f7fb;
      }
      .border-bottom {
        border-bottom: 1px solid #f0eff5;
      }
      .page-title {
        font-size: 1.3125rem;
        color: #495057;
        font-weight: 600;
      }
      .avatar {
        width: 6rem;
        height: 6rem;
        background-color: #ffffff;
        border: 2px solid #ffffff;
        border-radius: 50%;
        box-shadow: 1px 1px 6px #0000001c;
        object-fit: cover;
      }
      .user-profile-name {
        font-size: 18px;
        font-weight: 600;
      }
      .user-bio {
        color: #7a7f9a;
        font-size: 16px;
        line-height: 1.5;
        font-weight: 500;
      }
      .user-details {
        background-color: #ffffff;
        border: 1px solid #f0eff5;
        padding: 0.75rem 1.25rem;
        .user-about-section {
          display: flex;
          align-items: center;
          gap: 18px;
          font-size: 15px;
          font-weight: 600;
          margin-bottom: 26px;
        }
        .user-information {
          .user-labels-name {
            color: #7a7f9a;
            font-size: 15px;
            font-weight: 400;
            margin: 0;
          }
          .user-info {
            color: #495057;
            font-size: 16px;
            margin: 0;
            font-weight: 400;
          }
        }
      }
    }
    .mentor-image-chat {
      height: 70px;
      width: 70px;
      background: #495057;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto 10px;
      border: 2px solid #29294f;
      color: #fff;
    }
  }
}
.mentor-chatbot-sidebar {
  z-index: 5;
  width: 5rem;
  background-color: #29294f;
  height: 100%;
  box-shadow: 0 2px 4px #0f223a1f;
  @media only screen and (max-width: 1100px) {
    width: 5rem;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 5rem;
  }
  @media only screen and (max-width: 575px) {
    height: 55px;
  }
  .tooltip-mentor {
    position: relative;
    display: inline-block;
    font-size: 15px;
    color: black;
    cursor: pointer;
    font-weight: bold;
    margin-left: 5px;
    padding-left: 6px;
    padding-right: 6px;
  }
  .tooltip-mentor .tooltip-texts {
    visibility: hidden;
    font-weight: 300;
    width: auto;
    height: auto;
    background-color: #000;
    font-size: 12px;
    color: #fff;
    text-align: center;
    padding: 10px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    left: 7px;
    right: 0;
    bottom: 35px;
    &::before {
      content: "";
      width: 12px;
      display: inline-block;
      height: 12px;
      transform: rotate(45deg);
      position: absolute;
      background-color: #000;
      bottom: 0;
      left: 26px;
      top: 30px;
    }
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
  .tooltip-mentor:hover .tooltip-texts {
    visibility: visible;
  }
  .mentor-page-logo {
    margin-top: 22px;
    @media only screen and (max-width: 768px) {
      display: none;
    }
    .vedaslabs-logo {
      width: 50px;
      height: auto;
    }
  }
  .sidebar {
    gap: 3rem;
    flex-direction: column;
    height: calc(100vh - 115px);
    @media only screen and (max-width: 768px) {
      flex-direction: row;
      height: 55px;
    }
    @media only screen and (max-width: 575px) {
      gap: 0;
    }
    .profile,
    .msg {
      position: relative;
      width: 100%;
      cursor: pointer;
      text-align: center;
      &:hover i {
        // color: #7269ef;
      }
      i {
        font-size: 22px;
        // color: #898c94;
        color: #ffffff;
        transition: all 0.4s;
        cursor: pointer;
        @media only screen and (max-width: 575px) {
          font-size: 20px;
        }
      }
    }
  }
}
