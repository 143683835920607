/*=====================================

        amount raised card

 ===================================*/
.amount-raised-card-div {
  margin-top: 17px;
  // width: vw(417px);
  width: 100%;
  height: auto;
  //height: vw(610px);
  background: rgba(41, 41, 80, 0.91);
  border-radius: vw(20px);
  color: white;
  text-align: center;
  padding: vw(56px) 0 0 0;
  display: block;
  // margin-top: vw(18px);
  // margin-right: vw(32px);
  position: relative;

  .amount-raised-card-div-progress-bar {
    @media only screen and (max-width: 900px) {
      width: 100px;
      margin-top: 30px;
      margin-bottom: 30px;
      height: 100px;

      svg {
        width: 100px;
        height: 100px;
      }

      h5 {
        font-size: 15px;
        line-height: 1.1;
      }
    }
  }

  .amount-raised-card-div-invest-button {
    @media only screen and (max-width: 900px) {
      width: 125px;
      height: 35px;
      // width: auto;
      // height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 30px;

      span {
        font-size: 12px;
        line-height: 1.1;
      }
    }
  }

  h4 {
    line-height: 1.1;

    // font-family: "Roboto-bold";
    @media only screen and (max-width: 900px) {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }

  .amount-card-font-36-medium-roboto {
    line-height: 1.1;

    // font-family: "Roboto-bold";
    @media only screen and (max-width: 900px) {
      font-size: 16px;
    }
  }

  .amount-card-font-18-bold-roboto {
    line-height: 1.1;

    @media only screen and (max-width: 900px) {
      font-size: 10px;
      line-height: 1.1;
    }
  }

  .amount-raised-card-font-18-roboto-black {
    line-height: 1.1;

    @media only screen and (max-width: 900px) {
      font-size: 10px;
      margin-bottom: 15px;
      margin-top: 5px;
    }
  }

  .font-lineone {
    line-height: 1.1;

    @media only screen and (max-width: 900px) {
      font-size: 10px;
      margin-bottom: 15px;
      margin-top: 5px;
    }
  }

  .amount-card-font-13-regular-roboto {
    line-height: 1.1;

    @media only screen and (max-width: 900px) {
      font-size: 10px;
      margin-bottom: 15px;
    }
  }

  .amount-card-last-section {
    span {
      line-height: 1.1;

      @media only screen and (max-width: 900px) {
        font-size: 11px;
      }
    }
  }
}

.amount-card-font-36-medium-roboto {
  font-size: vw(36px);
  line-height: vw(42.19px);
  margin-bottom: vw(22px);
  @include fontRobotoMedium;
}

.amount-card-font-18-bold-roboto-title {
  font-size: vw(24px);
  line-height: vw(28.13px);
  @include fontRobotoBold;
  margin-bottom: vw(18px);
}

.amount-card-font-36-medium-roboto-currency,
.amount-card-font-18-bold-roboto-currency {
  .vl-form-input .input-border-div {
    background-color: transparent;
    border: none;
  }

  .error-message {
    display: none;
  }
}

.amount-card-font-36-medium-roboto-currency {
  margin-bottom: vw(22px);

  .vl-form-input {
    max-width: vw(417px);
    width: vw(417px);
  }

  .vl-form-input .input-border-div {
    height: vw(42px);
  }

  .vl-form-input input:disabled {
    color: #ffffff;
    font-size: vw(36px);
    line-height: vw(42px);
    margin-bottom: vw(22px);
    @include fontRobotoMedium;
  }
}

.amount-card-font-18-bold-roboto-currency {
  .vl-form-input {
    max-width: vw(200px);
    width: vw(200px);
  }

  .vl-form-input .input-border-div {
    height: vw(21px);
  }

  .vl-form-input input:disabled {
    color: #ffffff;
    padding-left: vw(25px);
    padding-right: vw(12px);
    font-size: vw(18px);
    line-height: vw(21px);
    // @include fontRobotoRegular;
    @include fontRobotoMedium;
    margin-bottom: 0;
  }
}

.amount-card-font-18-bold-roboto {
  font-size: vw(18px);
  line-height: vw(21.09px);
  @include fontRobotoBold;
  margin-bottom: 0;
}

.amount-card-font-18-regular-roboto {
  font-size: vw(18px);
  line-height: vw(21px);
  // @include fontRobotoRegular;
  @include fontRobotoMedium;
  padding-left: vw(24px);
  margin-bottom: 0;
}

.amount-card-font-18-regular-roboto--2 {
  padding-left: vw(25px);
  padding-right: vw(12px);
}

.amount-raised-card-div-progress-bar {
  width: 70%;
  height: auto;
  margin: auto;
  margin-top: vw(36px);
  margin-bottom: vw(26px);
  position: relative;
  // display: flex;
  // justify-content: center;
  // align-items: center;

  @media only screen and (max-width: 991px) {
    width: 180px;
  }
}

.amount-raised-card-font-24-roboto-bold {
  font-size: vw(24px);
  line-height: vw(10.13px);
  margin-bottom: vw(10px);
  @include fontRobotoBold;

  @media only screen and (max-width: 991px) {
    font-size: 20px;
    line-height: 1.5;
  }
}

.amount-raised-card-font-24-roboto-medium {
  position: relative;
  font-size: vw(24px);
  line-height: vw(28.13px);
  margin-bottom: 0;
  @include fontRobotoMedium;

  @media only screen and (max-width: 991px) {
    font-size: 20px;
    line-height: 1.5;
  }
}

.amount-raised-card-div-progress-bar-text-div {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  text-align: center;
  width: 100%;
  // top: 25%;
  // left: 23%;
  .vl-start-up-details-question-mark-main-div__tooltip {
    // background-color: red;
    padding: 8px;
    color: #000;
    white-space: break-spaces;
    width: 265px;
    top: -71px;
    left: 53px;
    text-align: center;
    &::before {
      // background-color: red;
      transform: rotate(90deg);
      left: -9px;
      top: 13px;
    }
    @media only screen and (max-width: 426px) {
      width: 120px;
    }
  }
}

.amount-raised-card-font-18-roboto-black {
  font-size: vw(18px);
  line-height: vw(21.09px);
  margin-bottom: vw(19px);
  padding-top: vw(26px);
  @include fontRobotoBlack;
}

.font-lineone {
  font-size: vw(18px);
  line-height: vw(21.09px);
  margin-bottom: vw(19px);
  padding-top: vw(26px);
}

.tooltip-hover {
  // position: relative;
  display: inline-block;
  border-radius: 50%;
  font-size: 15px;
  color: white;
  cursor: pointer;
  // font-weight: bold;
  margin-left: 5px;
  border: 1px solid white;
  padding-left: 6px;
  padding-right: 6px;

  @media only screen and (max-width: 525px) {
    font-size: 10px;
    padding-left: 0px;
    padding-right: 0px;
    width: 13px;
    height: 13px;
  }
}

/* Tooltip text */
.tooltip-hover .tooltiptext {
  visibility: hidden;
  font-weight: 300 !important;
  width: 70%;
  background-color: #fff;
  font-size: 12px;
  color: #000;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  // left: -227px;
  top: 53%;
  left: 15%;
  height: 200px;
  overflow: scroll;

  @media only screen and (max-width: 1300px) {
    top: 35%;
  }

  @media only screen and (max-width: 900px) {
    top: 30%;
    width: 80%;
    left: 10%;
  }

  // @media only screen and (max-width: 991px) {
  //   left: -165px;
  //   top: -220px;
  // }
  // @media only screen and (max-width: 767px) {
  //   left: -185px;
  //   top: -220px;
  // }
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-hover:hover .tooltiptext {
  visibility: visible;
}

.tooltip-hoverBlack {
  position: relative;
  display: inline-block;
  border-radius: 50%;
  font-size: 15px;
  color: black;
  cursor: pointer;
  font-weight: bold;
  margin-left: 5px;
  border: 1px solid black;
  padding-left: 6px;
  padding-right: 6px;
}

/* Tooltip text */
.tooltip-hoverBlack .tooltiptext {
  visibility: hidden;
  font-weight: 300 !important;
  width: 250px;
  height: 200px;
  overflow-y: scroll;
  background-color: #fff;
  font-size: 12px;
  color: #000;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  left: -175px;
  top: -180px;

  @media only screen and (max-width: 767px) {
    left: -165px;
    top: -180px;
  }
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-hoverBlack:hover .tooltiptext {
  visibility: visible;
}

.amount-card-font-13-regular-roboto {
  font-size: vw(18px);
  line-height: vw(21.09px);
  margin-bottom: vw(14px);
  @include fontRobotoRegular;
}

.amount-card-font-16-bold-roboto {
  font-size: vw(20px);
  line-height: vw(23.44px);
  @include fontRobotoBold;
  margin-bottom: vw(25px);
}

.vl-topnavbar-page-title {
  display: none !important;
  padding: 10px 20px;

  .mobile-menubar {
    display: none;

    i {
      color: rgb(0, 0, 0);
      font-size: 25px;
    }

    @media only screen and (max-width: 900px) {
      display: block;
      // margin-right: 5%;
    }
  }

  .titletext-top {
    button {
      // margin-right: 5px;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        @media only screen and (max-width: 900px) {
          font-size: 15px;
        }

        // @media only screen and (max-width: 525px) {
        //   font-size: 10px;
        // }
      }
    }

    h1 {
      font-size: 35px;
      margin: 0px;

      @media only screen and (max-width: 1440px) {
        font-size: 25px;
      }

      @media only screen and (max-width: 900px) {
        font-size: 20px;
      }

      @media only screen and (max-width: 525px) {
        font-size: 15px;
        line-height: 2;
      }
    }
  }

  @media only screen and (max-width: 900px) {
    display: block !important;
  }
}

.details-page-main {
  // margin-top: 105px;
  padding-top: 0;
  padding-left: 15px;
  padding-right: 15px;
  // padding-right: vw(78px);
  .startup-div {
    margin-top: 114px;
    .background-image {
      width: 100%;
      position: relative;
      // height: 280px;
      border-radius: 40px 40px 0px 0px;
      overflow: hidden;
      // background-color: #babac3
      img {
        width: 100%;
        // object-fit: cover;
        // height: 280px;
      }
      @media only screen and (max-width: 1100px) {
        // height: 180px;
      }

      @media only screen and (max-width: 530px) {
        // height: 130px;
        img {
          // height: 100%;
        }
      }
    }
    .profile-left {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .profile-img {
        margin-right: 0px;
        width: auto;
        position: relative;

        .profile-inner {
          width: 215px;
          height: 215px;
          border-radius: 50%;
          overflow: hidden;
          border: 4px solid #323277;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 40px;
          background-color: #fff;
          margin-top: -92px;
          margin-right: 0px;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          padding: 3px;

          @media only screen and (max-width: 1350px) {
            margin-left: 30px;
          }

          @media only screen and (max-width: 1150px) {
            width: 150px;
            height: 154px;
            margin-top: -50px;
          }

          @media only screen and (max-width: 991px) {
            width: 120px;
            height: 124px;
            margin-top: -50px;
          }

          @media only screen and (max-width: 600px) {
            margin-left: 20px;
          }

          @media only screen and (max-width: 390px) {
            width: 90px;
            height: 94px;
            margin-top: -20px;
          }
        }

        .playblue {
          position: absolute;
          right: 0px;
          bottom: 12px;

          img {
            @media only screen and (min-width: 1150px) {
              height: 50px;
              width: 50px;
            }
          }

          @media only screen and (max-width: 991px) {
            width: 40px;
            bottom: 0px;
          }

          @media only screen and (max-width: 525px) {
            right: 0px;
          }

          @media only screen and (max-width: 390px) {
            width: 30px;
          }
        }
      }

      .profile-name {
        display: flex;
        align-items: center;
        margin-left: 10px;

        h2 {
          font-weight: 700;
          font-size: 30px;
          line-height: 45px;
          margin-right: 10px;
          margin-bottom: 0px;
          font-family: "Roboto-bold";
          color: #000;

          @media only screen and (max-width: 1200px) {
            font-size: 20px;
          }

          @media only screen and (max-width: 1100px) {
            font-size: 17px;
          }

          @media only screen and (max-width: 525px) {
            font-size: 20px;
            margin-right: 5px;
            line-height: 20px;
          }

          @media only screen and (max-width: 390px) {
            font-size: 15px;
          }
        }

        img {
          width: 20px;

          @media only screen and (max-width: 525px) {
            width: 15px;
            // margin-top: 10px;
          }
        }

        @media only screen and (max-width: 525px) {
          margin-top: 15px;
        }
      }

      @media only screen and (max-width: 767px) {
        justify-content: flex-start;
        margin-bottom: 20px;
      }

      @media only screen and (max-width: 525px) {
        margin-bottom: 0px;
        flex-direction: column;
        align-items: flex-start;
      }
    }
    .follow-btn {
      display: flex;
      justify-content: flex-end;

      button {
        background-color: #292950;
        border-radius: 10px;
        color: #fff;
        padding: 10px 30px;
        margin-left: 10px;
        font-weight: 500;
        font-size: 17px;
        line-height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Roboto-regular";

        img,
        i {
          margin-right: 15px;

          @media only screen and (max-width: 525px) {
            width: 15px;
            margin-right: 7px;
          }
        }

        @media only screen and (max-width: 1150px) {
          padding: 10px 25px;
          font-size: 15px;
        }

        @media only screen and (max-width: 1048px) {
          padding: 7px 15px;
          font-size: 13px;
        }

        @media only screen and (max-width: 900px) {
          padding: 10px 25px;
          font-size: 15px;
        }

        @media only screen and (max-width: 525px) {
          font-size: 14px;
          margin-left: 0px;
        }

        @media only screen and (max-width: 390px) {
          font-size: 12px;
          padding: 5px 15px;
        }

        // span {
        //   width: 80px;
        // }
      }

      .follow-btn-inner {
        @media only screen and (max-width: 767px) {
          margin-bottom: 5px;
          margin-top: 5px;
        }
      }

      @media only screen and (max-width: 767px) {
        justify-content: center;
        flex-direction: column;
        align-items: flex-end;
        margin-left: 0px;
      }
    }
    &.rmMargin {
      margin-top: 28px;
      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        margin-top: 0;
      }
      @media only screen and (max-width: 769px) {
        margin-top: 0;
      }
    }
    &.addMargin {
      margin-top: 114px;
      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        margin-top: 85px;
      }
      @media only screen and (max-width: 425px) {
        margin-top: 75px;
      }
    }
    @media only screen and (max-width: 1024px) {
      margin-top: 0;
    }
    @media only screen and (max-width: 768px) {
      margin-top: 0;
    }
    @media only screen and (max-width: 500px) {
      margin-top: 0;
    }
  }
  .startup-no-image {
    margin-top: 90px;
    &.addMargin {
      margin-top: 85px;
      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        margin-top: 115px;
      }
      @media only screen and (max-width: 425px) {
        margin-top: 100px;
      }
    }
    .background-image {
      width: 100%;
      position: relative;
      // height: 280px;
      border-radius: 40px 40px 0px 0px;
      overflow: hidden;
      // background-color: #babac3;
      img {
        width: 100%;
        // object-fit: cover;
        // height: 280px;
      }

      @media only screen and (max-width: 1100px) {
        // height: 180px;
      }

      @media only screen and (max-width: 530px) {
        // height: 130px;
        img {
          // height: 100%;
        }
      }
    }
    .profile-left {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .profile-img {
        margin-right: 0px;
        width: auto;
        position: relative;

        .profile-inner {
          width: 215px;
          height: 215px;
          border-radius: 50%;
          overflow: hidden;
          border: 4px solid #323277;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 40px;
          background-color: #fff;
          margin-top: 0;
          margin-right: 0px;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          padding: 3px;

          @media only screen and (max-width: 1350px) {
            margin-left: 30px;
          }

          @media only screen and (max-width: 1150px) {
            width: 150px;
            height: 154px;
            margin-top: -50px;
          }

          @media only screen and (max-width: 991px) {
            width: 120px;
            height: 124px;
            margin-top: -50px;
          }

          @media only screen and (max-width: 600px) {
            margin-left: 20px;
          }

          @media only screen and (max-width: 390px) {
            width: 90px;
            height: 94px;
            margin-top: -20px;
          }
        }

        .playblue {
          position: absolute;
          right: 0px;
          bottom: 12px;

          img {
            @media only screen and (min-width: 1150px) {
              height: 50px;
              width: 50px;
            }
          }

          @media only screen and (max-width: 991px) {
            width: 40px;
            bottom: 0px;
          }

          @media only screen and (max-width: 525px) {
            right: 0px;
          }

          @media only screen and (max-width: 390px) {
            width: 30px;
          }
        }
      }

      .profile-name {
        display: flex;
        align-items: center;
        margin-left: 10px;

        h2 {
          font-weight: 700;
          font-size: 30px;
          margin-top: 100px;
          line-height: 45px;
          margin-right: 10px;
          margin-bottom: 0px;
          font-family: "Roboto-bold";
          color: #000;

          @media only screen and (max-width: 1200px) {
            font-size: 20px;
          }

          @media only screen and (max-width: 1100px) {
            font-size: 17px;
            margin-top: 10px;
          }

          @media only screen and (max-width: 525px) {
            font-size: 20px;
            margin-right: 5px;
            line-height: 20px;
          }

          @media only screen and (max-width: 390px) {
            font-size: 15px;
          }
        }

        img {
          width: 20px;

          @media only screen and (max-width: 525px) {
            width: 15px;
            // margin-top: 10px;
          }
        }

        @media only screen and (max-width: 525px) {
          margin-top: 15px;
        }
      }

      @media only screen and (max-width: 767px) {
        justify-content: flex-start;
        margin-bottom: 20px;
      }

      @media only screen and (max-width: 525px) {
        margin-bottom: 0px;
        flex-direction: column;
        align-items: flex-start;
      }
    }
    .follow-btn {
      display: flex;
      justify-content: flex-end;

      button {
        background-color: #292950;
        border-radius: 10px;
        color: #fff;
        padding: 10px 30px;
        margin-left: 10px;
        font-weight: 500;
        font-size: 17px;
        line-height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Roboto-regular";

        img,
        i {
          margin-right: 15px;

          @media only screen and (max-width: 525px) {
            width: 15px;
            margin-right: 7px;
          }
        }

        @media only screen and (max-width: 1150px) {
          padding: 10px 25px;
          font-size: 15px;
        }

        @media only screen and (max-width: 1048px) {
          padding: 7px 15px;
          font-size: 13px;
        }

        @media only screen and (max-width: 900px) {
          padding: 10px 25px;
          font-size: 15px;
        }

        @media only screen and (max-width: 525px) {
          font-size: 14px;
          margin-left: 0px;
        }

        @media only screen and (max-width: 390px) {
          font-size: 12px;
          padding: 5px 15px;
        }

        // span {
        //   width: 80px;
        // }
      }

      .follow-btn-inner {
        @media only screen and (max-width: 767px) {
          margin-bottom: 5px;
          margin-top: 5px;
        }
      }

      @media only screen and (max-width: 767px) {
        justify-content: center;
        flex-direction: column;
        align-items: flex-end;
        margin-left: 0px;
      }
    }
    @media only screen and (max-width: 1024px) {
      margin-top: 40px;
    }
    @media only screen and (max-width: 768px) {
      margin-top: 30px;
    }
    @media only screen and (max-width: 500px) {
      margin-top: 20px;
    }
  }
  .raise-amount-secttion {
    margin-top: 30px;

    .raise-amount-inner {
      background: rgba(105, 105, 183, 0.2);
      box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.25);
      border-radius: 30px;
      padding: 36px 30px;
      min-height: 350px;

      ul {
        margin: 0;
        padding: 0;

        li {
          list-style: none;
          font-size: 15px;
          line-height: 22px;
          color: #323277;
          display: flex;
          align-items: flex-start;
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0px;
          }

          p {
            font-family: "Roboto-bold";
            font-weight: 700;
            width: 35%;
            margin: 0;

            @media only screen and (max-width: 1350px) {
              width: 40%;
            }
          }

          span {
            font-family: "Roboto-regular";
            font-weight: 500;

            .tooltip-funding {
              display: inline-block;
              position: relative;
              cursor: pointer;
            }

            .tooltip-text {
              display: none;
            }

            .tooltip-funding:hover > .tooltip-text {
              display: inline-block;
              position: absolute;
              bottom: 100%;
              left: calc(50%);
              transform: translateX(-50%);
              padding: 3px 10px;
              border-radius: 10px;
              background-color: #fff;
              color: #000;
              font-size: 12px;
              white-space: nowrap;
            }
          }
          a {
            font-family: "Roboto-regular";
            font-weight: 500;
          }
          .teammain-btn {
            display: flex;
            flex-direction: column;

            .team-btn {
              button {
                background: #323277;
                color: #fff;
                box-shadow: 0px 0px 10px rgba(50, 50, 119, 0.4);
                border-radius: 4px;
                font-weight: 500;
                font-size: 13px;
                line-height: 15px;
                padding: 4px 8px;
                margin-right: 15px;

                &:nth-child(2) {
                  background: #008cba;
                }

                &:nth-child(3) {
                  background: #f44336;
                }

                &:nth-child(4) {
                  background: #014c7f;
                }

                &:nth-child(5) {
                  background: #555555;
                }

                &:nth-child(6) {
                  background: #4caf50;
                }

                &:nth-child(7) {
                  background: #fcbe00;
                }

                &:nth-child(8) {
                  background: #e7e7e7;
                  color: #000;
                }

                &:nth-child(9) {
                  background: #e66465;
                }

                &:last-child {
                  margin-right: 0px;
                }

                @media only screen and (min-width: 1800px) {
                  font-size: 12px;
                  padding: 7px 12px;
                }
              }
            }

            .teamline-one {
              button {
                margin-bottom: 10px;
                font-family: "Roboto-regular";
              }
            }
          }

          .startup-details {
            font-size: 12px;

            @media only screen and (min-width: 1800px) {
              font-size: 14px;
            }
          }

          @media only screen and (min-width: 1800px) {
            font-size: 18px;
          }

          @media only screen and (max-width: 1150px) {
            margin-bottom: 15px;
            font-size: 16px;
          }
        }
      }

      @media only screen and (max-width: 1580px) {
        padding: 20px;
        // min-height: 320px;
      }

      @media only screen and (max-width: 1199px) {
        margin-bottom: 20px;
      }

      @media only screen and (max-width: 525px) {
        min-height: 300px;
      }
    }

    .info-text {
      text-align: center !important;
      padding: 10px 10px 0px 10px !important;
      // margin-top: 10px !important;
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;

      &:last-child {
        padding: 10px 10px 10px 10px !important;
      }
    }

    .max-target-main {
      background: rgba(105, 105, 183, 0.2);
      box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.25);
      border-radius: 30px;
      padding: 32px 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 350px;

      .max-target {
        width: 40%;
        padding-right: 30px;
        margin-right: 30px;
        border-right: 2px solid #494980;
        font-weight: 600;

        .amount-raised-card-div-progress-bar {
          margin: auto;
        }

        .invest-btn {
          text-align: center;
          margin-top: 21px;

          button {
            font-family: "Roboto-regular";
            font-weight: 300;
            background: #323277;
            box-shadow: 0px 0px 60px -4px rgba(50, 50, 119, 0.43);
            border-radius: 10px;
            font-size: 20px;
            line-height: 20px;
            text-align: center;
            color: #ffffff;
            padding: 11px;
            width: 70%;

            @media only screen and (max-width: 530px) {
              width: 180px;
              font-size: 15px;
            }
          }
        }

        .invest-btn-disabled {
          text-align: center;
          margin-top: 21px;
          button {
            font-family: "Roboto-regular";
            font-weight: 300;
            background: #323277;
            box-shadow: 0px 0px 60px -4px rgba(50, 50, 119, 0.43);
            border-radius: 10px;
            font-size: 20px;
            line-height: 20px;
            text-align: center;
            color: #ffffff;
            padding: 11px;
            cursor: not-allowed;
            width: 70%;

            @media only screen and (max-width: 530px) {
              width: 180px;
              font-size: 15px;
            }
          }
        }

        .remaining {
          text-align: center;
          font-weight: 400;
          font-size: 12px;
          line-height: 25px;
          text-align: center;
          color: #323277;
          font-family: "Roboto-bold";

          span {
            font-weight: 700;

            .remaining-days {
              font-weight: 400;
              font-family: "Roboto-regular";
            }
          }

          @media only screen and (min-width: 1800px) {
            font-size: 15px;
          }
        }

        .reserved-list-text {
          font-size: 12px;
          text-align: center;
          line-height: 1.5;
        }

        @media only screen and (max-width: 1500px) {
          padding-right: 15px;
          margin-right: 15px;
        }

        @media only screen and (max-width: 530px) {
          width: 100%;
          border-right: none;
          margin-right: 0px;
          padding-left: 0px;
          margin-bottom: 15px;
          padding-bottom: 15px;
          border-bottom: 2px solid #494980;
        }
      }

      .target-tex {
        font-family: "Roboto-bold";
        width: 50%;
        padding-left: 40px;

        h3 {
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          text-align: center;
          color: #323277;
          margin-bottom: 5px;

          @media only screen and (min-width: 1800px) {
            font-size: 25px;
          }
        }

        h1 {
          text-align: center;
          font-weight: 700;
          font-size: 25px;
          line-height: 38px;
          color: #323277;
          margin-bottom: 20px;

          @media only screen and (min-width: 1800px) {
            font-size: 30px;
          }

          @media only screen and (max-width: 1150px) {
            font-size: 20px;
            margin-bottom: 10px;
          }
        }

        ul {
          li {
            list-style: none;
            display: flex;
            align-items: center;
            font-weight: 700;
            font-size: 14px;
            line-height: 21px;
            color: #323277;

            p {
              font-family: "Roboto-regular";
              width: 60%;
              font-weight: 600;
              margin: 0px;
            }

            .fund_details {
              max-width: 100px;
            }

            @media only screen and (max-width: 1108px) {
              font-size: 15px;
            }

            @media only screen and (min-width: 1800px) {
              font-size: 16px;
            }
          }

          @media only screen and (min-width: 1800px) {
            width: 75%;
            margin: 0 auto;
          }
        }

        .reserved-list-text {
          text-align: center;
          font-size: 12px;
          line-height: 18px;
          font-weight: 600;

          @media only screen and (min-width: 1800px) {
            font-size: 14px;
          }

          .tooltip-reserved {
            position: relative;
            display: inline-block;
            // border-radius: 50%;
            font-size: 15px;
            color: black;
            cursor: pointer;
            font-weight: bold;
            margin-left: 5px;

            i {
              font-size: 17px;
              color: #323277;
            }

            // border: 1px solid black;
            // padding-left: 6px;
            // padding-right: 6px;
          }

          /* Tooltip text */
          .tooltip-reserved .tooltiptext-reserved {
            visibility: hidden;
            font-weight: 300 !important;
            width: 250px;
            height: 200px;
            overflow-y: scroll;
            background-color: #fff;
            font-size: 12px;
            color: #000;
            text-align: center;
            padding: 10px;
            border-radius: 6px;
            position: absolute;
            z-index: 1;
            left: -175px;
            bottom: 30px;

            @media only screen and (max-width: 767px) {
              left: -165px;
            }
          }

          /* Show the tooltip text when you mouse over the tooltip container */
          .tooltip-reserved:hover .tooltiptext-reserved {
            visibility: visible;
          }
        }

        .closing-text {
          font-family: "Roboto-regular";
          text-align: center;

          p {
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
            color: #323277;

            span {
              font-family: "Roboto-bold";
              font-weight: 700;
            }

            @media only screen and (max-width: 1108px) {
              font-size: 15px;
            }
          }

          @media only screen and (min-width: 1800px) {
            margin-top: 60px;
          }
        }

        .issuers-btn {
          text-align: center;

          button {
            font-family: "Roboto-regular";
            background: #323277;
            box-shadow: 0px 0px 21px rgba(50, 50, 119, 0.45);
            border-radius: 20px;
            color: #fff;
            padding: 10px 28px;
            line-height: normal;
            font-size: 12px;
            font-weight: 400;

            img {
              margin-left: 10px;
            }

            @media only screen and (min-width: 1800px) {
              font-size: 14px;
              font-weight: 400;
            }
          }

          @media only screen and (max-width: 1580px) {
            margin-top: 20px;
            font-size: 12px;

            img {
              width: 15px;
            }
          }
        }
        @media only screen and (max-width: 990px) {
          padding-left: 60px;
        }
        @media only screen and (max-width: 530px) {
          width: 100%;
          padding-left: 0px;
        }
      }

      @media only screen and (max-width: 1580px) {
        padding: 20px;
        // min-height: 320px;
      }

      @media only screen and (max-width: 530px) {
        flex-direction: column;
      }
    }
  }

  .highlights-documents {
    margin-top: 48px;

    // font-family: "Poppins", sans-serif;
    h1 {
      font-family: "Roboto-bold";
      font-weight: 800;
      font-size: 22px;
      line-height: 33px;
      color: #000000;
      margin-bottom: 20px;
      @media only screen and (max-width: 530px) {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }

    .highlight-innet {
      ul {
        li {
          font-family: "Roboto-regular";
          padding: 8px 10px;
          background: #e1e1f1;
          // box-shadow: 0px 4px 4px rgba(50, 50, 119, 0.25);
          border-radius: 5px;
          margin-bottom: 15px;
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;
          color: #000000;
          list-style-position: inside;
          cursor: pointer;
          filter: drop-shadow(0px 4px 4px #323277);

          @media only screen and (min-width: 1800px) {
            font-size: 16px;
          }

          @media only screen and (max-width: 530px) {
            font-size: 13px;
          }

          p {
            font-size: 14px;
            line-height: 21px;
            color: #6d6d70;
            margin-top: 7px;
            margin-bottom: 0px;
            margin-left: 20px;

            @media only screen and (max-width: 530px) {
              font-size: 12px;
            }
          }
        }
      }
    }

    .documents-inner {
      background: #ffffff;
      border: 1px solid #292950;
      box-sizing: border-box;
      border-radius: 20px;

      p {
        font-family: "Roboto-regular";
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #000000;
        padding: 10px 25px;

        @media only screen and (min-width: 1800px) {
          font-size: 15px;
        }

        @media only screen and (max-width: 530px) {
          padding: 15px;
        }
      }

      .document-dow {
        background: rgba(196, 196, 196, 0.2);
        padding: 6px 25px;
        display: flex;
        align-items: center;

        @media only screen and (max-width: 530px) {
          padding: 6px 15px;
        }

        p {
          font-family: "Roboto-regular";
          border-right: 1px solid rgb(0, 0, 0);
          padding: 5px 15px 5px 20px;
          margin: 0;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #000000;

          @media only screen and (min-width: 1800px) {
            font-size: 14px;
          }
        }

        span {
          font-family: "Roboto-regular";
          padding: 5px 15px 5px 20px;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #000000;

          @media only screen and (min-width: 1800px) {
            font-size: 14px;
          }
        }
      }
      .react-pdf__Document {
        max-height: 90vh;
      }
      .company-documents {
        // margin-top: 17px;
        padding: 15px 25px 0px 25px;
        // .pagination-model-number {
        // }
        h3 {
          font-family: "Roboto-bold";
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          margin-bottom: 9px;

          @media only screen and (min-width: 1800px) {
            font-size: 14px;
          }
        }

        ul {
          padding: 0;
          li {
            list-style: none;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            margin-bottom: 9px;
            .full-vl-pitch-deck-file-modal-content-div {
              width: 100%;
              min-height: max-content;
              height: auto;
              background-color: #ffffff;
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0 auto;
              @media only screen and (max-width: 1024px) and (min-width: 768px) {
                // width: 85vw;
              }
              @media only screen and (max-width: 769px) and (min-width: 300px) {
                // width: 80vw;
              }
            }
            .pagination-model-number {
              position: fixed;
              left: 50%;
              top: 0;
              transform: translate(-50%, 0%);
              background-color: #292929;
              width: 100%;
              height: 50px;
              // border-radius: 0 0 10px 10px;
              box-shadow: 0px 0px 60px -4px #000000;
              @media only screen and (max-width: 768px) and (min-width: 424px) {
                height: 38px;
              }
              @media only screen and (max-width: 425px) and (min-width: 300px) {
                height: 35px;
              }
              .vl-pdf-screen-prev-btn {
                height: 22px;
                color: black;
                font-size: 18px;
                display: flex;
                align-items: center;
                justify-content: center;
                @media only screen and (max-width: 425px) and (min-width: 300px) {
                  font-size: 14px;
                  height: 14px;
                }
              }
              .vl-pdf-screen-text {
                font-size: 16px;
                text-transform: capitalize;
                color: black;
              }
              .vl-pdf-screen-next-btn {
                height: 22px;
                font-size: 18px;
                color: black;
                display: flex;
                justify-content: center;
                align-items: center;
                @media only screen and (max-width: 425px) and (min-width: 300px) {
                  font-size: 14px;
                  height: 14px;
                }
              }
            }
            .pdf-pagenation {
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .top-right-close {
              position: absolute;
              display: flex;
              font-size: 18px;
              border-radius: 50%;
              justify-content: center;
              align-items: center;
              z-index: 2;
              background: red;
              color: white;
              border-radius: 5px;
              justify-content: center;
              align-items: center;
              text-transform: uppercase;
              width: 80px;
              height: 50px;
              @media only screen and (min-width: 1440px) and (max-width: 2600px) {
                top: 8px;
                right: 15px;
                font-size: 15px;
                height: 30px;
              }
              @media only screen and (max-width: 1440px) and (min-width: 768px) {
                top: 8px;
                right: 15px;
                font-size: 15px;
                height: 32px;
              }
              @media only screen and (max-width: 769px) and (min-width: 424px) {
                top: 7px;
                right: 9px;
                font-size: 13px;
                width: 48px;
                height: 24px;
              }
              @media only screen and (max-width: 425px) and (min-width: 300px) {
                width: 42px;
                height: 24px;
                top: 6px;
                right: 6px;
                font-size: 10px;
              }
              .modal-content-close {
                font-size: 21px;
                color: #fff;
                @media only screen and (max-width: 769px) and (min-width: 424px) {
                  font-size: 17px;
                }
                @media only screen and (max-width: 425px) and (min-width: 374px) {
                  font-size: 13px;
                }
                @media only screen and (max-width: 375px) and (min-width: 319px) {
                  font-size: 12px;
                }
              }
            }
            button {
              font-family: "Roboto-regular";
            }
            .fullscreen {
              display: flex;
              justify-content: center;
              align-items: center;
              overflow-y: scroll;
              background-color: #fff;
              .vl-pdf-screen-text {
                font-size: 18px;
                color: black;
                display: flex;
                .page-number {
                  width: 30px;
                  height: 30px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 17px;
                  margin: 0;
                  background-color: black;
                  color: #fff;
                  padding: 0;
                  @media only screen and (max-width: 1024px) and (min-width: 768px) {
                    font-size: 15px;
                  }
                  @media only screen and (max-width: 425px) and (min-width: 300px) {
                    font-size: 12px;
                  }
                }
                p {
                  width: 30px;
                  height: 30px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 17px;
                  margin: 0 0 0 4px;
                  color: #fff;
                  padding: 0;
                  @media only screen and (max-width: 1024px) and (min-width: 768px) {
                    font-size: 15px;
                  }
                  @media only screen and (max-width: 425px) and (min-width: 300px) {
                    font-size: 12px;
                  }
                }
                @media only screen and (max-width: 1024px) and (min-width: 768px) {
                  font-size: 15px;
                }
                @media only screen and (max-width: 769px) and (min-width: 300px) {
                  font-size: 13px;
                }
              }
            }
            .fullScreenOff {
              display: none;
            }
            img {
              margin-right: 10px;
            }

            @media only screen and (min-width: 1800px) {
              font-size: 14px;
            }
          }

          .click-play-btn {
            margin-left: 3px;
          }
        }

        @media only screen and (max-width: 530px) {
          padding: 13px 15px 0px 15px;
        }
      }
    }
    .next {
      font-size: 20px;
      color: #fff;
      @media only screen and (max-width: 1024px) and (min-width: 768px) {
        font-size: 18px;
      }
      @media only screen and (max-width: 769px) and (min-width: 300px) {
        font-size: 16px;
      }
    }

    .highlight-innet {
      ul {
        list-style: none;
        padding: 0;
      }
    }

    .vl-tearm-table {
      border-radius: 20px;
      border: 1px solid;

      table {
        tbody {
          tr {
            td {
              line-height: 1;
            }
          }
        }
      }
    }
  }

  .menu-line {
    font-family: "Poppins", sans-serif;
    background: #292950;
    box-shadow: 0px 0px 54px -3px rgba(41, 41, 80, 0.4);
    border-radius: 10px;
    margin-top: 40px;
    margin-bottom: 60px;

    ul {
      display: flex;
      align-items: center;
      padding: 12px;
      overflow-y: scroll;
      scroll-behavior: smooth;

      li {
        list-style: none;
        color: #ffff;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        padding: 8px 50px;
        border-radius: 7px;
        margin-right: 10px;

        @media only screen and (max-width: 530px) {
          font-size: 13px;
          padding: 5px 25px;
        }
      }

      .active-menu {
        background-color: #ffffff;
        color: #292950;

        button {
          font-family: "Roboto-Regular";
        }
      }

      .inActive-menu {
        button {
          color: #ffffff;
          font-family: "Roboto-Regular";
        }
      }

      @media only screen and (max-width: 530px) {
        padding: 8px 12px;
      }
    }

    @media only screen and (max-width: 530px) {
      margin-bottom: 30px;
    }
  }

  .menudata-main {
    font-family: "Poppins", sans-serif;

    .menudata-head {
      padding: 0px 20px;
      margin-bottom: 30px;

      .menu-head-title {
        font-family: "Roboto-bold";
        padding-bottom: 15px;
        font-weight: 800;
        font-size: 22px;
        line-height: 33px;
        margin: 0;

        img {
          width: 30px;
          margin-right: 10px;
        }

        @media only screen and (max-width: 530px) {
          padding: 0;
          margin: 25px 0;
        }
      }

      .content-wrap {
        // display: flex;
        // justify-content: center;
        width: 100%;
        div {
          figure {
            text-align: center;
            margin-bottom: 30px;
            img {
              max-width: 100%;
              border: 1px solid #000;
              filter: drop-shadow(5px 8px 10px rgba(0, 0, 0, 0.2));
              border-radius: 10px;
            }
          }
          p {
            font-size: 16px;
            line-height: 1.3;
          }
          ul {
            padding-left: 15px;
          }
        }
      }

      h3 {
        padding-top: 15px;
        font-weight: 800;
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 6px;
      }

      .menu-head-text {
        font-family: "Roboto-regular";
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 30px;

        @media only screen and (max-width: 530px) {
          font-size: 14px;
        }
      }

      .aboutcontent-img {
        text-align: center;
        overflow: hidden;
        margin-bottom: 50px;
        margin-top: 30px;
        iframe {
          max-width: 100%;
          border: 1px solid #000;
          filter: drop-shadow(5px 8px 10px rgba(0, 0, 0, 0.2));
          border-radius: 10px;
        }
        img {
          border: 1px solid #000000;
          box-sizing: border-box;
          filter: drop-shadow(5px 8px 10px rgba(0, 0, 0, 0.2));
          border-radius: 10px;
        }
      }

      @media only screen and (max-width: 530px) {
        padding: 0px;
      }
    }
  }

  .menu-head-title {
    font-family: "Roboto-bold";
    padding-bottom: 15px;
    font-weight: 800;
    font-size: 22px;
    line-height: 33px;
    margin: 0;

    img {
      width: 30px;
      margin-right: 10px;
    }
  }

  .team-list {
    font-family: "Poppins", sans-serif;

    .team-list-inner {
      display: flex;
      // align-items: center;
      background: #e1e1f1;
      // background: #ffffff;
      border-radius: 30px;
      margin: 15px 0px;
      box-shadow: 0px 1px 20px 0px rgba(50, 50, 119, 0.4);
      overflow: hidden;

      .team-list-image {
        width: 40%;
        background-color: #fff;
        text-align: center;
        border-radius: 30px;
        overflow: hidden;
        box-shadow: 0px 1px 26px 1px rgba(50, 50, 119, 0.4);

        img {
          border-radius: 30px;

          @media only screen and (max-width: 454px) {
            width: 50%;
          }
        }

        .text-image-text {
          padding: 15px;

          h3 {
            font-family: "Roboto-bold";
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 0px;

            @media only screen and (max-width: 1690px) and (min-width: 1200px) {
              font-size: 15px;
            }

            @media only screen and (max-width: 900px) {
              font-size: 15px;
            }
          }

          p {
            font-family: "Roboto-regular";
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            margin: 0px;

            @media only screen and (max-width: 1690px) and (min-width: 1200px) {
              font-size: 12px;
            }

            @media only screen and (max-width: 900px) {
              font-size: 12px;
            }
          }

          @media only screen and (max-width: 900px) {
            padding: 10px;
          }

          @media only screen and (max-width: 454px) {
            width: 50%;
          }
        }

        @media only screen and (max-width: 1199px) and (min-width: 992px) {
          width: 33%;
        }

        // @media only screen and (max-width: 1200px) {
        //   width: 50%;
        // }
        @media only screen and (max-width: 900px) {
          width: 25%;
        }

        @media only screen and (max-width: 700px) {
          width: 35%;
        }

        @media only screen and (max-width: 575px) {
          width: 40%;
        }

        @media only screen and (max-width: 454px) {
          width: 100%;
          display: flex;
          align-items: center;
        }
      }

      .team-list-text {
        width: 60%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;

        // height: 300px;
        .team-list-text-top {
          h2 {
            font-family: "Roboto-bold";
            font-weight: 800;
            font-size: 18px;
            line-height: 27px;
            color: #323277;
            margin-bottom: 15px;

            @media only screen and (max-width: 900px) {
              font-size: 16px;
            }
          }

          p {
            font-family: "Roboto-regular";
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #4e4e51;
            height: 150px;
            overflow-y: scroll;

            @media only screen and (max-width: 1480px) and (min-width: 1200px) {
              height: 130px;
            }

            @media only screen and (max-width: 900px) {
              height: 100px;
              font-size: 12px;
            }
          }
        }

        .team-list-text-end {
          h1 {
            font-family: "Roboto-bold";
            font-weight: 800;
            font-size: 18px;
            line-height: 27px;
            color: #323277;

            @media only screen and (max-width: 900px) {
              font-size: 14px;
            }
          }

          img {
            margin-right: 10px;
          }
        }

        .linkdin_link {
          position: absolute;
          bottom: -10px;
          right: 20px;

          img {
            height: 30px;
            width: 30px;
            border-radius: 5px;
            box-shadow: 0px 1px 20px 0px #32327766;
          }

          @media only screen and (min-width: 1901px) {
            bottom: 20px;
          }

          @media only screen and (max-width: 1900px) and (min-width: 1601px) {
            bottom: 10px;
          }

          @media only screen and (max-width: 1600px) and (min-width: 1441px) {
            bottom: 15px;
          }

          @media only screen and (max-width: 1440px) and (min-width: 789px) {
            bottom: 12px;
          }

          @media only screen and (max-width: 768px) and (min-width: 320px) {
            bottom: 15px;
          }
        }

        @media only screen and (max-width: 1199px) and (min-width: 900px) {
          width: 66%;
        }

        // @media only screen and (max-width: 1200px) {
        //   width: 50%;
        // }
        @media only screen and (max-width: 900px) {
          padding: 15px;
          width: 75%;
        }

        @media only screen and (max-width: 575px) {
          width: 60%;
        }

        @media only screen and (max-width: 700px) {
          width: 65%;
        }
      }

      // @media only screen and (max-width: 991px) and (min-width: 901px) {
      //   flex-direction: column;
      //   .team-list-image {
      //     width: 100%;
      //   }
      //   .team-list-text {
      //     width: 100%;
      //   }
      // }
      // @media only screen and (max-width: 767px) and (min-width: 576px) {
      //   flex-direction: column;
      //   .team-list-image {
      //     width: 100%;
      //   }
      //   .team-list-text {
      //     width: 100%;
      //   }
      // }
      @media only screen and (max-width: 454px) {
        flex-direction: column;

        .team-list-image {
          width: 100%;
        }

        .team-list-text {
          width: 100%;
        }
      }
    }
  }

  .vl-table-teams {
    tbody {
      tr {
        td {
          span {
            font-size: 14px;
          }

          .tablefirst-line {
            font-size: 14px;
            font-weight: 700;
          }
        }
      }
    }
  }

  .discussion-tabs {
    font-family: "Poppins", sans-serif;

    .discussion-list-main {
      margin-top: 20px;

      .discussion-list-main-inner {
        padding: 25px;
        border-bottom: 1px solid #000000;

        .discussion-top {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .discussion-top-left {
            display: flex;
            align-items: center;
            width: 70%;

            .discussion-top-profile {
              margin-right: 30px;

              img {
                width: 60px;
                border-radius: 50%;
                overflow: hidden;
                height: 60px;
                color: #fff;

                @media only screen and (max-width: 1024px) {
                  width: 35px;
                  height: 35px;
                }
              }

              p {
                width: 60px;
                border-radius: 50%;
                overflow: hidden;
                height: 60px;
                background: #7474c4;
                color: #fff;
                box-shadow: 0 0 3px 3px rgba(116, 116, 196, 0.2);
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0;
                font-size: 40px;

                @media only screen and (max-width: 1024px) {
                  width: 35px;
                  height: 35px;
                  font-size: 25px;
                }
              }

              @media only screen and (max-width: 1024px) {
                margin-right: 20px;
              }
            }

            .discussion-top-text {
              width: 100%;

              .discussion-title {
                display: flex;
                align-items: center;

                h2 {
                  font-family: "Roboto-bold";
                  font-weight: 700;
                  font-size: 22px;
                  line-height: 33px;
                  color: #000000;
                  margin-right: 27px;
                  margin-bottom: 0px;

                  @media only screen and (max-width: 1024px) {
                    font-size: 18px;
                  }

                  @media only screen and (max-width: 525px) {
                    font-size: 15px;
                    margin-right: 10px;
                  }
                }

                span {
                  font-family: "Roboto-regular";
                  font-weight: 600;
                  font-size: 15px;
                  line-height: 22px;
                  color: #7474c4;

                  @media only screen and (max-width: 1024px) {
                    font-size: 13px;
                  }

                  @media only screen and (max-width: 525px) {
                    font-size: 10px;
                  }
                }
              }

              .discussion-title-text {
                display: flex;
                align-items: center;

                p {
                  font-family: "Roboto-bold";
                  font-weight: 600;
                  font-size: 13px;
                  line-height: 20px;
                  color: rgba(0, 0, 0, 0.5);
                  width: 140px;

                  @media only screen and (max-width: 525px) {
                    font-size: 10px;
                    width: 120px;
                  }
                }

                span {
                  font-family: "Roboto-regular";
                  font-weight: 600;
                  font-size: 13px;
                  line-height: 20px;
                  color: rgba(0, 0, 0, 0.5);
                  margin-right: 13px;

                  // width: 140px;
                  @media only screen and (max-width: 525px) {
                    font-size: 10px;
                    // width: 120px;
                  }
                }

                .startup-details {
                  font-size: 13px;
                }
              }
            }

            @media only screen and (max-width: 650px) {
              width: 100%;
            }
          }

          .discussion-thread-btn {
            display: block;

            .discussion-inner-btn {
              background: #292950;
              border-radius: 10px;
              font-weight: 600;
              font-size: 12px;
              line-height: 18px;
              padding: 10px 15px;
              color: #ffffff;
              font-family: "Roboto-medium";
            }

            @media only screen and (max-width: 650px) {
              display: none;
            }
          }
        }

        .discussion-full-text {
          P {
            font-family: "Roboto-regular";
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
            margin-bottom: 20px;
            height: 60px;
            overflow-y: scroll;

            @media only screen and (max-width: 650px) {
              font-size: 13px;
            }
          }

          .discussion-list {
            .discussion-btn {
              display: flex;
              align-items: center;

              .discussion-list-btn {
                display: flex;
                align-items: center;
                width: 100px;
                font-family: "Roboto-regular";

                @media only screen and (max-width: 525px) {
                  width: auto;
                  margin-right: 5px;

                  span {
                    font-family: "Roboto-regular";
                    font-size: 10px;
                  }
                }

                img {
                  margin-right: 5px;
                }
              }

              .discussion-active {
                font-family: "Roboto-regular";
                color: rgba(116, 116, 196, 1);
              }
            }

            .discussion-thread-btn {
              display: none;

              button {
                background: #292950;
                border-radius: 10px;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                padding: 10px 15px;
                color: #ffffff;

                @media only screen and (max-width: 525px) {
                  font-size: 10px;
                  padding: 7px 10px;
                }
              }

              @media only screen and (max-width: 650px) {
                display: block;
              }
            }

            @media only screen and (max-width: 650px) {
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
          }
        }

        @media only screen and (max-width: 1024px) {
          padding: 15px;
        }

        @media only screen and (max-width: 525px) {
          padding: 0px 0px 15px 0px;
        }
      }
    }
  }

  .not-authorize-text {
    height: 70vh;
    min-height: 70vh;
    p {
      font-size: 20px;
      font-weight: 700;
    }
  }

  @media only screen and (max-width: 1540px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media only screen and (max-width: 1024px) {
    padding: 20px;
  }

  @media screen and (max-width: 913px) and (min-width: 900px) {
    padding: 10px;
  }

  @media only screen and (max-width: 900px) {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 10px;
  }
  @media only screen and (max-width: 500px) {
    padding: 0;
  }
}
.start-up-detail-light-paper {
  padding: 8px 0;
  box-shadow: 3px 7px 90px #00000038;
  border-radius: 22px;
  margin: 70px 0 90px 0;
  @media only screen and (max-width: 1024px) {
    margin-top: 10px;
  }
  @media only screen and (max-width: 500px) {
    text-align: center;
    margin: 25px 0 45px 0;
  }
  align-items: center;
  .start-up-detail-light-paper-content {
    width: 100%;
    margin: 18px 0;
    .download-title {
      font-size: 16px;
      margin: 0;
      line-height: 1.3;
      font-weight: 400;
      color: #777;
      @media only screen and (max-width: 500px) {
        font-size: 16px;
      }
    }
    .heading {
      color: #292950;
      line-height: 1.2;
      font-size: 52px;
      white-space: nowrap;
      @media only screen and (max-width: 1024px) {
        font-size: 40px;
      }
      @media only screen and (max-width: 768px) {
        font-size: 38px;
      }
      @media only screen and (max-width: 500px) {
        font-size: 32px;
      }
    }
    .light-paper-sub_text {
      font-size: 25px;
      line-height: 1.2;
      font-weight: 400;
      margin: 0;
      color: #777;
      @media only screen and (max-width: 1024px) {
        margin: 12px 0 12px 0;
        font-size: 20px;
      }
      @media only screen and (max-width: 768px) {
        margin: 12px 0 12px 0;
        font-size: 18px;
      }
      @media only screen and (max-width: 500px) {
        margin: 0 0 12px 0;
        font-size: 14px;
      }
    }
  }
  .start-up-detail-light-paper-image {
    .download-btn {
      width: 65%;
      cursor: pointer;
      margin: 0 auto;
      @media only screen and (max-width: 1024px) {
        width: 100%;
      }
      @media only screen and (max-width: 768px) {
      }
      @media only screen and (max-width: 500px) {
        width: 60%;
      }
    }
  }
  .start-up-detail-light-paper-download {
    text-align: center;
    .light-paper-btn {
      padding: 12px 24px;
      background-color: #ffffff;
      color: #000000;
      border: 0.8px solid black;
      text-align: center;
      font-size: 18px;
      line-height: 1.1;
      font-weight: 500;
      &:hover {
        background-color: #292950;
        border: 0.8px solid #292950;
        transition: all 0.2s;
        color: #ffffff;
      }
      @media only screen and (max-width: 1024px) {
        padding: 8px 12px;
        font-size: 16px;
      }
      @media only screen and (max-width: 768px) {
        padding: 8px 12px;
        font-size: 14px;
      }
      @media only screen and (max-width: 500px) {
        text-align: start;
        margin-bottom: 30px;
      }
    }
  }
}
.startup-div-row__colm1 {
  width: -webkit-fill-available;
}

.amount-raised-card-div-invest-button {
  width: vw(255px);
  height: vw(68px);
  border-radius: 40px;
  background: transparent;
  border: 1.5px solid #ffffff;
  margin: 0 auto;

  font-size: vw(28px);
  line-height: vw(68px);
  @include fontRobotoBold;
  color: #ffffff;
  text-transform: uppercase;

  &:hover {
    background: #3899a8;
  }
}

.amount-raised-card-div-invest-button--disabled {
  border: 1.5px solid rgba(255, 255, 255, 0.7);
  color: rgba(255, 255, 255, 0.7);

  user-select: none;
  cursor: default;

  &:hover {
    background: transparent;
  }
}

.amount-card-last-section {
  // width: vw(417px);
  width: 100%;
  height: vw(61px);
  display: flex;
  align-items: center;
  justify-content: center;

  color: #ffffff;
  background: #7b7b84;
  border-radius: 0 0 vw(20px) vw(20px);

  span {
    font-size: vw(20px);
    line-height: vw(61px);

    .vl-common-save-next-btn__arrow-img {
      width: vw(33px);
    }
  }

  @media only screen and (max-width: 525px) {
    height: 30px;
  }
}

.amount-card-last-section--cursor {
  cursor: default;
}

/*======================================
       
              startup details
  
  =====================================*/
.startup-profile-img-div {
  // width: vw(190px);
  width: 100%;
  background: #f0f0f3;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
    border-radius: inherit;

    @media only screen and (max-width: 1540px) {
      height: 125px;
    }

    @media only screen and (max-width: 525px) {
      height: 105px;
    }
  }

  @media only screen and (max-width: 1199px) {
    display: block;
    margin-bottom: 20px;
  }

  @media only screen and (max-width: 991px) {
    display: flex;
    justify-content: flex-start;
  }

  @media only screen and (max-width: 767px) {
    height: auto;
  }

  @media only screen and (max-width: 575px) {
    width: 40%;
    // margin: auto;
    margin-bottom: 20px;
  }
}

.startup-font-14-roboto-medium {
  font-size: vw(16px);
  line-height: vw(18.75px);
  @include fontRobotoMedium;
  margin-bottom: 0;
  color: rgba(39, 45, 46, 0.38);
}

.startup-font-18-roboto-bold {
  font-size: vw(18px);
  line-height: vw(21px);
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.46);
  @include fontRobotoBold;
}

.image-style-align-left {
  display: flex;
  justify-content: flex-start;
}

.image-style-align-center {
  display: flex;
  justify-content: center;
}

.image-style-align-right {
  display: flex;
  justify-content: flex-end;
}

.text-huge {
  font-size: 1.8em;
}

.text-big {
  font-size: 1.4em;
}

.text-small {
  font-size: 0.85em;
}

.text-tiny {
  font-size: 0.7em;
}

.render-top-left-div-col-2 {
  padding-left: 34px;
  padding-top: 10px;
  width: 265px;
  word-break: break-all;
  flex-shrink: 0;

  @media only screen and (max-width: 1540px) {
    padding-left: 20px;
    width: 50%;
  }

  @media only screen and (max-width: 575px) {
    padding-left: 0px;
  }
}

.render-top-left-div-col-3 {
  padding-left: 34px;
  padding-top: 10px;

  @media only screen and (max-width: 1540px) {
    padding-left: 20px;
  }
}

.startup-team-img-div {
  width: 30px;
  height: 30px;
  margin-left: -14px;
  border-radius: 63px;
  background-color: #c4c4c4;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;

  @media only screen and (max-width: 575px) {
    width: 20px;
    height: 20px;
  }

  span {
    font-size: 13px;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: inherit;
  }
}

.startup-details {
  @media only screen and (max-width: 1300px) {
    font-size: 13px !important;
  }
}

.vl-disc-card-row__imgAndBtnDiv-img-row {
  .startup-team-img-div:nth-child(1) {
    background-color: #c4c4c4;
    margin-left: 0;
  }

  .startup-team-img-div:nth-child(2) {
    background-color: #cccccc;
  }

  .startup-team-img-div:nth-child(3) {
    background-color: #d6d6d7;
  }
}

.startup-font-18-roboto-medium {
  // font-size: vw(18px);
  font-size: 18px;
  line-height: vw(21.09px);
  color: rgba(0, 0, 0, 0.89);
  opacity: 0.3;
  @include fontRobotoMedium;
  margin-bottom: 0;
  padding-left: vw(15px);
  line-height: 1.1;

  @media only screen and (max-width: 767px) {
    font-size: 16px;
    margin-left: 10px;
    line-height: 1.3;
  }

  @media only screen and (max-width: 525px) {
    font-size: 14px;
  }
}

.startup-tag-name {
  width: vw(141px);
  min-height: vw(32px);
  background: #6969b7;
  border-radius: vw(39px);
  padding: vw(8px) vw(10px) vw(10px) vw(19px);
  color: white;
  font-size: vw(14px);
  line-height: vw(16px);
  color: #f0f0f3;
  @include fontRobotoBold;
  margin-bottom: 0;
}

.startup-tag-name--2 {
  width: vw(119px);
  background: #6dbbc7;
  margin-left: vw(6px);
}

.render-top-left-div {
  padding-bottom: vw(43px);
  // padding-right: vw(150px);
  border-bottom: 1px solid rgba(0, 0, 0, 0.22);

  .render-inner {
    display: flex;

    .render-top-comman {
      margin-bottom: 17px;

      // margin-left: 10px;
      // padding-left: 0px;
      // width: 40%;
      h4 {
        font-size: 15px;
        margin-bottom: 20px;
        font-family: "Roboto-regular";
        line-height: 1.1;

        @media only screen and (max-width: 767px) {
          font-size: 15px;
          margin-bottom: 10px;
        }

        @media only screen and (max-width: 525px) {
          // font-size: 13px;
          margin-bottom: 6px;
        }
      }

      a,
      h5 {
        font-size: 17px;
        margin: 0px;
        padding: 0px;
        font-family: "Roboto-bold";
        line-height: 1.1;

        @media only screen and (max-width: 767px) {
          font-size: 16px;
        }

        @media only screen and (max-width: 525px) {
          // font-size: 12px;
          line-height: 2;
          // margin-bottom: 6px;
        }
      }

      p {
        font-size: 17px;
        margin: 0px;
        padding-top: 5px;
        font-family: "Roboto-bold";
        line-height: 1.1;

        @media only screen and (max-width: 767px) {
          font-size: 16px;
        }

        @media only screen and (max-width: 525px) {
          // font-size: 12px;
          line-height: 2;
          // margin-bottom: 6px;
        }
      }

      .explore-card-btn-div {
        padding: 0px;
        display: flex;
        flex-wrap: wrap;

        // margin-top: 25px;
        .tag-btn {
          width: 130px;
          font-family: "Montserrat-SemiBold", sans-serif, "Helvetica";
          font-size: 14px;
          padding: 10px 10px;
          margin-top: 15px;
          margin-bottom: 0px;
          cursor: pointer;
          box-shadow: 0px 2px 48px 0px #00000013;

          @media only screen and (max-width: 1140px) {
            font-size: 13px;
            width: 100px;
          }

          @media only screen and (max-width: 767px) {
            margin-top: 10px;
            font-size: 12px;
            padding: 13px 10px;
            margin-left: 10px;
          }
        }
      }

      @media only screen and (max-width: 767px) {
        width: 50%;
      }
    }
  }
}

.startup-details-blue-btn {
  width: vw(255px);
  height: vw(64px);
  display: flex;
  justify-content: center;
  align-items: center;
  background: #292950;
  border-radius: vw(4px);
  font-size: vw(18px);
  line-height: vw(21px);
  color: #f0f0f3;
  margin-right: vw(6px);
}

.startup-details-blue-btn--2 {
  background: #6dbbc7;
  margin-right: 0;
}

.render-top-right-div {
  padding-right: 44px;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media only screen and (max-width: 1440px) {
    padding-left: 0px;
    padding-right: 0px;
  }

  @media only screen and (max-width: 767px) {
    width: 290px;
  }

  @media only screen and (max-width: 525px) {
    margin: auto;
  }
}

.render-top-right-div-col--1 {
  display: flex;
}

.render-bottom-left-div {
  padding-top: vw(25px);

  .click-inside {
    font-size: 14px;
    color: #949494;
    text-align: center;
    font-weight: 400;
    font-family: "Roboto-regular";
  }

  .startup-rightside {
    h4 {
      font-size: 18px;
      font-family: "Roboto-bold";
      line-height: 1.1;

      @media only screen and (max-width: 525px) {
        font-size: 15px;
      }
    }

    .startup-fonttext {
      // padding-top: 60px;
    }

    .render-bottom-left-para-div,
    h1,
    h2 {
      font-size: 17px;
      font-family: "Roboto-Medium", sans-serif, "Helvetica";
      line-height: 1.1;

      @media only screen and (max-width: 525px) {
        font-size: 13px;
      }
    }

    .render-bottom-left-para-div {
      font-family: "Roboto-regular";
      font-size: 14px;
      line-height: 20px;
      width: 100%;

      @media only screen and (max-width: 525px) {
        font-size: 12px;
      }

      p {
        font-family: "Roboto-Medium", sans-serif, "Helvetica";
        font-size: 15px;
        line-height: 20px;

        span {
          strong {
            color: rgba(39, 45, 46, 0.38);
            font-family: "Roboto-Medium", sans-serif, "Helvetica";
          }
        }

        @media only screen and (max-width: 525px) {
          font-size: 12px;
        }
      }

      ul {
        li {
          font-family: "Roboto-regular";
          font-size: 14px;
          line-height: 17px;

          @media only screen and (max-width: 525px) {
            font-size: 12px;
          }
        }

        @media only screen and (max-width: 767px) {
          padding-left: 15px;
        }
      }

      ol {
        li {
          font-family: "Roboto-regular";
          font-size: 14px;
          line-height: 17px;

          @media only screen and (max-width: 525px) {
            font-size: 12px;
          }
        }

        @media only screen and (max-width: 767px) {
          padding-left: 15px;
        }
      }
    }

    @media only screen and (max-width: 767px) {
      margin-top: 20px;
    }
  }

  .investors-main {
    padding-top: 50px;

    .bottom-line {
      h5 {
        font-size: 17px;
        font-family: "Roboto-bold";
        line-height: 1.1;
        margin-bottom: 20px;
      }
    }

    @media only screen and (max-width: 1024px) {
      padding-bottom: 30px;
    }
  }
}

.vl-render-bottom-left-div-video-url {
  color: rgba(0, 0, 0, 0.46);
  text-decoration: underline;
}

.vl-render-bottom-left-div-video-url-block,
.render-bottom-left-img-div {
  // width: vw(535px);
  width: 100%;
  text-align: center;
}

.vl-render-bottom-left-div-video-url-block {
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    display: inherit;

    span {
      line-height: 1.5;
      font-size: 17px;
      font-family: "Roboto-bold";

      @media only screen and (max-width: 767px) {
        font-size: 18px;
      }

      @media only screen and (max-width: 525px) {
        font-size: 14px;
        margin-top: 10px;
      }
    }
  }
}

.render-bottom-left-img-div {
  height: fit-content;
  max-height: vw(410px);
  margin-right: vw(34px);
  color: rgba(39, 45, 46, 0.38);
  background: #ffffff;
  border-radius: vw(20px);
  margin-bottom: vw(11px);
  overflow-x: hidden;
  overflow-y: auto;

  @media only screen and (max-width: 767px) {
    max-height: 40vh;
  }
}

.render-bottom-left-para-div {
  color: rgba(39, 45, 46, 0.38);
  font-size: vw(16px);
  line-height: vw(18.75px);
  @include fontRobotoMedium;
  margin-bottom: 0;
  width: vw(438px);
  word-break: break-word;
  padding-top: vw(8px);

  img {
    width: inherit;
    height: inherit;
  }

  li {
    &:first-child {
      margin-top: 0px;
    }

    margin-top: 5px;
  }
}

.startup-font-18-roboto-bold--about {
  padding-top: vw(15px);
}

.startup-font-18-roboto-bold--deal {
  padding-top: 40px;
}

.startup-font-18-roboto-bold--investor,
.startup-font-18-roboto-bold--about,
.startup-font-18-roboto-bold--deal,
.startup-font-18-roboto-bold--mentors {
  color: rgba(39, 45, 46, 0.84);
}

.startup-font-18-roboto-bold--mentors {
  padding-top: vw(45px);
}

.startup-font-18-roboto-bold--investor {
  padding-top: vw(84px);
}

.startup-investors-img-div {
  // width: vw(57px);
  // height: vw(57px);
  width: 47px;
  height: 47px;
  border-radius: 100%;
  margin-left: vw(-30px);
  text-align: center;

  img {
    width: inherit;
    height: inherit;
    border-radius: inherit;
  }

  @media only screen and (max-width: 767px) {
    width: 30px;
    height: 30px;
  }
}

.startup-investors-img-div--text {
  background-color: #c4c4c4;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.startup-investors-main-div {
  .startup-investors-img-div:nth-child(1),
  .startup-investors-img-div--text:nth-child(1) {
    background-color: #c4c4c4;
  }

  .startup-investors-img-div:nth-child(2),
  .startup-investors-img-div--text:nth-child(2) {
    background-color: #cccccc;
  }

  .startup-investors-img-div:nth-child(3),
  .startup-investors-img-div--text:nth-child(3) {
    background-color: #d6d6d7;
  }
}

.start-up-details-tabs-div {
  padding-top: vw(67px);

  .vl-tabs-explore {
    .react-tabs {
      width: 100%;

      ul {
        li {
          font-size: 18px;
          font-family: "Roboto-bold";
          line-height: 1.1;

          &::after {
            content: "";

            @media only screen and (max-width: 500px) {
              height: 1.09844vw;
            }
          }

          @media only screen and (max-width: 767px) {
            font-size: 18px;
          }
        }
      }

      .menus-inners {
        h2 {
          font-size: 16px;
          font-family: "Roboto-bold";
          margin-bottom: 10px;
          line-height: 1.1;

          @media only screen and (max-width: 525px) {
            font-size: 14px;
          }
        }

        .startup-text-comman {
          // font-size: 14px;
          font-family: "Roboto-Medium";
          line-height: 20px;

          @media only screen and (max-width: 900px) {
            width: 100%;
          }

          @media only screen and (max-width: 525px) {
            font-size: 12px;
          }

          span,
          h1,
          p,
          h2 {
            // font-size: 24px;
            font-family: "Roboto-Medium", sans-serif, "Helvetica";
            line-height: 28px;

            @media only screen and (max-width: 1140px) {
              font-size: 17px;
            }

            @media only screen and (max-width: 525px) {
              font-size: 12px;
            }
          }

          h3 {
            @media only screen and (max-width: 525px) {
              font-size: 17px;
            }
          }

          // h1 {
          //   font-size: 14px;
          //   font-family: "Roboto-bold";
          //   line-height: 20px;
          // }
          // p {
          //   font-size: 14px;
          //   font-family: "Roboto-regular";
          //   line-height: 20px;
          // }
          // h2 {
          //   font-size: 12px;
          //   font-family: "Roboto-bold";
          //   line-height: 20px;
          // }
          ul {
            li {
              // font-size: 14px;
              font-family: "Roboto-bold";
              line-height: 20px;

              @media only screen and (max-width: 525px) {
                font-size: 12px;
              }
            }
          }
        }

        .start-up-detail-sbout-teams-div {
          h2 {
            font-size: 16px;
            font-family: "Roboto-bold";
            margin-right: 10px !important;

            @media only screen and (max-width: 525px) {
              width: 50%;
              margin-top: 30px;
            }
          }

          p {
            font-size: 14px;
            font-family: "Roboto-regular";
          }

          table {
            width: 50%;

            tbody {
              tr {
                td {
                  .tablefirst-line {
                    font-size: 14px;
                    font-family: "Roboto-bold";
                    line-height: 1.5;

                    @media only screen and (max-width: 767px) {
                      font-size: 12px;
                    }
                  }

                  span {
                    font-size: 14px;
                    font-family: "Roboto-regular";
                    line-height: 1.5;
                    color: rgba(39, 45, 46, 0.72);

                    @media only screen and (max-width: 767px) {
                      font-size: 12px;
                    }
                  }
                }
              }
            }

            @media only screen and (max-width: 1100px) {
              width: 100%;
            }
          }

          .start-up-financials-block {
            width: 50%;
            margin: 20px 0px;

            @media only screen and (max-width: 525px) {
              width: 100%;
            }
          }
        }

        .financials-sections {
          display: none;
        }

        figure {
          // justify-content: start;
          // margin-top: 10px;
          // width: 33.33%;
          // padding: 15px;
          // float: left;
          // @media only screen and (max-width: 767px) {
          //   width: 100%;
          // }
          img {
            width: 100%;
            // margin-right: 15px;
          }
        }
      }
    }

    .react-tabs__tab {
      border: none;
      padding: 0 vw(15px);
      padding-bottom: vw(21px);
      margin-right: vw(83px);
      //border-bottom: 2px solid rgba(194, 203, 197, 0.4);
    }

    .react-tabs__tab--disabled {
      color: rgba(0, 0, 0, 0.08);
    }

    .react-tabs__tab-list {
      font-size: vw(18px);
      line-height: vw(21.09px);
      border-radius: none !important;
      color: rgba(0, 0, 0, 0.08);
      background: transparent;
      white-space: nowrap;
      @include fontRobotoBold;
      margin-bottom: 0;
      border-bottom: none;
      position: relative;

      &::after {
        position: absolute;
        content: "";
        width: 94%;
        height: vw(15px);
        bottom: vw(-9px);
        left: 0;
        background-image: url("../../../imgs/desktop/backgrounds/explore-arrow-bg.png");
        background-size: 100%;
        background-color: transparent;
      }

      span {
        font-size: vw(24px);
        line-height: vw(28px);
        @include fontRobotoBlack;
      }
    }

    .react-tabs__tab--selected,
    .react-tabs__tab:focus {
      cursor: default;
      background: transparent;
      //border-bottom: vw(8px) solid #292950;
      color: #292950;
      position: relative;

      &::after {
        position: absolute;
        content: "";
        bottom: -7%;
        left: 0;
        width: 100%;
        height: vw(7px);
        background: #292950;
        border-radius: vw(30px);
        z-index: 99;
      }

      span {
        color: rgba(41, 41, 80, 0.71);
      }
    }

    .react-tabs__tab:focus {
      box-shadow: 0 0 0;
      border-color: transparent;
    }

    .react-tabs__tab-panel {
      padding-top: vw(58px);
      width: 100%;
      // padding-bottom: vw(685px);
      padding-bottom: 60px;

      @media only screen and (max-width: 1024px) {
        margin-top: 30px;
      }

      @media only screen and (max-width: 575px) {
        padding-bottom: 0px;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    padding-top: 30px;
  }
}

.startup-font-18-roboto-bold {
  font-size: vw(18px);
  line-height: vw(21.09px);
  @include fontRobotoBold;
  color: rgba(39, 45, 46, 0.84);
  margin-bottom: vw(19px);
}

.startup-font-18-roboto-bold-website {
  width: auto;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  padding-top: 0;
  margin-top: vw(15px);
}

.startup-font-14-roboto-medium-width {
  font-size: vw(16px);
  line-height: vw(18.75px);
  color: rgba(39, 45, 46, 0.38);
  width: vw(1189px);
  @include fontRobotoMedium;
  margin-bottom: 0;

  @media only screen and (max-width: 767px) {
    width: 90%;
  }

  img {
    width: inherit;
    height: inherit;
  }

  li {
    &:first-child {
      margin-top: 0px;
    }

    margin-top: 5px;
  }
}

/*=====================================================================
                            Updates
======================================================================*/
.start-up-details-tab-panel-updates-div {
  padding-right: vw(268px);
  word-break: break-all;

  @media only screen and (max-width: 1024px) {
    padding-right: 0px;
  }
}

.startup-details-tab-panel-updates-text-1 {
  color: rgba(0, 0, 0, 0.86);
  width: 25%;
  padding-right: vw(21px);

  @media only screen and (max-width: 1024px) {
    font-size: 15px;
    width: 100%;
    line-height: 1.3;
    margin-top: 10px;
  }
}

.startup-details-tab-panel-updates-text-2 {
  line-height: vw(26.86px) !important;
  color: #000000;
  padding-right: vw(21px);

  @media only screen and (max-width: 1024px) {
    font-size: 12px;
    padding: 0px;
    line-height: 1.3 !important;
  }
}

.startup-details-tab-panel-updates-text-3 {
  color: rgba(0, 0, 0, 0.86);
  width: 30%;
}

.startup-details-tab-panel-updates-bottom-div {
  padding-top: vw(25px);
  color: rgba(0, 0, 0, 0.46);

  span {
    @media only screen and (max-width: 1024px) {
      font-size: 11px !important;
      // padding: 0px !important;
      line-height: 1.3 !important;
    }

    @media only screen and (max-width: 767px) {
      padding-top: 10px;
    }
  }

  @media only screen and (max-width: 1024px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.startup-details-tab-panel-updates-text-4 {
  padding-right: vw(3px);
}

.startup-details-tab-panel-updates-text-5 {
  padding-left: vw(7px);
  padding-right: vw(7px);
  line-height: vw(10px);
}

.vl-update-like-btn {
  width: vw(60px);
  height: vw(21px);
  border: vw(1px) solid #292950;
  border-radius: vw(5px);
  font-size: vw(14px);
  line-height: vw(21px);
  color: #292950;
  @include fontRobotoBold;
  margin-left: vw(21px);

  @media only screen and (max-width: 1024px) {
    font-size: 11px;
    line-height: 1.3;
    height: auto;
    width: auto;
    padding: 4px 20px;
    margin-left: 20px;
  }
}

// .vl-update-like-btn--unlike {

// }

/*===============================================================================
                           Investor
=================================================================================*/
.startup-details-tab-panel-investor-title-text {
  color: rgba(39, 45, 46, 0.84);
  padding-bottom: vw(38px);
}

.explore-startup-investor-profile-img {
  width: vw(57px);
}

.explore-startup-investor-card-left-section {
  width: 70%;
}

.explore-startup-investor-card-right-section {
  width: 30%;
  text-align: center;
}

.explore-startup-investor-card {
  width: vw(358px);
  height: auto;
  padding: vw(26px) vw(16px) vw(29px) vw(19px);
  background: #ffffff;
  border-radius: vw(10px);
  word-break: break-all;
  margin-right: vw(20px);
  margin-bottom: vw(20px);
  display: flex;
  align-items: flex-start;
}

.explore-startup-investor-card-text-1 {
  color: #6969b7;
  padding-bottom: vw(8px);
}

.explore-startup-investor-card-text-2 {
  color: rgba(0, 0, 0, 0.39);
  padding-bottom: vw(8px);
}

.explore-startup-investor-card-text-3 {
  color: #272d2e;
}

.explore-startup-investor-card-text-4 {
  line-height: vw(10px);
  color: #c4c4c4;
  padding: 0 vw(5px);
}

.explore-startup-investor-card-text-5 {
  color: rgba(39, 45, 46, 0.87);
}

.explore-startup-investor-card-text-6 {
  color: #272d2e;
  padding-bottom: vw(18px);
}

.explore-startup-investor-card-text-7 {
  color: rgba(23, 23, 111, 0.73);
  padding-top: vw(7px);
}

.kyc-img {
  width: vw(22px);
  height: auto;
}

.explore-startup-investor-card-text-8 {
  color: #8ba995;
  padding-top: vw(9px);
}

.startup-details-investor-left-section {
  width: 79%;
}

.startup-details-investor-right-section {
  width: 29%;
}

.explore-startup-investor-card-text-div {
  width: 60%;
}

.explore-startup-investor-card-text-div-bottom {
  padding-right: vw(20px);
}

.explore-startup-right-card {
  width: vw(346px);
  height: auto;
  background: #ffffff;
  border-radius: vw(10px);
  padding: vw(16px) vw(11px) vw(13px) vw(30px);
  display: flex;
  align-items: center;
  word-break: break-all;
  position: relative;
  margin-bottom: vw(28px);

  &::before {
    position: absolute;
    width: vw(8px);
    height: 75%;
    background: #292950;
    content: "";
    left: vw(-7px);
    top: vw(10px);
  }
}

.explore-startup-right-card-left-side {
  width: 50%;
}

.explore-startup-right-card-text-1 {
  color: rgba(39, 45, 46, 0.35);
}

.explore-startup-right-card-text-2 {
  color: #292950;
  padding-top: vw(10px);
  text-align: left;
}

.startup-details-investor-right-section {
  padding-left: vw(30px);
  border-left: 1px solid rgba(0, 0, 0, 0.27);
}

/*=============================================

===============================================*/
.startup-details-about-mission-img {
  // width: vw(37px);
  width: 37px;
  height: auto;

  @media only screen and (max-width: 1024px) {
    width: 27px;
  }

  @media only screen and (max-width: 525px) {
    width: 20px;
  }
}

.startup-details-about-problem-img {
  // width: vw(54px);
  width: 37px;
  height: auto;

  @media only screen and (max-width: 1024px) {
    width: 27px;
  }

  @media only screen and (max-width: 525px) {
    width: 20px;
  }
}

.startup-details-img-div {
  width: 5%;
  text-align: right;
  padding-right: vw(25px);

  @media only screen and (max-width: 1024px) {
    margin-right: 10px;
  }

  @media only screen and (max-width: 525px) {
    margin-right: 15px;
  }
}

.start-up-detail-sbout-teams-div {
  padding-left: vw(80px);
  padding-top: vw(44px);

  @media only screen and (max-width: 525px) {
    padding: 0px;
  }
}

.font-14-roboto-medium--startup-details-team-name {
  padding-left: vw(36px);
  padding-right: vw(36px);
  color: rgba(39, 45, 46, 0.38);
}

.startup-details-about-team-memeber-img-block {
  width: vw(95px);
  height: vw(95px);
  border-radius: 100%;
  background-color: #c4c4c4;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;

  @media only screen and (max-width: 1024px) {
    width: vw(80px);
    height: vw(80px);
  }

  @media only screen and (max-width: 900px) {
    width: vw(130px);
    height: vw(130px);
  }

  @media only screen and (max-width: 550px) {
    width: vw(180px);
    height: vw(180px);
  }

  img {
    width: inherit;
    height: inherit;
    border-radius: inherit;
  }
}

.startup-about-teams-block-with {
  width: 100%;
}

.startup-about-teams-block-with-inner {
  width: 25%;
  padding-bottom: 0px;

  .startup-innermain {
    display: flex;
    align-items: center;
    // flex-wrap: wrap;
    margin: 15px;
    border: 1px solid #00000015;
    border-radius: 10px;
    padding: 10px 20px;
    transition: all ease-in-out 0.5s;
    cursor: pointer;

    &:hover {
      // box-shadow: 0px 0px 6px 0px #00000061;
      box-shadow: 0 3px 30px #00000020;
    }

    .text-startup {
      margin-left: 15px;

      h5 {
        font-size: 16px;
        font-weight: "Roboto-Bold";

        @media only screen and (max-width: 1400px) {
          font-size: 14px;
        }
      }

      p {
        font-size: 14px;
        font-family: "Roboto-regular";
        margin-bottom: 0px;

        @media only screen and (max-width: 1400px) {
          font-size: 12px;
        }
      }
    }

    @media only screen and (max-width: 550px) {
      margin: 15px 0px;
    }
  }

  @media only screen and (max-width: 1340px) {
    width: 33.33%;
  }

  @media only screen and (max-width: 991px) {
    width: 50%;
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
  }
}

.start-up-financials-block {
  width: vw(695px);
  height: vw(476px);
  margin-top: vw(23px);
  background: #ffffff;
  border-radius: vw(20px);
  margin-right: vw(23px);

  img {
    width: 100%;
    height: 100%;
    border-radius: inherit;
  }
}

.vl-table-teams {
  tbody {
    color: #292950;
    word-break: break-all;

    tr {
      vertical-align: top;
    }

    td {
      width: 50%;
      padding: 17px;
      @media only screen and (max-width: 524px) {
        padding: 12px;
      }

      &:nth-child(1) {
        width: 221px;
        padding-left: 15px;
      }
    }
  }
}

// vl-form-input--displayCurrencyAboutTab
.vl-form-input.vl-form-input--displayCurrencyAboutTab {
  max-width: vw(250px);

  .input-border-div {
    height: vw(19px);
    border: none;

    input {
      color: rgba(39, 45, 46, 0.38) !important;
      padding: 0;
      font-size: vw(16px);
      line-height: vw(19px);
      margin-bottom: 0;
      @include fontRobotoLight;
    }
  }

  .error-message {
    display: none;
  }
}

// website page
.vl-main-page-div.vl-main-page-div--website-page {
  margin-left: 0;
  border-bottom-left-radius: 0;
  // margin-top: vw(60px);
  margin-top: 0;
  height: 100%;
  overflow: hidden;
  color: #292950;
  background-color: #fff;
  // padding-right: vw(50px);
  // padding-left: vw(50px);
}

// tooltip
.vl-start-up-details-question-mark {
  position: absolute;
  right: -12px;
  bottom: 16px;
  background: #f0f0f3;
  border: vw(2px) solid rgba(39, 45, 46, 0.38);
  border-radius: 100%;
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  padding-top: vw(1px);
  color: rgba(39, 45, 46, 0.38);
  font-size: 10px;
  line-height: 11px;
  margin-bottom: 0;
  @include fontRobotoMedium;
  @media only screen and (max-width: 1480px) {
    right: -14px;
    bottom: 10px;
  }
  @media only screen and (max-width: 1024px) {
    right: -12px;
    bottom: 6px;
  }
  @media only screen and (max-width: 768px) {
    right: -14px;
    bottom: 16px;
  }
}

.vl-start-up-details-question-mark-main-div {
  position: relative;
  margin-left: 5px;
}

.vl-start-up-details-question-mark-main-div__tooltip {
  position: absolute;
  top: -35px;
  left: -111px;
  color: #ffffff;
  background: #c4c4c4;
  border-radius: 7px;
  white-space: nowrap;
  font-size: 14px !important;
  line-height: 16px !important;
  margin-bottom: 0;
  @include fontRobotoRegular;
  padding: 6px 3px 5px 3px;

  @media only screen and (max-width: 900px) {
    font-size: 12px !important;
  }

  @media only screen and (max-width: 525px) {
    line-height: 14px !important;
  }

  &::before {
    position: absolute;
    left: 111px;
    top: 23px;

    content: "";
    display: block;
    // background: #c4c4c4;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-top: 6px solid #c4c4c4;
    border-right: 6px solid transparent;
    // @media only screen and (max-width: 920px) {
    //   top: 22px;
    //   content: "";
    // }
    // @media only screen and (max-width: 900px) {
    //   top: 20px;
    //   content: "";
    // }
  }
}

// financials slieder modal image div
.vl-financials-slider {
  img {
    cursor: pointer;
  }
}

.vl-financials-modal-div {
  width: 100%;
  margin: 0 auto;
  text-align: center;

  img {
    width: 100%;
    height: auto;
    border-radius: vw(3px);
  }
}

.react-responsive-modal-root {
  .react-responsive-modal-container {
    .react-responsive-modal-modal {
      width: auto;
      max-width: fit-content;
      padding: 20px 20px 20px 20px !important;
      // max-height: 92vh;
      .react-responsive-modal-closeButton {
        position: absolute;
        top: 0;
        right: 0;
        border: none;
        padding: 0;
        cursor: pointer;
        background-color: transparent;
        display: flex;
      }
      // overflow-y: scroll !important;
      .full-screen-btn {
        .full-screen-main {
          position: absolute;
          top: 28px;
          left: 25px;
          @media only screen and (max-width: 1024px) and (min-width: 768px) {
            top: 20px;
          }
          @media only screen and (max-width: 767px) and (min-width: 424px) {
            font-size: 15px;
          }
          @media only screen and (max-width: 425px) and (min-width: 300px) {
            top: 11px;
          }
          .expand {
            font-size: 21px;
            color: #848484;
            @media only screen and (max-width: 768px) {
              font-size: 18px;
            }
          }
        }
      }

      .model-pagination {
        width: 100%;
        justify-content: flex-end;
        // @media only screen and (min-width: 768px) and (max-width: 300px) {
        //   margin: 25px 0;
        // }
        .model-prev-next-btn {
          display: flex;
          align-items: center;
          width: 100%;
          line-height: 1.3;
          justify-content: center;
          margin: 12px 0;
          @media only screen and (max-width: 768px) and (min-width: 424px) {
            margin: 0 0 15px 0;
          }
          @media only screen and (max-width: 425px) and (min-width: 300px) {
            margin: -35px 0 8px 0;
          }
          .vl-pdf-screen-prev-btn {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .vl-pdf-screen-text {
            display: flex;
            font-size: 17px;
            text-transform: capitalize;
            color: #ffffff;
            .page-number {
              margin: 0 4px 0 0;
              padding: 0;
              margin-right: 8px;
              display: flex;
              font-size: 18px;
              width: 40px;
              height: 30px;
              background-color: #1c1c39;
              justify-content: center;
              align-items: center;
              @media only screen and (max-width: 1024px) and (min-width: 767px) {
                font-size: 15px;
                margin: -8px 6px 0 0;
              }
              @media only screen and (max-width: 768px) and (min-width: 424px) {
                font-size: 15px;
                margin: 0 6px 0 0;
              }
              @media only screen and (max-width: 425px) and (min-width: 300px) {
                font-size: 12px;
                margin: -10px 6px 0 0;
              }
            }
            p {
              margin: 0 4px 0 0;
              padding: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 18px;
              @media only screen and (max-width: 1024px) and (min-width: 768px) {
                font-size: 15px;
              }
              @media only screen and (max-width: 767px) and (min-width: 424px) {
                font-size: 15px;
              }
              @media only screen and (max-width: 425px) and (min-width: 300px) {
                font-size: 12px;
              }
            }
            @media only screen and (max-width: 1024px) and (min-width: 768px) {
              font-size: 14px;
              height: 14px;
            }
            @media only screen and (max-width: 769px) and (min-width: 426px) {
              font-size: 12px;
              height: auto;
            }
            @media only screen and (max-width: 425px) and (min-width: 280px) {
              font-size: 12px;
              height: 8px;
            }
          }
          .vl-pdf-screen-next-btn {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .closeIconInModal {
            &::after,
            &::before {
              content: "";
              height: 20px;
              top: 28px;
              @media only screen and (max-width: 1024px) and (min-width: 767px) {
                top: 20px;
                right: 32px;
              }
              @media only screen and (max-width: 768px) and (min-width: 424px) {
                top: 14px;
                right: 20px;
              }
              @media only screen and (max-width: 425px) and (min-width: 280px) {
                top: 10px;
                right: 20px;
              }
            }

            font-size: 15px;
          }
        }
      }
      .download-light-paper {
        margin: 0;
        @media only screen and (max-width: 575px) {
          font-size: 15px;
        }
      }

      .vl-pitch-deck-file-modal-content-div {
        width: 100%;
        height: 100%;
        margin-bottom: 0px;
        @media only screen and (max-width: 900px) {
          width: 100%;
        }
        .react-pdf__Document {
          .react-pdf__Page {
            max-height: 82vh;
            overflow-y: scroll;
            overflow-x: hidden;
          }
        }
      }
      // .closeIconInModal {
      //   position: fixed;
      //   z-index: 1;
      //   &::after,
      //   &::before {
      //     top: -10px;
      //     right: -205px;
      //     @media only screen and (max-width: 1024px) and (min-width: 767px) {
      //       top: -6px;
      //       // right: -400px;
      //     }
      //     @media only screen and (max-width: 768px) and (min-width: 529px) {
      //       top: 0;
      //     }
      //     @media only screen and (max-width: 530px) and (min-width: 300px) {
      //       top: 0;
      //     }
      //   }
      // }
      .vl-pdf-screen-text {
        font-size: 18px;
        line-height: 1;
        @media only screen and (max-width: 1024px) and (min-width: 767px) {
          font-size: 14px;
          height: 14px;
        }

        @media only screen and (max-width: 600px) {
          font-size: 12px;
          height: 0;
        }
      }
      .model-back {
        font-size: 24px;
        color: #fff;
      }
      .model-next {
        font-size: 24px;
        color: #fff;
      }
      // .vl-pdf-screen-text {
      //   @media only screen and (max-width: 600px) {
      //     margin-top: 8px;
      //   }
      // }
      .img-Sucess {
        width: 100px;
      }

      .title-Sucess {
        font-size: 35px;
        margin-top: 25px;
      }

      .text-Sucess {
        font-size: 15px;
        line-height: 1.5;
      }

      .special_reserves_flow {
        h6 {
          font-size: 20px;
        }

        p {
          font-size: 15px;
          font-weight: 600;
        }

        ul {
          margin: 0;
          padding: 0 30px;
        }

        .qualifies {
          a {
            color: #007bff !important;
            text-decoration: underline !important;
          }
        }
      }

      @media only screen and (max-width: 900px) {
        width: 80% !important;
        max-width: 80% !important;
        border-radius: vw(30px) !important;
      }

      @media only screen and (max-width: 525px) {
        padding: 40px 10px 20px 10px !important;
        width: 90% !important;
        max-width: 90% !important;
        margin: auto;
        border-radius: vw(40px) !important;
      }
    }

    .reservemodal-popup {
      width: 60% !important;
      min-width: 35%;
      max-width: 35%;
      border-radius: 20px !important;
      padding: 50px 30px 30px 30px !important;
      // .react-responsive-modal-closeButton {
      //   position: absolute;
      //   top: 14px;
      //   right: 14px;
      //   border: none;
      //   padding: 0;
      //   cursor: pointer;
      //   background-color: initial;
      //   display: flex;
      //   @media only screen and (max-width: 525px) {
      //     top: 3px;
      //   }
      // }
      .reserveContainer {
        @media only screen and (max-width: 900px) {
          .vl-form-input {
            width: 95%;
            max-width: none;

            label {
              font-size: 15px;
            }

            .input-border-div {
              height: 50px;
              margin-bottom: 15px;

              input {
                font-size: 15px;

                &::placeholder {
                  font-size: 20px;
                }
              }
            }
          }

          .form-textlist {
            margin-bottom: 15px;
            max-width: 100%;

            span {
              font-size: 15px;
              line-height: 1;
            }
          }

          .raise-capital-startup-btn {
            padding: 10px 20px;

            span {
              font-size: 15px;
              line-height: 1.3;
            }
          }
        }

        @media only screen and (max-width: 767px) {
          .vl-form-input {
            width: 100%;
          }
        }

        .form-textlist {
          margin-bottom: 30px;
          max-width: 31.14583vw;

          span {
            font-size: 15px;
            line-height: 1.5;
          }

          @media only screen and (max-width: 900px) {
            max-width: 100%;
            margin-bottom: 20px;
          }
        }
      }

      @media only screen and (max-width: 900px) {
        width: 90% !important;
      }

      @media only screen and (max-width: 767px) {
        padding: 20px !important;
      }
      // @media only screen and (max-width: 525px) {
      //   padding: 30px !important;
      // }
    }
    .discussion-model{
      .react-responsive-modal-closeButton{
        top: 20px;
        right: 20px;
      }
    }
  }
}

.container {
  position: sticky;
  justify-content: space-between;
  flex-direction: column;
  display: flex;
}

.container .btn-holder {
  justify-content: flex-end;
  display: flex;
}

.scrollTop {
  position: fixed;
  height: 50px;
  width: 50px;
  bottom: 20px;
  align-items: center;
  right: 20px;
  justify-content: center;
  z-index: 1;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;
  background-color: rgba(50, 50, 119, 1);
  box-shadow: 0px 0px 16px 4px rgba(50, 50, 119, 0.4);
  border-radius: 30px;

  img {
    width: 17px;
    height: 17px;
  }
}

.scrollTop:hover {
  opacity: 1;
}

.special_reserve {
  .form-check {
    display: flex;
    align-items: center;
    gap: 4px;    
    label {
      font-size: 14px;
      height: 16px;
    }
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.5;
  }
}
