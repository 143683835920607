.page-break-after {
  page-break-after: always;
  .VedasLabs-image {
    position: relative;
  }
  .vedaslabs-main-content {
    margin: 0;
    position: absolute;
    bottom: 80px;
    color: white;
    width: 70%;
    line-height: 1.5;
    font-size: 32px;
    font-family: sans-serif;
    font-weight: 500;
    left: 50%;
    top: 72%;
    transform: translate(-50%, -50%);
    @media only screen and (max-width: 530px) {
      font-size: 22px;
      transform: translate(-50%, -200%);
    }
  }
  .VedasLabs-copyRight {
    margin: 0;
    position: absolute;
    bottom: 80px;
    color: white;
    width: 70%;
    line-height: 1.5;
    font-size: 11px;
    font-family: sans-serif;
    font-weight: 500;
    left: 50%;
    top: 75.9%;
    transform: translate(-50%, -50%);
    @media only screen and (max-width: 530px) {
      font-size: 12px;
      transform: translate(-50%, -260%);
    }
  }
  .vedaslabs-image-content {
    margin: 0;
    position: absolute;
    bottom: 80px;
    color: white;
    width: 70%;
    line-height: 1.5;
    font-size: 15px;
    font-weight: 500;
    bottom: 30px;
    left: 50%;
    transform: translate(-50%, -70%);
    @media only screen and (max-width: 530px) {
      width: 94%;
      font-size: 9px;
      transform: translate(-50%, -25%);
    }
  }
}

.lightpaper-wrapp {
  background-color: #fff;
  color: #000;
  font-family: "Roboto", sans-serif;

  .lightpaper_main_wrapp {
    max-width: 1200px;
    margin: 0 auto;
  }
}

.features-img {
  width: 50%;
  height: 100%;
}

.w-40 {
  width: 40%;
}

.tital-wrap {
  @media only screen and (max-width: 768px) {
    margin-bottom: 30px;
  }

  h2 {
    width: 350px;
    font-weight: 800;
    font-size: 42px;
    text-align: left;
    line-height: 55px;

    @media only screen and (max-width: 768px) {
      width: 100%;
      font-size: 30px;
      text-align: center;
    }
  }
}

.community-wrap {
  border: 2px solid #000;
}

.title-wrap {
  color: #596d75;
  font-weight: 900;
  font-size: 32px;
  margin: 0;
  line-height: 1.3;
  @media only screen and (max-width: 768px) {
    font-size: 25px;
  }
}

.opportunity-wrap {
  .opportunity-title {
    font-weight: 900;
    font-size: 50px;
    line-height: 1.3;
    @media only screen and (max-width: 768px) {
      font-size: 30px;
    }
  }
}

.desktop-title {
  font-size: 24px;
  font-weight: 900;
  margin: 40px 0px;
  line-height: 1.3;
}

.chart-wrap {
  justify-content: center;
  align-items: center;
}

.community-main-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.main-wrap-title {
  font-size: 42px;
  font-weight: 900;
  line-height: 1.3;

  @media only screen and (max-width: 768px) {
    font-size: 30px;
  }
}

.exchange-content {
  font-size: 26px;
  line-height: 1.3;
  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
}

.architecture-content-main {
  font-size: 26px;
  line-height: 1.3;
  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
}

.architecture-content {
  font-size: 24px;
  line-height: 1.3;
  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
}

.milestones-content {
  font-size: 24px;
  line-height: 1.3;
  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
}

.investment-terms {
  font-size: 26px;
  line-height: 1.3;
  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
}

.investment-funds {
  font-size: 24px;
  line-height: 1.3;
  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
}

.revenue-model-content {
  font-size: 24px;
  line-height: 1.3;
  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
}

.page-footer {
  color: #000;
  font-size: 18px;
  margin-top: 50px;
  line-height: 1.3;
}

.page-footer-pt {
  margin-top: 90px;
  line-height: 1.3;
}

.our-visio-wrap {
  background-image: url(../../../assets/imgs/desktop/light-paper/Picture6.png);
  background-position: top;
  background-repeat: no-repeat;
  color: #ffffff;
  padding: 20px;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
}

.our-value {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px 280px;
  @media only screen and (max-width: 768px) {
    margin: 0;
  }
}

.our-value h2 {
  font-weight: 900;
  font-size: 50px;
  line-height: 1.3;
  margin: 0;
  @media only screen and (max-width: 768px) {
    font-size: 30px;
  }
}

.our-partner {
  background-image: url("../../../assets/imgs/desktop/light-paper/1234.png.jpg");
  background-repeat: repeat;
  background-position: center;
  height: 100vh;
  width: 100%;
}

.our-partners {
  width: 100%;
  height: 100vh;
  position: relative;
}

.black-page {
  width: 100%;
  height: 100vh;
  position: relative;
}

.vedaslab-main {
  position: absolute;
  z-index: 1;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -70%);
  @media only screen and (max-width: 768px) {
    top: 63%;
    width: 100%;
  }
}

.our-partner h2 {
  font-size: 42px;
  width: 100%;
  text-align: center;
  font-weight: 900;
  color: white;
  position: absolute;
  z-index: 1;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -25%);
}

// .our-partner {
//   color: #fff;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   padding: 50px 150px;
// }

.vedaslab-Footer {
  position: relative;
  // background-color: #16191a;
  // height: 100vh;
}

.contact-details {
  color: #fff;
}

.contact-wrap {
  display: flex;
  justify-content: center;
  margin: 55px 0;
  @media only screen and (max-width: 768px) {
    margin: 45px 0;
  }
}

.contact-details p {
  font-size: 16px;
  margin-bottom: 10px;
}

.contact-details p span {
  background-color: #fff;
  color: #000;
  border-radius: 61%;
  padding: 2px 5px;
  font-size: 13px;
  margin-right: 5px;
}

.footer-2022 {
  color: #f1f1f1;
  font-size: 16px;
  margin-top: 80px;
  @media only screen and (max-width: 560px) {
    margin: 0;
  }
}

.partners-logo {
  position: absolute;
  top: 45%;
  left: 35%;
  transform: translate(-25%, -25%);
  @media only screen and (max-width: 560px) {
    transform: translate(-24%, -35%);
  }
}

.team-name {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 0px;
  line-height: 1.3;
  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
}

.team-mamber-text {
  font-size: 24px;
  font-weight: 100;
  line-height: 1.3;
  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
}

.w-85 {
  width: 85%;
}

.last-page-title {
  font-size: 26px;
  line-height: 1.3;
}

.last-page {
  font-size: 24px;
  line-height: 1.3;
  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
}

.weather-last-page {
  font-size: 24px;
  margin-top: 34px;
  line-height: 1.3;
  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
}

.content-main {
  .contents-wrap {
    p {
      font-size: 21px;
      line-height: 1.5;

      @media only screen and (max-width: 768px) {
        font-size: 18px;
      }
    }
  }
}

.opportunity-title {
  font-size: 35px;
  line-height: 1.3;
  @media only screen and (max-width: 768px) {
    font-size: 28px;
  }
}

.opportunity-content {
  font-size: 26px;
  line-height: 1.3;
  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
}

.content-main-wrap {
  .market-main {
    .market-content {
      font-size: 24px;
      line-height: 1.3;
      @media only screen and (max-width: 768px) {
        font-size: 18px;
      }
    }
  }
}

.use-case-main ul {
  margin-left: 50px;
}

.use-case-main ul li {
  font-size: 24px;
  line-height: 1.3;
  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
}

.participants-content {
  font-size: 24px;
  line-height: 1.3;
}

.vedaslabs-logo {
  width: 75%;
  height: auto;
}

.introducing-title {
  text-align: start;
  font-size: 48px;
  line-height: 1.3;
  font-weight: bolder;
  margin: 0;
  @media only screen and (max-width: 768px) {
    font-size: 30px;
  }
}

.venture-content {
  font-size: 24px;
  line-height: 1.3;
  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
}

.community-members-content {
  font-size: 24px;
  line-height: 1.3;
  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
}

.community-members {
  font-size: 21px;
  font-weight: 900;
  line-height: 1.3;
}

.our-platform-content {
  font-size: 26px;
  line-height: 1.3;
  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
}

.our-platform-issue {
  font-size: 26px;
  line-height: 1.3;
}

.platform-issue-content {
  font-size: 24px;
  line-height: 1.3;
  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
}

.platform-mentors-content {
  font-size: 24px;
  margin-top: 24px;
  line-height: 1.3;
  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
}

.our-value-content {
  font-size: 20px;
  margin: 0;
  line-height: 1.3;
  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
}

.workflows-content {
  font-size: 24px;
  line-height: 1.3;
}

.vedaslabs-content {
  font-size: 24px;
  line-height: 1.3;
  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
}

.downloadBtn {
  position: fixed;
  right: 30px;
  bottom: 20px;
  height: 80px;
  width: 80px;
  background: #292950;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  // box-shadow: 0px 0px 60px -1px rgb(50 50 119 / 85%);
  box-shadow: 0px 0px 60px -1px #323277d9;

  i {
    color: #ffffff !important;
    font-size: 30px;
  }
}
