.vl-disc-main-page {
  padding: vw(95px) vw(34px) vw(72px) vw(72px);
}

.vl-disc-card-overflow {
  padding-top: vw(31px);
  // padding-right: vw(163px);
  // margin-right: vw(26px);
  max-height: vw(655px);
  overflow: auto;
  @media only screen and (max-width: 1024px) {
    max-height: 280px;
  }
}

.vl-disc-card-row {
  padding-bottom: vw(56px);
  margin-bottom: vw(19px);
  border-bottom: vw(2px) solid rgba(0, 0, 0, 0.15);
  padding-right: vw(23px);
}

.vl-disc-card-row__img-block-outer {
  margin-right: 28px;
  @media only screen and (max-width: 525px) {
    margin-right: 15px;
  }
}

.vl-disc-card-row__img-block {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  @media only screen and (max-width: 1024px) {
    width: 35px;
    height: 35px;
  }
  span {
    background: #7474c4;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 vw(3px) vw(3px) rgba(116, 116, 196, 0.2);
    @media only screen and (max-width: 1024px) {
      font-size: 13px;
    }
  }

  img {
    width: inherit;
    height: inherit;
    border-radius: inherit;
  }
}

.vl-disc-card-row__text-content-div {
  width: 100%;
}

.vl-disc-card-row__text1 {
  color: #292950;
  padding-right: vw(406px);
  margin-bottom: vw(23px);
  letter-spacing: vw(0.32px);
  margin-bottom: vw(23px);
}

.vl-disc-card-row__text2 {
  color: #292950;
  letter-spacing: vw(0.32px);
  margin-top: vw(8px);
  margin-bottom: vw(22px);
  // margin-bottom: vw(44px);
  @media only screen and (max-width: 1024px) {
    line-height: 1.2;
  }
  @media only screen and (max-width: 575px) {
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.vl-disc-card-row__text-top-div {
  margin-bottom: 0px;
  color: rgb(0, 0, 0);
  font-family: Montserrat-Bold;

  span {
    font-weight: 700;
    font-size: 22px;
    line-height: 33px;
  }

  .fa-circle {
    font-size: vw(8px) !important;
    margin-right: vw(7px);
    vertical-align: middle;
    margin-bottom: vw(2px);
  }

  .vl-disc-card-row__text-top-div-blue-text {
    color: #4285f4;
    opacity: 1;
  }
  @media only screen and (max-width: 1024px) {
    span {
      font-size: 13px;
      font-family: Montserrat-Bold;
    }
  }
}
.date-list {
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.5);
}
.vl-disc-card-row__text2 {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  font-family: "Roboto-regular";
  @media only screen and (max-width: 1024px) {
    font-size: 12px;
  }
}
.vl-disc-card-row__text3 {
  color: rgba(0, 0, 0, 0.89);
  opacity: 0.3;
  // margin-left: vw(11px);
  margin-left: 18px;
  margin-bottom: 2vw;
  @media only screen and (max-width: 1024px) {
    font-size: 12px;
    font-family: Montserrat-Bold;
    margin-left: 18px;
  }
  @media only screen and (max-width: 900px) {
    margin-left: 20px;
  }
  @media only screen and (max-width: 500px) {
    margin-left: 20px;
  }
}

// details
.vl-disc-details-main-block {
  // padding-right: vw(26px);

  .vl-disc-card-row {
    padding-bottom: vw(46px);
    padding-right: 0;
  }

  .vl-disc-card-row__text1 {
    padding-right: 0;
    font-size: vw(28px);
    line-height: vw(32.81px);
    margin-bottom: vw(23px);
    @include fontRobotoBold;
  }

  .vl-disc-card-row__text2 {
    border-bottom: vw(2px) solid rgba(0, 0, 0, 0.14);
    padding-bottom: vw(40px);
    margin-bottom: vw(36px);
  }

  .startup-team-img-div {
    margin-left: 0;
    background-color: transparent;
    width: 80px;
    height: 60px;
    span {
      width: 60px;
      border-radius: 50%;
      overflow: hidden;
      height: 60px;
      background: #7474c4;
      color: #fff;
      box-shadow: 0 0 3px 3px rgba(116, 116, 196, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      font-size: 40px;
      @media only screen and (max-width: 1024px) {
        width: 35px;
        height: 35px;
        font-size: 25px;
      }
    }
    @media only screen and (max-width: 1024px) {
      width: 65px;
      height: 45px;
    }
  }
}

.vl-disc-details-go-back-btn {
  width: max-content;
  height: auto;
  font-size: vw(18px);
  line-height: vw(21px);
  @include fontRobotoBlack;
  margin-bottom: 46px;
  img {
    margin-right: 10px;
  }
  @media only screen and (max-width: 1024px) {
    font-size: 13px;
    line-height: 1.3;
  }
}

.desc-bottom-sec {
  padding-top: 48px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
  .desc-inpute-inner {
    width: 80%;
    textarea {
      width: 100%;
      background: #ffffff;
      border: 1px solid #000000;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
      height: 180px;
      padding: 20px;
      font-size: 13px;
    }
    @media only screen and (max-width: 1024px) {
      width: 100%;
    }
  }
  .vl-disc-card-row__imgAndBtnDiv-btn {
    background: #26262c;
    background: #5a9ba5;
    border-radius: 5px;
    padding: 20px 33px;
    font-size: vw(18px);
    line-height: vw(21px);
    @include fontRobotoBold;
    color: #ffffff;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #ffffff;
    @media only screen and (max-width: 1024px) {
      height: auto;
      width: auto;
      font-size: 15px;
      padding: 15px 20px;
    }
    @media only screen and (max-width: 767px) {
      margin-top: 20px;
    }
    @media only screen and (max-width: 575px) {
      padding: 8px 15px;
    }
  }
  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.vl-disc-details-comments-overflow__textColm {
  width: 100%;
  margin-left: 27px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.14);
  margin-bottom: 15px;

  .vl-disc-card-row__text2 {
    padding-bottom: 0;
    margin-bottom: vw(25px);
    border-bottom: none;
  }

  .vl-disc-card-row__text-top-div {
    margin-bottom: 0px;
    span {
      font-weight: 700;
      font-size: 18px;
      line-height: 27px;
      color: #000000;
      @media only screen and (max-width: 1024px) {
        font-size: 13px;
      }
    }
  }
  .vl-disc-date-line {
    font-family: "Roboto-regular";
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.5);
  }
  @media only screen and (max-width: 525px) {
    margin-left: 15px;
  }
}

.vl-disc-details-comments-overflow {
  width: 100%;
  height: 351px;
  overflow: auto;
  padding-right: 20px;
  @media only screen and (max-width: 525px) {
    height: 280px;
    padding-right: 0px;
  }

  .vl-disc-details-comments-overflow__row:last-child {
    .vl-disc-details-comments-overflow__textColm {
      border-bottom: none;
    }
  }
}

.vl-disc-details-comments-overflow__textColm-text1 {
  margin-bottom: vw(30px);
}

.vl-disc-details-comments-overflow__reply {
  margin-bottom: vw(9px);
  display: block;
}

// create new discussion
.vl-create-new-discussion {
  padding-right: vw(551px);
}

.vl-create-new-discussion-title-block {
  margin-bottom: vw(26px);
}

.vl-create-new-discussion-title {
  font-size: vw(20px);
  line-height: vw(23px);
  @include fontRobotoBold;
  color: rgba(0, 0, 0, 0.69);
  margin-bottom: 0;
  @media only screen and (max-width: 1024px) {
    font-size: 15px;
    line-height: 1.3;
  }
}

.vl-create-new-discussion-back-arrow-btn {
  width: vw(40px);
  height: vw(40px);
  margin-right: vw(20px);
  padding: 0;
  border-radius: 100%;
  @media only screen and (max-width: 1024px) {
    height: 15px;
    width: 15px;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    vertical-align: top;
  }
}

.vl-discussion-start-button-div {
  // padding-right: vw(120px);
  // margin-bottom: vw(30px);
  .start-dis-btn {
    font-family: "Roboto-regular";
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #ffffff;
    background: #5a9ba5;
    border-radius: 5px;
    padding: 14px 18px;
    height: auto !important;
    width: auto !important;
    @media only screen and (max-width: 1024px) {
      height: 30px;
      width: 110px;
      font-size: 11px;
      margin-bottom: 20px;
    }
  }
}

.popup-new-discussion {
  // width: 600px;
  .popup-new-discussion-title {
    font-size: vw(20px);
    line-height: vw(23px);
    @include fontRobotoBold;
    color: rgba(0, 0, 0, 0.69);
    margin-bottom: 0;
    @media only screen and (max-width: 1024px) {
      font-size: 15px;
      line-height: 1.3;
    }
  }
  .popup-inner-list {
    width: 100%;
    .description-input{
      width: 100%;
      max-width: 100%;
    }
  }
  .vl-common-save-btn {
    color: #ffffff;
    background: #5a9ba5;
    border-radius: 5px;
    padding: 14px 18px;
    width: 100px;
    height: auto;
    line-height: 22px;
    span {
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
    }
  }
  @media only screen and (max-width: 1100px) and (min-width: 901px) {
    // width: 450px;
  }
  @media only screen and (max-width: 900px) {
    width: auto;
  }
}
