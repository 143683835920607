.privacy-policy-main-div {
  // background-color: #272d2e;
  background-color: #455051;
  color: #ffffff;

  .vl-navbar,
  .privacy-policy-page-title-block {
    background: #1e2225;
  }

  .privacy-policy-page-title-block {
    width: 100%;
    // height: vw(276px);
    border-radius: 0 0 0 vw(117px);
    margin-top: vw(-2px);

    h1 {
      padding-top: 4.73958vw;
      padding-left: vw(264px);
      padding-bottom: 3.64583vw;
      font-size: vw(55px);
      line-height: vw(67px);
      @include fontRobotoBold;
      //@include fontMontserratBold;
      margin-bottom: 0;
      @media only screen and (max-width: 900px) {
        font-size: 25px;
        padding-bottom: 50px;
        line-height: 1.5;
      }
      @media only screen and (max-width: 767px) {
        font-size: 20px;
        line-height: 1.5;
        padding-left: 0px;
        padding-top: 0px;
      }
    }
    @media only screen and (max-width: 900px) {
      height: auto;
    }
    @media only screen and (max-width: 767px) {
      padding-top: 50px;
      padding-left: 0px;
      text-align: center;
    }
  }
}

.footer-row-div--privacy-policy {
  padding-left: vw(210px);
  // height: vw(110px);
  height: auto;

  a {
    width: vw(460px);
    .footer-knowledge-div {
      height: 100%;
      width: 100%;
      color: #272d2e;
    }
    @media only screen and (max-width: 900px) {
      width: 100%;
    }
  }

  .footer-top-right-div {
    height: inherit;
    color: #ffffff;
    background-color: #8ba995;
    padding-top: vw(28px);
    padding-left: vw(60px);
    width: 100%;
    @media only screen and (max-width: 900px) {
      text-align: center;
      padding: 25px 50px;
    }
    @media only screen and (max-width: 767px) {
      padding: 25px;
      h5 {
        font-size: 12px;
        line-height: 1.4;
      }
    }
  }
  @media only screen and (max-width: 900px) {
    display: flex;
    height: auto;
    padding-left: 0px;
  }
}

.privacy-policy-content-block {
  padding: vw(93.68px) vw(171.9px) 50px vw(223px);
  @media only screen and (max-width: 900px) {
    padding: 40px;
  }
  @media only screen and (max-width: 575px) {
    padding: 15px;
  }
  
  /*p {
    font-size: vw(22px);
    line-height: vw(26.82px);
    @include fontMontserratRegular;
    margin-bottom: vw(25px);
  }*/
  p,
  h3,
  li {
    font-size: vw(22px);
    line-height: vw(25.78px);
    @include fontRobotoRegular;
    @media only screen and (max-width: 900px){
      font-size: 15px;
      line-height: 1.3;
    }
    @media only screen and (max-width: 575px){
      font-size: 13px;
    }
  }
  .terms-font-22-roboto-bold {
    margin-bottom: 0;
    @include fontRobotoBold;
    text-decoration: underline;
    padding-top: vw(30px);
  }
  .font-roboto-italic {
    @include fontRobotoItalic;
  }
  .font-bold-italic {
    font-style: italic;
    @include fontRobotoBold;
  }
  .font-bold {
    @include fontRobotoBold;
  }
  u {
    @include fontRobotoRegular;
    //@include fontMontserratRegular;
  }
  ul {
    padding-left: vw(80px);
    li {
      list-style: circle;
      padding-left: 0;
      //padding-bottom: vw(20px);
    }
  }
  .terms-ul-style-dot {
    li {
      list-style: disc;
    }
  }
}

.privacy-policy-content-block--terms {
  padding-top: vw(68px);
  padding-right: vw(221px);
  padding-bottom: 50px;
  @media only screen and (max-width: 900px){
    padding: 40px;
  }
  @media only screen and (max-width: 575px){
    padding: 15px;
  }

  .terms-table {
    width: vw(700px);
    margin: auto;
    margin-top: vw(50px);
    margin-bottom: vw(50px);

    th {
      padding: 0;
      vertical-align: text-top;
      font-size: vw(22px);
      line-height: vw(25px);
      @include fontRobotoBold;
      text-align: center;
    }

    td {
      vertical-align: text-top;
      font-size: vw(22px);
      line-height: vw(25px);
      @include fontRobotoRegular;
      padding-top: vw(20px);
      padding-bottom: vw(20px);
      text-align: center;
      min-height: vw(100px);
      &:nth-child(2) {
        text-align: right;
        padding-right: vw(20px);
      }
      &:nth-child(3),
      &:nth-child(4) {
        text-align: right;
      }
    }
  }
  th,
  td {
    width: 20%;
  }

  tr {
    min-height: vw(100px);
  }
  th:nth-child(3),
  td:nth-child(3) {
    width: 40%;
  }

  th:nth-child(4) {
    padding-left: vw(35px);
  }

  .terms-total-row {
    border-top: 1px solid white;
    td {
      &:nth-child(1) {
        text-align: left;
        padding-left: vw(25px);
      }
    }
  }
}
