.vl-mentor-main-page {
  padding-top: vw(148px);
}

.vl-tabs-explore--mentor-mainPage {
  .react-tabs__tab-panel {
    padding-top: 0;
    padding-bottom: 0;
  }

  .react-tabs__tab-list {
    margin-left: vw(63px);
    margin-right: vw(297px);
    @media only screen and (max-width: 575px) {
      margin-left: 15px;
      margin-right: 15px;
    }
    &::after {
      width: 100%;
    }
  }

  .react-tabs__tab {
    color: rgba(50, 50, 119, 0.45);
    font-size: vw(24px);
    line-height: vw(28px);
    padding: 6px 12px 0;
    @include fontRobotoRegular;
    text-transform: uppercase;
    @media only screen and (max-width: 1536px) {
      padding-bottom: 4px;
    }
  }

  .react-tabs__tab--selected,
  .react-tabs__tab:focus {
    color: #323277;
    @include fontRobotoBold;
  }
}

.vl-mentor-mainPage-company-tabpanel-content {
  padding: vw(44px) vw(22px) vw(175px) vw(66px);
  @media only screen and (max-width: 575px) {
    padding: 0px 15px 20px 15px;
  }
}
.vl-mentor-mainPage-updates-tabpanel-content {
  padding: vw(39px) vw(47px) vw(175px) vw(63px);
  @media only screen and (max-width: 575px) {
    padding: 0px 15px 20px 15px;
  }
}
.vl-mentor-mainPage-profile-tabpanel-content {
  padding: vw(64px) vw(22px) vw(200px) vw(67px);
  @media only screen and (max-width: 575px) {
    padding: 15px 15px;
  }
}
.raise_capital_page_wrap {
  .raise_capital_background {
    padding: 50px 100px;
    @media screen and (max-width: 1440px) {
      padding: 50px;
    }
    @media screen and (max-width: 992px) {
      padding: 50px 20px;
    }
    .react-tabs__tab-panel {
      padding-top: 15px;
    }
    // .react-tabs__tab {
    //   @media screen and (max-width: 1536px) {
    //     padding-bottom: 4px !important;
    //   }
    // }
    .input-border-div {
      textarea {
        padding: 20px;
        @media screen and (max-width: 992px) {
          font-size: 15px;
        }
      }
    }
    .team_disable_wrap {
      padding-right: 0 !important;
    }
  }
  // .raise_capital_main_nav {
  //   margin-left: 3.28125vw;
  //   margin-right: 15.46875vw;
  //   border-bottom: 1px solid #aaa;
  //   margin: 0 0 10px;
  //   padding: 0;
  // }
}
.vl-mentor-updates-tabpanel-content {
  .vl-mentor-updates-tabpanel-content__title {
    color: $vl-web-app-pages-text-color;
    margin-right: vw(15px);
  }

  .vl-mentor-updates-tabpanel-content__text1 {
    color: #000000;
    line-height: vw(26.86px);
  }

  .vl-mentor-updates-tabpanel-content__text2 {
    color: rgba(0, 0, 0, 0.46);
    margin-right: vw(13px);
  }

  .vl-mentor-updates-tabpanel-content__dateTime {
    margin-top: vw(22px);
    margin-bottom: vw(23px);
  }

  hr {
    border-top: 1px solid rgba(0, 0, 0, 0.14);
    margin: 0;
    width: 83%;
    margin-bottom: vw(27px);
  }
}

// tabs profile
.vl-tabs-explore--main-mentor-page-profile-tabs {
  .react-tabs__tab-list {
    margin-left: 0;
  }

  .vl-mentor-basic-form-tabpanel-content,
  .vl-mentor-expertise-form-tabpanel-content {
    padding: vw(74px) 0 0 0;
  }

  .react-tabs__tab {
    font-size: vw(18px);
    line-height: vw(21px);
    color: rgba(0, 0, 0, 0.08);
    @include fontRobotoBold;
    text-align: center;
    border-radius: vw(150px);
    width: vw(242px);
    padding: vw(15px) 0;
    // margin-right: vw(11px);
  }

  .react-tabs__tab--selected,
  .react-tabs__tab:focus {
    color: #ffffff;
    background: #8ba995;
    border: vw(2px) solid #8ba995;

    &::after {
      display: none;
    }
  }

  .react-tabs__tab-list::after {
    display: none;
  }
}
