.know-before-you-invest-main-div {
  background: #1e2225;
  border-radius: 0 0 0 vw(117px);
  padding-bottom: vw(70px);
  .know-before-you-invest-title-block {
    padding-top: vw(91px);
    padding-left: vw(264px);
    h1 {
      font-size: vw(55px);
      margin-bottom: 0;
      line-height: vw(67.05px);
      //@include fontMontserratBold;
      @include fontRobotoBold;
      @media only screen and (max-width: 900px) {
        font-size: 25px;
        line-height: 1.5;
      }
      @media only screen and (max-width: 767px) {
        font-size: 20px;
        line-height: 1.5;
      }
    }
    @media only screen and (max-width: 767px) {
      padding-top: 50px;
      padding-left: 0px;
      text-align: center;
    }
  }
  @media only screen and (max-width: 900px) {
    padding-bottom: 50px;
  }
}
.know-before-you-invest-text-block {
  padding: vw(94px) vw(223px) vw(129px) vw(150px);

  .kn-font-24-bold-montserrat {
    font-size: vw(24px);
    line-height: vw(29.26px);
    margin-bottom: 0;
    //@include fontMontserratBold;
    @include fontRobotoBold;
  }
  .kn-font-22-medium-montserrat {
    margin-bottom: 0;
    font-size: vw(22px);
    line-height: vw(26.82px);
    //@include fontMontserratRegular;
    @include fontRobotoRegular;
  }
  .kn-font-24-regular-montserrat {
    margin-bottom: 0;
    font-size: vw(24px);
    line-height: vw(28px);
    //@include fontMontserratRegular;
    @include fontRobotoRegular;
  }
  .kn-font-22-semibold-montserrat {
    font-size: vw(22px);
    line-height: vw(26.82px);
    //@include fontMontserratSemiBold;
    @include fontRobotoBold;
    margin-bottom: 0;
  }
  .kn-font-24-semibold-montserrat {
    font-size: vw(24px);
    line-height: vw(28px);
    //@include fontMontserratSemiBold;
    @include fontRobotoBold;
    margin-bottom: 0;
  }
  .kn-font-22-italic-montserrat {
    font-size: vw(22px);
    line-height: vw(26.82px);
    //@include fontMontserratItalic;
    @include fontRobotoItalic;
    margin-bottom: 0;
  }
  .kn-font-24-italic-montserrat {
    font-size: vw(24px);
    line-height: vw(28px);
    //@include fontMontserratItalic;
    @include fontRobotoItalic;
    margin-bottom: 0;
  }
  u {
    @include fontRobotoRegular;
    //@include fontMontserratRegular;
  }
}

.know-before-you-invest-text-block--new {
  padding: 0;

  .accordion__panel {
    padding-right: vw(40px);
  }

  .know-before-you-invest-text-block-desc-div {
    padding-right: vw(20px);
  }
}

.know-before-you-invest-text-block-desc-div {
  padding-left: vw(41px);
}

.faq-second-div--inner-accordion {
  padding: 0 0 0 vw(41px);

  .accordion {
    padding: 0;
  }

  .accordion__heading {
    padding-top: vw(26px);
    padding-bottom: vw(26px);
  }

  .accordion__button {
    font-size: vw(26px);
    line-height: vw(30px);
  }

  .accordion__panel {
    padding-right: vw(156px);
    padding-bottom: vw(45px);
  }
}
