/* ===============================================================
                    
                            Mixins

================================================================== */

/* ===============================================================
                            roboto
================================================================== */
@mixin fontRobotoItalic {
  font-weight: 300;
  font-family: "Roboto-Italic", sans-serif, "Helvetica";
}
@mixin fontRobotoLight {
  font-weight: 300;
  font-family: "Roboto-Light", sans-serif, "Helvetica";
}
@mixin fontRobotoRegular {
  font-weight: 400;
  font-family: "Roboto-Regular", sans-serif, "Helvetica";
}
@mixin fontRobotoMedium {
  font-weight: 500;
  font-family: "Roboto-Medium", sans-serif, "Helvetica";
}
@mixin fontRobotoBold {
  // font-weight: 700;
  font-weight: 500;
  font-family: "Roboto-Bold", sans-serif, "Helvetica";
}
@mixin fontRobotoBlack {
  // font-weight: 900;
  font-weight: 500;
  font-family: "Roboto-Black", sans-serif, "Helvetica";
}
/*=================================================================
                         Montserrat 
====================================================================*/
@mixin fontMontserratItalic {
  font-weight: 300;
  font-family: "Montserrat-Italic", sans-serif, "Helvetica";
}
@mixin fontMontserratRegular {
  font-weight: 400;
  font-family: "Montserrat-Regular", sans-serif, "Helvetica";
}
@mixin fontMontserratMedium {
  font-weight: 500;
  font-family: "Montserrat-Medium", sans-serif, "Helvetica";
}
@mixin fontMontserratSemiBold {
  // font-weight: 600;
  font-weight: 500;
  font-family: "Montserrat-SemiBold", sans-serif, "Helvetica";
}
@mixin fontMontserratBold {
  // font-weight: 700;
  font-weight: 500;
  font-family: "Montserrat-Bold", sans-serif, "Helvetica";
}
@mixin fontMontserratExtraBold {
  // font-weight: 800;
  font-weight: 500;
  font-family: "Montserrat-ExtraBold", sans-serif, "Helvetica";
}
@mixin fontMontserratBlack {
  // font-weight: 900;
  font-weight: 500;
  font-family: "Montserrat-Black", sans-serif, "Helvetica";
}
/*=================================================================
                         DM Serif Display
====================================================================*/
@mixin fontDMSerifDisplayRegular {
  font-weight: 400;
  // font-family: "DMSerifDisplay-Regular", sans-serif, "Helvetica";
  // font-family: "Roboto";
  font-weight: 700;
  font-family: "Roboto", sans-serif;
}
/*=================================================================
                         Open Sans
====================================================================*/
@mixin fontOpenSansRegular {
  font-weight: 400;
  font-family: "OpenSans-Regular", sans-serif, "Helvetica";
}
/*=================================================================
                         Playfair Display
====================================================================*/
@mixin fontPlayfairDisplayBold {
  // font-weight: 700;
  font-weight: 500;
  font-family: "PlayfairDisplay-Bold", sans-serif, "Helvetica";
}
/*=================================================================
                         Lora
====================================================================*/
@mixin fontLoraRegular {
  font-weight: 400;
  font-family: "Lora-Regular", sans-serif, "Helvetica";
}
