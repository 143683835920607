.vl-glossary-web-app {
  .glossary-que-outer-div {
    padding: vw(37px) vw(189px) vw(212px) vw(118px);

    .accordion,
    .accordion__button {
      color: $vl-web-app-pages-text-color;
      @media only screen and (max-width: 769px) {
        font-size: 16px;
      }
      @media only screen and (max-width: 530px) {
        font-size: 13px;
      }
    }
    .accordion__item {
      padding: 20px 0;
    }
    .accordion .accordion__item {
      border-bottom-color: $vl-web-app-pages-text-color;
    }
  }

  .glossary-font-40-bold {
    margin: 48px 0 18px 0;
    padding: 0;
    color: $vl-web-app-pages-text-color;
    @media only screen and (max-width: 530px) {
      font-size: 18px;
    }
  }
}
