.faq-fold-one-div {
  background: #1e2225;
  border-radius: 0 0 0 vw(117px);
  padding-bottom: vw(70px);
  .faq-title {
    padding-top: vw(91px);
    padding-left: vw(264px);
    font-size: vw(55px);
    margin-bottom: 0;
    line-height: vw(67.05px);
    //@include fontMontserratBold;
    @include fontRobotoBold;
    @media only screen and (max-width: 900px) {
      font-size: 25px;
      line-height: 1.5;
    }
    @media only screen and (max-width: 767px) {
      font-size: 20px;
      line-height: 1.5;
    }
    @media only screen and (max-width: 767px) {
      padding-top: 50px;
      padding-left: 0px;
      text-align: center;
    }
  }
  @media only screen and (max-width: 900px) {
    padding-bottom: 50px;
  }
}
.footer-row-div--privacy-policy {
  padding-left: vw(210px);
  // height: vw(110px);
  height: auto;

  a {
    width: vw(460px);
    .footer-knowledge-div {
      height: 100%;
      width: 100%;
      color: #272d2e;
    }
    @media only screen and (max-width: 900px) {
      width: 100%;
    }
  }

  .footer-top-right-div {
    height: inherit;
    color: #ffffff;
    background-color: #8ba995;
    padding-top: vw(28px);
    padding-left: vw(60px);
    width: 100%;
    @media only screen and (max-width: 900px) {
      text-align: center;
      padding: 25px 50px;
    }
    @media only screen and (max-width: 767px) {
      padding: 25px;
      h5 {
        font-size: 12px;
        line-height: 1.4;
      }
    }
  }
  @media only screen and (max-width: 900px) {
    display: flex;
    height: auto;
    padding-left: 0px;
  }
}
// .faq-fold-one-bottom-div {
//   padding-left: vw(210px);
//   // height: vw(101px);
//   a {
//     width: vw(460px);
//     .faq-fold-one-kb-div {
//       background: white;
//       height: 100%;
//       width: 100%;
//       color: #272d2e;
//       h5 {
//         margin-bottom: 0;
//         font-size: 1.5625vw;
//         line-height: 1.90469vw;
//         font-weight: 500;
//         font-family: "Roboto-Bold", sans-serif, "Helvetica";
//         @media only screen and (max-width: 900px) {
//           padding: 20px 0px;
//           font-size: 16px;
//         }
//       }
//     }
//     @media only screen and (max-width: 900px) {
//       width: 100%;
//     }
//   }
//   .faq-fold-one-right-div {
//     padding-bottom: 1.67917vw;
//     padding-left: 3.125vw;
//     padding-right: 4.72448vw;
//     background: #29294f;
//     height: inherit;
//     color: #ffffff;
//     background-color: #8ba995;
//     padding-top: vw(28px);
//     width: 100%;
//     h5 {
//       font-size: 25px;
//       line-height: 30px;
//       font-family: "Roboto-regular";
//       margin-bottom: 0px;
//     }
//     @media only screen and (max-width: 900px) {
//       text-align: center;
//       padding: 25px 50px;
//     }
//     @media only screen and (max-width: 767px) {
//       padding: 25px;
//       h5 {
//         font-size: 12px;
//         line-height: 1.4;
//       }
//     }
//   }
//   @media only screen and (max-width: 900px) {
//     display: flex;
//     height: auto;
//     padding-left: 0px;
//   }
// }

.faq-second-div {
  padding: vw(77.5px) vw(202.97px) vw(225px) vw(278.44px);
  .accordion {
    border: none !important;
    color: white;
    padding: vw(10px) 0 vw(51px) 0;
    &__button {
      background: none;
      padding: vw(10px) 0 0 0;
      color: white;
      width: auto;
      height: auto;
      font-size: vw(30px);
      line-height: vw(36.57px);
      //@include fontMontserratBold;
      @include fontRobotoBold;

      &:hover {
        background: none !important;
        border: none !important;
      }

      &:focus {
        border: none !important   ;
        outline: none !important;
      }

      &::before {
        width: vw(24px);
        height: vw(24px);
        float: right;
        transform: rotate(45deg);
      }
    }
    //.accordion__button[aria-expanded="true"]::before,
    //.accordion__button[aria-selected="true"]::before {
    //}
    &__panel {
      font-size: vw(22px);
      line-height: vw(26.82px);
      //@include fontMontserratRegular;
      @include fontRobotoRegular;

      padding: 0 vw(60px) vw(60px) 0;
      width: vw(1477px);

      p {
        margin-bottom: 0;
      }
      span {
        border-bottom: 1px solid white;
      }
      .font-semibold {
        border: none;
        //@include fontMontserratSemiBold;
        @include fontRobotoBold;
      }
    }
    &__heading {
      padding-bottom: vw(51px);
      padding-top: vw(36px);
    }
    .accordion__item {
      border-bottom: vw(2px) solid white;
      .accordion__panel {
        @media only screen and (max-width: 768px) {
          width: 100%;
        }
      }
      @media only screen and (max-width: 900px) {
        .accordion__heading {
          font-size: 18px;
          .accordion__button {
            font-size: 15px;
            line-height: 1.3;
          }
        }
        .accordion__panel {
          p,
          li,
          span {
            font-size: 12px;
            line-height: 1.3;
          }
        }
      }
      @media only screen and (max-width: 575px) {
        .accordion__heading {
          .accordion__button {
            font-size: 13px;
          }
        }
        .accordion__panel {
          padding-right: 0px;
          p,
          li,
          span {
            font-size: 12px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}