.vl-profile-page-phone-number-block {
  .input-country-phone-number-container.input-country-phone-number-container--disabled {
    border: none;
  }

  .react-phone-input-block {
    height: vw(20px);
  }

  .react-phone-input-block .react-tel-input .form-control {
    padding: 0;
    padding-left: 0;

    font-size: vw(18px);
    line-height: vw(22px);
    @include fontMontserratBold;
    margin-bottom: vw(24px);
    color: rgba(50, 50, 119, 0.74);
  }

  .react-phone-input-block .react-tel-input .selected-flag {
    padding-left: 0;
  }

  .flag-dropdown {
    user-select: none;
    display: none;
  }

  .react-phone-input-block .react-tel-input .selected-flag {
    width: vw(41px);
  }

  .error-message,
  .selected-flag .arrow {
    display: none;
  }
}

//image upload
.vl-profile-page-image-upload-div {
  .upload-img__mainBlock {
    width: vw(263px);
    height: vw(261px);
    border-radius: vw(8px);
    background: #ffffff;

    .upload-img__img-block,
    img {
      width: 100%;
      height: 100%;
      border-radius: inherit;
      @media only screen and (max-width: 575px) {
        width: 191px;
      }
    }

    .upload-img__img-block {
      margin-bottom: vw(12px);
    }
  }

  .upload-img__btn-input-block {
    border: none;
    height: fit-content;
  }

  .upload-img__btn-input-block .upload-img__btn-input-block-text {
    font-size: vw(14px);
    line-height: vw(17px);
    @include fontMontserratMedium;
    margin-bottom: 0;
  }

  .vl-display-loader-div div svg {
    width: vw(35px);
  }
}

.vl-profile-page-image-upload-div--display {
  .upload-img__btn-input-block.opacity-0,
  .upload-img__btn-input-block {
    display: none;
  }

  .upload-img__mainBlock {
    margin-bottom: vw(16px);
  }

  .vl-common-skip-btn,
  .vl-common-save-btn {
    width: vw(100px);
    height: vw(50px);

    font-size: vw(18px);
    line-height: vw(20px);
    @media only screen and (max-width: 767px) {
      font-size: 15px;
      line-height: 1.3;
      height: auto;
      width: auto;
      padding: 10px 30px;
    }
  }
}

.vl-profile-page-button {
  padding: vw(5px) vw(7px);
  border-radius: vw(3px);
  font-size: vw(14px);
  line-height: vw(17px);
  @include fontMontserratMedium;
  margin-bottom: 0;

  .vl-display-loader-div div svg {
    width: vw(25px);
  }
}
