.raise-capital-startup-profile-div__img-block {
  width: vw(151px);
  height: vw(239.18px);
  margin: 0 auto;
  margin-bottom: vw(87px);

  img {
    width: inherit;
    height: inherit;
  }
}

.raise-capital-more-info-note-text {
  color: $vl-web-app-pages-text-color;
  @media only screen and (max-width: 900px) {
    font-size: 13px;
    line-height: 1.5;
  }
}

.raise-capital-form-basic-tab-text {
  color: $vl-web-app-pages-text-color;
  font-size: vw(20px);
  line-height: vw(23px);
  margin-bottom: 0;
  padding-bottom: vw(15px);
  @include fontRobotoItalic;
  @media only screen and (max-width: 768px) {
    font-size: 13px;
    line-height: 1.5;
    margin-bottom: 20px;
  }
}

.raise-capital-font-42-montserrat-bold {
  font-size: vw(42px);
  line-height: vw(51.2px);
  margin-bottom: vw(23px);
  @include fontRobotoBold;
  color: rgba(0, 0, 0, 0.86);
}
.raise-capital-font-20-montserrat-medium {
  font-size: vw(20px);
  line-height: vw(24.38px);
  margin-bottom: vw(53px);
  @include fontRobotoMedium;
  color: rgba(0, 0, 0, 0.69);
}
.raise-capital-startup-btn {
  width: vw(377px);
  padding: vw(15px) vw(55px) vw(16px) vw(55px);
  background: $vl-web-app-pages-text-color;
  border: 1px solid #feffff;
  box-sizing: border-box;
  border-radius: vw(59px);
  width: fit-content;
  color: white;
  font-size: vw(24px);
  line-height: vw(29px);
  @include fontRobotoMedium;
}
.raise_capital_button_wrap {
  background: #a0a0a0;
}
.raise-capital-startup-profile-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: vw(231px);
  padding-bottom: vw(141px);
  // cursor: pointer;
  @media only screen and (max-width: 900px) {
    .raise-capital-startup-profile-div__img-block {
      width: 40%;
      height: auto;
    }
    h2 {
      font-size: 25px;
      line-height: 1.5;
    }
    h5 {
      font-size: 15px;
      line-height: 1.5;
    }
    .raise-capital-startup-btn {
      padding: 10px 30px;
      span {
        font-size: 15px;
        line-height: 1.5;
      }
    }
  }
  @media only screen and (max-width: 575px) {
    .raise-capital-startup-profile-div__img-block {
      width: 50%;
    }
    h2 {
      font-size: 20px;
      line-height: 1.3;
      margin-bottom: 10px;
      margin-top: 20px;
    }
    h5 {
      line-height: 1.3;
      margin-bottom: 20px;
    }
    .raise-capital-startup-btn {
      padding: 7px 25px;
      span {
        font-size: 14px;
        line-height: 1.3;
      }
    }
  }
  @media only screen and (max-width: 575px) {
    justify-content: center;
    padding: 0px;
    height: 100vh;
    width: 95%;
    margin: auto;
  }
}
.raise-capital-tab-outer-div {
  padding: vw(71px) 0 vw(58px) vw(60px);
  @media only screen and (max-width: 768px) {
    padding: vw(71px) vw(60px) vw(58px) vw(60px);
    h5 {
      font-size: 13px;
      line-height: 1.3;
      margin-bottom: 30px;
    }
  }
}
.raise_tab_capital_main {
  padding: vw(71px) vw(58px) vw(60px);
  .information_details_main {
    .instruction_wrap {
      font-weight: 800;
    }
    .button_arrow {
      margin-bottom: 0 !important;
    }
  }
  .raise-capital-more-info-div {
    @media screen and (max-width: 768px) {
      padding: 0 !important;
    }
  }
}
.investor_details_wrap {
  .react-tabs {
    .react-tabs__tab-list {
      .react-tabs__tab {
        color: #000;
        font-family: "Roboto-Medium", sans-serif, "Helvetica";
        font-size: 20px;
        font-weight: 500;
        line-height: normal;
        @media only screen and (max-width: 1536px) {
          padding-bottom: 4px;
        }
      }
    }
  }
}
.vl-tabs-explore--raise-capital {
  .react-tabs__tab {
    font-weight: 600;
    @media only screen and (max-width: 900px) {
      font-size: 15px;
      line-height: 1.5;
      width: auto;
      padding: 7px 10px;
    }
    @media only screen and (max-width: 767px) {
      font-size: 13px;
      line-height: 1.3;
    }
    span {
      opacity: 0;
      padding-right: vw(5px);
    }
  }
  .react-tabs__tab--selected {
    span {
      opacity: 1;
    }
  }
  .react-tabs__tab-panel {
    padding-bottom: 0;
    padding-top: vw(42px);
  }
  .react-tabs__tab-list {
    &::after {
      width: vw(1280px);
    }
  }
}
.raise-capital-font-20-roboto-bold {
  font-size: vw(20px);
  line-height: vw(23.44px);
  @include fontRobotoBold;
  color: rgba(0, 0, 0, 0.69);
  margin-bottom: vw(47px);
}
.raise-capital-basic-div {
  .vl-form-input {
    margin-top: vw(20px);
    margin-bottom: vw(10px);
    @media only screen and (max-width: 767px) {
      max-width: 100%;
      .input-border-div {
        height: 40px;
        margin-bottom: 0px;
        input {
          font-size: 12px;
        }
      }
      label {
        font-size: 13px;
      }
    }
  }
  .profile_image_error {
    margin-bottom: 10px;
  }
  .upload_profile_space {
    margin-bottom: 0 !important;
    .upload-img__img-block {
      @media screen and (max-width: 480px) {
        margin-bottom: 10px;
      }
    }
  }
}
.raise_capital_div_wrap {
  .profile_default_img {
    border-radius: 20px;
  }
}
.raise-capital-basic-div {
  .upload-img__mainBlock {
    margin-bottom: vw(80px);
    margin-left: vw(-17px);
    @media only screen and (max-width: 767px) {
      margin: 0px;
    }
  }
  .raise-capital-basic-upload {
    justify-content: space-between;
    align-items: center;
  }
  .vl-form-input--textarea {
    .input-border-div {
      width: vw(977px);
      @media only screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
  .raise-flex-direactio-col {
    @media only screen and (max-width: 575px) {
      flex-direction: column;
    }
  }
  .basic_raise_capital_textarea {
    width: 100%;
    max-width: 100%;
    .input-border-div {
      width: 100%;
      @media screen and (max-width: 767px) {
        height: 17.3958333333vw !important;
      }
    }
  }
}
.vl-form-input--raise-capital {
  width: vw(598px);
  height: auto;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}
.vl-form-input--raise-capital-select {
  // width: vw(598px);
  // margin-left: vw(159px);
  @media only screen and (max-width: 767px) {
    margin-left: 0px;
    width: 100%;
  }
}
.raise-capital-label-company-info {
  font-size: vw(28px);
  line-height: vw(33px);
  color: $vl-web-app-pages-text-color;
  @include fontRobotoItalic;
  margin-bottom: vw(12px);
  @media only screen and (max-width: 768px) {
    font-size: 13px;
    line-height: 1.3;
  }
}
.vl-form-input--textarea--raise-capital {
  max-width: vw(977px);
}
.pr-260 {
  padding-right: vw(260px);
}
.common-input-capital {
  padding-right: 100px !important;
  @media screen and (max-width: 576px) {
    padding-right: 0 !important;
  }
}
.raise-capital-more-info-div {
  padding-right: 100px;
  .vl-form-input {
    width: vw(598px);
    @media only screen and (max-width: 900px) {
      width: 100%;
      max-width: 100%;
    }
  }
  .funding-date-picker {
    // padding: 0 !important;
    .date-picker-common {
      .react-datepicker-wrapper {
        .react-datepicker__input-container {
          input {
            height: 100% !important;
          }
        }
      }
    }
  }
  .raise_capital_text_area {
    width: 100%;
    max-width: 100%;
    .input-border-div {
      width: 100% !important;
    }
  }
  .funding-details-input-main {
    width: 50% !important;
    @media screen and (max-width: 992px) {
      width: 100% !important;
      max-width: 100% !important;
    }
    .investment_input_label {
      @media screen and (max-width: 768px) {
        left: 25px;
      }
      @media screen and (max-width: 576px) {
        left: 20px;
      }
    }
    .input-border-div {
      width: 100%;
    }
    .funding-inputs {
      width: 100%;
      max-width: 100%;
    }
    .date_picker_wrap {
      width: 100% !important;
      max-width: 100% !important;
    }
    .date-picker-common {
      @media screen and (max-width: 992px) {
        width: 100% !important;
        max-width: 100% !important;
      }

      margin-right: 0 !important;
      .react-datepicker-wrapper {
        .react-datepicker__input-container {
          input {
            padding: 20px;
          }
        }
      }
    }
    .vl-form-input {
      @media screen and (max-width: 992px) {
        width: 100% !important;
        max-width: 100% !important;
      }
    }
  }
  .funding_left_add {
    label {
      @media screen and (max-width: 768px) {
        left: 20px;
      }
    }
  }
  .dropdown_edits_input {
    @media screen and (max-width: 992px) {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
  .disable_fund_input {
    width: 100% !important;
    max-width: 100% !important;
  }
  .vl-form-input--textarea {
    .input-border-div {
      width: vw(977px);
      @media only screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    padding-right: 0 !important;
  }
}
.raise_cap_main {
  .vl-form-input {
    width: 100%;
    max-width: 100%;
    height: auto;
    min-height: auto;
    margin-bottom: 0 !important ;
  }
  .column_one {
    @media screen and (max-width: 768px) {
      padding: 0 !important;
    }
  }
}
.raise-capital-more-info-label {
  font-size: vw(28px);
  line-height: vw(32.81px);
  @include fontRobotoItalic;
  color: $vl-web-app-pages-text-color;
  margin-bottom: 0;
  @media only screen and (max-width: 768px) {
    font-size: 13px;
    line-height: 1;
    margin-bottom: 10px;
    margin-top: 20px;
  }
}
.raise_product_textarea {
  max-width: 100% !important;
  width: 100%;
  .input-border-div {
    width: 100% !important  ;
  }
}
.raise_capital_title_main {
  @media screen and (max-width: 1024px) {
    margin-right: 22px;
  }
}
.vl-form-input--textarea--raise-capital-more-info {
  max-width: vw(977px);
  @media only screen and (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }
}
.raise-capital-more-info-div-team {
  padding-right: vw(270px);
  @media only screen and (max-width: 767px) {
    padding-right: 0px;
  }

  .vl-form-input--textarea {
    .input-border-div {
      width: vw(1273px);
      height: vw(168px);
    }
  }

  .vl-form-input--textarea--raise-capital-team {
    max-width: vw(1283px);
  }
}
.raise_cap_team_wrap {
  padding-right: 0 !important;
  .vl-mentor-add-area-field-button {
    width: fit-content;
    margin: 0 auto;
  }
  .edit-team-main {
    @media screen and (max-width: 992px) {
      margin-top: 40px;
    }
    .edit_inputs_wrap{
      width: 100%;
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      .edit-profile-input-main{
        .edit-profile-input{
          &::placeholder{
            font-size: 5px !important;
          }
        }
      }
    }
  }
  .upload-img__mainBlock {
    margin-bottom: 20px !important;
  }
  .save-button{
    font-size: 1.25vw;
    font-weight: 500;
  }
}
.raise-capital-more-info-label-2 {
  font-size: vw(18px);
  line-height: vw(21.09px);
  color: $vl-web-app-pages-text-color;
  @include fontRobotoItalic;
  margin-bottom: vw(14px);
}
.raise-capital-team-textarea-div {
  position: relative;
  // padding-bottom: vw(31px);
  // &::after {
  //   position: absolute;
  //   content: "";
  //   width: vw(1367px);
  //   height: 1px;
  //   background: #000000;
  //   left: 0;
  //   bottom: 0;
  // }
}
.pitch-text-filed {
  //margin-left: vw(102px);
  .vl-form-input {
    width: vw(598px);
    @media only screen and (max-width: 900px) {
      width: 100%;
      max-width: 100%;
    }
  }
}
.pitch-product-btn {
  padding-top: vw(6px);
  padding-right: vw(272px);
}
.pitch_button_button {
  padding-right: 0;
}
.pitch-and-product-drop-down-div {
  width: vw(750px);
  height: auto;
  background: transparent;
  box-sizing: border-box;

  input {
    width: 100%;
    height: 100%;
    border-radius: 25px;
  }
  p {
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #272d2e;
    padding: 10px 0;
    background: #fff;
    margin: 0;
    color: #000;
    text-align: center;
    font-size: 20px;
    font-style: italic;
    font-weight: 400;
    @include fontRobotoItalic;
    color: #000000;
  }
  &:focus {
    outline: none;
  }

  .error-message {
    margin-bottom: vw(10px);
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    height: 70px;
    p {
      font-size: 12px;
      line-height: 0.8;
    }
  }
}

.required-error-message {
  color: #000;
  font-size: 20px;
  font-style: italic;
  font-weight: 400;
  text-align: end;
  @media screen and (max-width: 992px) {
    font-size: 14px;
  }
}
.raise_pitch_error {
  margin-bottom: 0;
}
.pitch-and-product-drop-down-main-div {
  margin-bottom: vw(55px);
  margin-right: vw(40px);
  div {
    &:focus {
      outline: none;
    }
  }
  .drag-drop-box {
    width: 100%;
    height: 100%;
  }
}
.pitch-and-product-font-18-regular {
  font-size: 18px;
  line-height: 21.09px;
  @include fontRobotoRegular;
  @media only screen and (max-width: 768px) {
    font-size: 13px;
  }
}
.pitch-upload-btn {
  width: vw(174px);
  height: vw(49px);
  border-radius: vw(4px);
  border: vw(2px) solid #000000;
  font-size: vw(13px);
  line-height: vw(15.23px);
  @include fontRobotoMedium;
  &:focus {
    outline: none;
  }
}
.drag-and-drop-item-list {
  font-size: vw(20px);
  line-height: vw(23.44px);
  @include fontRobotoBold;
  color: #6969b7;
  padding-top: 31px;
  //padding-bottom: vw(px);
  li {
    list-style: none;
    padding-bottom: 19px;
    @media only screen and (max-width: 768px) {
      display: flex;
    }
    @media only screen and (max-width: 768px) {
      padding-top: 15px;
    }
  }
  .file-icon {
    color: #323277;
    margin-right: vw(16px);
    font-size: vw(22px) !important;
    margin-bottom: 0;
    vertical-align: top;
    @media only screen and (max-width: 768px) {
      font-size: 18px !important;
    }
  }
  button {
    margin-left: vw(91px);
  }
  .colse-icon {
    color: black;
    font-size: vw(22px) !important;
    padding-right: vw(5px);
    padding-left: vw(5px);
    @media only screen and (max-width: 768px) {
      font-size: 12px !important;
    }
  }
}
.drag-and-drop-item-list__fileName {
  width: vw(303px);
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
    width: 100%;
    line-height: 2;
  }
}
.sign_up_date_main {
  height: 66px !important;
  background-color: transparent !important;
  border: 1px solid #ffffff !important;
  border-radius: 8px !important;
  margin-right: 0;
  @media screen and (max-width: 768px) {
    margin-bottom: 25px;
  }
  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      input {
        background: transparent !important;
        border: none;
        color: #fff;
        @media screen and (max-width: 1024px) {
          height: 100% !important;
        }
        &::placeholder {
          color: #fff !important;
          font-size: 14px !important;
          font-weight: 500 !important;
          font-family: "Roboto-Medium", sans-serif, "Helvetica";
        }
      }
    }
  }
}
.information_details_main {
  .button_arrow {
    margin-bottom: 0 !important;
  }
}
