.already-registered-card {
  width: vw(605px);
  height: auto;
  background: rgba(62, 58, 101, 0.71);
  border-radius: vw(17px);
  transform: rotate(-0.11deg);
  padding-top: vw(45px);
  padding-bottom: vw(38px);
  margin-top: vw(31px);
  text-align: center;
}
.ar-font-24-roboto {
  font-size: vw(24px);
  line-height: vw(28.13px);
  color: rgba(255, 255, 255, 0.82);
  @include fontRobotoBold;
  margin-bottom: vw(26px);
}
.ar-font-18-roboto-regular {
  font-size: vw(18px);
  line-height: vw(21.09px);
  @include fontRobotoRegular;
  margin-bottom: vw(27px);
  color: rgba(255, 255, 255, 0.44);
}
.google-signup-btn--ar {
  margin: auto;
}
.facebook-signup-btn--ar {
  margin: auto;
  margin-top: vw(20px);
}
.ar-go-back-text {
  font-size: vw(14px);
  line-height: vw(16.41px);
  color: rgba(254, 255, 255, 0.48);
  @include fontRobotoBold;
  margin-top: vw(35px);
}
