.customCloseButton-website {
  display: none !important;
}

.customOverlay-website {
  background: rgba(255, 255, 255, 0.59);
  z-index: 0;
}

.customModal-website {
  -webkit-box-shadow: 0 0 0;
  box-shadow: 0 0 0;
  max-width: 90%;
  height: fit-content;
  color: #000000;
  background-color: #ffffff;
}

.closeIconInModal-website {
  cursor: pointer;

  &::before,
  &::after {
    content: " ";
    position: absolute;
    top: vw(30px);
    right: vw(40px);
    background-color: white;
    height: vw(25px);
    width: vw(4px);
    z-index: 111 !important;
    @media only screen and (max-width: 767px) {
      height: 15px;
      width: 2px;
      right: 10px;
      top: 10px;
    }
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}
