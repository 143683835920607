.company-profile-page-main {
  .company-details-main {
    margin-bottom: 50px;
    .company-logo-main {
      .company-logo {
        width: 200px;
        height: 200px;
      }
    }
    .company-name {
      color: #000;
      font-size: 28px;
      font-weight: 600;
      text-transform: capitalize;
      font-family: 'Roboto-Regular';
      margin: 10px 0 0;
    }
    .city-name {
      color: #000;
      font-size: 20px;
      font-style: italic;
      font-weight: 500;
      font-family: "Roboto-Regular";
    }
  }
  .company-bio-main {
    margin-bottom: 50px;
    .company-bio-text {
      color: #000;
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 15px;
      font-family: "Roboto-Regular";
    }
    .company-bio-textarea {
      width: 100%;
      max-width: 100%;
    }
  }
  .team-details-main {
    .team-title {
      color: #000;
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 0;
    }
    .background_img_main {
      width: 100%;
      background: #f4f4f4;
      height: 250px;
      border-radius: 25px;
      margin: 0 auto;
      @media screen and (max-width: 1280px) {
        height: 140px;
      }
      @media screen and (max-width: 992px) {
        height: 220px;
      }
      .background-img {
        width: 100%;
        height: 100%;
        border-radius: 25px;
        object-fit: scale-down;
      }
    }
    .card-name {
      color: #000;
      text-align: center;
      font-size: 20px;
      font-style: italic;
      font-weight: 400;
      font-family: "Roboto-Regular";
    }
  }
  .edit-profile-btn-main {
    margin-top: 50px;
    margin-right: 40px;
    @media screen and (max-width: 992px) {
      margin-right: 0;
    }
    .edit-profile-btn {
      width: 126px;
      height: 41px;
      font-family: "Roboto-Bold", sans-serif, "Helvetica";
      border-radius: 5px;
      background: #d9d9d9;
      border: none;
      font-size: 20px;
      font-weight: 400;
      font-style: italic;
      color: #000;
      &:hover {
        background: #d9d9d9;
        border: none;
      }
      &:focus {
        box-shadow: none;
      }
    }
  }
}
