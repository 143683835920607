.kn-base-bg {
  background: #272d2e;
  min-height: 100vh;
  color: white;
}

.kn-base-fold-one-bg {
  background-image: url("../../../imgs/desktop/background/kn-base-bg.png");
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .kb-fold-one-text-div {
    // padding: vw(232px) 0 0 vw(654px);
    text-align: center;
    // position: relative;
    .kb-logo {
      width: vw(610px);
      @media only screen and (max-width: 767px) {
        width: 220px;
      }
    }
    .kn-title {
      margin-left: 10%;
      margin-top: -2%;
      @media only screen and (max-width: 767px) {
        margin-left: 120px;
        font-size: 20px;
        position: unset;
      }
      @media only screen and (max-width: 525px) {
        margin-top: -15px;
        margin-left: 70px;
      }
    }
  }
  .kn-fold1-bottom-div {
    // margin-top: vw(298px);
    width: 100%;
    height: 110px;
    display: flex;
    justify-content: flex-end;
    .about-fold1-experience-div--kn {
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      color: black;
      padding-left: 35px;
      padding-right: 35px;
      @media only screen and (max-width: 1556px) {
        padding-left: 40px;
        padding-right: 20px;
      }
      h5 {
        font-size: 35px;
        font-family: Montserrat-bold;
        margin: 0px;
        @media only screen and (max-width: 1556px) {
          font-size: 30px;
        }
        @media only screen and (max-width: 900px) {
          font-size: 25px;
        }
        @media only screen and (max-width: 767px) {
          padding: 20px 0px;
          font-size: 20px;
        }
      }
    }
    .about-fold1-down-right-div-kn {
      display: flex;
      justify-content: center;
      align-items: center;
      // padding-left: 90px;
      // padding-right: 156px;
      background: #29294f;
      width: 70%;

      @media only screen and (max-width: 1556px) {
        padding-left: 40px;
        padding-right: 20px;
      }
      @media only screen and (max-width: 767px) {
        width: 100%;
      }

      h5 {
        font-size: 25px;
        font-family: Montserrat-regular;
        margin: 0px;
        line-height: 1.3;
        @media only screen and (max-width: 1556px) {
          font-size: 20px;
        }
        @media only screen and (max-width: 900px) {
          font-size: 17px;
        }
        @media only screen and (max-width: 767px) {
          padding: 20px 0px;
          font-size: 16px;
        }
      }
    }
    @media only screen and (max-width: 767px) {
      display: block;
      height: auto;
    }
  }
  @media only screen and (max-width: 575px) {
    height: 65vh;
  }
}

.kn-fold2-div {
  padding-top: vw(135.09px);

  .kn-fold2-div__search-row {
    justify-content: center;
  }
}

.kb-input-div {
  width: vw(618.5px);
  height: vw(72px);
  position: relative;
  display: flex;
  input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: vw(4px) solid #ffffff;
    box-sizing: border-box;
    border-radius: vw(797.574px);
    //padding: 0 vw(30px) 0 vw(120px);
    padding: 0 vw(30px) 0 vw(80px);
    font-size: vw(25px);
    line-height: vw(30px);
    color: #c4c4c4;
    //@include fontMontserratBold;
    @include fontRobotoBold;
    @media only screen and (max-width: 900px) {
      font-size: 15px;
      line-height: 1.5;
      padding-left: 40px;
    }

    &::placeholder {
      font-size: vw(25px);
      line-height: vw(30px);
      color: #c4c4c4;
      //@include fontMontserratRegular;
      @include fontRobotoRegular;
      @media only screen and (max-width: 900px) {
        font-size: 15px;
        line-height: 1.5;
      }
    }
  }
  @media only screen and (max-width: 900px) {
    width: 50%;
    height: 40px;
  }
  @media only screen and (max-width: 575px) {
    width: 90%;
    height: 40px;
  }
}
.search-btn {
  position: absolute;
  background: transparent;
  color: white;
  //@include fontMontserratRegular;
  //left: vw(30px);
  left: vw(15px);
  //top: vw(20px);
  top: vw(10px);
  @media only screen and (max-width: 900px) {
    top: 10px !important;
    left: 10px;
  }
  i {
    //@include fontRobotoRegular;
    font-style: normal;
    font-size: vw(30px) !important;
  }
  .search-img {
    width: vw(54px);
    height: auto;
    cursor: pointer;
    @media only screen and (max-width: 900px) {
      width: 20px !important;
    }
  }
}
.kb-card-img-block {
  // width: vw(502.38px);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: auto !important;
  height: vw(372.81px);
  border-radius: vw(11px) vw(11px) 0 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
  }
  @media only screen and (max-width: 900px) {
    width: 100% !important;
    height: 220px;
  }
  @media only screen and (max-width: 575px) {
    height: 354px;
  }
}
.kb-card {
  color: black;
  //   margin-right: vw(40.34px);
  margin-bottom: vw(75.61px);
  @media only screen and (max-width: 575px) {
    width: 100%;
    margin-right: 0px;
  }
}
.font-15-montserrat-regular {
  font-size: vw(15px);
  margin-bottom: 0;
  line-height: vw(18.29px);
  //@include fontMontserratRegular;
  @include fontRobotoRegular;
}
.kb-card-text-div {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 15px 10px 15px 10px;
  background: white;
  border-radius: 0 0 vw(20px) vw(20px);
  min-height: vw(125.9px);
  // width: vw(502.38px);
  width: auto !important;
  @media only screen and (max-width: 900px) {
    width: 100% !important;
    padding: 13px;
    .font-25-montserrat-bold {
      font-size: 15px;
      line-height: 1.5;
      // min-height: 45px;
    }
    .font-15-montserrat-regular {
      font-size: 12px;
      line-height: 1.3;
      min-height: 40px;
    }
  }
  @media only screen and (max-width: 600px) {
    .font-15-montserrat-regular {
      min-height: 50px;
    }
  }
}
.kb-card-div {
  padding-top: vw(129px);
  padding-left: 100px;
  padding-right: 100px;
  @media only screen and (max-width: 900px) {
    padding: 40px;
  }
  @media only screen and (max-width: 575px) {
    padding: 15px;
    margin-top: 30px;
  }
  .no-data-found{
    text-align: center;
    margin-top: 100px;
    font-weight: 700;
    font-size: 24px;
  }
}
.start-btn {
  width: vw(485.17px);
  display: flex;
  height: vw(95.62px);
  align-items: center;
  justify-content: center;
  background: white;
  color: black;
  @media only screen and (max-width: 767px) {
    width: auto;
    height: auto;
    span {
      padding: 10px 15px;
      font-size: 13px;
      line-height: 1.3;
    }
  }
}
.start-btn-div {
  padding-top: vw(50px);
  padding-bottom: vw(114.99px);
  @media only screen and (max-width: 767px) {
    padding-bottom: 50px;
  }
}

.kn-fold2-div--webApp {
  padding-top: vw(92px);
  padding-left: vw(84px);
  padding-right: vw(84px);
  @media only screen and (max-width: 767px) {
    padding-left: 15px;
    padding-right: 15px;
  }

  .kb-card-img-block,
  .kb-card-text-div {
    width: vw(473px);
  }

  .kn-fold2-div__search-row {
    justify-content: left;
    @media only screen and (max-width: 900px) {
      justify-content: center;
    }
  }

  .kb-card-div {
    padding-top: vw(110px);
    padding-left: 0;
    padding-right: 0px;
  }

  .kb-input-div {
    input {
      border: none;
      background-color: #e9e9eb;
      color: #000000;
    }
  }

  .search-btn {
    top: vw(16px);
    @media only screen and (max-width: 525px) {
      top: 10px;
      left: 10px;
    }
  }

  .search-btn {
    .search-img {
      width: vw(44px);
      @media only screen and (max-width: 900px) {
        width: 20px;
      }
    }
  }
}
