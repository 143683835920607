.mentor-bg {
  background-color: #ffffff;
  .without-btn {
    height: 60vh !important;
  }
  .background-image-mentor {
    background-image: url(../../../assets/imgs/desktop/background/mentor-background-image.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 70vh;
    position: relative;
    &::before {
      content: "";
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #000000b5;
    }
    .nav-bar-expand {
      z-index: 1;
      background-color: #ffffff;
      .mentor-header {
        color: #29294f;
        .navbar-nav {
          margin: 20px 0 0 20px;
          .log-in-btn {
          }
          .nav-link {
            .header-icon {
              @media only screen and (max-width: 991px) {
                display: none;
              }
            }
          }
        }
      }
      .header {
        width: 250px;
        height: auto;
        @media only screen and (max-width: 1024px) {
          width: 150px;
        }
      }
      .navbar-main-wrap {
        align-items: baseline;
        flex-direction: row;
        display: flex;
        @media only screen and (max-width: 786px) {
          align-items: baseline;
        }
        .navbar-main {
          .signup-btn-main {
            @media only screen and (max-width: 991px) {
              align-items: flex-start;
              margin: 0 0 0 20px;
            }
            .nav-link {
              color: #29294f !important;
            }
          }
          .login-profile {
            position: relative;
            .mentor-profile-picture {
              width: 45px;
              height: 45px;
              border-radius: 50%;
              border: 1px solid #ffffff;
              object-fit: cover;
              cursor: pointer;
              @media only screen and (max-width: 991px) {
                display: none;
              }
            }
            // .sign-out-btn {
            //   position: absolute;
            //   bottom: -28px;
            //   left: -32px;
            //   overflow: hidden;
            //   width: 110px;
            //   right: 0;
            //   top: 50px;
            //   background: #29294e;
            //   height: 81px;
            //   border-radius: 6px;
            //   .log-out {
            //     background-color: transparent;
            //     font-size: 14px;
            //     white-space: nowrap;
            //     letter-spacing: 0.6px;
            //     line-height: 1.5;
            //     color: #fff;
            //     font-weight: 500;
            //     border-radius: 6px;
            //     font-family: "Roboto-Regular";
            //     padding: 10px 15px;
            //     i {
            //       font-size: 16px;
            //       padding-right: 8px;
            //     }
            //   }
            //   .user-profile {
            //     background-color: transparent;
            //     font-size: 14px;
            //     color: #ffffff !important;
            //     white-space: nowrap;
            //     letter-spacing: 0.6px;
            //     line-height: 1.5;
            //     font-weight: 500;
            //     border-radius: 6px;
            //     font-family: "Roboto-Regular";
            //     padding: 10px 15px;
            //     width: 100%;
            //     display: inline-block;
            //     i {
            //       padding-right: 12px;
            //       color: #fff;
            //     }
            //   }
            // }
          }
          @media only screen and (max-width: 786px) {
            display: block !important;
          }
        }
      }
      .navbar-main {
        @media only screen and (max-width: 992px) {
          flex-direction: column;
        }
      }
    }
    .nav-bar {
      position: unset !important;
      .log-in-btn-main {
        height: 40px;
        width: 50%;
        font-size: 18px;
        line-height: 1.1;
        border-radius: 6px;
        padding: 12px;
        color: #ffffff !important;
        letter-spacing: 1px;
        background-color: transparent;
        text-align: center;
        font-size: 14px;
        margin: 0 20px 0 0;
        @media only screen and (min-width: 1024) {
          margin: 0;
        }
        &::after {
          position: unset;
          background-color: transparent;
        }
        &:hover {
          // padding: 10px;
          border: 2px solid #fff;
          transition: background-color 0.3s ease, box-shadow 0.3s ease;
          // width: 90px;
          background-color: transparent;
          color: #fff !important;
        }
      }
      .sign-up-btn-main {
        height: 40px;
        width: 50%;
        font-size: 18px;
        line-height: 1.1;
        border-radius: 6px;
        padding: 7px;
        color: #292950 !important;
        letter-spacing: 1px;
        background-color: #fff;
        text-align: center;
        font-size: 14px;
        margin: 0;
        @media only screen and (max-width: 1024px) {
          width: 200px;
        }
        &::after {
          position: unset;
          background-color: transparent;
        }
        &:hover {
          // padding: 10px;
          border: 2px solid #fff;
          transition: background-color 0.3s ease, box-shadow 0.3s ease;
          // width: 90px;
          background-color: transparent;
          color: #fff !important;
        }
      }
      .navbar-main-wrap {
        flex-direction: row;
        display: flex;
        @media only screen and (max-width: 786px) {
          align-items: baseline;
        }
        .navbar-main {
          z-index: 20;
          @media only screen and (max-width: 1024px) and (min-width: 992px) {
            width: 100%;
          }
          @media only screen and (min-width: 1025px) {
            width: 100%;
          }
          @media only screen and (max-width: 992px) {
            flex-direction: column;
          }
          .login-profile {
            .mentor-profile-picture {
              width: 45px;
              border: 1px solid #ffffff;
              border-radius: 50%;
              height: 45px;
              object-fit: cover;
              cursor: pointer;
              @media only screen and (max-width: 991px) {
                display: none;
              }
            }
            .mentor-profile-picture--text {
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #ffffff;
              color: #29294f;
              font-size: 30px;
            }
          }
          @media only screen and (max-width: 786px) {
            display: block !important;
          }
        }
      }
      .mentor-header {
        .signup-btn-main {
          width: auto;
          gap: 0;
          .nav-link {
            font-size: 16px;
            line-height: 1.5;
            padding: 8px;
            border-radius: 4px;
            text-align: center;
          }
        }
      }
      .navbar-toggler {
        z-index: 20;
        border-color: #ffffff;
      }
      .navbar-brand {
        .header {
          width: 250px;
          height: auto;
          @media only screen and (max-width: 1024px) {
            width: 150px;
          }
        }
      }
      .mentor-header {
        margin-top: 8px;
        padding: 0;
        color: #ffffff;
        justify-content: center;
        .home-page-navbar {
          gap: 30px;
          align-items: flex-start;
          margin: 15px 0 0 0;
          @media only screen and (max-width: 1024px) {
            gap: 15px;
          }
        }
        .login-profile {
          position: relative;
          .mentor-profile-picture {
            width: 45px;
            height: 45px;
            border-radius: 50%;
            border: 1px solid #ffffff;
            object-fit: cover;
            cursor: pointer;
            @media only screen and (max-width: 991px) {
              display: none;
            }
          }
          .sign-out-btn {
            position: absolute;
            bottom: -28px;
            left: -55px;
            overflow: hidden;
            width: 120px;
            right: 0;
            top: 55px;
            background: #ffffff;
            height: 81px;
            border-radius: 6px;
            .log-out {
              background-color: transparent;
              font-size: 14px;
              white-space: nowrap;
              letter-spacing: 0.6px;
              line-height: 1.5;
              color: #29294f;
              font-weight: 500;
              border-radius: 6px;
              font-family: "Roboto-Regular";
              padding: 10px 15px;
              i {
                font-size: 16px;
                padding-right: 8px;
              }
            }
            .user-profile {
              background-color: transparent;
              font-size: 14px;
              color: #29294f !important;
              white-space: nowrap;
              letter-spacing: 0.6px;
              line-height: 1.5;
              font-weight: 500;
              border-radius: 6px;
              font-family: "Roboto-Regular";
              padding: 10px 15px;
              width: 100%;
              display: inline-block;
              i {
                padding-right: 12px;
                color: #29294f;
              }
            }
          }
        }
        .navbar-nav {
          .nav-link {
            display: flex;
            margin: 0;
            padding: 0;
            font-weight: 500;
            font-size: 18px;
            text-transform: capitalize;
            line-height: 20px;
            text-align: justify;
            letter-spacing: 1px;
            &::after {
              display: none;
            }
            .header-icon {
              width: 16px;
              height: auto;
              @media only screen and (max-width: 991px) {
                display: none;
              }
            }
          }
        }
      }
    }
    .mentor-main {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      margin-top: 50px;
      @media only screen and (max-width: 575px) {
        margin-top: 0;
        gap: 20px;
      }
      .mentor-sub-title {
        .mentor-title {
          font-weight: 700;
          font-size: 40px;
          line-height: 49px;
          text-align: center;
          color: #ffffff;
          margin-bottom: 30px;
          text-transform: capitalize;
          @media only screen and (max-width: 1024px) {
            width: 80%;
            margin: 0 auto 15px auto;
          }
          @media only screen and (max-width: 575px) {
            font-size: 35px;
            width: 100%;
          }
        }
        .mentor-sub {
          width: 86%;
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
          color: #ffffff;
          margin: auto auto 30px auto;
          @media only screen and (max-width: 1024px) {
            width: 60%;
            margin: 30px auto 30px auto;
          }
          @media only screen and (max-width: 575px) {
            width: 90%;
            font-size: 16px;
            margin: 0 auto 25px auto;
          }
        }
      }
      .sign-in-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
        width: 317px;
        height: 56px;
        color: #29294f !important;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        margin-right: 10px;
        background-color: #ffffff;
        &:hover {
          transition: 0.5s;
          color: #fff !important;
          background-color: transparent;
          border: 1px solid #ffffff;
        }
        @media only screen and (max-width: 575px) {
          width: 180px;
          font-size: 14px;
          height: 45px;
        }
      }
      .demo-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 30px;
        width: 317px;
        height: 56px;
        color: #29294f !important;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        background-color: #ffffff;
        text-transform: capitalize;
        margin-left: 10px;
        &:hover {
          color: #fff !important;
          transition: 0.3s;
          background-color: transparent;
          border: 1px solid #ffffff;
        }
        @media only screen and (max-width: 575px) {
          width: 270px;
          font-size: 14px;
          height: 45px;
        }
      }
    }
  }
  .mentor-section-wrapper {
    background-color: #ffffff;
    color: #000;
    margin-bottom: 30px;
    @media only screen and (max-width: 575px) {
      text-align: center;
    }
    .mentor-section-main {
      margin: 0 75px;
      @media only screen and (max-width: 1024px) {
        margin: 0;
      }
      .mentor-section {
        position: relative;
        padding-top: 17px;
        .mentorship-wrap {
          position: relative;
        }
        .vl-mentorship-select {
          position: absolute;
          top: 30px;
          left: 0;
          z-index: 10000;
          margin: 40px 0 0 0;
          width: 430px;
          height: 100%;
          .vl-select__control {
            border-color: #29294f;
          }
          .vl-select__indicator-separator {
            background-color: #29294f;
          }
          .vl-select__placeholder {
            font-size: 14px;
          }
          label {
            background-color: #ffffff;
          }
          @media only screen and (max-width: 475px) {
            top: 60px;
          }
        }
        @media only screen and (max-width: 1024px) {
          padding: 0;
          margin-top: 15px;
        }
        .mentor-title {
          color: #000;
          font-size: 40px;
          font-weight: bold;
          line-height: 29px;
          text-align: left;
          margin-bottom: 57px;
          padding-top: 75px;
          @media only screen and (max-width: 1024px) {
            padding-top: 40px;
            margin-bottom: 40px;
          }
        }
        .mentor-searchBar {
          border-bottom: 1px solid #d7dee9;
          padding: 0 0 5px 15px;
          i {
            font-size: 22px;
            color: #d7dee9;
          }
          .input-searchBar {
            border: none;
            margin-left: 20px;
            &::placeholder {
              color: #000;
              font-size: 14px;
              font-weight: 400;
              line-height: 16px;
            }
          }
        }
        .skills-btn {
          border: 2px solid #29294f;
          padding: 8px 16px;
          border-radius: 12px;
          font-size: 14px;
          line-height: 17px;
          margin-right: 14px;
          color: #000;
          @media only screen and (max-width: 1298px) {
            margin: 0 14px 14px 0;
          }
          .dropdown-icon {
            margin-left: 15px;
          }
        }
        .skill-details,
        .industry-details,
        .location-details,
        // .software-details,
        // .price-details,
        // .time-details,
        // .days-details,
        // .language-details,
        // .country-details,
        .company-details {
          box-shadow: 0 14px 33px #00000040, 0 -2px 8px #00000038;
          border-radius: 18px;
          padding: 18px;
          margin-top: 15px;
          width: 35%;
          position: absolute;
          z-index: 10000000;
          background-color: #ffffff;
          .skills-title {
            color: #000;
            text-align: left;
            font-size: 16px;
            text-transform: uppercase;
            font-weight: 600;
            line-height: 17px;
            margin-bottom: 12px;
          }
          .search-area {
            .searchBar {
              padding: 10px;
              border-radius: 6px;
              background-color: #ced7e080;
              border: none;
              margin-bottom: 20px;
              &::placeholder {
                font-size: 14px;
                font-weight: 400;
                line-height: 14px;
                color: #000;
              }
            }
            ul {
              list-style-type: none;
              margin: 0;
              li {
                label {
                  display: flex;
                  align-items: baseline;
                }
                span {
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 15px;
                  color: #000;
                  margin-left: 8px;
                }
              }
            }
          }
        }
        .skill-details {
          left: 0;
          @media only screen and (max-width: 1024px) and (min-width: 991px) {
            width: 80%;
          }
          @media only screen and (max-width: 1024px) {
            left: 0px;
          }
          @media only screen and (max-width: 992px) {
            width: 100%;
          }
        }
        .company-details {
          left: 308px;
          @media only screen and (max-width: 1024px) and (min-width: 991px) {
            width: 80%;
          }
          @media only screen and (max-width: 1024px) {
            left: 0;
            width: 80%;
          }
          @media only screen and (max-width: 992px) {
            width: 100%;
          }
        }
        .industry-details {
          left: 0;
          @media only screen and (max-width: 1024px) and (min-width: 991px) {
            width: 80%;
          }
          @media only screen and (max-width: 1024px) {
            left: 0px;
            width: 80%;
          }
          @media only screen and (max-width: 992px) {
            width: 100%;
          }
        }
        .location-details {
          left: 175px;
          @media only screen and (max-width: 1024px) and (min-width: 991px) {
            width: 80%;
          }
          @media only screen and (max-width: 1024px) {
            left: 0px;
            width: 80%;
          }
          @media only screen and (max-width: 992px) {
            width: 100%;
          }
        }
        // .software-details {
        //   left: 98px;
        //   @media only screen and (max-width: 1024px) and (min-width: 991px) {
        //     width: 80%;
        //   }
        //   @media only screen and (max-width: 1024px) {
        //     left: 0px;
        //     width: 80%;
        //   }
        //   @media only screen and (max-width: 992px) {
        //     width: 100%;
        //   }
        // }
        // .price-details {
        //   left: 338px;
        //   @media only screen and (max-width: 1024px) and (min-width: 991px) {
        //     width: 80%;
        //   }
        //   @media only screen and (max-width: 1024px) {
        //     left: 0px;
        //     width: 80%;
        //   }
        //   @media only screen and (max-width: 992px) {
        //     width: 100%;
        //   }
        // }
        // .time-details {
        //   left: 437px;
        //   @media only screen and (max-width: 1024px) and (min-width: 991px) {
        //     width: 80%;
        //   }
        //   @media only screen and (max-width: 1024px) {
        //     left: 0px;
        //     width: 80%;
        //   }
        //   @media only screen and (max-width: 992px) {
        //     width: 100%;
        //   }
        // }
        // .days-details {
        //   left: 594px;
        //   @media only screen and (max-width: 1024px) and (min-width: 991px) {
        //     width: 80%;
        //   }
        //   @media only screen and (max-width: 1024px) {
        //     left: 0px;
        //     width: 80%;
        //   }
        //   @media only screen and (max-width: 992px) {
        //     width: 100%;
        //   }
        // }
        // .language-details {
        //   left: 745px;
        //   @media only screen and (max-width: 1024px) and (min-width: 991px) {
        //     width: 80%;
        //   }
        //   @media only screen and (max-width: 1024px) {
        //     left: 0px;
        //     width: 80%;
        //   }
        //   @media only screen and (max-width: 992px) {
        //     width: 100%;
        //   }
        // }
        // .country-details {
        //   right: 258px;
        //   @media only screen and (max-width: 1024px) and (min-width: 991px) {
        //     width: 80%;
        //   }
        //   @media only screen and (max-width: 1024px) {
        //     left: 0px;
        //     width: 80%;
        //   }
        //   @media only screen and (max-width: 992px) {
        //     width: 100%;
        //   }
        // }
      }
      .mentor-main {
        .mentors-number {
          margin: 46px 0;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #000;
        }
        .filter-card {
          border: 1px solid #d9d9d9;
          border-radius: 5px;
          .rectangle-img {
            width: 10px;
            height: auto;
          }
          .filter-name,
          .filter-select {
            background-color: #fff;
            border-radius: 5px;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            color: #535353;
            &:focus-visible {
              outline: none;
            }
            option {
              background-color: transparent;
            }
          }
        }
        .mentor-card {
          .view-more-btn {
            color: #ffffff;
            background-color: #29294f;
            font-size: 18px;
            line-height: 21px;
            font-weight: 500;
            width: 156px;
            height: 48px;
            border-radius: 12px;
            @media only screen and (max-width: 1024px) {
              width: 130px;
              height: 40px;
              font-size: 16px;
              border-radius: 8px;
            }
          }
          .view-more-btn-none {
            display: none;
          }
          .card {
            border: none;
            position: relative;
            background-color: #ffffff;
            border-radius: 10px;
            overflow: hidden;
            margin: 0 24px 48px 24px;
            min-height: 450px;
            @media only screen and (max-width: 3000px) and (min-width: 2198px) {
              width: 430px;
            }
            @media only screen and (max-width: 1024px) and (min-width: 400px) {
              margin: 0 auto 18px;
              width: 100%;
            }
            @media only screen and (max-width: 575px) and (min-width: 435px) {
              min-height: 400px;
              width: 400px;
              margin: 0 auto 18px;
            }
            .card-wrap {
              position: relative;
              &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                height: 225px;
                background: linear-gradient(
                  360deg,
                  rgba(0, 0, 0, 0.4117647059) 0.96%,
                  rgba(0, 0, 0, 0.4117647059) 26.56%,
                  rgba(0, 0, 0, 0.4117647059) 65.56%,
                  rgba(0, 0, 0, 0) 96.65%
                );
                z-index: 0;
                width: 100%;
              }
            }
            .mentor-image {
              width: 100%;
              max-width: 100%;
              height: 450px;
              object-fit: cover;
              position: relative;
              @media only screen and (max-width: 575px) {
                height: 450px;
              }
            }

            .mentorSub {
              color: #ffffff;
              position: absolute;
              left: 0;
              right: 0;
              bottom: 12px;
              z-index: 1;
            }
            .mentor-name {
              font-size: 26px;
              font-weight: 600;
              line-height: 21px;
              margin-bottom: 15px;
            }
            .mentor-post {
              font-size: 17px;
              line-height: 20px;
              font-weight: 400;
              width: 80%;
              margin: auto;
            }
          }
          .no-record-found {
            font-size: 18px;
            font-weight: 700;
          }
        }
      }
    }
  }
  .mentor-card-main {
    background-color: #fff;
    height: 100vh;
    width: 100%;
    color: black;
    font-family: "Roboto-regular";
    overflow: auto;
    margin-bottom: 0;
    padding: 0;
    .mentor-section-main {
      margin: 60px 36px 60px 317px;
      @media screen and (max-width: 900px) {
        margin: 60px 36px;
      }
      @media screen and (max-width: 768px) {
        margin: 20px 26px;
      }
      .mentor-section {
        .mentor-title {
          color: #000;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          padding-top: 0;
          margin-bottom: 20px;
        }
      }
      .mentor-main {
        .mentor-card {
          .card {
            height: 290px;
            min-height: 290px;
            margin: 0;
            @media screen and (min-width: 1680px) {
              height: 450px;
              min-height: 450px;
            }
            .card-wrap {
              .mentor-image {
                width: 100%;
                height: 290px;
                @media screen and (min-width: 1680px) {
                  height: 450px;
                }
              }
            }
          }
        }
      }
    }
    .mentor_apply_footer {
      margin-left: 317px;
      @media screen and (max-width: 900px) {
        margin: 0;
      }
    }
  }
  .footer-wrap-mentor {
    padding-bottom: 75px;
    @media only screen and (max-width: 1024px) {
      padding-bottom: 15px;
    }
    @media only screen and (max-width: 768px) {
      padding: 0;
    }
    .vl-footer-bg {
      color: #ffffff;
      border-radius: 55px;
      margin: 0 75px;
      background: #29294f;
      padding: 3.2291666667vw 4.1666666667vw 2.34375vw 6.3020833333vw;
      box-shadow: 2px 4px 20px 7px rgb(0 0 0 / 38%);
      @media only screen and (max-width: 1024px) {
        margin: 0 15px;
      }
      @media only screen and (max-width: 768px) {
        margin: 0;
        border-radius: 0;
      }
      .ft-menu {
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        color: #fff;
        white-space: nowrap;
        @media only screen and (max-width: 1024px) {
          font-size: 15px;
        }
        h5 {
          font-size: 20px;
          line-height: 1.5;
          color: #ffffff;
          text-align: start;
          @media only screen and (max-width: 1024px) {
            font-size: 15px;
          }
        }
      }
    }
  }

  .mentor-flex {
    display: flex;
  }
}
