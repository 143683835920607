/* ===============================================================
                    
                    typography Nunito

================================================================== */

/* ===============================================================
                            roboto
================================================================== */
@font-face {
  font-family: Roboto-Italic;
  src: url("../fonts/roboto/Roboto-Italic.ttf");
}

@font-face {
  font-family: Roboto-Light;
  src: url("../fonts/roboto/Roboto-Light.ttf");
}
@font-face {
  font-family: Roboto-Regular;
  src: url("../fonts/roboto/Roboto-Regular.ttf");
}
@font-face {
  font-family: Roboto-Medium;
  src: url("../fonts/roboto/Roboto-Medium.ttf");
}
@font-face {
  font-family: Roboto-Bold;
  src: url("../fonts/roboto/Roboto-Bold.ttf");
}
@font-face {
  font-family: Roboto-Black;
  src: url("../fonts/roboto/Roboto-Black.ttf");
}

/*=================================================================
                         Montserrat 
====================================================================*/
@font-face {
  font-family: Montserrat-Italic;
  src: url("../fonts/montserrat/Montserrat-Italic.ttf");
}

@font-face {
  font-family: Montserrat-Regular;
  src: url("../fonts/montserrat/Montserrat-Regular.ttf");
}
@font-face {
  font-family: Montserrat-Medium;
  src: url("../fonts/montserrat/Montserrat-Medium.ttf");
}
@font-face {
  font-family: Montserrat-SemiBold;
  src: url("../fonts/montserrat/Montserrat-SemiBold.ttf");
}
@font-face {
  font-family: Montserrat-Bold;
  src: url("../fonts/montserrat/Montserrat-Bold.ttf");
}
@font-face {
  font-family: Montserrat-ExtraBold;
  src: url("../fonts/montserrat/Montserrat-ExtraBold.ttf");
}
@font-face {
  font-family: Montserrat-Black;
  src: url("../fonts/montserrat/Montserrat-Black.ttf");
}

/*=================================================================
                         DM Serif Display
====================================================================*/
@font-face {
  font-family: DMSerifDisplay-Regular;
  src: url("../fonts/dm-serif-display/DMSerifDisplay-Regular.ttf");
}
/*=================================================================
                         Open Sans
====================================================================*/
@font-face {
  font-family: OpenSans-Regular;
  src: url("../fonts/open-sans/OpenSans-Regular.ttf");
}
/*=================================================================
                         Playfair Display
====================================================================*/
@font-face {
  font-family: PlayfairDisplay-Bold;
  src: url("../fonts/playfair-display/PlayfairDisplay-Bold.ttf");
}
/*=================================================================
                         Lora
====================================================================*/
@font-face {
  font-family: Lora-Regular;
  src: url("../fonts/lora/Lora-Regular.ttf");
}
