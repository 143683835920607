.vl-invest-main-page {
  padding-top: vw(126px);
  .react-tabs {
    .react-tabs {
      @media only screen and (max-width: 900px) {
        .invest-calcultor-font-25-medium {
          font-size: 15px;
          line-height: 1.5;
        }
        .invest-calcultor-font-20-regular {
          font-size: 13px;
          line-height: 1.5;
        }
        .invest-calcultor-font-16-regular {
          font-size: 13px;
          line-height: 1.5;
          margin-bottom: 30px;
        }
        .invest-types {
          margin-bottom: 20px;
          .invest-preferences-font-24-roboto {
            margin: 0px;
          }
          .vl-form-input--bordered-select-div {
            margin-bottom: 0px !important;
          }
        }
        .investment-text-line {
          font-size: 13px;
          line-height: 1;
        }
      }
      @media only screen and (max-width: 900px) {
        padding: 0px;
        margin-top: 20px;
        .react-tabs__tab-list {
          margin-bottom: 30px;
        }
      }
    }
    .react-tabs__tab-list {
      .react-tabs__tab {
        @media only screen and (max-width: 900px) {
          font-size: 15px;
          line-height: 1.5;
          width: auto;
          padding: 7px 10px;
        }
        @media only screen and (max-width: 425px) {
          font-size: 12px;
          padding: 10px 12px;
        }
      }
      @media only screen and (max-width: 767px) {
        margin: 0px;
      }
    }

    @media only screen and (max-width: 767px) {
      padding: 15px;
    }
  }
  .invest-personal-tab-panel--calculator {
    .vl-form-input {
      @media only screen and (max-width: 900px) {
        max-width: 100%;
        label {
          font-size: 13px;
        }
        .input-border-div,
        .vl-select-container {
          height: 50px;
          .vl-select__value-container {
            line-height: 1.5;
            font-size: 13px;
          }
          input {
            font-size: 13px;
            line-height: 1.5;
          }
        }
      }
    }
    .vl-form-input--bordered-select-div {
      @media only screen and (max-width: 900px) {
        margin-bottom: 50px;
        height: auto !important;
      }
    }
  }
}
.revenue-stage {
  margin-top: 30px;
  margin-bottom: 30px;
  h5 {
    margin-bottom: 0px;
    margin-right: 92px;
    @media only screen and (max-width: 900px) {
      margin-right: 20px;
    }
    @media only screen and (max-width: 525px) {
      margin-right: 0px;
      margin-bottom: 20px;
    }
  }
}
.revenue_space {
  margin-bottom: 46px;
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    margin-bottom: 65px;
    gap: 10px;
  }
  h5 {
    @media screen and (max-width: 768px) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .raise_capital_checkbox {
    @media screen and (max-width: 768px) {
      margin-top: 0 !important;
    }
    @media screen and (max-width: 576px) {
      gap: 10px;
    }
  }
  .custom_dropdown_wrap {
    @media screen and (max-width: 576px) {
      gap: 10px;
    }
  }
  .custom-radio-btn-block {
    @media screen and (max-width: 576px) {
      margin-right: 0;
    }
  }
}
.revenue_disable_space {
  @media screen and (max-width: 768px) {
    margin-top: 46px;
  }
}
.vl-tabs-explore--main-mentor-page-profile-tabs.vl-tabs-explore--main-mentor-page-profile-tabs--invest {
  .react-tabs__tab-list {
    margin-bottom: 53px;
    @media only screen and (max-width: 525px) {
      margin-bottom: 40px;
    }
  }

  .react-tabs__tab {
    width: vw(262px);
  }
}

.vl-invest-after-profile-setup-tabpanel-company {
  padding: vw(55px) vw(22px) vw(50px) vw(63px);
  @media only screen and (max-width: 767px) {
    padding: 15px;
  }
  @media only screen and (max-width: 425px) {
    padding: 0px;
    margin-top: 30px;
  }
}

.vl-invest-after-profile-setup-tabpanel-profile {
  padding: vw(72px) vw(22px) vw(80px) vw(66px);
  @media only screen and (max-width: 767px) {
    padding: 0px;
  }
}
.invest-updates-tab-panel {
  padding: vw(63px) vw(296px) vw(100px) vw(69px);
  @media only screen and (max-width: 767px) {
    padding: 0px;
    margin-top: 20px;
  }
}
.invest-update-tab-panel-text-1 {
  color: rgba(0, 0, 0, 0.86);
  margin-right: vw(14px);
  @media only screen and (max-width: 900px) {
    font-size: 15px;
    line-height: 1;
  }
}
.invest-update-tab-panel-text-2 {
  color: #000000;
  line-height: vw(30px);
  @media only screen and (max-width: 900px) {
    font-size: 12px;
    line-height: 1.3;
  }
}
.invest-update-tab-panel-text-3 {
  justify-self: flex-end;
  word-break: break-all;
}
.invest-update-tab-panel-text-4 {
  color: #000000;
  padding-top: vw(10px);
  padding-bottom: vw(25px);
}
.invest-update-date-time-div {
  display: flex;
  color: rgba(0, 0, 0, 0.46);
  @media only screen and (max-width: 900px) {
    span {
      font-size: 10px;
      line-height: 1;
    }
  }
}
.invest-update-tab-panel-text-5 {
  padding-right: vw(10px);
}
.invest-update-tab-panel-text-6 {
  padding-left: vw(10px);
}
.invest-updates-tab-panel-top-div {
  padding-top: vw(7px);
  padding-right: vw(80px);
  padding-bottom: vw(26px);
  @media only screen and (max-width: 767px) {
    padding-bottom: 10px;
  }
}
/*=============================================
           calculator
==============================================*/
.invest-after-profile-setup-calculator-div {
  padding-right: vw(260px);
  padding-bottom: vw(400px);
  justify-content: space-between;
  display: flex;
  align-items: flex-start;
}
.invest-after-profile-setup-calculator-col1 {
  padding-right: vw(160px);
  width: 63%;
}
.invest-after-profile-setup-calculator-col1-text1 {
  color: #323277;
  padding-bottom: vw(19px);
}
.invest-after-profile-setup-calculator-col1-text2 {
  color: #000000;
}
.invest-after-profile-setup-calculator-col1-row2 {
  padding-top: vw(70px);
}
.invest-after-profile-setup-calculator-col2 {
  width: vw(455px);
  height: auto;
  border: 1px solid #455051;
  box-sizing: border-box;
  @media only screen and (max-width: 900px) {
    width: 100%;
    margin-left: 15px;
  }
  @media only screen and (max-width: 767px) {
    margin-top: 30px;
    margin-left: 0px;
  }
  .invest-after-profile-setup-calculator-col2-row1 {
    width: 100%;
    color: white;
    background: #292950;
    border: 1px solid #455051;
    box-sizing: border-box;
    text-align: center;
    padding-top: vw(27px);
    padding-bottom: vw(20px);
    h4 {
      font-size: vw(28px);
      line-height: vw(32.81px);
      @include fontRobotoMedium;
      margin-bottom: 0;
      @media only screen and (max-width: 900px) {
        font-size: 15px;
        line-height: 1.5;
      }
    }
  }
  .invest-after-profile-setup-calculator-col2-row2 {
    padding: vw(46px) 0 vw(80px) vw(43px);
    .invest-after-profile-setup-calculator-col2-row2-div {
      border-left: vw(8px) solid #292950;
      padding: vw(5px) vw(5px) vw(5px) vw(30px);
      @media only screen and (max-width: 900px) {
        margin: 20px 0px;
        h4 {
          font-size: 14px;
          line-height: 1;
        }
      }
    }

    .invest-after-profile-setup-calculator-col2-row2-text3 {
      color: #292950;
      padding-top: vw(10px);
    }
    .invest-after-profile-setup-calculator-col2-row2-div--2 {
      margin-top: vw(74px);
    }
    .invest-after-profile-setup-calculator-col2-row2-div--3 {
      margin-top: vw(59px);
    }

    @media only screen and (max-width: 900px) {
      padding: 20px 40px 20px;
    }
    @media only screen and (max-width: 425px) {
      padding: 20px 20px 20px;
    }
  }
}

.invest-after-profile-setup-calculator-col2-row2-text1,
.invest-after-profile-setup-calculator-col2-row2-text2 {
  color: #272d2e;
  @media only screen and (max-width: 900px) {
    margin: 10px 0px;
  }
}

/*========================================================

                           Portfolio
                          
=========================================================*/
.invest-after-profile-setup-portfolio-div {
  padding: vw(80px) vw(26px) vw(25px) vw(32px);
  .vl-invest-after-setup-portfolio-invest-card-row {
    .invest-card {
      .explore-card-invest-top-div {
        width: 100%;
        span {
          @media only screen and (max-width: 1024px) {
            font-size: 15px;
            line-height: 1.5;
          }
        }
      }
      .explore-card {
        .tag-btn {
          @media only screen and (max-width: 1024px) {
            font-size: 15px;
            height: auto;
            line-height: 1;
            padding: 6px;
          }
          @media only screen and (max-width: 525px) {
            width: 55%;
          }
        }
        .explore-card-text-div {
          padding: 30px;
        }
        @media only screen and (max-width: 1024px) {
          .explore-card-text-div {
            padding: 15px;
            .font-22-dm-serif-regular {
              font-size: 15px;
              line-height: 1.5;
            }
            img {
              width: 13px;
              height: 15px;
            }
            h5 {
              font-size: 13px;
              margin: 0px;
              line-height: 1;
            }
            .explore-card-follow-btn {
              font-size: 12px;
              width: auto;
              padding: 5px;
              height: auto;
              i {
                font-size: 12px;
                margin-right: 5px;
              }
            }
            .explore-desc {
              font-size: 15px;
            }
            .percentage-text-div {
              width: auto;
              .percentage-text {
                font-size: 14px;
                line-height: 1;
              }
              .funds-text {
                font-size: 9px;
              }
            }
            .funds-amount-div {
              width: 62%;
              table {
                tbody {
                  tr {
                    td {
                      span {
                        font-size: 10px;
                        line-height: 1.5;
                      }
                    }
                  }
                }
              }
            }
            .explore-card-btns {
              a {
                .investbtn-card {
                  width: 100px;
                  height: 40px;
                  margin-top: 10px;
                  span {
                    font-size: 13px;
                  }
                }
              }
            }
          }
        }
        @media only screen and (max-width: 525px) {
          .explore-desc {
            min-height: 60px;
          }
        }

        @media only screen and (max-width: 1024px) {
          width: 100%;
        }
      }
      @media only screen and (max-width: 1024px) {
        width: 35%;
      }
      @media only screen and (max-width: 900px) {
        width: 50%;
      }
      @media only screen and (max-width: 575px) {
        width: 100%;
      }
      // width: 35%;
    }
  }
  @media only screen and (max-width: 767px) {
    padding: 0px;
  }
}
.invest-after-profile-setup-portfolio-inner-div {
  width: 100%;
  border: 1px solid #455051;
  box-sizing: border-box;
  padding: vw(61px) 0 vw(109px) 0;
}
.invest-after-profile-setup-portfolio-div-row-1 {
  width: vw(848px);
  height: auto;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 0 vw(60px) vw(24px) vw(100px);
  border-bottom: 1px solid rgba(69, 80, 81, 0.27);
}
.invest-after-profile-setup-portfolio-text-1 {
  color: rgba(39, 45, 46, 0.37);
}
.invest-after-profile-setup-portfolio-text-2 {
  color: #323277;
  padding-left: vw(12px);
}
.portfolio-card-div {
  width: vw(387px);
  min-height: vw(83px);
  background: rgba(90, 155, 165, 0.6);
  border-radius: vw(10px);
  color: white;
  padding: vw(21px) vw(18px) vw(20px) vw(18px);
  display: flex;
  align-items: flex-start;
  word-break: break-all;
  margin-bottom: vw(14px);
}
.invest-after-profile-setup-portfolio-div-row-2 {
  display: flex;
}
.invest-after-profile-setup-portfolio-div-row-2-col1 {
  width: 50%;
  padding: vw(84px) vw(187px) vw(80px) vw(93px);
  border-right: 1px solid rgba(69, 80, 81, 0.27);
}
.portfolio-card-img-div {
  width: vw(38px);
  height: vw(38px);
  background: #ffffff;
  border-radius: 50%;
  margin-right: vw(16px);
  margin-top: 1px;
}

.portfolio-card-text-div-1 {
  width: vw(214px);
}

.portfolio-card-text-div-1-text2 {
  font-size: vw(12px);
  line-height: vw(14.06px);
  margin-bottom: 0;
  @include fontRobotoRegular;
  padding-top: vw(7px);
}
.portfolio-card-text-div-2-text1 {
  font-size: vw(13px);
  line-height: vw(15.23px);
  @include fontRobotoRegular;
  padding-right: vw(8px);
  padding-top: vw(9px);
}
.invest-after-profile-setup-portfolio-div-row-2-col2 {
  margin: 0 auto vw(90px) auto;
  width: vw(582px);
  height: vw(582px);
  position: relative;
  @media only screen and (max-width: 575px) {
    width: 50%;
    height: auto;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
.invest-after-profile-setup-portfolio-chart-text-div {
  position: absolute;
  top: vw(255px);
  left: 35%;
  text-align: center;
  width: 33%;

  @media only screen and (max-width: 575px) {
    top: 40%;
  }
  // overflow: auto;
  // white-space: nowrap;

  .font-36-roboto-bold {
    color: #323277;
    line-height: 1.5;
    @media only screen and (max-width: 575px) {
      font-size: 15px;
    }
  }
  .font-24-roboto-medium {
    color: rgba(39, 45, 46, 0.37);
    padding-top: vw(7px);
    line-height: 1.5;
    @media only screen and (max-width: 767px) {
      font-size: 11px;
      margin-bottom: 20px;
    }
  }
}
/*============================================================
                      
                        Investor

============================================================*/
.vl-raise-capital-investor-table-heading,
.vl-raise-capital-investor-table {
  margin-right: vw(280px);
  padding-right: vw(16px);
}
.vl-raise-capital-investor-table {
  height: vw(300px);
  overflow: auto;
}
.vl-raise-capital-investor-table-heading,
.vl-raise-capital-investor-table {
  table {
    width: 100%;
  }

  tr {
    vertical-align: top;
    border-bottom: vw(2px) solid rgba(194, 203, 197, 0.4);
    min-height: vw(50px);
  }

  th {
    color: #111316;
    padding-bottom: vw(31px);
  }
  td {
    padding: vw(30px) vw(20px) vw(27px) 0;
    color: rgba(0, 0, 0, 0.5);
  }

  th,
  td {
    width: 20%;
    font-size: vw(16px);
    line-height: vw(18.75px);
    @include fontRobotoRegular;
  }
}
.vl-raise-capital-investors-card-div {
  width: vw(1083px);
  height: vw(532px);
  background: rgba(17, 19, 22, 0.85);
  border-radius: vw(30px);
  margin-bottom: vw(65px);
  padding: vw(51px) vw(113px) vw(40px) vw(139px);
  color: #6dbbc7;
}
.vl-raise-capital-investors-card-progresbar {
  width: vw(441px);
  height: auto;
  position: relative;
  margin-right: vw(61px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.vl-raise-capital-investors-card-progresbar-text-div {
  position: absolute;
  // left: vw(34px);
  // top: vw(34px);
  @include fontRobotoBold;

  // width: vw(300px);
  // height: vw(300px);
  // overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  h4 {
    font-size: vw(45px);
    line-height: vw(52.73px);
    margin: 0 auto;
    margin-bottom: vw(20px);

    width: vw(230px);
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }
  h5 {
    font-size: vw(26px);
    line-height: vw(30.47px);
    margin-bottom: 0;
  }
}
.vl-raise-capital-investors-card-right-text-div {
  text-align: center;
  padding: vw(15px) vw(70px);
}
.vl-raise-capital-investors-card-right-text1 {
  font-size: vw(36px);
  line-height: vw(42.19px);
  margin-bottom: 0;
  @include fontRobotoBold;
}
.vl-raise-capital-investors-card-right-text2 {
  font-size: vw(20px);
  line-height: vw(23.44px);
  @include fontRobotoMedium;
  margin-bottom: vw(30px);
  white-space: nowrap;
}

// vl-need-help-net-worth-block
.vl-net-worth-with-note-block {
  position: relative;
  width: vw(598px);
  @media only screen and (max-width: 900px) {
    width: 100%;
  }
}

.vl-need-help-net-worth-block {
  color: #292950;
  position: absolute;
  // top: vw(65px);
  right: 0;
  bottom: 0;

  a,
  span {
    display: block;
    @media only screen and (max-width: 900px) {
      font-size: 10px;
      line-height: 1.3;
    }
  }
}
