.vl-faq-web-app {
  .faq-second-div {
    padding: vw(44px) vw(203px) vw(152px) vw(92px);

    .accordion__button,
    .accordion__panel {
      color: $vl-web-app-pages-text-color;
    }

    .accordion .accordion__item {
      border-bottom-color: $vl-web-app-pages-text-color;
    }

    .accordion__panel {
      padding-right: vw(150px);
      @media only screen and (min-width: 1440px) {
        padding-right: 200px;
      }
      @media screen and (max-width: 1440px) and (min-width: 900px) {
        padding-right: 200px;
      }
    }
  }
}
