.forgot-password-font-18-medium {
  font-size: vw(18px);
  line-height: vw(21.09px);
  @include fontRobotoMedium;
  margin-bottom: 0;
  padding-top: vw(14px);
  color: rgba(255, 255, 255, 0.44);
}
.forgot-password-bg {
  background: rgba(62, 58, 101, 0.71);
  border-radius: vw(17px);
  transform: rotate(-0.11deg);
  width: vw(605px);
  min-height: vw(355px);
  text-align: center;
  padding: vw(38.49px) 0 vw(49.78px) 0;
  margin-top: vw(46px);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.forgot-password-font-18-medium--link-sent {
  padding-top: vw(20px);
}
.forgot-password-bg--link {
  margin-top: vw(97px);
  min-height: vw(189.56px);
  padding-top: vw(58px);
  padding-bottom: vw(63px);
  margin-bottom: vw(100px);
}

.forget-password-buttons {
  justify-content: space-between;
  display: flex;
  width: 79%;
  align-items: center;
  .sign-up-back-btn {
    margin-top: vw(30px);
  }
}
