// video
.vl-video-component {
  width: 100%;
  height: vw(554px);
  // height: auto;

  position: relative;

  video {
    width: 100%;
    display: block;
    margin-bottom: vw(-1px);
    height: inherit;
  }

  .player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.html5-video-player:not(.ytp-transparent),
.html5-video-player.unstarted-mode,
.html5-video-player.ad-showing,
.html5-video-player.ended-mode,
.html5-video-player.ytp-fullscreen {
  background-color: transparent !important;
}

// slider
.vl-financials-slider {
  margin-top: vw(20px);
  margin-bottom: vw(10px);
  padding: vw(35px) vw(52px);

  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-initialized .slick-slide div {
    height: 100%;
  }

  .slick-initialized .slick-slide div {
    display: block;
    height: 100%;
    width: 100% !important;
    text-align: center;
  }

  .slick-slide img {
    display: block;
    height: vw(400px) !important;
    width: vw(400px) !important;
  }

  .slick-prev:before,
  .slick-next:before {
    content: "" !important;
    width: vw(15px) !important;
    height: vw(15px) !important;
    border: 2px solid rgba(39, 45, 46, 0.84) !important;
    display: block !important;
    border-top: 0 !important;
    border-right: 0 !important;
  }

  .slick-prev {
    left: vw(-15px) !important;
  }
  .slick-next {
    right: vw(-23px) !important;
  }

  .slick-prev,
  .slick-next {
    top: 50% !important;
  }
  .slick-prev:before {
    transform: rotate(45deg);
  }
  .slick-next:before {
    transform: rotate(225deg);
  }

  .slick-prev:before,
  .slick-next:before {
    color: rgba(39, 45, 46, 0.84) !important;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: vw(30px) !important;
  }
}

// pitch deck modal
.vl-pitch-deck-file-modal-content-div {
  width: vw(800px);
  min-height: max-content;
  height: auto;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0 auto;
  margin-bottom: 0;
}

.vl-pdf-screen-prev-btn,
.vl-pdf-screen-next-btn {
  width: vw(30px);
  height: vw(30px);
  color: #ffffff;
  font-size: vw(30px);
  line-height: vw(30px);
  z-index: 9999;
  display: block;
  @media only screen and (max-width: 600px) {
    width: 15px;
    height: 15px;
  }
}

.vl-pdf-screen-prev-btn,
.vl-pdf-screen-next-btn {
  &:disabled {
    opacity: 0.5;
  }
}

.vl-pdf-screen-text {
  color: #f0f0f3;
  padding-left: vw(20px);
  padding-right: vw(20px);
  @media only screen and (max-width: 900px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}
