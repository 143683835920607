//mt
.mt-15 {
  margin-top: vw(15px);
}
.mt-46 {
  margin-top: vw(46px);
}
.mt-25 {
  margin-top: vw(25px);
}
.mt-26 {
  margin-top: vw(26px);
}
.mt-23 {
  margin-top: vw(23px);
}
.mt-28 {
  margin-top: vw(28px);
}
.mt-38 {
  margin-top: vw(38px);
}
.mt-45 {
  margin-top: vw(45px);
}
.mt-51 {
  margin-top: vw(51px);
}
.mt-89 {
  margin-top: vw(89px);
}
//pl
.pl-17 {
  padding-left: vw(17px);
}
.pl-30 {
  padding-left: vw(30px);
}
.pl-60 {
  padding-left: vw(60px);
}
//pt
.pt-6 {
  padding-top: vw(6px);
}
.pt-10 {
  padding-top: vw(6px);
}
.pt-20 {
  padding-top: vw(20px);
}
.pt-22 {
  padding-top: vw(22px);
}

.pt-28 {
  padding-top: vw(28px);
}
//.pt-31 {
//  padding-top: vw(31px);
//}

.pt-32 {
  padding-top: vw(32px);
}
.pt-36 {
  padding-top: vw(36px);
}
.pt-47 {
  padding-top: vw(47px);
}
.pt-50 {
  padding-top: vw(50px);
}
.pt-57 {
  padding-top: vw(57px);
}
.pt-62 {
  padding-top: vw(62px);
}

.pt-65 {
  padding-top: vw(65px);
}
.pt-74 {
  padding-top: vw(74px);
}
.pt-82 {
  padding-top: vw(82px);
}
.pt-90 {
  padding-top: vw(90px);
}
//pb
.pb-10 {
  padding-bottom: vw(10px);
}
.pb-22 {
  padding-bottom: vw(22px);
}
.pb-32 {
  padding-bottom: vw(32px);
}
.pb-70 {
  padding-bottom: vw(70px);
}
//mb
.mb-24 {
  margin-bottom: vw(24px);
}
//mb
.mt-30 {
  margin-top: vw(30px);
}
// color
.color-offwhite {
  color: rgba(255, 255, 255, 0.45);
}
.color-white {
  color: white;
}
.opacity-0 {
  opacity: 0;
}
.cursor-default {
  cursor: default;
}
