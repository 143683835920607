.vl-invest-payment-page-div {
  padding: vw(65px) vw(205px) vw(70px) vw(65px);

  @media only screen and (max-width: 900px) {
    padding: 15px;
  }
}

.vl-invest-payment-text-1 {
  font-size: vw(24px);
  line-height: vw(28px);
  @include fontRobotoMedium;
  margin-top: vw(25px);
  margin-bottom: vw(25px);

  @media only screen and (max-width: 900px) {
    font-size: 15px;
    line-height: 1.3;
    margin-bottom: 10px;
  }
}

.vl-invest-payment-text-main {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .vl-invest-payment-text-2 {
    font-size: vw(16px);
    line-height: vw(18px);
    //   @include fontRobotoRegular;
    @include fontRobotoMedium;
    margin-bottom: vw(10px);

    @media only screen and (max-width: 900px) {
      font-size: 12px;
      line-height: 1.3;
      margin-bottom: 20px;
    }
  }
  .payment-update-btn-div {
    button {
      min-width: 110px;
      min-height: 35px;
    }
    @media only screen and (max-width: 900px) {
      width: 100%;
    }
  }
}

// modal

#fa-invest-now-modal {
  color: #292950;
  background-color: #f0f0f3 !important;

  .modal-body,
  .loading-spinner {
    background-color: #f0f0f3 !important;
  }

  .ssl {
    display: none !important;
  }

  .modal-header {
    background-image: none !important;
    background-color: #292950 !important;
    color: #fff !important;
  }

  .close {
    color: #fff !important;
    opacity: 1 !important;
  }

  .modal-footer {
    background-image: none !important;
    background-color: #f0f0f3 !important;
    border-top: none !important;
  }

  .btn-primary {
    background-image: none !important;
    background-color: #292950 !important;
  }

  .btn-primary:hover {
    background-color: #292950 !important;
  }

  .help {
    background-image: none !important;
    background-color: #292950 !important;
    box-shadow: none !important;
    color: #fff !important;
  }

  .btn-default {
    background-image: none !important;
  }

  .alert-danger {
    background-image: none !important;
  }

  .input-group-addon {
    color: #ffffff !important;
    background-color: #292950 !important;
  }
}
.react-responsive-modal-root {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  .investOverlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }
  .react-responsive-modal-container {
    height: 100%;
    outline: 0;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: center;
    .model-invest-text {
      margin: 14px 0 8px 0;
      text-align: center;
      font-size: 26px;
      color: #000000;
      b {
        color: #292950;
      }
      @media only screen and (max-width: 900px) {
        font-size: 20px;
        margin: 2px 0 6px 0;
      }
    }
    .model-invest {
      width: 65vw;
      @media only screen and (max-width: 900px) {
        width: 75vw;
      }
      @media only screen and (max-width: 500px) {
        width: 80vw;
      }
    }
    .investModal {
      width: auto;
      margin: 0;
      border-radius: 20px;
      max-width: fit-content;
      padding: 20px 20px 20px 20px !important;
      @media only screen and (min-width: 900px) {
        width: auto !important;
        max-width: 90% !important;
        border-radius: vw(30px) !important;
      }
      @media only screen and (max-width: 900px) {
        width: auto !important;
        max-width: 80% !important;
        border-radius: vw(30px) !important;
      }

      @media only screen and (max-width: 525px) {
        padding: 40px 10px 20px 10px !important;
        max-width: 90% !important;
        margin: auto;
        border-radius: vw(40px) !important;
      }
      .invest-model-close {
        padding: 16px;
        @media screen and (min-width: 1517px) {
          padding: 16px 30px 16px 16px;
        }
      }
    }
  }
}
//create-profile-model
.react-responsive-modal-root {
  .profileOverlay {
  }
  .react-responsive-modal-containerCenter {
    .create_invest-profile-model-main {
      min-width: auto;
      height: auto;
      border-radius: 20px;
      @media only screen and (max-width: 900px) {
        min-width: auto;
        height: auto;
      }
      .title-Sucess {
        font-size: 35px;
        margin-top: 25px;
        @media only screen and (max-width: 500px) {
          font-size: 22px;
        }
      }
      .create-profile-btn {
        padding: 14px;
        background-color: #292950;
        color: #ffffff;
        border-radius: 8px;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.2;
        white-space: nowrap;
        @media only screen and (max-width: 500px) {
          font-size: 14px;
        }
      }
      .cancel-btn {
        padding: 14px;
        background-color: #292950;
        color: #ffffff;
        border-radius: 8px;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.2;
        white-space: nowrap;
        @media only screen and (max-width: 500px) {
          font-size: 14px;
        }
      }
    }
  }
}
