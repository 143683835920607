.reset-password-title {
  font-size: vw(24px);
  line-height: vw(28.13px);
  @include fontRobotoBold;
  margin-bottom: 0;
}
.reset-password-bg {
  background: rgba(62, 58, 101, 0.71);
  border-radius: vw(17px);
  transform: rotate(-0.11deg);
  width: vw(605.31px);
  min-height: vw(421.33px);
  margin-top: vw(31px);
  text-align: center;
  padding: vw(46px) 0 vw(46.48px) 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.reset-password-bg--congratulations {
  min-height: vw(295.54px);

  .font-18-roboto-medium {
    color: rgba(255, 255, 255, 0.44);
  }
}
