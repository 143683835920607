.raise-capital-tags-arrow-btn {
  background: #000000;
  border-radius: vw(20px);
  color: white;
  width: vw(40px);
  height: vw(40px);
  font-size: vw(17px);
  margin-left: vw(15px);
  margin-bottom: vw(40px);
  @media only screen and (max-width: 768px) {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    font-size: 13px;
    margin-bottom: 27px;
  }
  @media only screen and (max-width: 430px) {
    margin: 0 0 30px 5px;
  }
}

.vl-all-tags-row {
  margin-bottom: vw(20px);
}

.vl-added-tags-text {
  margin-bottom: vw(23px);
  font-style: italic;
  @media only screen and (max-width: 900px) {
    font-size: 13px;
    line-height: 1.3;
    margin-bottom: 20px;
  }
}

.vl-added-tag-block {
  // width: vw(232px);
  width: auto;
  min-height: vw(45px);
  height: auto;
  word-break: break-all;
  color: #ffffff;
  background: #5a9ba5;
  border-radius: vw(39px);
  padding: vw(11px) vw(36px) vw(11px) vw(23px);
  margin-right: vw(20px) !important;
  margin-bottom: vw(20px);

  button {
    margin-left: vw(23px);
    padding: 0;
    color: #ffffff;
    background-color: transparent;
    padding-right: vw(5px);
    padding-left: vw(5px);
    font-size: vw(25px);
    line-height: vw(28px);
    @include fontRobotoBold;
    @media only screen and (max-width: 768px) {
      font-size: 13px;
      margin-left: 10px;
    }
  }
  p {
    margin-top: 0px;
    line-height: 1.3;
  }
  @media only screen and (max-width: 768px) {
    padding: 8px 20px;
    min-width: auto;
    width: auto;
    margin-bottom: 20px;
    p {
      font-size: 13px;
      line-height: 1.3;
      margin: 0;
    }
  }
}

.instruction_wrap {
  font-weight: 800;
  @media screen and (max-width: 1536px) {
    font-size: 12px;
  }
  @media screen and (min-width: 2000px) {
    font-size: 20px;
  }
}
