.vl-topnavbar-profile-rc-dropdown {
  pointer-events: unset !important;
  width: vw(296px);
  // top: vw(158px) !important;
  // left: vw(1610px) !important;
  @media only screen and (max-width: 1140px) {
    top: 100px !important;
  }
  @media only screen and (max-width: 900px) {
    top: 80px !important;
  }
  @media only screen and (max-width: 768px) {
    left: vw(800px) !important;
    width: vw(396px);
    top: 120px !important;
  }
  @media only screen and (max-width: 575px) {
    top: 105px !important;
    left: vw(1400px) !important;
    box-shadow: 0 0.2083333333vw 0.46875vw #3f343487;
    width: 24vw;
  }
  .profile-link-div {
    width: 100%;
    height: 100%;
  }

  .rc-dropdown-menu {
    margin-top: 0;
    margin-bottom: 0;
    background: #ffffff;
    box-shadow: 0 vw(4px) vw(9px) rgba(63, 52, 52, 0.25);
    border-radius: vw(7px);
    padding: vw(7px) vw(14px) vw(2px) vw(23px);
  }

  .rc-dropdown-menu,
  .rc-dropdown-menu.rc-dropdown-menu-root.rc-dropdown-menu-vertical {
    border: 0;
    z-index: 999;
  }

  .rc-dropdown-menu > .rc-dropdown-menu-item {
    color: rgba(0, 0, 0, 0.78);
    cursor: pointer !important;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 0;
    @include fontRobotoMedium;
    @media only screen and (max-width: 575px) {
      font-size: 13px;
      margin: 0 !important;
      padding: 4px !important;
    }
  }

  .rc-dropdown-menu > .rc-dropdown-menu-item {
    margin: vw(18px) vw(13px) vw(18px) vw(13px);
    padding: 0;
  }

  .rc-dropdown-menu > .rc-dropdown-menu-item:hover,
  .rc-dropdown-menu > .rc-dropdown-menu-item-active,
  .rc-dropdown-menu > .rc-dropdown-menu-item-selected {
    background-color: transparent;
  }

  .rc-dropdown-menu > .rc-dropdown-menu-item-divider {
    margin: 0;
    background: rgba(0, 0, 0, 0.15);
  }

  .rc-dropdown-menu > .rc-dropdown-menu-item-selected:after {
    display: none;
  }
}
