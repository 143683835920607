@media screen and (max-width: 767px) {
  .signup-bg {
    // background-size: 100%;
    justify-content: flex-start;
    padding-top: 80px;
  }
  .signup-bg--more-details {
    padding-top: 100px;
  }
  .signup-bg--email {
    padding-top: 100px;
  }
}
