.scrollbar {
  float: left;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.force-overflow {
  min-height: 450px;
}
.scrollbar-primary::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}
.scrollbar-primary::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #4285f4;
}
////////////////////////////////////////////////
.maincontainer-custom {
  width: 75%;
  margin: auto;
  @media only screen and (max-width: 1440px) {
    width: 85%;
  }
  @media only screen and (max-width: 900px) {
    width: 100%;
  }
}
.light-paper {
  background: #ddd;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px;
  position: fixed;
  width: 100%;
  z-index: 9999;
  h5 {
    padding: 0;
    margin: 0 10px 0 0;
    color: #000;
    text-align: center;
    @media only screen and (max-width: 900px) {
      font-size: 12px;
      margin: 0 0 4px 0;
    }
  }
  .download-btn {
    border-radius: 25px;
    padding: 15px;
    background: #29294f;
    color: white;
    font-size: 14px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    @media only screen and (max-width: 900px) {
      font-size: 12px;
      padding: 10px;
    }
  }
}
.home-fold-one-div {
  //display: flex;
  //flex-direction: column;
  //align-items: center;
  background-image: url("../../../imgs/desktop/background/home-fold1-bg.png");
  //background-size: 43.5% 100%;
  background-size: 42.8% 100%;
  background-repeat: no-repeat;
  padding-top: 71px;
  //padding-bottom: vw(35px);
  .font-16-Roboto-medium {
    font-size: vw(19px);
  }
  .font-25-Roboto-regular {
    margin-top: vw(-20px);
  }
  @media only screen and (max-width: 900px) {
    min-height: 100vh;
    background-size: cover;
  }
  @media only screen and (max-width: 525px) {
    background-size: cover;
    // min-height: 100vh;
    min-height: 93vh;
    padding-top: 100px;
  }
}
.vl-navbar {
  padding: vw(56px) vw(48px) 0 vw(74px);
  width: 100%;
  .navbar-logo {
    width: vw(256px);
    // width: 11.33333vw;
    height: auto;
    @media only screen and (min-width: 300px) and (max-width: 900px) {
      width: 160px;
      // display: none;
    }
  }
  .myOverlay {
    display: none;
    //background: #000000 !important;
    background-size: cover !important;
    background-position: bottom;
    width: 100% !important;
    height: 100% !important;
    right: 0;
    top: vw(20px);
    background: inherit !important;
  }
  @media only screen and (max-width: 1024px) {
    margin-top: 0;
    padding: 15px 20px;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 0;
    padding: 0 20px;
  }
  @media only screen and (max-width: 525px) {
    // margin-top: 25px;
  }
}

.bm-burger-button {
  display: flex;
  align-items: center;
  position: relative;
  .bm-icon {
    max-width: 55px;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    cursor: pointer;
    @media only screen and (max-width: 1024px) {
      max-width: 40px;
    }
    @media only screen and (max-width: 768px) {
      max-width: 40px;
    }
    @media only screen and (max-width: 525px) {
      max-width: 35px;
    }
  }
  span {
    display: none;
  }
  // img,
  // button {
  //   width: vw(73px) !important;
  //   height: vw(40px) !important;
  //   @media only screen and (min-width: 300px) and (max-width: 900px) {
  //     width: 29px !important;
  //     height: 20px !important;
  //   }
  // }

  img {
    float: right;
  }
  button {
    // left: unset !important;
    // right: vw(48px) !important;
    //top: vw(60px) !important;
    position: unset !important;
    height: 50px !important;
    width: 50px !important;
    @media only screen and (max-width: 1024px) {
      width: 35px !important;
      height: 22px !important;
    }
    @media only screen and (max-width: 768px) {
      width: 35px !important;
      height: 22px !important;
    }
    @media only screen and (max-width: 525px) {
      width: 35px !important;
      height: 25px !important;
    }
    // top: vw(142px) !important;
    // background: url("../../../imgs/desktop/common/menu.svg") !important;
    // &::before {
    //   content: "\f0c9";
    //   font-family: FontAwesome;
    //   // font-family: "Font Awesome 5 Free";
    //   // content: "\f095";
    //   color: #ffffff;
    //   font-size: 50px;
    //   font-weight: normal;
    //   @media only screen and (max-width: 525px) {
    //     font-size: 30px;
    //   }
    // }
  }
}
// .scrolled {
// }
.box-shadow {
  box-shadow: 0 0 15px #00000030;
  background-color: #ffffff !important;
}
.nav-bar {
  position: fixed !important;
  left: 0;
  top: 0;
  width: 100%;
  padding: 24px 0;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  z-index: 999;
  padding: 0;
  background-color: transparent;
  // background-color: #ffffff;
  .navbar-brand {
    display: inline-block;
    padding: 0;
    margin: 0;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;

    @media only screen and (max-width: 1024px) {
      margin-right: 0.2rem;
    }

    .header-logo {
      width: 230px;
      height: auto;
      @media only screen and (max-width: 1050px) {
        width: 170px;
      }
      @media only screen and (max-width: 1024px) {
        width: 150px;
        height: auto;
      }

      @media only screen and (max-width: 768px) {
        width: 145px;
        height: 40px;
      }
    }
  }
  .navbar-collapse {
    display: flex;
    color: #000000;
    padding: 8px;
    font-family: "Roboto-Bold", sans-serif, "Helvetica";
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 1024px) {
      font-size: 16px;
      font-weight: 300;
    }
    @media only screen and (max-width: 991px) {
      flex-direction: column;
    }
    .navbar-menu {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-left: 0;
      gap: 20px;
      list-style: none;
      margin-top: 5px !important;
      @media only screen and (max-width: 1200px) {
        gap: 10px;
      }
      @media only screen and (max-width: 991px) {
        flex-direction: column;
        margin-top: 5px !important;
      }
      .nav-link {
        position: relative;
        white-space: nowrap;
        color: #000000;
        font-size: 18px;
        line-height: 1.3;
        font-weight: 500;
        letter-spacing: 0.5px;
        font-family: "Helvetica";
        text-transform: capitalize;
        display: inline-block;
        margin: 0;
        padding: 0;
        @media only screen and (max-width: 1200px) {
          font-size: 16px;
        }
      }
      .nav-link:after {
        background: none repeat scroll 0 0 transparent;
        bottom: 0;
        content: "";
        display: block;
        height: 2px;
        left: 50%;
        position: absolute;
        background: #bbadef;
        transition: width 0.3s ease 0s, left 0.3s ease 0s;
        width: 0;
      }
      .nav-link:hover:after {
        width: 100%;
        left: 0;
      }
    }
  }
  .navbar-nav-scroll {
    width: 200px;
    display: block;
    padding-left: 0;
    margin: 0;
    list-style: none;
    overflow: visible;
    @media only screen and (max-width: 1120px) {
      width: 186px;
    }
    // @media only screen and (max-width: 991px) {
    //   display: flex !important;
    // }
    .log-in-btn {
      height: 40px;
      width: 50%;
      font-size: 18px;
      line-height: 1.1;
      border-radius: 6px;
      padding: 12px;
      color: #29294f !important;
      letter-spacing: 1px;
      background-color: transparent;
      text-align: center;
      font-size: 14px;
      margin: 0 20px 0 0;
      &::after {
        position: unset;
        background-color: transparent;
      }
      &:hover {
        // padding: 10px;
        border: 2px solid #29294f;
        transition: background-color 0.3s ease, box-shadow 0.3s ease;
        // width: 90px;
        background-color: transparent;
      }
    }
    .sign-up-btn {
      height: 40px;
      width: 50%;
      font-size: 18px;
      line-height: 1.1;
      border-radius: 6px;
      padding: 12px;
      background: #29294f;
      color: #fff !important;
      letter-spacing: 1px;
      text-align: center;
      font-size: 14px;
      &::after {
        position: unset;
        background-color: transparent;
      }
      &:hover {
        padding: 10px;
        border: 2px solid #29294f;
        transition: background-color 0.3s ease, box-shadow 0.3s ease;
        color: #000 !important;
        // width: 90px;
        background-color: transparent;
      }
      @media only screen and (max-width: 1024px) {
        font-size: 12px;
        font-weight: 300;
        height: 36px;
        padding: 0;
        width: 75px;
      }
    }
  }
  .navbar-nav{
    flex-direction: row;
  }
  .login-profile {
    position: relative;
    .mentor-profile-picture {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      border: 1px solid #ffffff;
      object-fit: cover;
      cursor: pointer;
      @media only screen and (max-width: 991px) {
        display: block;
      }
    }
    .mentor-profile-picture--text {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #29294f;
      color: #ffffff;
      font-size: 30px;
    }
    .sign-out-btn {
      position: absolute;
      bottom: -28px;
      // left: -32px;
      overflow: hidden;
      width: 120px;
      right: 0;
      top: 50px;
      background: #29294e;
      height: 81px;
      border-radius: 6px;
      .log-out {
        background-color: transparent;
        font-size: 14px;
        white-space: nowrap;
        letter-spacing: 0.6px;
        line-height: 1.5;
        color: #fff;
        font-weight: 500;
        border-radius: 6px;
        font-family: "Roboto-Regular";
        padding: 10px 15px;
        i {
          font-size: 16px;
          padding-right: 8px;
        }
      }
      .user-profile {
        background-color: transparent;
        font-size: 14px;
        color: #ffffff !important;
        white-space: nowrap;
        letter-spacing: 0.6px;
        line-height: 1.5;
        font-weight: 500;
        border-radius: 6px;
        font-family: "Roboto-Regular";
        padding: 10px 15px;
        width: 100%;
        display: inline-block;
        i {
          padding-right: 12px;
          color: #fff;
        }
      }
    }
    @media only screen and (max-width: 991px) {
      display: block;
    }
  }
}
.max-height {
  height: 100vh;
}
.background-image-home {
  background-image: url(../../../imgs/desktop/background/purple-background.svg);
  width: 100%;
  height: 100%;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.home-bg-color {
  background-color: #f1f2fe;
}
.investor-main {
  align-items: center;
  @media only screen and (max-width: 575px) {
    text-align: center;
  }
  .invest-main {
    .invest-title {
      font-size: 50px;
      line-height: 1;
      font-weight: 900;
      letter-spacing: 0.8px;
      color: #000;
      margin-bottom: 15px;
      @media only screen and (max-width: 768px) {
        font-size: 45px;
        margin-bottom: 15px;
      }
      @media only screen and (max-width: 575px) {
        font-size: 35px;
      }
    }
    @media only screen and (max-width: 575px) {
      margin-bottom: 30px;
    }
  }
  .invest-sub {
    font-size: 23px;
    line-height: 1.3;
    font-weight: 400;
    color: #000;
    margin-bottom: 32px;
    @media only screen and (max-width: 768px) {
      font-size: 20px;
    }
    @media only screen and (max-width: 575px) {
      font-size: 18px;
    }
  }
  .investor-email {
    margin-bottom: 32px;
    .error-message {
      margin-left: 0;
    }
    input {
      width: 300px;
      font-size: 18px;
      border-radius: 6px;
      margin-bottom: 8px;
      padding: 12px 14px;
      line-height: 1.1;
      display: block;
      box-shadow: none;
      background: #fff;
      font-weight: 400;
      letter-spacing: 0;
      border: 1px solid #ddd;
      color: #333;
      @media only screen and (max-width: 768px) {
        // width: 182px;
        font-size: 16px;
      }
      @media only screen and (max-width: 575px) {
        font-size: 15px;
        width: 75%;
        margin: 8px auto;
        padding: 11px 14px;
      }
      @media only screen and (max-width: 425px) {
        width: 100%;
      }
    }
  }
  .investing-sub {
    margin-top: 10px;
    line-height: 1;
    font-size: 15px;
    font-weight: 400;
    color: #000;
    letter-spacing: 0.2px;
    .action-link {
      text-decoration: underline !important;
    }
  }
  .vedasLabs-btn {
    color: #fff;
    line-height: 1.3;
    width: 300px;
    padding: 12px 30px;
    font-size: 18px;
    white-space: nowrap;
    height: 48px;
    font-weight: 600;
    border-radius: 6px;
    font-family: "Roboto-regular";
    background: #29294f;
    &:hover {
      color: #000000 !important;
      background-color: #fff;
      transition: background-color 0.3s ease, box-shadow 0.3s ease;
      border: 2px solid #29294f;
    }
    @media only screen and (max-width: 768px) {
      // width: 182px;
      font-size: 15px;
    }
    @media only screen and (max-width: 575px) {
      padding: 12px 10px;
      font-size: 15px;
      white-space: nowrap;
      width: 75%;
      margin: 0 auto;
    }
    @media only screen and (max-width: 425px) {
      width: 100%;
    }
  }
  .investor-image {
    svg {
      width: 650px !important;
      @media only screen and (max-width: 1024px) {
        width: 100% !important;
      }
      @media only screen and (max-width: 575px) {
        // width: 80% !important;
        display: none;
      }
    }
  }
}
.bm-menu {
  width: 100%;
  background: #242427 !important;
  border-radius: vw(73px) 0 0 vw(73px);
  height: 100vh !important;
  //transform: matrix(-1, 0, 0, -1, 0, 0);
  //height: vw(1070px) !important ;
  @media only screen and (min-width: 300px) and (max-width: 429px) {
    width: 100%;
    height: auto !important;
    border-radius: 0;
    box-shadow: #000000d9 -4px 14px 22px;
  }
  @media only screen and (min-width: 300px) and (max-width: 430px) {
    width: 100%;
    height: 100vh !important;
    border-radius: 0;
    box-shadow: #000000d9 -4px 14px 22px;
  }
}
.bm-menu-wrap {
  //width: vw(658px) !important;
  width: vw(441px) !important;
  background-size: cover !important;
  background-position: bottom;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  top: 0;
  z-index: 99999 !important;
  @media only screen and (min-width: 300px) and (max-width: 900px) {
    width: 100vw !important;
    align-items: end;
  }
}
.menu-item {
  width: fit-content;
  margin: 0;
  //margin-bottom: vw(40px);
  font-size: vw(25px);
  line-height: vw(30.48px);
  //@include fontMontserratSemiBold;
  white-space: nowrap;
  @include fontRobotoBold;
  padding-bottom: vw(15px);
  position: relative;
  &:focus {
    outline: 0;
  }

  @media only screen and (min-width: 300px) and (max-width: 900px) {
    line-height: inherit;
    font-size: 17px;
    line-height: 2;
  }
}
.menu-item:hover {
  cursor: pointer;
}
.bm-item-list {
  margin-top: vw(220px);
  //padding-left: vw(114.52px);
  padding-left: vw(54.52px);
  //text-align: center;
  overflow-y: hidden;
  height: auto !important;
  @media only screen and (min-width: 300px) and (max-width: 530px) {
    margin: 0;
    padding: 40px;
    height: auto !important;
  }
  @media only screen and (min-width: 530px) and (max-width: 768px) {
    height: 100vh !important;
  }
  @media only screen and (min-width: 424px) and (max-width: 768px) {
    padding: 0;
    margin: 40px;
  }
}
.bm-cross-button {
  margin-top: vw(50px) !important;
  margin-right: vw(50px) !important;
  right: 0 !important;
  top: 0 !important;
  width: fit-content !important;
  height: auto !important;
  .close-icon {
    font-size: vw(25px) !important;
    padding-left: vw(10px);
    font-weight: 100;
    @media only screen and (min-width: 300px) and (max-width: 900px) {
      font-size: 25px !important;
    }
  }
}

.home-fold-one-inner-div {
  padding-top: vw(250px);
  padding-left: vw(722px);
  .vedaslab-textline {
    width: 60%;
    margin-top: -1.04167vw;
    @media only screen and (max-width: 900px) {
      margin-top: 0px;
      width: 100%;
    }
  }
  .mb-join-btn {
    display: none;
  }
  .column-div {
    .bottom__text {
      font-size: 15px;
      width: 100%;
      text-align: end;
      @media only screen and (max-width: 1440px) {
        font-size: 12px;
      }
    }
    .joinnow-main {
      display: flex;
      justify-content: flex-end;
      .home-fold1-down-right-div {
        width: 70%;
        h5 {
          margin: 0px;
          font-size: 20px;
          @media only screen and (max-width: 1440px) {
            font-size: 18px;
          }
          @media only screen and (max-width: 1092px) {
            font-size: 15px;
          }
        }
        @media only screen and (max-width: 1440px) {
          width: 68%;
          padding: 25px 50px;
          font-size: 18px;
        }
        @media only screen and (max-width: 1092px) {
          width: 69%;
          padding: 25px 30px;
          font-size: 15px;
        }
        @media only screen and (max-width: 900px) {
          width: 100%;
        }
      }
    }
  }
  @media only screen and (max-width: 900px) {
    // position: absolute;
    // height: calc(100vh - 90px);
    height: 94vh;
    width: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
    h5 {
      width: 100%;
      padding: 30px 20px !important;
      font-size: 16px;
      line-height: initial;
      text-align: center;
    }
    .mb-join-btn {
      display: inherit;
      // padding-bottom: 27%;
      .home-fold1-join-now-div {
        width: 180px;
        height: 50px;
        h5 {
          color: #000000;
        }
        @media only screen and (max-width: 525px) {
          margin-bottom: 15vh;
        }
      }
    }
    .desk_joinBtn {
      display: none;
    }
    .column-div {
      width: 100%;
      .bottom__text {
        padding: 12px 50px;
        font-size: 12px;
        @media only screen and (max-width: 900px) {
          text-align: center;
        }
      }
    }
  }
  @media only screen and (max-width: 525px) {
    height: 90vh;
  }
}
.home-img-logo {
  width: vw(643px);
  height: auto;
  @media only screen and (max-width: 900px) {
    width: 320px;
  }
}
.column-div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-left: 11.97917vw;
  @media only screen and (max-width: 1140px) {
    padding-left: 0px;
  }
}
.pt-301 {
  @media only screen and (max-width: 900px) {
    padding-top: 30px !important;
  }
  @media only screen and (max-width: 525px) {
    padding-top: 30px;
  }
}
.home-fold1-join-now-div {
  width: vw(268px);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
}
.home-fold1-down-div {
  // height: vw(112px);
  color: black;
  margin-top: 8px;
  @media only screen and (min-width: 300px) and (max-width: 900px) {
    height: auto;
    width: 100%;
    margin-top: 0px;
  }
}
.home-fold1-down-right-div {
  background: #8ba995;
  padding-top: 31px;
  padding-bottom: 31px;
  padding-left: 52px;
  padding-right: 45px;
  .font-22-Roboto-medium {
    @include fontRobotoRegular;
  }
  @media only screen and (min-width: 300px) and (max-width: 900px) {
    width: 100%;
    padding: 20px 30px;
    h5 {
      padding: 0 !important;
    }
  }
}
/*-------------------------------------------------------

                 fold2

--------------------------------------------------------*/
.home-fold2-div-outer-div {
  width: 100%;
  overflow: hidden;
  position: relative;
  .home-fold2-div {
    position: relative;
    padding-top: vw(167px);
    padding-bottom: vw(200px);
    &::before {
      position: absolute;
      content: "";
      background: #29294f;
      width: vw(1722px);
      height: 100%;
      top: 0;
      border-radius: vw(49px);
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      @media only screen and (max-width: 549px) {
        border-radius: 50px 0px 0px 50px;
        right: 0;
        width: 95%;
      }
    }
    .accelerate-inner {
      width: 80%;
      h4 {
        font-size: 44px;
        font-family: Roboto-bold;
        margin-bottom: 28px;
        @media only screen and (max-width: 1440px) {
          font-size: 32px;
        }
        @media only screen and (max-width: 991px) and (min-width: 550px) {
          font-size: 28px;
          width: 80%;
        }
        @media only screen and (max-width: 549px) {
          font-size: 18px;
          margin-bottom: 20px;
        }
      }
      h5 {
        font-size: 24px;
        font-family: Roboto-regular;
        @media only screen and (max-width: 1440px) {
          font-size: 20px;
        }
        @media only screen and (max-width: 991px) and (min-width: 550px) {
          font-size: 15px;
          width: 80%;
        }
        @media only screen and (max-width: 549px) {
          font-size: 15px;
          width: 100%;
        }
      }
      .accelerateinner-main {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: flex-start;
        width: 100%;
        @media only screen and (max-width: 991px) {
          justify-content: flex-start;
        }
        .accelerateinner-text {
          width: 50%;
          h2 {
            text-align: center;
            font-size: 24px;
            margin-bottom: 20px;
            font-family: Roboto-bold;
            @media only screen and (max-width: 1440px) {
              font-size: 20px;
            }
            @media only screen and (max-width: 991px) {
              font-size: 15px;
            }
          }
          .startup-image {
            height: 224px;
            width: 100%;
            @media only screen and (max-width: 549px) {
              height: 170px;
            }
            svg {
              width: 70% !important;
              height: auto;
            }
          }
          .investor-image {
            svg {
              width: 70% !important;
              height: auto;
            }
          }
          h3 {
            text-align: center;
            font-size: 24px;
            margin-bottom: 20px;
            font-family: Roboto-bold;
            @media only screen and (max-width: 1440px) {
              font-size: 20px;
            }
            @media only screen and (max-width: 991px) {
              font-size: 15px;
            }
            @media only screen and (max-width: 549px) {
              margin: 0px;
              // width: 123px;
            }
          }
          p {
            font-size: 17px;
            margin-top: 0px;
            margin-bottom: 40px;
            font-family: Roboto-regular;
            width: 59%;
            line-height: 1.3;
            margin: 0 auto;
            @media only screen and (max-width: 1440px) {
              font-size: 17px;
              width: 83% !important;
            }
            @media only screen and (max-width: 991px) {
              width: 50%;
              font-size: 15px;
            }
            @media only screen and (max-width: 549px) {
              width: 80% !important;
              margin-bottom: 0px;
              margin-top: 12px;
            }
          }
          .accelerateinner-novice {
            width: 100%;
          }
          @media only screen and (max-width: 991px) {
            width: 90%;
          }
          @media only screen and (max-width: 549px) {
            margin-top: 100%;
          }
        }
        .access-main {
          @media only screen and (max-width: 575px) {
            margin-top: 70px;
          }
          @media only screen and (max-width: 575px) {
            margin-top: 70px;
          }
        }
        @media only screen and (max-width: 991px) {
          width: 65%;
        }
        @media only screen and (max-width: 575px) {
          justify-content: center;
          width: 100%;
          margin-top: 80px;
          flex-direction: column;
        }
      }
      @media only screen and (max-width: 1024px) {
        margin: auto;
      }
      @media only screen and (max-width: 768px) {
        width: 90%;
        margin: auto;
      }
      @media only screen and (max-width: 549px) {
        margin-left: 40px;
      }
      @media only screen and (max-width: 360px) {
        margin-left: 30px;
      }
    }
    @media only screen and (max-width: 549px) {
      padding-top: 65px;
      padding-bottom: 100px;
    }
  }
  .home-fold2-text {
    position: absolute;
    transform: rotate(89.55deg);
    right: 0;
    top: 140px;
    color: #29294f;
    background-color: #f1f2fe;
    font-size: 33px;
    @media only screen and (max-width: 1024px) {
      font-size: 20px;
      top: 222px;
    }
    @media only screen and (max-width: 990px) {
      font-size: 20px;
      top: 110px;
    }
    @media only screen and (max-width: 768px) and (min-width: 550px) {
      top: 12.69792vw;
    }
    @media only screen and (max-width: 549px) {
      display: none;
    }
  }
  .fold2-img {
    position: absolute;
    height: 70%;
    right: 0;
    bottom: 104px;
    @media only screen and (max-width: 1440px) {
      bottom: 125px;
      height: 60%;
    }
    @media only screen and (max-width: 1024px) and (min-width: 768px) {
      height: 45%;
      bottom: 230px;
    }
    @media only screen and (max-width: 768px) and (min-width: 550px) {
      height: 45%;
      display: none;
    }
    @media only screen and (max-width: 549px) {
      height: 22%;
      top: 275px;
    }
    @media only screen and (max-width: 390px) {
      height: 20%;
      top: 320px;
    }
  }
}

// .home-fold2-div {
//margin-top: vw(-35px);
// background: rgba(17, 19, 22, 0.75);
// border-radius: vw(49px);
// border-top-left-radius: 0;
// border-bottom-left-radius: 0;
// padding-top: vw(167px);
// padding-left: vw(238px);
// padding-bottom: vw(200px);
// width: vw(1722px);
//padding-bottom: vw(240px);
//margin-right: vw(198px);
// }
// .home-fold2-div-outer-div {
//   position: relative;
// }
// .home-subtitle {
//   transform: rotate(89.55deg);
//   position: absolute;
//   right: vw(50px);
//   top: vw(200px);
// }
// .home-fold2-img-div {
//   position: absolute;
// width: vw(880px);
//width: vw(966px);
// height: auto;
// right: 0;
//top: vw(300px);
// bottom: 0;
// img {
//   width: 100%;
//   height: 100%;
// }
// }
/*-------------------------------------------------------

                 fold3

--------------------------------------------------------*/
.home-fold-3-div {
  position: relative;
  padding: 0;
}
.know-more-section {
  margin: 60px 0 0 0;
  .category-sub {
    font-size: 30px;
    line-height: 35px;
    font-weight: 900;
    letter-spacing: 0.06em;
    margin-bottom: 20px;
    color: #000;
    @media only screen and (max-width: 768px) {
      font-size: 24px;
      letter-spacing: 0.1px;
      line-height: 1.1;
      margin: 6px 0 15px 0;
    }
  }
  .know-more-sub {
    max-width: 50%;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.05px;
    color: #000;
    margin: auto;
    padding-bottom: 90px;
    @media only screen and (max-width: 1024px) {
      max-width: 65%;
    }
    @media only screen and (max-width: 768px) {
      max-width: 65%;
      font-size: 16px;
      line-height: 21px;
      margin-top: 4px;
      margin-bottom: 0;
    }
    @media only screen and (max-width: 575px) {
      max-width: 90%;
    }
  }
}
.icee-background {
  background-image: url(../../../imgs/desktop/background/Dark-Icee.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.section-background-color {
  background-color: #dbd1fa4d;
  box-shadow: 0 9px 25px #0000008a;
  margin: 100px 0 100px 0;
  .browes-main {
    max-width: 75%;
    margin: 0 auto;
    padding: 90px 0;
    .browes-title {
      font-size: 45px;
      line-height: 56px;
      font-weight: 400;
      letter-spacing: 0.06em;
      color: #ffffff;
      margin-bottom: 30px;
      @media only screen and (max-width: 768px) {
        font-size: 35px;
        text-align: center;
        letter-spacing: 0.01em;
        line-height: 40px;
        margin-bottom: 20px;
      }
      @media only screen and (max-width: 575px) {
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 10px;
      }
    }
    .card-main {
      .card-number {
        color: #ffffff;
        font-size: 20px;
        white-space: nowrap;
        font-weight: 400;
        line-height: 20.8px;
        letter-spacing: 2px;
        margin: 0;
        @media only screen and (max-width: 768px) {
          font-size: 17px;
          letter-spacing: 1px;
        }
      }
      .user-sign-up {
        font-size: 28px;
        font-weight: 500;
        line-height: 31px;
        letter-spacing: 2px;
        color: #ffffff;
        margin: 8px 0;
        text-transform: uppercase;
        @media only screen and (max-width: 768px) {
          font-size: 24px;
          letter-spacing: 0.1px;
          line-height: 1.1;
          margin: 6px 0 0 0;
        }
        @media only screen and (max-width: 575px) {
          font-size: 19px;
          line-height: 22px;
        }
      }
      .user-profile {
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.05px;
        color: #ffffff;
        @media only screen and (max-width: 768px) {
          font-size: 16px;
          line-height: 21px;
          margin-top: 4px;
          margin-bottom: 0;
        }
      }
      @media only screen and (max-width: 768px) {
      }
    }
    @media only screen and (max-width: 1024px) {
      max-width: 85%;
    }
    @media only screen and (max-width: 768px) {
      max-width: 100%;
    }
    @media only screen and (max-width: 575px) {
      max-width: 100%;
    }
  }
}
.CIK_text {
  h4 {
    font-size: 45px;
    font-family: Roboto-bold;
    margin-top: 60px;
    margin-bottom: 20px;
    line-height: 2;
    @media only screen and (max-width: 1440px) {
      font-size: 30px;
    }
    @media only screen and (max-width: 991px) {
      font-size: 25px;
    }
    @media only screen and (max-width: 767px) {
      margin-bottom: 15px;
      font-size: 20px;
      margin-top: 35px;
      line-height: 1.1;
    }
  }
  h5 {
    font-size: 30px;
    line-height: 1.1;
    font-family: Roboto-regular;
    margin-top: 16px;
    padding-bottom: 50px;
    width: 60%;
    margin: auto;
    @media only screen and (max-width: 1440px) {
      font-size: 20px;
    }
    @media only screen and (max-width: 991px) {
      width: 80%;
      font-size: 16px;
    }
    @media only screen and (max-width: 525px) {
      width: 100%;
    }
  }
  @media only screen and (max-width: 767px) {
    padding: 0px 15px;
  }
}
.invest-card {
  // background-image: url("../../../imgs/desktop/backgrounds/explore-card-bg.png");
  background-image: url("../../../../web-app-assets/imgs/desktop/backgrounds/explore-card-bg.png");
  background-position: top;
  background-size: cover;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 0 15px #00000030;
  border: 0.5px solid #929292;
  height: 100%;
  // min-height: 623px;
  // min-height: 580px;

  // min-height: vw(623px);
  // @media only screen and(min-width: 1500px) {
  //   min-height: 22vw;
  // }
  // @media only screen and(max-width: 1440px) {
  //   min-height: 500px;
  // }
  // @media only screen and(max-width: 1200px) {
  //   min-height: 473px;
  // }
  // @media only screen and(max-width: 1024px) {
  //   min-height: 370px;
  // }
  // @media only screen and(max-width: 575px) {
  // min-height: 55vw;
  // min-height: 370px;
  // }
  // @media only screen and(max-width: 525px) {
  //   min-height: 85vw;
  // }
  @media only screen and (min-width: 300px) and (max-width: 900px) {
    margin-right: 0;
    // margin-bottom: 36px;
  }
  // @media only screen and (max-width: 399px) {
  // min-height: 120vw;
  // min-height: 350px;
  // }
}
.invest-card-img-div {
  // background-color: #ededed;
  width: 100%;
  height: 274px;
  overflow: hidden;
  text-align: center;
  @media only screen and (max-width: 1440px) {
    height: 225px;
  }
  @media only screen and (max-width: 1024px) {
    height: 200px;
  }
  // @media only screen and (max-width: 575px) {
  //   height: 150px;
  // }
  img {
    width: auto;
    height: 100%;
    // border-radius: 20px 20px 0px 0px;
  }
}
.invest-card-text-div {
  height: 100%;
  background: white;
  color: black;
  padding: 42px 33px 37px 33px;
  border-radius: 0 0 20px 20px;
  overflow-y: hidden;

  .invest-card-title {
    margin-bottom: 0;
    font-size: 28px;
    line-height: 35px;
    @include fontRobotoBold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media only screen and (max-width: 1440px) {
      font-size: 25px;
    }
    @media only screen and (max-width: 1024px) {
      font-size: 20px;
      line-height: 25px;
    }
    @media only screen and (max-width: 767px) {
      font-size: 20px;
    }
    @media only screen and (max-width: 575px) {
      font-size: 16px;
      line-height: 2;
    }
  }
  .invest-card-subtitle {
    height: 30px;
    margin-bottom: 0;
    font-size: 25px;
    line-height: 30px;
    font-family: "Roboto-regular";
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media only screen and (max-width: 1440px) {
      font-size: 18px;
    }
    @media only screen and (max-width: 1024px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 575px) {
      font-size: 15px;
      line-height: inherit;
      margin-bottom: 10px;
      font-family: "Roboto-regular";
      line-height: 1.1;
    }
  }
  .invest-card-main {
    // min-height: 160px;
    min-height: 100px;
    padding-top: 13px;

    .invest-card-para {
      font-size: 19px;
      line-height: 24px;
      display: block;
      display: -webkit-box;
      max-width: 100%;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
      font-family: "Roboto-regular";
      @media only screen and (max-width: 1440px) {
        font-size: 16px;
      }
      @media only screen and (max-width: 767px) {
        font-size: 13px;
        font-family: "Roboto-regular";
        line-height: 1.4;
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        width: 100%;
        min-height: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        max-height: inherit;
        overflow-y: hidden;
      }
      img {
        width: inherit;
        height: inherit;
      }
      li {
        &:first-child {
          margin-top: 0px;
        }
        margin-top: 5px;
      }
      @media only screen and (max-width: 525px) {
        font-size: 14px;
        line-height: 1.2;
        font-family: "Roboto-regular";
        // min-height: 80px;
        margin-bottom: 30px;
      }
    }
    @media only screen and (max-width: 1440px) {
      min-height: 90px;
    }
    @media only screen and (max-width: 575px) {
      min-height: 80px;
      padding-top: 8px;
    }
  }
  .invest-card-swipe-text {
    padding-top: 17px;
    margin-bottom: 0;
    font-size: 20px;
    line-height: 24px;
    font-family: "Roboto-regular";
    //@include fontRobotoMedium;
    @media only screen and (max-width: 1440px) {
      font-size: 15px;
      padding-top: 0px;
    }
    @media only screen and (max-width: 767px) {
      font-size: 13px;
      line-height: 1.3;
      // text-align: center;
      font-weight: 700;
    }
  }
  @media only screen and (max-width: 1200px) {
    min-height: auto;
    max-height: 100%;
    padding: 32px 23px 27px 23px;
  }
  @media only screen and (max-width: 1024px) {
    padding: 20px;
  }
  @media only screen and (min-width: 1700px) {
    min-height: auto;
    max-height: 100%;
  }
  @media only screen and (min-width: 1700px) {
    padding: 40px 20px;
  }
  @media only screen and (max-width: 900px) {
    min-height: auto !important;
    max-height: 100% !important;
    border-radius: 0 0 20px 20px;
    padding: 22px;
  }
}
.invest-card--hover {
  position: relative;
  border-radius: 20px;
  // min-height: 32.44792vw;
  height: 100%;
  // @media only screen and(min-width: 1500px) {
  //   min-height: 22vw;
  //   top: auto;
  // }
  @media only screen and (max-width: 575px) {
    // min-height: 55vw;
    // min-height: 370px;
  }
  // @media only screen and (max-width: 525px) {
  //   min-height: 85vw;
  // }
  @media only screen and (max-width: 399px) {
    // min-height: 120vw;
    // min-height: 350px;
  }
  // margin-bottom: vw(50px);

  .invest-card-img-div {
    overflow: hidden;
    @media only screen and (min-width: 300px) and (max-width: 900px) {
      height: 150px;
      border-radius: 0;
    }
  }
  .invest-card-text-div {
    // min-height: vw(519px);
    position: absolute;
    top: vw(104px);
    z-index: 1;
    width: 100%;
    bottom: 0px;
    // h5 {
    //   margin: 0px;
    // }
    @media only screen and (min-width: 1900px) {
      min-height: auto;
      top: auto;
    }
    @media only screen and (min-width: 300px) and (max-width: 900px) {
      // min-height: 250px;
      // max-height: 250px;
      border-radius: 0 0 20px 20px;
      bottom: 0;
      top: unset;
    }
  }
}
.invest-card__hover-block {
  padding-top: 42px;
  @media only screen and (max-width: 1440px) {
    padding-top: 20px;
  }
}
.details-btn {
  margin-top: 40px;
  @media only screen and (max-width: 1200px) {
    margin-top: 20px;
  }
}
.invest-card__hover-block-text {
  padding-bottom: 20px;
  line-height: 30px;
  .explore-heading-font {
    font-family: roboto-bold;
  }
  span {
    font-size: 22px;
    @media only screen and (max-width: 1440px) {
      font-size: 20px;
    }
    @media only screen and (max-width: 1140px) {
      font-size: 17px;
    }
    @media only screen and (max-width: 900px) {
      font-size: 15px;
    }
  }
  @media only screen and (max-width: 1440px) {
    padding-bottom: 10px;
  }
  @media only screen and (max-width: 1024px) {
    line-height: 20px;
  }
  @media only screen and (min-width: 300px) and (max-width: 900px) {
    line-height: initial;
  }
}

.main-review {
  margin-top: 60px;
  position: relative;
  .home-how-it-works-text {
    position: absolute;
    left: -20px;
    top: 45%;
    transform: rotate(-90.16deg);
    @media only screen and (max-width: 900px) {
      display: none;
    }
  }
  .how-it-works-div {
    padding: 71px 147px 81px 117px;
    background: rgba(17, 19, 22, 0.75);
    border-radius: 60px;
    margin: 15px;

    .how-it-work-card {
      width: 95%;
      h3 {
        font-family: "Roboto-bold";
        @media only screen and (min-width: 1700px) {
          font-size: 34px;
        }
      }
      img {
        margin-top: 0px;
        margin-bottom: 10px;
      }
      h5 {
        font-family: "Roboto-regular";
        @media only screen and (min-width: 1700px) {
          font-size: 25px;
        }
      }
      &:nth-child(2) {
        width: vw(341px);
      }
      @media only screen and (max-width: 1440px) {
        width: 100%;
      }
    }
    @media only screen and (min-width: 1700px) {
      padding: 68px 100px;
    }
    @media only screen and (max-width: 991px) {
      margin: 0;
      position: relative;
      margin-top: 70px;
      padding: 50px 20px;
      border-radius: 50px;
      @media only screen and (max-width: 525px) {
        margin-top: 00px;
      }
      div {
        div {
          &:last-child {
            .how-it-work-card {
              margin-bottom: auto;
              &::after {
                content: "";
                background-image: none;
                position: inherit;
                height: 0;
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 525px) {
    margin-top: 30px;
  }
}
.how-it-work-card {
  // width: vw(320px);
  height: auto;
  @media only screen and (max-width: 991px) {
    margin-bottom: 80px;
    &::after {
      content: "";
      background: url("../../../imgs/desktop/icon/down-arrow-1.png") no-repeat;
      position: absolute;
      opacity: 0.3;
      left: 0px;
      height: 40px;
      background-size: 60px auto;
      display: inline-block;
      width: 100%;
      background-position: bottom;
      margin-top: 10px;
    }
    h3 {
      font-size: 22px;
      line-height: 1.2;
      font-family: "Roboto-bold";
      text-align: center;
      font-weight: 700;
      margin-bottom: 10px;
    }
    img {
      display: none;
    }
    h5 {
      font-size: 15px;
      line-height: 1.2;
      font-family: "Roboto-regular";
      text-align: center;
    }
  }
}
.how-it-word-card-arrow {
  width: 100%;
  height: auto;
  margin-bottom: vw(10px);
}

.home-fold-one-inner-span-text {
  padding-bottom: vw(1px);
  // text-decoration: underline;
}
.exploer_head {
  padding: 0 15px;
  margin-top: 87px;
  h5 {
    color: #29294f;
    line-height: 1.1 !important;
    margin-bottom: 3px;
  }
  .head-main {
    font-family: "Roboto-regular";
    font-size: 35px;
  }
  .head-sub {
    font-family: "Roboto-regular";
    font-size: 20px;
    // margin-bottom: 30px;
    margin-bottom: 0px;
  }
  @media only screen and (max-width: 1440px) {
    margin-top: 36px;
  }
  @media only screen and (min-width: 300px) and (max-width: 900px) {
    text-align: center;
    margin-top: 36px;
    margin-bottom: 26px;
    .head-main {
      font-size: 20px;
      line-height: inherit;
      font-weight: 300;
      margin-bottom: 0;
    }
    .head-sub {
      font-size: 20px;
      line-height: 1;
      font-weight: 700;
      margin-bottom: 0;
      font-family: "Roboto-bold";
    }
  }
}
.mobile-btn {
  text-align: center;
  display: none;
  a {
    color: #272d2e;
    button {
      // width: 180px;
      height: 50px;
      color: #272d2e;
      margin-top: 50px;
      margin-bottom: 30px;
      font-size: 20px;
      font-family: Roboto-bold;
      padding: 12px 35px;
      background-color: #fff;
      line-height: 1.1;
    }
  }
  p {
    font-size: 25px;
    font-family: Roboto-regular;
  }
  @media only screen and (max-width: 525px) {
    display: block;
  }
}
