.vl-tabs-explore--mentor {
  .react-tabs__tab-panel {
    padding: 30px 15px;
  }

  .react-tabs__tab-list {
    margin-left: vw(72px);
    margin-right: vw(288px);

    &::after {
      width: 100%;
    }
    @media only screen and (max-width: 900px) {
      margin: 0px 15px 15px 15px;
      li {
        font-size: 14px;
        line-height: 1.4;
      }
    }
  }
}

.vl-mentor-form-tabs-title-div {
  padding: vw(85px) vw(20px) vw(58px) vw(69px);
  h3 {
    @media only screen and (max-width: 900px) {
      font-size: 15px;
      line-height: 1.5;
    }
  }
}

.vl-mentor-page-one-main-block {
  min-height: 85vh;
  // padding-top: vw(157px);

  .vl-mentor-page-one-main-block__div {
    min-height: max-content;
    text-align: center;
  }
}

.vl-mentor-profile-page-setup-one-img-block {
  width: vw(639px);
  height: vw(321px);
  margin-bottom: vw(31px);

  img {
    width: inherit;
    height: inherit;
  }
}

.mentor-label-bio {
  color: rgba(0, 0, 0, 0.46);
  font-size: vw(24px);
  line-height: vw(28px);
  @include fontRobotoBold;
  margin-bottom: vw(49px);
}

.vl-mentor-remove-area-field-button {
  color: rgba(0, 0, 0, 0.46);
  font-size: vw(20px);
  line-height: vw(23px);
  text-decoration-line: underline;
  @include fontRobotoRegular;
  font-style: italic;
  padding: 0;
  margin-bottom: vw(20px);
  padding: vw(5px) vw(8px);
  width: fit-content;
  margin: 0 auto;
  @media only screen and (max-width: 900px) {
    font-size: 12px;
    line-height: 1.3;
  }
}

.vl-mentor-areas-fields-main-block {
  .vl-mentor-areas-fields-row:last-child {
    .vl-form-input--bordered-select-div,
    .vl-mentor-remove-area-field-button {
      margin-bottom: vw(18px);
    }
  }
  .edit-team-main {
    display: flex;
    align-items: center;
    gap: 20px;
    @media screen and (max-width: 1024px) {
      flex-wrap: wrap;
      justify-content: center !important;
    }

    .upload-image-profile {
      height: 50px;
      width: 50px;
      margin-bottom: 0;
      @media screen and (max-width: 992px) {
        width: 80px;
        height: 80px;
      }
      @media screen and (min-width: 1800px) {
        width: 70px;
        height: 70px;
      }
      @media screen and (min-width: 2560px) {
        width: 90px;
        height: 90px;
      }
      @media screen and (min-width: 3840px) {
        width: 120px;
        height: 120px;
      }
      .upload-img__img-block {
        width: 100%;
        height: 100%;
        position: relative;
        max-width: 400px;
        margin-bottom: 0 !important;
        cursor: pointer;
        &:hover {
          .overlay {
            opacity: 1;
          }
        }
        .upload-profile-img {
          display: block;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          object-fit: cover;
          @media screen and (max-width: 992px) {
            width: 80px;
            height: 80px;
          }
          @media screen and (min-width: 1800px) {
            width: 70px;
            height: 70px;
          }
          @media screen and (min-width: 2560px) {
            width: 90px;
            height: 90px;
          }
          @media screen and (min-width: 3840px) {
            width: 120px;
            height: 120px;
          }
        }
        .overlay {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          height: 100%;
          width: 100%;
          opacity: 0;
          border-radius: 50%;
          transition: 0.3s ease;
          background: linear-gradient(2deg, #3e3e3e 0%, #00000000 100%);
          // @media screen and (min-width: 768px) {
          //   width: 110px;
          //   height: 110px;
          // }
          // @media screen and (min-width: 1800px) {
          //   width: 120px;
          //   height: 120px;
          // }
          // @media screen and (min-width: 3840px) {
          //   width: 200px;
          //   height: 200px;
          // }
          .upload_icon {
            width: 35px;
            height: 35px;
            color: white;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            img {
              width: 35px !important;
              height: 35px !important;
            }
          }
        }
        .user-profile-picture {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          object-fit: cover;
          @media screen and (max-width: 992px) {
            width: 80px;
            height: 80px;
          }
          @media screen and (min-width: 1800px) {
            width: 70px;
            height: 70px;
          }
          @media screen and (min-width: 2560px) {
            width: 90px;
            height: 90px;
          }
          @media screen and (min-width: 3840px) {
            width: 120px;
            height: 120px;
          }
          img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            object-fit: cover;
            @media screen and (max-width: 992px) {
              width: 80px;
              height: 80px;
            }
            @media screen and (min-width: 1800px) {
              width: 70px;
              height: 70px;
            }
            @media screen and (min-width: 2560px) {
              width: 90px;
              height: 90px;
            }
            @media screen and (min-width: 3840px) {
              width: 120px;
              height: 120px;
            }
          }
        }
      }
    }
    .edit-profile-input-main {
      width: 30%;
      @media screen and (max-width: 992px) {
        width: 48%;
      }
      @media screen and (max-width:575px) {
        width: 100% !important;
      }
      // @media screen and (max-width: 375px) {
      //   width: 100%;
      // }
      .error-message {
        margin-bottom: 0;
      }
    }
    .edit-profile-input {
      width: 100%;
      max-width: 100% !important;
    }
    .upload-button {
      width: fit-content;
      padding: 0 20px;
    }
    .edit_inputs_wrap {
      width: 100%;
      display: flex;
      gap: 20px;
        flex-wrap: wrap;
      // justify-content: space-between;
      // @media screen and (max-width: 992px) {
      //   gap: 0;
      //   row-gap: 20px;
      // }
    }
  }
  .vl-mentor-areas-fields-row__colm3 {
    .vl-mentor-remove-area-field-button {
      width: fit-content;
      margin: 0 auto;
    }
  }

  .error-message {
    margin-bottom: vw(13px);
  }
  .team-profile-main {
    display: flex;
    justify-content: center !important;
    align-items: center;
    gap: 20px;
    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
    }
    @media screen and (max-width: 992px) {
      align-items: end !important;
      padding-top: 50px !important;
    }
    @media screen and (max-width: 576px) {
      gap: 20px;
      padding-top: 40px !important;
    }
    .team-profile-img-main {
      @media screen and (max-width: 576px) {
        display: flex;
        justify-content: center;
      }
      .team-profile-img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
        @media screen and (max-width: 992px) {
          width: 90px;
          height: 90px;
        }
        @media screen and (min-width: 1800px) {
          width: 70px;
          height: 70px;
        }
      }
    }
    .team-profile-input {
      width: 100% !important;
      max-width: 100%;
    }
    .teams_disable_input {
      width: 100%;
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      // justify-content: space-between;
      // @media screen and (max-width: 992px) {
      //   flex-wrap: wrap;
      //   gap: 0;
      //   row-gap: 20px;
      // }
    }
  }
}

.vl-mentor-areas-fields-row__colm1,
.vl-mentor-areas-fields-row__colm2 {
  width: vw(598px);
  flex-shrink: 0;
}

.vl-mentor-areas-fields-row__colm1 {
  margin-right: vw(122px);
}

.vl-mentor-areas-fields-row__colm2 {
  margin-right: vw(27px);
}

.vl-mentor-add-area-field-button {
  padding: vw(8px);
  border-radius: vw(3px);
  color: rgba(0, 0, 0, 0.46);
  font-size: vw(20px);
  line-height: vw(23px);
  @include fontRobotoBold;
  @media only screen and (max-width: 900px) {
    font-size: 15px;
    line-height: 1;
    margin-bottom: 10px;
  }
}

.vl-mentor-basic-form-tabpanel-content {
  padding: vw(47px) vw(22px) vw(105px) vw(90px);
}

.vl-mentor-expertise-form-tabpanel-content {
  padding: vw(81px) vw(22px) vw(48px) vw(69px);
}

.vl-mentor-form-horizontal-line {
  border-top: none;
  width: 83%;
  height: vw(15px);
  margin-left: 0;
  margin-top: vw(29px);
  margin-bottom: vw(50px);
  background-image: url("../../../imgs/desktop/backgrounds/explore-arrow-bg.png");
  background-size: 100%;
}

.vl-mentor-save-next-button-div {
  margin-right: vw(222px);
}

.vl-mentor-save-next-button-div--save {
  margin-top: vw(30px);
}

// mentor card
.explore-card--mentor {
  padding-top: vw(245px);

  .explore-card-view-details-div {
    width: vw(372px);
    margin-left: vw(11px);
  }
}
