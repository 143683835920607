.about-us-fold-one {
  background: #1e2225;
  border-radius: 0 0 0 vw(117px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
  //min-height: 100vh;
  .about-us-fold-one-text-div {
    width: 70%;
    h5 {
      font-size: 55px;
      font-family: Montserrat-bold;
      @media only screen and (max-width: 1200px) {
        font-size: 40px;
      }
      @media only screen and (max-width: 575px) {
        font-size: 20px;
      }
    }
    p {
      font-size: 25px;
      font-family: Montserrat-regular;
      margin-top: 23px;
      line-height: 1.4;
      @media only screen and (max-width: 1200px) {
        font-size: 18px;
      }
      @media only screen and (max-width: 575px) {
        font-size: 16px;
      }
    }
    @media only screen and (max-width: 991px) {
      width: 80%;
    }
    @media only screen and (max-width: 575px) {
      width: 90%;
    }
  }
  .about-fold1-down-div {
    // margin-top: vw(298px);
    width: 100%;
    height: 110px;
    display: flex;
    justify-content: flex-end;
    .about-fold1-experience-div {
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      color: black;
      padding-left: 26px;
      padding-right: 26px;
      @media only screen and (max-width: 1556px) {
        padding-left: 0px;
        padding-right: 0px;
      }
      h5 {
        font-size: 35px;
        font-family: Montserrat-bold;
        margin: 0px;
        @media only screen and (max-width: 1556px) {
          font-size: 30px;
        }
        @media only screen and (max-width: 900px) {
          font-size: 25px;
        }
        @media only screen and (max-width: 767px) {
          padding: 20px 0px;
          font-size: 20px;
        }
      }
    }
    .about-fold1-down-right-div {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 90px;
      padding-right: 156px;
      background: #29294f;
      width: 70%;

      @media only screen and (max-width: 1556px) {
        padding-left: 40px;
        padding-right: 20px;
      }
      @media only screen and (max-width: 767px) {
        width: 100%;
      }

      h5 {
        font-size: 25px;
        font-family: Montserrat-regular;
        margin: 0px;
        @media only screen and (max-width: 1556px) {
          font-size: 20px;
        }
        @media only screen and (max-width: 900px) {
          font-size: 17px;
        }
        @media only screen and (max-width: 767px) {
          padding: 20px 0px;
          font-size: 16px;
        }
      }
    }
    @media only screen and (max-width: 767px) {
      display: block;
      height: auto;
    }
  }
}

.about-us-fold-two {
  padding-top: 87px;
  padding-bottom: 126px;
  @media only screen and (max-width: 767px) {
    padding-top: 60px;
    padding-bottom: 0px;
  }
  h2 {
    text-align: center;
    font-size: 45px;
    font-family: Montserrat-bold;
    @media only screen and (max-width: 767px) {
      font-size: 22px;
    }
  }
  .ourteam-list {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      background: rgba(17, 19, 22, 0.75);
      border-radius: 49px 0px 0px 49px;
      width: 90%;
      height: 100%;
      top: 0;
      right: 0;
      @media only screen and (max-width: 1440px) {
        width: 98%;
        content: "";
      }
      @media only screen and (max-width: 767px) {
        width: 97%;
        border-radius: 49px 0px 0px 0px;
        right: 0;
        left: auto;
        content: "";
      }
    }
    .our-team-right-div {
      margin-top: vw(97px);
      padding: 51px 0px 30px 0px;
      width: auto;
      height: auto;
      @media only screen and (max-width: 900px) {
        width: 80%;
        margin: auto;
        margin-top: 40px;
      }
      @media only screen and (max-width: 767px) {
        width: 100%;
        padding: 51px 0px 0px 0px;
        margin: auto;
      }
      @media only screen and (max-width: 575px) {
        width: 70%;
      }
    }
  }
  .our-teamleft {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      background: rgba(17, 19, 22, 0.75);
      border-radius: 0px 49px 49px 0px;
      width: 90%;
      height: 100%;
      top: 0;
      left: 0;
      @media only screen and (max-width: 1440px) {
        width: 98%;
        content: "";
      }
      @media only screen and (max-width: 767px) {
        width: 97%;
        border-radius: 0px 0px 0px 49px;
        right: 0;
        left: auto;
        content: "";
      }
    }
    .our-team-left-div {
      margin-top: vw(97px);
      padding: 51px 0px 30px 0px;
      width: auto;
      height: auto;
      @media only screen and (max-width: 900px) {
        width: 80%;
        margin: auto;
        margin-top: 40px;
      }
      @media only screen and (max-width: 767px) {
        width: 40%;
        margin: auto;
        padding: 0px 0px 30px 0px;
      }
      @media only screen and (max-width: 575px) {
        width: 70%;
      }
    }
  }
}
.about-us-fold-three {
  h2 {
    text-align: center;
    font-size: 45px;
    font-family: Montserrat-bold;
    @media only screen and (max-width: 767px) {
      font-size: 22px;
    }
  }
  .ouradvisors-one {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      background: rgba(55, 56, 60, 0.75);
      border-radius: 49px 0px 0px 49px;
      width: 90%;
      height: 100%;
      top: 0;
      right: 0;
      @media only screen and (max-width: 1440px) {
        width: 98%;
        content: "";
      }
      @media only screen and (max-width: 767px) {
        width: 97%;
        content: "";
        border-radius: 0px 49px 0px 0px;
        right: auto;
        left: 0;
      }
    }
    .our-advisor-right-div {
      margin-top: vw(97px);
      padding: 51px 0px 30px 0px;
      width: auto;
      height: auto;
      @media only screen and (max-width: 900px) {
        width: 80%;
        margin: auto;
        margin-top: 40px;
      }
      @media only screen and (max-width: 767px) {
        width: 40%;
        margin: auto;
        padding: 51px 0px 0px 0px;
      }
      @media only screen and (max-width: 575px) {
        width: 70%;
      }
    }
  }
  .ouradvisors-two {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      background: rgba(55, 56, 60, 0.75);
      border-radius: 0px 49px 49px 0px;
      width: 90%;
      height: 100%;
      top: 0;
      left: 0;
      @media only screen and (max-width: 1440px) {
        width: 98%;
        content: "";
      }
      @media only screen and (max-width: 767px) {
        width: 97%;
        content: "";
        border-radius: 0px 0px 49px 0px;
      }
    }
    .our-advisor-left-div {
      margin-top: vw(97px);
      padding: 51px 0px 30px 0px;
      width: auto;
      height: auto;
      @media only screen and (max-width: 900px) {
        width: 80%;
        margin: auto;

        margin-top: 40px;
      }
      @media only screen and (max-width: 767px) {
        width: 80%;
        padding: 0px 0px 30px 0px;
        margin: auto;
      }
      @media only screen and (max-width: 575px) {
        width: 70%;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    padding-top: 60px;
  }
}
.commonlist-main {
  @media only screen and (max-width: 767px) {
    margin-top: 60px;
  }
}

// .our-team-left-div {
//width: vw(1658.49px);
// margin-top: vw(54.17px);
// border-radius: 0 vw(49px) vw(49px) 0;
// padding: vw(51.12px) vw(48.53px) vw(61.83px) vw(100px);
// width: auto;
// height: auto;
// background: rgba(17, 19, 22, 0.75);
// }
// .our-team-img1 {
//   width: vw(413.76px);
//   height: vw(430.4px);
// }

// .our-team-img2 {
//   width: vw(424.46px);
//   height: vw(430.4px);
// }
// .our-team-img3 {
//   width: vw(462.5px);
//   height: vw(430.4px);
// }
// .our-team-img4 {
//   width: vw(430.4px);
//   height: vw(432.78px);
// }
// .our-team-img5 {
//   width: vw(424.46px);
//   height: vw(432.78px);
// }
// .our-team-img6 {
//   width: vw(444.02px);
//   height: vw(432.78px);
// }
// .our-team-img7 {
//   width: vw(420.07px);
//   height: vw(436.97px);
// }
// .our-team-img8 {
//   width: vw(430.94px);
//   height: vw(436.97px);
// }
// .our-team-img12 {
//   width: vw(404.06px);
//   height: vw(409.71px);
// }

.about-us-fold-three {
  padding-bottom: vw(184.56px);
}

.our-advisor-left-div {
  // background: rgba(55, 56, 60, 0.75);
  // padding: vw(57.72px) vw(53.2px) vw(66.3px) vw(128.88px);
  // margin-top: vw(65.81px);
  // border-radius: 0 vw(49px) vw(49px) 0;
}

.about-hover-card {
  position: absolute;
  //width: vw(413px);
  //width: inherit;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  //display: flex;
  //align-items: flex-end;
  background: rgba(30, 34, 37, 0.7);
  //background: rgba(196, 196, 196, 0.24);
  //z-index: 99;
  color: white;
  padding: vw(30px) vw(34px) vw(43px) vw(34px);
  p {
    font-size: 20px;
    line-height: 30px;
    font-family: Montserrat-regular;
    margin-bottom: 0;
    @media only screen and (max-width: 1440px) {
      font-size: 13px;
      line-height: 1.5;
    }
    @media only screen and (max-width: 767px) {
      font-size: 14px;
      line-height: 1.5;
    }
  }
  // @media only screen and (min-width: 1900px){
  //   height: auto;
  // }
  // @media only screen and (max-width: 1600px) {
  //   height: 311px;
  // }
  // @media only screen and (max-width: 1200px) {
  //   height: 256px;
  // }
  // @media only screen and (max-width: 900px) {
  //   height: 200px;
  // }
}

.about-card {
  .about-wrap {
    opacity: 1;
    width: 90%;
    overflow: hidden;
    margin: 0 auto;
    @media only screen and (min-width: 1900px) {
      min-height: auto;
    }
    // @media only screen and (max-width: 1000px) {
    //   width: 100%;
    // }
  }
  .about-img-div {
    @media only screen and (max-width: 575px) {
      height: 350px;
    }
    .aboutimg-inner {
      border: none;
      position: relative;
      overflow: hidden;
      min-height: 430px;
      margin: 0 24px 48px 24px;
      @media only screen and (max-width: 3000px) and (min-width: 2198px) {
        min-height: 600px;
      }
      @media only screen and (max-width: 1000px) and (min-width: 768px) {
        margin: 0 0 28px 0;
      }
      @media only screen and (max-width: 768px) and (min-width: 575px) {
        margin: auto;
        width: 370px;
      }
      @media only screen and (max-width: 575px) {
        height: 350px;
        margin: 0;
      }
      img {
        width: 100%;
        height: 430px;
        object-fit: cover;
        // height: 100%;
        // @media only screen and (max-width: 3000px) and (min-width: 1670px) {
        //   width: 100%;
        //   height: 600px;
        // }
        // @media only screen and (max-width: 1670px) and (min-width: 1440px) {
        //   width: 100%;
        //   height: 350px;
        // }
        // @media only screen and (max-width: 1440px) and (min-width: 1024px) {
        //   width: 100%;
        //   height: 370px;
        // }
        // @media only screen and (max-width: 1024px) and (min-width: 768px) {
        //   width: 100%;
        //   height: 260px;
        // }
        // @media only screen and (max-width: 768px) and (min-width: 575px) {
        //   width: 100%;
        //   height: 200px;
        // }
        // @media only screen and (max-width: 575px) {
        //   width: 100%;
        //   height: auto;
        // }
        @media only screen and (min-width: 3000px) {
          height: 600px;
        }
        @media only screen and (max-width: 3000px) and (min-width: 2198px) {
          height: 600px;
        }
        @media only screen and (max-width: 575px) {
          height: 350px;
        }
      }
    }
  }
  .about-hover-card {
    opacity: 0;
    transition: 0.5s;
    @media only screen and (max-width: 575px) {
      height: 350px;
    }
  }
  .card-text {
    margin-left: 20px;
    position: relative;
    h4 {
      font-size: 36px;
      font-family: Montserrat-bold;
      @media only screen and (max-width: 1556px) {
        font-size: 30px;
      }
      @media only screen and (max-width: 1200px) {
        font-size: 25px;
      }
      @media only screen and (max-width: 900px) {
        font-size: 20px;
      }
    }
    h5 {
      font-size: 25px;
      font-family: Montserrat-regular;
      @media only screen and (max-width: 1556px) {
        font-size: 20px;
      }
      @media only screen and (max-width: 1556px) {
        font-size: 15px;
      }
      @media only screen and (max-width: 900px) {
        font-size: 12px;
      }
    }
    @media only screen and (max-width: 767px) {
      margin: 0px;
      margin-top: 20px;
      text-align: center;
    }
  }
  &:hover {
    .about-img-div {
      opacity: 0.5;
      //opacity: 0;
    }
    .about-hover-card {
      opacity: 1;
      z-index: 99;
    }
  }
  @media only screen and (max-width: 767px) {
    margin-bottom: 50px;
  }
}
// .about-card--3 {
//   .about-hover-card {
//     height: vw(410px);
//   }
// }
// .card1-width {
//   width: vw(413.76px);
// }
// .card2-width {
//   width: vw(424.46px);
// }
// .card3-width {
//   width: vw(463px);
// }
// .card4-width {
//   width: vw(430.4px);
//   height: vw(432.78px);
// }
// .card5-width {
//   width: vw(424.46px);
//   height: vw(432.78px);
// }
// .card6-width {
//   width: vw(444.02px);
//   height: vw(432.78px);
// }
// .card7-width {
//   width: vw(420.07px);
//   height: vw(436.97px);
// }
// .card8-width {
//   width: vw(430.94px);
//   height: vw(436.97px);
// }
// .card12-width {
//   width: vw(404.06px);
//   height: vw(409.71px);
// }

// .our-team-img9,
// .about-hover-card.card9-width {
//   width: vw(424px);
//   height: vw(427px);
// }
// .our-team-img10,
// .about-hover-card.card10-width {
//   width: vw(396px);
//   height: vw(427px);
// }
// .our-team-img11,
// .about-hover-card.card11-width {
//   width: vw(416.51px);
//   height: vw(427px);
// }
