.vl-select-container {
  width: vw(120px);
  height: vw(20px);
  margin-bottom: vw(32px);

  .vl-select {
    &__control {
      font-size: vw(16px) !important;
      line-height: vw(19.5px);
      @include fontMontserratBlack;
      border: none !important;
      box-shadow: none;
      border-radius: vw(4px);
      background: transparent !important;
      padding: 0;
      min-height: -webkit-fill-available !important;
      border: 1px solid #00000026;
      outline: none !important;
      color: rgba(0, 0, 0, 1);
      @media only screen and (max-width: 900px) {
        font-size: 13px;
      }
      &:focus {
        border: none !important;
        outline: none !important;
      }
    }

    &__value-container {
      padding: 0 !important;
    }

    &__placeholder {
      color: rgba(0, 0, 0, 0.78) !important;
    }
    &__single-value {
      color: rgba(0, 0, 0, 0.78) !important;
    }
    &__indicator,
    &__multi-value__remove {
      color: #040404 !important;
      padding: 0 !important;
      padding-left: vw(5px) !important;
      padding-right: vw(5px) !important;

      svg {
        width: vw(18px);
        height: vw(18px);
        @media only screen and (max-width: 900px) {
          width: 13px !important;
          height: 13px !important;
        }
      }
    }

    &__multi-value__remove:hover {
      background-color: rgba(0, 0, 0, 0.1) !important;
    }

    &__indicator-separator {
      display: none !important;
    }
    // &__option--is-selected {
    //   background-color: lightblue !important;
    // }

    &__menu {
      margin-top: vw(5px);
      z-index: 2;
    }

    &__menu,
    &__option,
    &__option--is-focused,
    &__option--is-selected {
      //min-width: min-content;
      min-width: max-content;
    }

    &__menu-list {
      padding: 0 !important;
      color: #000000 !important;
      font-size: vw(16px) !important;
      line-height: vw(19.5px);
      @include fontRobotoBold;
    }
  }

  // .vl-select__menu {
  //   background-color: #ffffff;
  //   font-size: vw(16px);
  //   line-height: vw(18.75px);
  //   @include fontRobotoItalic;
  // }

  .vl-select__option {
    color: #000000;
    // padding: vw(16px) vw(30px) vw(7px) vw(30px);
    // @include fontRobotoItalic;
    @media only screen and (max-width: 768px) {
      font-size: 13px;
    }
  }

  .vl-select__option--is-focused,
  .vl-select__option:hover {
    color: #000000;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .vl-select__option--is-selected {
    color: #ffffff;
    background-color: #292950;
  }
}

.vl-form-input--bordered-select-div {
  height: vw(58px);
  margin-bottom: vw(60px);
  @media only screen and (max-width: 900px) {
    height: 40px;
    .vl-select__single-value {
      font-size: 13px;
    }
  }
  .vl-select__value-container {
    .css-b8ldur-Input {
      input {
        @media only screen and (max-width: 900px) {
          font-size: 13px;
          line-height: 1;
        }
      }
    }
  }

  .vl-select-container {
    width: inherit;
    height: inherit;
    background-color: $form-field-background;
    border: vw(2px) solid $form-field-border-color;
    border-radius: $form-field-border-radius;

    .vl-select {
      &__control {
        height: 100%;
        padding-left: vw(34px);
        padding-right: vw(20px);
        font-size: $form-field-font-size !important;
        line-height: $form-field-line-height;
        @include fontRobotoBold;
      }

      &__placeholder {
        color: rgba(0, 0, 0, 0.48) !important;
        font-size: $form-field-font-size !important;
        // @include fontRobotoBold;
        @include fontRobotoLight;
        font-style: italic;
        @media only screen and (max-width: 900px) {
          font-size: 13px !important;
        }
      }
    }

    .vl-select__single-value {
      color: #000000 !important;
      line-height: 1;
    }

    .vl-select__multi-value__label {
      color: #000000 !important;
      padding: 5px;
      padding-left: 10px;
      font-size: $form-field-font-size !important;
      line-height: $form-field-line-height;
      @include fontRobotoBold;
      @media only screen and (max-width: 900px) {
        font-size: 10px !important;
        line-height: 1.3;
      }
    }
  }

  .vl-select-container.vl-select--is-disabled {
    border-color: $form-field-disabled-border-color;

    // .vl-select__indicator {
    //   display: none;
    // }
  }
  @media only screen and (max-width: 900px) {
    height: 40px;
    margin-bottom: 20px !important;
    width: 100% !important;
    max-width: 100%;
    .vl-select-container {
      .vl-select__control {
        font-size: 12px !important;
      }
      .select-industry-dropdown {
        height: auto;
      }
      .vl-select__menu {
        ul {
          li {
            font-size: 13px;
          }
        }
      }
    }
  }
}
.funding-details-page-main {
  .vl-select-container {
    height: auto;
    .vl-select__control {
      font-size: 12px !important;
      padding-top: 12px;
      padding-bottom: 12px;
    }
    .select-industry-dropdown {
      height: auto;
    }
    .vl-select__menu {
      ul {
        li {
          font-size: 13px;
        }
      }
    }
  }
}
.traction-input-main {
  .vl-select-container {
    height: auto;
    width: 100%;
    max-width: 100%;
    .vl-select__control {
      font-size: 12px !important;
      padding-top: 20px;
      padding-bottom: 12px;
    }
    .select-industry-dropdown {
      height: auto;
    }
    .vl-select__menu {
      ul {
        li {
          font-size: 13px;
        }
      }
    }
  }
}

.raise-capital-basic-div
  .vl-form-input.vl-form-input.vl-form-input--bordered-select-div {
  margin-bottom: vw(50px);
}

.vl-dropdown-common {
  .vl-select-container {
    margin-bottom: vw(5px);
  }
}

.vl-form-input--bordered-select-div--multiSelect {
  min-height: vw(58px);
  height: vw(58px);
  @media only screen and (max-width: 900px) {
    min-height: 40px;
    height: 40px;
  }
}
