.vl-know-before-you-invest-web {
  // .know-before-you-invest-text-block {
  //   padding: vw(100px) vw(174px) vw(125px) vw(88px);
  // }

  .faq-second-div {
    padding: vw(44px) vw(203px) vw(152px) vw(92px);

    .accordion__button,
    .accordion__panel {
      color: $vl-web-app-pages-text-color;
    }

    .accordion .accordion__item {
      border-bottom-color: $vl-web-app-pages-text-color;
    }

    .accordion__panel {
      padding-right: vw(150px);
    
    }
  }

  .faq-second-div--inner-accordion {
    padding: 0 0 0 vw(41px);
  }

  .faq-second-div--inner-accordion .accordion__panel {
    padding-right: vw(257px);
  }
}
