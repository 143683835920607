.vl-display-loader-div {
  width: inherit;
  height: inherit;
  text-align: center;

  div {
    width: inherit;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: vw(50px);
      height: auto;
      margin-bottom: vw(3px);
    }
  }
}
