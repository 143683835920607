.career-main {
  background-color: #ffffff;
  color: #292950;
  .nav-bar {
    background-color: #fff;
    box-shadow: 0 0 15px #00000030;
    .navbar-brand {
      .header-logo {
        @media only screen and (max-width: 1124px) {
          width: 170px;
          height: auto;
        }
      }
    }
  }
  .career-page-wrapper {
    position: relative;
    margin-top: 95px;
    @media only screen and (max-width: 1125px) {
      margin-top: 75px;
    }
    .vl-footer {
      .vl-footer-bg {
        background: #171824;
      }
    }
    .career-profile-details {
      // height: calc(100vh - 95px);
      .card-details {
        margin-top: 15px;
        .career-title {
          color: #4b5058;
          font-size: 2.5rem;
          border-bottom: 1px solid #d1d1d1;
          margin: 0;
          padding: 30px 0;
          font-weight: 700;
          text-transform: capitalize;
          @media only screen and (max-width: 768px) {
          }
          @media only screen and (max-width: 575px) {
            font-size: 1.8rem;
            padding: 20px 0;
          }
        }
        .user-name {
          margin: 40px 0 0 30px;
          @media only screen and (max-width: 1200px) {
          }
          @media only screen and (max-width: 575px) {
            margin: 20px 0 0 10px;
          }
          .main-title {
            font-size: 30px;
            @media only screen and (max-width: 575px) {
              font-size: 22px;
              margin: 25px 0 0 0;
            }
          }
        }
        .submit-data {
          background-color: #fff;
          border: 1px solid #000;
          border-radius: 8px;
          color: #000 !important;
          display: inline-block;
          font-size: 15px;
          font-weight: 700;
          margin: 15px 0 0 30px;
          line-height: 1.2;
          padding: 10px 32px;
          width: fit-content;
          transition: all ease-in-out 0.3s;
          &:hover {
            background-color: #292950;
            border: 1px solid #292950;
            color: #ffffff !important;
          }
          @media only screen and (max-width: 575px) {
            margin: 20px 0 0 10px;
          }
        }
      }
    }
    .main-title {
      font-size: 30px;
      line-height: 1.2;
      font-weight: 600;
      font-family: Roboto-Bold, sans-serif, Helvetica;
      color: #4b5058;
      @media only screen and (max-width: 768px) {
        font-size: 28px;
      }
      @media only screen and (max-width: 575px) {
        font-size: 24px;
        letter-spacing: 0.8px;
        margin: 10px;
      }
    }
    .education-title {
      color: #292950;
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 0.7px;
      line-height: 1.5;
      width: 100%;
      text-transform: capitalize;
      @media only screen and (max-width: 575px) {
        font-size: 15px;
        font-weight: 800;
      }
    }
    .education-subtitle {
      color: #6d6d70;
      font-size: 16px;
      margin: 0;
      @media only screen and (max-width: 575px) {
        font-size: 15px;
      }
    }
    .discription {
      margin: 0;
      ul {
        margin-left: 25px;
        li {
          margin: 0 0 0 6px;
          color: #6d6d70;
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 0.7px;
          text-transform: capitalize;
          line-height: 1.5;
          @media only screen and (max-width: 575px) {
            font-size: 15px;
          }
        }
      }
      p {
        margin: 0;
        color: #6d6d70;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.7px;
        line-height: 1.5;
        @media only screen and (max-width: 575px) {
          font-size: 15px;
        }
      }
    }
    @media only screen and (max-width: 1052px) {
      margin-top: 85px;
    }
    @media only screen and (max-width: 1052px) {
      margin-top: 70px;
    }
    .career-back-btn {
      position: absolute;
      left: 32px;
      top: 30px;
      @media only screen and (max-width: 1048px) and (min-width: 992px) {
        left: 15px;
        top: 20px;
      }

      @media only screen and (max-width: 768px) {
        left: 20px;
        top: 20px;
        z-index: 10;
      }
      @media only screen and (max-width: 576px) {
        left: 15px;
        top: 15px;
        z-index: 10;
      }
      i {
        font-size: 35px;

        color: #4b5058;

        @media only screen and (max-width: 576px) {
          font-size: 35px;
        }
      }
    }
    .vl-footer {
      .footer-knowledge-div {
        // @media only screen and (min-width: 300px) and (max-width: 575px) {
        //   width: 100%;
        //   border-top: 1px solid #000;
        // }
      }
    }
  }
}
.career-main-page {
  width: 100%;
  background-color: #32327742;
  .vl-navbar {
    padding: 15px !important;
    background-color: #11131603;
  }
  .career-main {
    width: 100%;
    margin: auto;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .career-we-hiring {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    .main-title {
      font-size: 50px;
      line-height: 1.2;
      font-weight: 900;
      font-family: sans-serif;
      margin-bottom: 20px;
      letter-spacing: 0.8px;
      text-align: center;
      @media only screen and (max-width: 1024px) {
        font-size: 40px;
      }
      @media only screen and (max-width: 768px) {
        font-size: 28px;
        padding: 10px;
      }
      @media only screen and (max-width: 575px) {
        font-size: 24px;
        letter-spacing: 0.8px;
        margin: 10px 10px 30px 10px;
      }
    }
    .title-sub {
      font-size: 23px;
      text-align: justify;
      width: 80%;
      color: #fff;
      line-height: 1.2;
      margin: 0 0 5px 0;
      @media only screen and (max-width: 1024px) {
        font-size: 18px;
      }
      @media only screen and (max-width: 768px) {
        font-size: 13px;
        width: 80%;
        margin: 0;
      }
      @media only screen and (max-width: 575px) {
        width: 100%;
      }
    }
    @media only screen and (max-width: 575px) {
      margin: 25px;
    }
  }
  .main-bg {
    .career-heading {
      text-align: center;
      font-size: 2.5rem;
      color: #fff;
      margin-top: 40px;
      @media only screen and (max-width: 768px) {
        margin-bottom: 0;
      }
      @media only screen and (max-width: 575px) {
        font-size: 28px;
        letter-spacing: 0.8px;
        margin: 20px 0 10px 0;
      }
    }
    .career-management {
      padding: 30px 100px;
      .career-data {
        .career-card {
          position: relative;
          padding: 0;
          text-align: left;
          border-radius: 4px;
          color: #000;
          background-color: #f7f7f7;
          transition: all 0.4s;
          margin: 0 auto;
          .content-wrapper {
            padding: 1rem 1rem 1rem 1rem;
            .career-title {
              cursor: pointer;
              width: fit-content;
              color: #292950;
              font-size: 22px;
              line-height: 35px;
              font-weight: 500;
              font-family: "Roboto-Bold", sans-serif, "Helvetica";
              // white-space: nowrap;
              margin-bottom: 12px;
              &:hover {
                text-decoration: underline;
              }
              @media only screen and (max-width: 575px) {
                font-size: 19px;
              }
              @media only screen and (max-width: 380px) {
                margin: 10px 0 4px 0;
              }
            }
            .education,
            .position {
              display: flex;
              // justify-content: space-between;
              gap: 16px;
            }
            .discription {
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              display: block;
              display: -webkit-box;
              font-family: Roboto-regular;
              font-size: 19px;
              line-height: 24px;
              margin-bottom: 0;
              max-width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              margin-bottom: 15px;
            }
            .career-skills {
              color: #292950;
              font-size: 22px;
              font-weight: 500;
              font-family: "Roboto-Bold", sans-serif, "Helvetica";
              margin: 18px 0 4px 0;
              line-height: 1.5;
              @media only screen and (max-width: 768px) {
                font-size: 19px;
              }
              @media only screen and (max-width: 575px) {
                font-size: 19px;
              }
            }
          }
          .submit-data {
            border: none;
            width: fit-content;
            padding: 0;
            position: absolute;
            bottom: 25px;
            transform: translate(-50%, 0);
            left: 50%;
            z-index: 100;
            padding: 10px 32px;
            font-size: 15px;
            border: 1px solid #000;
            line-height: 1.2;
            color: #000000 !important;
            background-color: #fff;
            font-weight: 700;
            border-radius: 8px;
            display: inline-block;
            border: 1px solid #000;
            // -webkit-transition: all 200ms ease-in;
            // -webkit-transform: scale(1);
            // -ms-transition: all 200ms ease-in;
            // -ms-transform: scale(1);
            // -moz-transition: all 200ms ease-in;
            // -moz-transform: scale(1);
            // transition: all 200ms ease-in;
            // transform: scale(1);
            &:hover {
              background-color: #292950;
              color: #fff !important;
              border: none;
              // -webkit-transition: all 200ms ease-in;
              // -webkit-transform: scale(1.5);
              // -ms-transition: all 200ms ease-in;
              // -ms-transform: scale(1.5);
              // -moz-transition: all 200ms ease-in;
              // -moz-transform: scale(1.5);
              // transition: all 200ms ease-in;
              // transform: scale(1.1);
            }
            @media only screen and (max-width: 768px) {
              padding: 9px 20px;
            }
            @media only screen and (max-width: 575px) {
              padding: 8px 16px;
              font-size: 14px;
            }
          }
          &:hover {
            box-shadow: 6px 0 25px #00000033;
          }
          @media only screen and (max-width: 575px) {
            width: 95%;
          }
          @media only screen and (max-width: 768px) {
            width: 100%;
          }
        }
        .career-img {
          max-width: 100%;
          width: 100%;
          height: 255px;
          object-fit: cover;
        }
        ul {
          width: auto;
          text-align: left;
          li {
            word-break: break-all;
            color: #6d6d70;
            width: auto;
            text-align: left;
            margin-left: 20px;
          }
        }
        .career-education,
        .career-position {
          font-size: 17px;
          color: #292950;
          line-height: 1.2;
          font-weight: 500;
          font-family: "Roboto-regular";
          margin: 0;
          @media only screen and (max-width: 575px) {
            font-size: 15px;
            width: 50%;
          }
          .career-position-sub {
            font-size: 17px;
            line-height: 21px;
            font-weight: 500;
            color: #292950;
            @media only screen and (max-width: 575px) {
              font-size: 15px;
            }
          }
        }
      }
      @media only screen and (max-width: 1024px) {
        padding: 0;
      }
      @media only screen and (max-width: 768px) {
        padding: 15px;
      }
      @media only screen and (max-width: 575px) {
        padding: 0 15px;
      }
    }
  }
  .career {
    display: flex;
    padding: 60px 0 0 0;
    background-color: #455051;
    align-items: center;
    .career-wrapper {
      display: flex;
      padding: 50px;
      background-color: rgba(17, 19, 22, 0.75);
      @media only screen and (max-width: 1000px) {
        padding: 50px 0 50px 25px;
      }
      @media only screen and (max-width: 575px) {
        flex-flow: wrap;
        border-radius: 0;
        margin-right: 0;
      }
      @media only screen and (max-width: 768px) {
        padding: 20px;
      }
    }
    @media only screen and (max-width: 575px) {
      margin: 0;
      padding: 0;
    }
  }
  .discription {
    p {
      color: #6d6d70;
    }
  }
  .content-wrap {
    background-color: #455051;
  }
}
.react-responsive-modal-root {
  .react-responsive-modal-container {
    .career-model {
      overflow: hidden;
      max-width: 800px;
      height: 50%;
      width: 750px;
      border-radius: 18px;
      .skills-name {
        // color: #000000;
        color: #292950;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0.7px;
        text-transform: capitalize;
        line-height: 1.5;
        .main-title {
          font-size: 26px;
        }
        .education-subtitle {
          color: #6d6d70;
          font-size: 16px;
          margin: 0;
        }
        .user-title {
          text-align: center;
          color: #000000;
          font-size: 24px;
          font-weight: 800;
          letter-spacing: 0.7px;
          text-transform: capitalize;
          line-height: 1.5;
        }
        .discription {
          margin: 20px 0 0 0;
          p {
            margin: 0 0 0 6px;
            color: #6d6d70;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0.7px;
            line-height: 1.5;
          }
        }
      }
      ul {
        margin-left: 25px;
        margin-top: 20px;
        li {
          margin: 0 0 0 6px;
          color: #6d6d70;
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 0.7px;
          text-transform: capitalize;
          line-height: 1.5;
        }
      }
      .react-responsive-modal-closeButton {
        position: absolute;
        top: 15px;
        right: 15px;
        border: none;
        padding: 0;
        cursor: pointer;
        background-color: transparent;
      }
    }
  }
}
