.vl-raise-capital-main-page {
  padding-top: vw(95px);
  .react-tabs {
    ul {
      li {
        @media only screen and (max-width: 900px) {
          font-size: 13px;
          line-height: 1.3;
        }
      }
    }
    .react-tabs__tab
      .vl-tabs-explore--main-mentor-page-profile-tabs--raiseCapital {
      .react-tabs {
        .react-tabs__tab-list {
          li {
            @media only screen and (max-width: 900px) {
              font-size: 12px;
              padding: 5px 20px;
              width: auto;
            }
          }
        }
      }
    }
  }
}

.vl-tabs-explore--main-mentor-page-profile-tabs--raiseCapital {
  .react-tabs__tab-list {
    margin-bottom: vw(53px);
    @media only screen and (max-width: 575px) {
      margin-bottom: 30px;
    }
  }
}

.vl-raise-capital-updates-post-details {
  .input-border-div {
    border: none;
    background-color: #dadadb;
    border-radius: 0;

    textarea {
      padding: vw(15px);
    }
  }

  .vl-form-input {
    width: 100%;
    max-width: vw(900px);
  }
}

.vl-raise-capital-updates-post-details__post-btn-div {
  margin-left: vw(30px);

  .vl-common-save-btn,
  .vl-common-skip-btn {
    width: vw(150px);
    height: vw(58px);
    font-size: vw(21px);
    @include fontRobotoMedium;
    @media only screen and (max-width: 900px) {
      font-size: 15px;
      line-height: 1.3;
      height: auto;
      width: auto;
      padding: 10px 30px;
    }
  }
}

.vl-raise-capital-updates-post-details--edit {
  margin-top: vw(15px);
  .error-message {
    display: none;
  }
}
.tab_list_wrap {
  padding: 0;
  .react-tabs__tab {
    @media screen and (max-width: 768px) {
      width: 50%;
    }
    &:focus {
      box-shadow: none;
    }
  }
  .tab_lists{

  }
}
