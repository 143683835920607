.vl-blog-div {
  background: #f7f7f7;
  color: black;
  min-height: 100vh;

  .footer-row-div {
    display: none;
  }
}

.vl-blog-title {
  font-size: vw(60px);
  line-height: vw(73.14px);
  @include fontRobotoBold;
  color: #1e2225;
  margin-bottom: 0;
  padding-top: vw(43px);
}

.vl-blog-author {
  font-size: vw(25px);
  line-height: vw(30px);
  @include fontRobotoBold;
  margin-bottom: vw(62px);
  color: #1e2225;
}
.vl-blog-img {
  width: vw(1618px);
  height: auto;
}
.knowledge-base-blog-footer-row-div {
  width: 100%;
  // height: 100px;
  // height: vw(120.17px);
  .footer-knowledge-div {
    width: 25%;
    padding: 47px;
    @media only screen and (max-width: 900px) {
      width: 100%;
      height: auto;
    }
  }
  .footer-top-right-div {
    width: 75%;
    padding-right: vw(95px);
    @media only screen and (max-width: 900px) {
      width: 100%;
    }
    @media only screen and (max-width: 575px) {
      padding: 20px;
    }
  }
  @media only screen and (max-width: 900px) {
    height: auto;
  }
}
.vl-blog-details-card-div {
  margin: vw(107px) vw(160px) vw(92px) vw(160px);
  overflow-x: auto;
  .font-25-montserrat-bold {
    padding-right: vw(100px);
  }
  .kd-card {
  }
}
.blogtitle-head {
  position: relative;
  .investing-startups-lines {
    display: none;
    position: absolute;
    top: 9px;
    i {
      font-size: 15px;
      line-height: 1.3;
    }
    @media only screen and (max-width: 900px) {
      display: block;
    }
    @media only screen and (max-width: 767px) {
      top: 6px;
    }
  }
}
