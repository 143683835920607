@import "react-responsive-modal/styles.css";

.customCloseButton {
  display: none !important;
}

.closeIconInModal {
  cursor: pointer;

  &::before,
  &::after {
    content: " ";
    position: absolute;
    top: vw(30px);
    right: vw(40px);
    background-color: #848484;
    height: vw(25px);
    width: vw(4px);
    z-index: 111 !important;
    @media screen and (max-width: 768px) {
      height: 14px !important;
      right: 15px;
    }
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

.customOverlay {
  background: rgba(255, 255, 255, 0.59);
  z-index: 0;
}

.customModal {
  -webkit-box-shadow: 0 0 0;
  box-shadow: 0 0 0;
  max-width: 90%;
  height: fit-content;
  color: #000000;
  background-color: #f0f0f3 !important;
}

.customModal--modalStructure {
  width: vw(1082px) !important;
  border-radius: vw(15px) !important;
  padding: vw(30px) !important;
  box-shadow: 0 vw(3px) vw(50px) 0 rgba(0, 0, 0, 0.16) !important;
}
.reserveContainer {
  margin-top: 30px;
  h5 {
    font-size: 1rem;
  }
}
.reserveContainer .vl-form-input {
  margin: auto;
}
.customModal--profile-review {
  width: vw(1107px) !important;
  border-radius: vw(4px) !important;
  background-color: #292950 !important;
}
.reserveModal--profile-review {
  // width: vw(1107px) !important;
  border-radius: vw(4px) !important;
  // background-color: #292950 !important;
}
.reserveModal--reserve-success {
  width: 500px !important;
  border-radius: vw(15px) !important;
  // background-color: #292950 !important;
}
.customModal--imageCrop {
  max-width: vw(952px) !important;
  width: vw(952px) !important;
  border-radius: vw(15px) !important;
  padding: vw(30px) !important;
  background-color: #292950 !important;
  // box-shadow: 0 vw(3px) vw(50px) 0 rgba(0, 0, 0, 0.16) !important;
}

.customModal--pitch-deck-pdf {
  // max-width: vw(1082px) !important;
  // width: vw(1082px) !important;
  width: 90%;
  margin: auto;
  height: auto !important;
  border-radius: vw(15px) !important;
  padding: vw(76px) vw(30px) vw(39px) vw(30px) !important;
  background-color: #292950 !important;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.customModal--pitch-video {
  max-width: vw(1082px) !important;
  width: vw(1082px) !important;
  height: auto !important;
  border-radius: vw(15px) !important;
  padding: vw(5px) vw(80px) vw(60px) vw(80px) !important;
  background-color: #292950 !important;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}
.invester-review-page-main {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .review-page-image-logo {
    height: 110px;
    width: 130px;
    margin-bottom: 15px;
  }
  .review-page-text {
    margin: 0 0 20px;
    color: rgba(0, 0, 0, 0.69);
    font-size: 24px;
    font-weight: 800;
    font-family: "Montserrat-ExtraBold", sans-serif, "Helvetica";
    @media screen and (max-width: 375px) {
      font-size: 20px;
    }
  }
  .review-explore-btn-main {
    button {
      border-radius: 59px;
      border: 1px solid #feffff;
      background: #4e4e51;
      padding: 10px 50px;
      border: 1px solid #4e4e51;
      font-family: "Montserrat-Regular", sans-serif, "Helvetica";
      &:focus {
        box-shadow: none;
      }
      &:active {
        background-color: #4e4e51;
        border-color: #4e4e51;
      }
    }
  }
}
